import criteria from "../../../utils/criteria";
import { livingArrangementsTypes, employmentTypes } from "../../../constants";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } = livingArrangementsTypes;
const { FULL_TIME, PART_TIME, CASUAL, CONTRACT, SELF_EMPLOYED } = employmentTypes;

const {
  assetValueBetween,
  assetType,
  ageOfAssetAtEndLessThan,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
  termIsLessThan,
  assetCondition,
  creditScoreLessThan,
} = criteria;

const base = [
  {
    name: "Tier A",
    rate: 8.85,
    productType: "Standard",
    criteria: [
      assetCondition(["New", "Demo"]),
      livingArrangements([PROPERTY_OWNER]),
      employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT]),
      timeOfEmploymentGreaterThan(24),
      creditScoreGreaterThan(750),
    ],
  },
  {
    name: "Tier A",
    rate: 9.85,
    productType: "Standard",
    criteria: [
      assetCondition(["Used"]),
      livingArrangements([PROPERTY_OWNER]),
      employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT]),
      timeOfEmploymentGreaterThan(24),
      creditScoreGreaterThan(750),
    ],
  },

  {
    name: "Tier B",
    rate: 9.5,
    productType: "Standard",
    criteria: [
      assetCondition(["New", "Demo"]),
      livingArrangements([PROPERTY_OWNER]),
      employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(24),
      creditScoreGreaterThan(550),
    ],
  },
  {
    name: "Tier B",
    rate: 10.5,
    productType: "Standard",
    criteria: [
      assetCondition(["Used"]),
      livingArrangements([PROPERTY_OWNER]),
      employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(24),
      creditScoreGreaterThan(550),
    ],
  },

  {
    name: "Tier C",
    rate: 9.5,
    productType: "Standard",
    criteria: [
      assetCondition(["New", "Demo"]),
      livingArrangements([PROPERTY_OWNER]),
      employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(12),
      creditScoreGreaterThan(450),
    ],
  },
  {
    name: "Tier C",
    rate: 10.5,
    productType: "Standard",
    criteria: [
      assetCondition(["Used"]),
      livingArrangements([PROPERTY_OWNER]),
      employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(12),
      creditScoreGreaterThan(450),
    ],
  },

  {
    name: "Tier D",
    rate: 12.85,
    productType: "Standard",
    criteria: [
      assetCondition(["New", "Demo"]),
      livingArrangements([PROPERTY_OWNER]),
      employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(12),
      creditScoreLessThan(451),
    ],
  },
  {
    name: "Tier D",
    rate: 13.5,
    productType: "Standard",
    criteria: [
      assetCondition(["Used"]),
      livingArrangements([PROPERTY_OWNER]),
      employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(12),
      creditScoreLessThan(451),
    ],
  },

  {
    name: "Tier A",
    rate: 10.2,
    productType: "Standard",
    criteria: [
      assetCondition(["New", "Demo"]),
      livingArrangements([RENTING, LIVING_WITH_PARENTS, BOARDING]),
      employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT]),
      timeOfEmploymentGreaterThan(24),
      creditScoreGreaterThan(750),
    ],
  },
  {
    name: "Tier A",
    rate: 11.35,
    productType: "Standard",
    criteria: [
      assetCondition(["Used"]),
      livingArrangements([RENTING, LIVING_WITH_PARENTS, BOARDING]),
      employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT]),
      timeOfEmploymentGreaterThan(24),
      creditScoreGreaterThan(750),
    ],
  },

  {
    name: "Tier B",
    rate: 11.0,
    productType: "Standard",
    criteria: [
      assetCondition(["New", "Demo"]),
      livingArrangements([RENTING, LIVING_WITH_PARENTS, BOARDING]),
      employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(24),
      creditScoreGreaterThan(550),
    ],
  },
  {
    name: "Tier B",
    rate: 12.0,
    productType: "Standard",
    criteria: [
      assetCondition(["Used"]),
      livingArrangements([RENTING, LIVING_WITH_PARENTS, BOARDING]),
      employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(24),
      creditScoreGreaterThan(550),
    ],
  },

  {
    name: "Tier C",
    rate: 12.2,
    productType: "Standard",
    criteria: [
      assetCondition(["New", "Demo"]),
      livingArrangements([RENTING, LIVING_WITH_PARENTS, BOARDING]),
      employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(12),
      creditScoreGreaterThan(450),
    ],
  },
  {
    name: "Tier C",
    rate: 13.55,
    productType: "Standard",
    criteria: [
      assetCondition(["Used"]),
      livingArrangements([RENTING, LIVING_WITH_PARENTS, BOARDING]),
      employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(12),
      creditScoreGreaterThan(450),
    ],
  },

  {
    name: "Tier D",
    rate: 13.55,
    productType: "Standard",
    criteria: [
      assetCondition(["New", "Demo"]),
      livingArrangements([RENTING, LIVING_WITH_PARENTS, BOARDING]),
      employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(12),
      creditScoreLessThan(451),
    ],
  },
  {
    name: "Tier D",
    rate: 14.15,
    productType: "Standard",
    criteria: [
      assetCondition(["Used"]),
      livingArrangements([RENTING, LIVING_WITH_PARENTS, BOARDING]),
      employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(12),
      creditScoreLessThan(451),
    ],
  },
];

function motorVehicles(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      assetValueBetween(5000, 150000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      ageOfAssetAtEndLessThan(19),
    ],
  }));
}

function caravans(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      assetValueBetween(5000, 110000),
      assetType(["Caravan"]),
      ageOfAssetAtEndLessThan(16),
    ],
  }));
}

function traileredBoat(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      assetValueBetween(5000, 100000),
      assetType(["Trailered boat"]),
      ageOfAssetAtEndLessThan(16),
    ],
  }));
}

function motorBike(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      assetValueBetween(5000, 55000),
      assetType(["Motorbike"]),
      ageOfAssetAtEndLessThan(16),
    ],
  }));
}

function atvs(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      assetValueBetween(5000, 50000),
      assetType(["ATV/Off-Road Bike"]),
      ageOfAssetAtEndLessThan(16),
    ],
  }));
}

function tractorsHorseFloatsRideOnMowers(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      assetValueBetween(5000, 55000),
      assetType(["Ride on mower", "Horse float", "Tractor"]),
      ageOfAssetAtEndLessThan(16),
    ],
  }));
}

function maxTerm(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, termIsLessThan(61)],
  }));
}

const a = [
  ...maxTerm([
    ...motorVehicles(base),
    ...traileredBoat(base),
    ...caravans(base),
    ...motorBike(base),
    ...atvs(base),
    ...tractorsHorseFloatsRideOnMowers(base),
  ]),
];
export default a;
