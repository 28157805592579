import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
// import _ from "lodash";
import * as Yup from "yup";
import { debounce } from "lodash";
import regex from "../../utils/regex";
import { expensesTypeList, frequencyList, validFrequencyList } from "../../constants";
import { NumericFormatCustom, currencyMaskedValue } from "../../utils/currencyMaskFormat";
import {
  saveValue,
  createExpense,
  // createFinanceItem,
  deleteExpense,
  // deleteFinanceItem,
  updateExpense,
  // updateExpenses,
  // updateExpensesList,
  userDetailsSelector,
  setRequiredFieldsErrors,
} from "../../store/slices/applicationFormSlice";
import { userPermissionsSelector } from "../../store/slices/userSlice";
import { customerSelector } from "../../store/slices/customerSlice";

export default function Expenses({ expenses, customerId, applicationId, personalFinLoaders, customerIndex }) {
  const dispatch = useDispatch();
  const permissions = useSelector(userPermissionsSelector);
  const { isCustomerLogin } = useSelector(customerSelector);
  const isDisable = isCustomerLogin ? true : permissions?.application?.edit;

  // const [newExpenseName, setNewExpenseName] = useState("");
  const { requireFieldErrors } = useSelector(userDetailsSelector);
  const [expensesList, setExpensesList] = useState(expenses || [])
  // const { consumerFieldErrors } = lenderRequiredFieldsError;

  useEffect(() => {
    if (expenses?.length > 0) {
      setExpensesList(expenses)
    }
  }, [expenses])

  const [changeDetected, setChangeDetected] = useState(false);
  const isAddExpenseLoader = personalFinLoaders?.addExpenseLoader && personalFinLoaders?.id === customerId;

  // const [expenseFieldErrors, setExpenseFieldErrors] = useState({
  //   expensesType: "",
  //   amount: "",
  //   frequency: "",
  // });

  // useEffect(() => {
  //   if (consumerFieldErrors?.expenses) {
  //     setExpenseFieldErrors({
  //       expensesType: consumerFieldErrors?.expenses?.expensesType || "",
  //       amount: consumerFieldErrors?.expenses?.amount || "",
  //       frequency: consumerFieldErrors?.expenses?.frequency || "",
  //     });
  //   }
  // }, [consumerFieldErrors?.expenses]);
  const validExpenseTypesList = expensesTypeList.map((item) => item.value);

  const validationSchema = Yup.object({
    expensesType: Yup.string()
      .oneOf(validExpenseTypesList, "Please select valid expense type")
      .required("Expense type is required"),
    amount: Yup.string().matches(regex.allowOnlyNumber, "Only numbers allowed.").max(10, "Maximum of 7 digits"),
    frequency: Yup.string()
      .oneOf(validFrequencyList, "Please select valid frequency")
      .required("Frequency is required"),
  });

  const debouncedValidation = useCallback(
    debounce(async (name, value) => {
      const fieldName = name.split("_").length > 0 ? name.split("_")[0] : name;
      try {
        await validationSchema.validateAt(fieldName, { [fieldName]: value });
        dispatch(
          setRequiredFieldsErrors({
            ...requireFieldErrors,
            [name]: "",
          }),
        );
      } catch (error) {
        dispatch(
          setRequiredFieldsErrors({
            ...requireFieldErrors,
            [name]: error.message,
          }),
        );
      }
    }, 300),
    []
  );

  // const validateField = async (name, value) => {
  //   const fieldName = name.split("_").length > 0 ? name.split("_")[0] : name;
  //   try {
  //     await validationSchema.validateAt(fieldName, { [fieldName]: value });
  //     // setExpenseFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
  //     dispatch(
  //       setRequiredFieldsErrors({
  //         ...requireFieldErrors,
  //         [name]: "",
  //       }),
  //     );
  //   } catch (error) {
  //     // setExpenseFieldErrors((prevErrors) => ({
  //     //   ...prevErrors,
  //     //   [fieldName]: error.message,
  //     // }));
  //     dispatch(
  //       setRequiredFieldsErrors({
  //         ...requireFieldErrors,
  //         [name]: error.message,
  //       }),
  //     );
  //   }
  // };

  const onAddNewExpenses = async ({ expensesType, amount, frequency }) => {
    const data = {
      expensesType: expensesType || "",
      frequency: frequency || "",
      amount: amount || "",
      application: applicationId,
      customer: customerId,
    };
    dispatch(
      saveValue({
        personalFinLoaders: {
          ...personalFinLoaders,
          id: customerId,
        },
      }),
    );
    dispatch(createExpense({ data }));
  };

  const removeExpenses = (Element, index) => {
    const data = {
      expenseId: Element._id,
      application: applicationId,
      customer: customerId,
    };
    delete data._id;
    dispatch(
      saveValue({
        personalFinLoaders: {
          ...personalFinLoaders,
          id: Element._id,
        },
      }),
    );
    dispatch(deleteExpense({ data }));
    // let requireFieldError = requireFieldErrors;
    // requireFieldError = Object.keys(requireFieldError)
    //   .filter(
    //     (objKey) =>
    //       objKey !== `expensesType_expenses_${customerId}_${index}` &&
    //       objKey !== `amount_expenses_${customerId}_${index}` &&
    //       objKey !== `frequency_expenses_${customerId}_${index}`,
    //   )
    //   .reduce((newObj, key) => {
    //     newObj[key] = requireFieldError[key];
    //     return newObj;
    //   }, {});
    // dispatch(
    //   setRequiredFieldsErrors({
    //     ...requireFieldErrors,
    //     requireFieldError,
    //   }),
    // );
  };

  const handle = {
    onBlur: (name, value, newID) => {
      const temp = [...expensesList];

      temp.map((ele) => {
        if (ele._id === newID) {
          let updatedAssetData = {
            ...ele,
            [name]: value,
            expenseId: newID,
            application: applicationId,
            customer: customerId,
          };

          delete updatedAssetData._id;

          if (changeDetected) {
            dispatch(updateExpense({ data: updatedAssetData }));
            setChangeDetected(false);
          }
          return updatedAssetData;
        } else {
          return ele;
        }
      });
    },
    onChange: async (field, value, newID, index) => {
      const name = field.split("_")[0];
      // await validateField(field, value);
      debouncedValidation(field, value);

      const temp = [...expensesList];

      let isChangeDetected = false;

      const updateExpenses = temp.map((ele) => {
        if (!ele._id) {
          const dataWithOutId = {
            ...ele,
            [name]: value,
            application: applicationId,
            customer: customerId,
          };
          isChangeDetected = true;
          dispatch(createExpense({ data: dataWithOutId }));
        }

        if (ele._id === newID) {
          const updatedExpensesData = {
            ...ele,
            [name]: value,
          };
          if (ele[name] !== value) {
            isChangeDetected = true;
          }
          // validateField(name, value);
          return updatedExpensesData;
        } else {
          return ele;
        }
      });

      setChangeDetected(isChangeDetected);
      if (isChangeDetected) {
        setExpensesList(updateExpenses)
        // dispatch(updateExpensesList(updateExpenses));
      }
    },
    // expenses: (update) => {
    //   dispatch(updateExpenses({ data: update }));
    // },
    // createExpense: async () => {
    //   try {
    //     if (expenseFieldErrors["asset"] === "") {
    //       await dispatch(
    //         createFinanceItem({
    //           data: {
    //             name: _.camelCase(newExpenseName),
    //             label: newExpenseName,
    //             expensesId: expenses._id,
    //           },
    //           endpoint: "expenses",
    //         }),
    //       ).unwrap();
    //       setNewExpenseName("");
    //     }
    //   } catch (error) {
    //     const newErrors = {};
    //     error.inner.forEach((validationError) => {
    //       newErrors[validationError.path] = validationError.message;
    //     });
    //     setExpenseFieldErrors(newErrors);
    //   }
    // },
    // deleteExpense: async (delData) => {
    //   await dispatch(
    //     deleteFinanceItem({
    //       data: { expenseId: delData._id, expensesId: expenses._id },
    //       endpoint: "expenses",
    //     }),
    //   ).unwrap();
    // },
    checkExistingExpense: async (expenses) => {
      // Check expense is not exist then create new expense
      // expensesTypeList
      for await (let currentExpense of expensesTypeList) {
        // Call create expense api for all expenses with value default 0 and frequency is monthly

        const value = currentExpense.value;
        // Check if the value exists in expensesList
        const exists = expenses.some((expense) => expense?.expensesType === value);

        if (!exists) {
          if (value === "Groceries") {
            const data = {
              expensesType: "Groceries",
              amount: "0.00",
              frequency: "Monthly",
              application: applicationId,
              customer: customerId,
              expenseId: expenses?.[0]?._id,
            };

            await dispatch(updateExpense({ data })).unwrap();
          } else {
            await onAddNewExpenses({
              expensesType: value,
              amount: "0.00",
              frequency: "Monthly",
            });
          }
        }
      }
    },
  };

  // UseEffect For add all expenses (It should be from Backend)
  // useEffect(() => {
  //   if (expenses?.length > 0 && customerId && applicationId) {
  //     handle.checkExistingExpense(expenses)
  //   }
  // }, [])

  return (
    <>
      <Grid xs={12}>
        <Typography variant="subtitle2" sx={{ fontWeight: "bold" }} style={{ marginBottom: "5px", marginTop: "20px" }}>
          Expenses
        </Typography>
      </Grid>
      {expensesList?.map((ele, index) => (
        <Grid container spacing={2} style={{ marginBottom: "20px" }} key={ele?._id}>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth variant="filled">
                <InputLabel
                  id="years-at-label"
                  error={requireFieldErrors[`expensesType_expenses_${customerId}_${index}`]}
                >
                  Expense type
                </InputLabel>
                <Select
                  size="small"
                  labelId="years-at-label"
                  id="demo-simple-select"
                  disabled={!isDisable}
                  value={ele?.expensesType}
                  label="Expense type"
                  name={`expensesType_expenses_${customerId}_${index}`}
                  onChange={(event) => {
                    handle.onChange(
                      `expensesType_expenses_${customerId}_${index}`,
                      event.target.value,
                      ele?._id,
                      index,
                    );
                  }}
                  onBlur={() => handle.onBlur("expensesType", ele?.expensesType, ele?._id)}
                  error={requireFieldErrors[`expensesType_expenses_${customerId}_${index}`]}
                >
                  {expensesTypeList.map((i) => (
                    <MenuItem value={i.value}>{i.label}</MenuItem>
                  ))}
                </Select>
                {requireFieldErrors[`expensesType_expenses_${customerId}_${index}`] && (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {requireFieldErrors[`expensesType_expenses_${customerId}_${index}`]}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                size="small"
                id="outlined-basic"
                // type="tel"
                type="text"
                inputProps={{ min: 0 }}
                disabled={!isDisable}
                label="Expense amount"
                variant="filled"
                style={{ width: "100%" }}
                value={ele?.amount}
                name={`amount_expenses_${customerId}_${index}`}
                error={requireFieldErrors[`amount_expenses_${customerId}_${index}`]}
                helperText={requireFieldErrors[`amount_expenses_${customerId}_${index}`]}
                InputProps={{
                  inputComponent: NumericFormatCustom,
                }}
                onChange={(event) =>
                  handle.onChange(`amount_expenses_${customerId}_${index}`, event.target.value, ele?._id, index)
                }
                onBlur={() => handle.onBlur("amount", currencyMaskedValue(ele?.amount), ele?._id)}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormControl fullWidth variant="filled">
                <InputLabel id="years-at-label" error={requireFieldErrors[`frequency_expenses_${customerId}_${index}`]}>
                  Expense frequency
                </InputLabel>
                <Select
                  size="small"
                  labelId="years-at-label"
                  id="demo-simple-select"
                  value={ele?.frequency}
                  disabled={!isDisable}
                  label="Expense frequency"
                  name={`frequency_expenses_${customerId}_${index}`}
                  onChange={(event) => {
                    handle.onChange(`frequency_expenses_${customerId}_${index}`, event.target.value, ele?._id, index);
                  }}
                  onBlur={() => handle.onBlur("frequency", ele?.frequency, ele?._id)}
                  error={requireFieldErrors[`frequency_expenses_${customerId}_${index}`]}
                >
                  {frequencyList.map((i) => (
                    <MenuItem value={i.value}>{i.label}</MenuItem>
                  ))}
                </Select>
                {requireFieldErrors[`frequency_expenses_${customerId}_${index}`] && (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {requireFieldErrors[`frequency_expenses_${customerId}_${index}`]}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            {expensesList?.length !== 0 && index !== 0 && (
              <Grid item xs={1}>
                <Button
                  // style={{ marginLeft: "5px" }}
                  size="small"
                  style={{ marginTop: "10px" }}
                  onClick={() => removeExpenses(ele, index)}
                  variant="outlined"
                  disabled={personalFinLoaders?.removeExpenseLoader && personalFinLoaders?.id === ele?._id}
                >
                  {personalFinLoaders?.removeExpenseLoader && personalFinLoaders?.id === ele?._id
                    ? "Removing..."
                    : "Remove"}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <div>
          <Button
            onClick={() => onAddNewExpenses({ expensesType: "", amount: "", frequency: "" })}
            variant="outlined"
            size="small"
            disabled={
              isAddExpenseLoader ||
              !isDisable ||
              (!expensesList?.[0]?.expensesType && !expensesList?.[0]?.frequency && !expensesList?.[0]?.amount ? true : false)
            }
          >
            {isAddExpenseLoader ? "Adding..." : "Add expense"}
          </Button>
        </div>
      </Grid>
    </>
  );
}
