
// eslint-disable-next-line import/no-extraneous-dependencies
import Finance from 'tvm-financejs';

function calculateBaseRate(nper, pmt, pv, fv, type, guess) {
  nper = typeof nper !== "undefined" ? nper : 60;
  pmt = typeof pmt !== "undefined" ? pmt : 0;
  pv = typeof pv !== "undefined" ? pv : 0;
  fv = typeof fv !== "undefined" ? fv : 0;
  type = typeof type !== "undefined" ? type : 0;
  guess = typeof guess !== "undefined" ? guess : 0.1;

  const finance = new Finance()


  const effectiveRateRaw = finance.RATE(
    nper,
    pmt,
    pv, // This should be negative value
    fv,
    type,
    guess
  );

  // const effectiveRate = effectiveRateRaw * 12 * 100
  const effectiveRate = effectiveRateRaw && !isNaN(effectiveRateRaw) && isFinite(effectiveRateRaw)
    ? effectiveRateRaw * 12 * 100
    : 0;

  return effectiveRate

}
export default calculateBaseRate;
