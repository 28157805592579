import criteria from "../../utils/criteria";
import { typeOfSaleList, livingArrangementsTypes } from "../../constants";

const { PRIVATE_SALE, DEALER_SALE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE, BALLOON_RESIDUAL_REFINANCE } =
  typeOfSaleList;

const { PROPERTY_OWNER, LIVING_WITH_PARENTS, RENTING, BOARDING } = livingArrangementsTypes;

const {
  livingArrangements,
  hasDeposit,
  gstRegistrationGreaterThan,
  termIsLessThan,
  assetValueBetween,
  assetType,
  assetCondition,
  ageOfAssetBetween,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  timeInBusinessLessThan,
  creditScoreGreaterThan,
  typeOfSale,
} = criteria;

const branded = {
  date: "16 Oct 2023",
  lender: "Branded",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos/BFS-AUS-updated-logo.png",
  actions: ["upload"],
  lenderCredentials: [
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
  ],
  requiredFields: {
    entity: [
      "abn",
      "entityName",
      "entityType",
      "industryType",
      "turnover",
      // "addresses[0].streetAddress",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
    ],
    customer: [
      "customerTitle",
      "firstName",
      "lastName",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      "dateOfBirth",
      "IDNumber",
      "IDCardNumber",
      "IDExpiryDate",
      // "addresses[0].streetAddress",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "customerProfile.livingArrangements",
      "customerProfile.numberOfDependants",
      "customerProfile.residencyStatus",
      "customerProfile.maritalStatus",
      "customerAssets.[0].assetType",
      "customerAssets.[0].value",
      "customerAssets.[0].description",
      "liabilities.[0].liabilitiesType",
      "liabilities.[0].outstandingAmount",
      "liabilities.[0].limit",
      "liabilities.[0].financier",
      "liabilities.[0].repaymentFrequency",
      "liabilities.[0].repaymentAmount",
      "liabilities.[0].description",
      // "emails[0].address",
      // "phoneNumbers[0].number",
    ],
    loanDetails: ["rate"],
    asset: ["assetType", "typeOfSale", "ageOfAsset", "glassesMake", "glassesModel", "glassesVariant", "assetValue"],
  },
  fees: [
    {
      name: "Authorised supplier",
      value: 395,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(DEALER_SALE)],
    },
    {
      name: "Private sale",
      value: 395,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(PRIVATE_SALE)],
    },
    {
      name: "Origination fee",
      value: 1250,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Account fee",
      value: 5.5,
      capitalised: false,
      frequency: "monthly",
    },
    {
      name: "PPSR",
      value: 6,
      capitalised: true,
      frequency: "upfront",
    },
  ],
  brokerage: [
    {
      value: 0,
      max: 6,
      valueType: "percentage",
      criteria: [assetValueBetween(5000, 2500000)],
    },
  ],
  loading: [],
  info: [
    `
      New ABNs considered
    `,
    `
     Contact lender for Balloon amounts - balloons for assets upto 6 years at start are offered.
    `,
    `
      MV restriction upto <7.5T (EXCLUDED FROM fast track)
    `,
    `
      Maximum 13 years at the beginning of the term and 18 years at end of term
    `,
    `
      120% for standard vehicles
    `,
  ],
  productTiers: [
    {
      name: "Low doc",
      rate: 7.89,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 50000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(0),
        ageOfAssetAtEndLessThan(19),
        livingArrangements([PROPERTY_OWNER]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        assetCondition(["New"]),
        termIsLessThan(61),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Low doc",
      rate: 8.89,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 50000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(0),
        ageOfAssetBetween(0, 71),
        ageOfAssetAtEndLessThan(19),
        livingArrangements([PROPERTY_OWNER]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        termIsLessThan(61),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Low doc",
      rate: 9.89,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 50000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(0),
        ageOfAssetBetween(72, 180),
        ageOfAssetAtEndLessThan(19),
        livingArrangements([PROPERTY_OWNER]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        termIsLessThan(61),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(549),
      ],
    },

    {
      name: "Low doc",
      rate: 7.89,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 150000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetAtEndLessThan(19),
        livingArrangements([PROPERTY_OWNER]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        assetCondition(["New"]),
        termIsLessThan(61),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Low doc",
      rate: 8.89,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 150000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetBetween(0, 71),
        ageOfAssetAtEndLessThan(19),
        livingArrangements([PROPERTY_OWNER]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        termIsLessThan(61),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Low doc",
      rate: 9.89,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 150000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetBetween(72, 180),
        ageOfAssetAtEndLessThan(19),
        livingArrangements([PROPERTY_OWNER]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        termIsLessThan(61),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(549),
      ],
    },

    {
      name: "Low doc",
      rate: 7.89,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 50000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(0),
        ageOfAssetAtEndLessThan(19),
        livingArrangements([LIVING_WITH_PARENTS, BOARDING, RENTING]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        assetCondition(["New"]),
        termIsLessThan(61),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        hasDeposit(10),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Low doc",
      rate: 8.89,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 50000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(0),
        ageOfAssetBetween(0, 71),
        ageOfAssetAtEndLessThan(19),
        livingArrangements([LIVING_WITH_PARENTS, BOARDING, RENTING]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        termIsLessThan(61),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        hasDeposit(10),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Low doc",
      rate: 9.89,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 50000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(0),
        ageOfAssetBetween(72, 180),
        ageOfAssetAtEndLessThan(19),
        livingArrangements([LIVING_WITH_PARENTS, BOARDING, RENTING]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        termIsLessThan(61),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        hasDeposit(10),
        creditScoreGreaterThan(549),
      ],
    },

    {
      name: "Low doc",
      rate: 7.89,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetAtEndLessThan(19),
        livingArrangements([LIVING_WITH_PARENTS, BOARDING, RENTING]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        assetCondition(["New"]),
        termIsLessThan(61),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        hasDeposit(10),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Low doc",
      rate: 8.89,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetBetween(0, 71),
        ageOfAssetAtEndLessThan(19),
        livingArrangements([LIVING_WITH_PARENTS, BOARDING, RENTING]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        termIsLessThan(61),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        hasDeposit(10),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Low doc",
      rate: 9.89,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetBetween(72, 180),
        ageOfAssetAtEndLessThan(19),
        livingArrangements([LIVING_WITH_PARENTS, BOARDING, RENTING]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        termIsLessThan(61),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        hasDeposit(10),
        creditScoreGreaterThan(549),
      ],
    },

    {
      name: "Light doc",
      rate: 7.89,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(5000, 80000),
        timeInBusinessLessThan(12),
        gstRegistrationGreaterThan(0),
        ageOfAssetAtEndLessThan(19),
        livingArrangements([PROPERTY_OWNER]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Motorbikes, ATVs",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        assetCondition(["New"]),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        hasDeposit(10),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Light doc",
      rate: 8.89,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(5000, 150000),
        timeInBusinessLessThan(12),
        gstRegistrationGreaterThan(0),
        ageOfAssetBetween(0, 71),
        ageOfAssetAtEndLessThan(19),
        livingArrangements([PROPERTY_OWNER]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Motorbikes, ATVs",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        hasDeposit(10),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Light doc",
      rate: 9.89,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(5000, 80000),
        timeInBusinessLessThan(12),
        gstRegistrationGreaterThan(0),
        ageOfAssetBetween(72, 180),
        ageOfAssetAtEndLessThan(19),
        livingArrangements([PROPERTY_OWNER]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Motorbikes, ATVs",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        hasDeposit(10),
        creditScoreGreaterThan(549),
      ],
    },

    {
      name: "Light doc",
      rate: 9.85,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(5000, 80000),
        timeInBusinessLessThan(12),
        gstRegistrationGreaterThan(0),
        ageOfAssetAtEndLessThan(19),
        livingArrangements([LIVING_WITH_PARENTS, BOARDING, RENTING]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Motorbikes, ATVs",
          "Trailers and Caravans",
        ]),
        assetCondition(["New"]),
        termIsLessThan(61),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        hasDeposit(20),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Light doc",
      rate: 10.85,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(5000, 80000),
        timeInBusinessLessThan(12),
        gstRegistrationGreaterThan(0),
        ageOfAssetBetween(0, 71),
        ageOfAssetAtEndLessThan(19),
        livingArrangements([LIVING_WITH_PARENTS, BOARDING, RENTING]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Motorbikes, ATVs",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        hasDeposit(20),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Light doc",
      rate: 11.85,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(5000, 80000),
        timeInBusinessLessThan(12),
        gstRegistrationGreaterThan(0),
        ageOfAssetBetween(72, 180),
        ageOfAssetAtEndLessThan(19),
        livingArrangements([LIVING_WITH_PARENTS, BOARDING, RENTING]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Motorbikes, ATVs",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        hasDeposit(20),
        creditScoreGreaterThan(549),
      ],
    },

    {
      name: "Full doc",
      rate: 7.89,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(80000, 250000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(0),
        ageOfAssetAtEndLessThan(19),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Motorbikes, ATVs",
          "Trailers and Caravans",
        ]),
        assetCondition(["New"]),
        termIsLessThan(61),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Full doc",
      rate: 8.89,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(5000, 250000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(0),
        ageOfAssetBetween(0, 71),
        ageOfAssetAtEndLessThan(19),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Motorbikes, ATVs",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Full doc",
      rate: 9.89,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(5000, 250000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(0),
        ageOfAssetBetween(72, 180),
        ageOfAssetAtEndLessThan(19),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Motorbikes, ATVs",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(549),
      ],
    },
  ],
};
export default branded;
