import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from '@mui/material';

const CustomSelectInput = ({ name, label, value, itemList, error, changeHandler, blurHandler, isOnlyValue }) => 
   (
    <Grid item mt={1}>
      <FormControl fullWidth variant="filled">
        <InputLabel
          id={`${name}-id`}
          error={error}
        >
          {label}
        </InputLabel>
        <Select
          size="small"
          labelId={`${name}-label`}
          id={`${name}-id`}
          value={value}
          label={label}
          name={name}
          onChange={(event) => changeHandler(event.target?.value, name)}
          onBlur={blurHandler}
          error={error}
        >
          {itemList?.length > 0 && itemList.map((i) => (
            <MenuItem value={isOnlyValue ? i : i.value}>
              {isOnlyValue ? i : i.label}
            </MenuItem>
          ))}
        </Select>
        {error && (
          <FormHelperText sx={{ color: "#d32f2f" }}>{error}</FormHelperText>
        )}
      </FormControl>
    </Grid>
  )


export default CustomSelectInput