import React from "react";
import { Typography, Box, Divider } from "@mui/material";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import moment from "moment";

const ServiceLogSidebarGroup = ({ logs = [] }) => {
  const id = logs["_id"];
  const servicesName = logs?.services?.name;
  const servicesFees = `$${Number(logs?.services?.fee / 100).toFixed(2)}`;
  return (
    <>
      <TimelineItem key={id}>
        <TimelineOppositeContent
          sx={{
            fontSize: "12px",
            m: "auto 0",
            p: "0 16px 0 0",
            display: "flex",
            flexDirection: "column",
          }}
          variant="body2"
          color="text.secondary"
        >
          {/* .tz("Australia/Sydney") */}
          <span
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {moment(logs?.createdAt).format("DD-MM-YYYY")}
          </span>

          <span
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {moment(logs?.createdAt).format("hh:mm a")}
          </span>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot>
            <RequestQuoteOutlinedIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <Box sx={{ pl: "12px", px: 2 }} style={{ overflow: "auto" }}>
          <Box sx={{ my: "6px" }}>
            <Typography style={{ fontSize: "14px" }}>
              {servicesName} - {servicesFees}
            </Typography>
            <Typography style={{ fontSize: "12px" }}>
              {`${
                logs?.user?.lastName ? `${logs?.user?.firstName} ${logs?.user?.lastName}` : `${logs?.user?.firstName}`
              }`}
            </Typography>
          </Box>
        </Box>
      </TimelineItem>
      <Divider sx={{ my: "5px" }} />
    </>
  );
};

export default ServiceLogSidebarGroup;
