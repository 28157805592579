import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, TextField } from "@mui/material";
import { ConditionFieldOptions } from "../../../constants";
import {
  saveAsset,
  updateAsset,
  assetSelector
} from "../../../store/slices/applicationFormSlice";

export default function AssetCondition({disabled}) {
  const dispatch = useDispatch();
  const { _id, condition, typeOfSale } = useSelector(assetSelector);

  function handleUpdateAssetCondition(name, value) {
    const dataToBeSave = {
      condition: value,
    }

    dispatch(saveAsset(dataToBeSave))

    if (_id) {
      dispatch(updateAsset({
        _id,
        ...dataToBeSave
      }));
    }
  }

  return (
    <Autocomplete
      value={condition || ""}
      options={ConditionFieldOptions}
      onChange={(event, newValue) => {
        handleUpdateAssetCondition("condition", newValue);
      }}
      disabled={disabled || typeOfSale === "Private sale"}
      size="small"
      renderInput={(params) => (
        <TextField size="small" {...params} label="Condition" variant="filled" />
      )}
    />
  )
}