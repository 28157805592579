import React from "react";
import { CardContent, CircularProgress, Stack, Typography } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent";
import ServiceLogSidebarGroup from "./ServiceLogSidebarGroup";

const ServiceLogSidebar = React.memo(({ serviceList, loader }) => {
  let totalFees = 0;

  if (serviceList && serviceList !== undefined && serviceList.length > 0) {
    totalFees = serviceList.reduce(
      (accumulator, currentValue) => accumulator + Number(currentValue?.services?.fee),
      0,
    );

  }


  return (
    <CardContent style={{ overflow: "" }}>
      <Typography fontWeight={600}>Service Log</Typography>
      {serviceList.length !== 0 && (
        <Typography style={{ marginBottom: 5 }} fontWeight={500}>
          Total Charges :- ${(totalFees / 100).toFixed(2)}
        </Typography>
      )}

      <Timeline
        sx={{
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: 0.6,
          },
        }}
      >
        {loader ? (
          <Stack
            sx={{
              alignContent: "center",
              justifyContent: "center",
              marginTop: "200px",
            }}
          >
            <CircularProgress size={50} />
          </Stack>
        ) : (
          serviceList?.length > 0 &&
          serviceList?.map((activity, index) => (
            <ServiceLogSidebarGroup key={"ActivityGroup" + index} logs={activity} />
          ))
        )}
      </Timeline>
    </CardContent>
  );
});

export default ServiceLogSidebar;
