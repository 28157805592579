import { livingArrangementsTypes, employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } = livingArrangementsTypes;

const { SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL } = employmentTypes;

const { assetType, loanAmountBetween, employmentType, timeOfEmploymentGreaterThan, livingArrangements } = criteria;

function permanentEmployment(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, employmentType([FULL_TIME, PART_TIME]), timeOfEmploymentGreaterThan(11)],
  }));
}

function temporaryEmployment(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, employmentType([SELF_EMPLOYED, CASUAL, CONTRACT]), timeOfEmploymentGreaterThan(17)],
  }));
}

const gold = [...permanentEmployment(base()), ...temporaryEmployment(base())];

function base() {
  return [
    {
      name: "Gold",
      rate: 17.95,
      info: ["Adverse more than 18 months", "Ex-bankkrupt more than 6 months"],
      productType: "Adverse",
      criteria: [
        loanAmountBetween(8000, 75000),
        livingArrangements([PROPERTY_OWNER]),
        assetType([
          "Motor Vehicle",
          "Electric Motor Vehicle",
          "Caravan",
          "Trailered boat",
          "Motorbike",
          "Horse float",
          "Ride on mower",
          "Jetski/PWC",
          "ATV/Off-Road Bike",
          "Tractor",
        ]),
      ],
    },
    {
      name: "Gold",
      rate: 17.95,
      productType: "Adverse",
      info: ["Adverse more than 18 months", "Ex-bankkrupt more than 6 months"],
      criteria: [
        loanAmountBetween(8000, 50000),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        assetType([
          "Motor Vehicle",
          "Electric Motor Vehicle",
          "Caravan",
          "Trailered boat",
          "Motorbike",
          "Horse float",
          "Ride on mower",
          "Jetski/PWC",
          "ATV/Off-Road Bike",
          "Tractor",
        ]),
      ],
    },
  ];
}
export default gold;
