import React from 'react'
import { Box, Grid } from '@mui/material'
import CustomSelectInput from './common/CustomSelectInput'
import CalCustomTitleBar from './common/CalCustomTitleBar'

const CalFinance = React.memo(({ allValues, lenderList, calculatorFieldsErrors, validateField, saveLoanDetails, setAllValue }) => {

  const handle = {
    onChangeField: async (e, name) => {
      let isValid = true;

      if (!isValid) {
        await validateField({ fieldName: name, value: e, fieldObj: 'finance' });
        return;
      }

      if (name === 'state'
        || name === 'lender') {
        saveLoanDetails({
          [name]: e,
        });
        setAllValue({ ...allValues, [name]: e });
      }
      await validateField({ fieldName: name, value: e, fieldObj: 'finance' });
    }
  }

  return (
    <Box>
      <CalCustomTitleBar title={"Finance"} />

      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        mb={"20px"}
        spacing={3}
        direction="row"
      >
        <Grid item xs={12} sm={12}>
          {lenderList &&
            <CustomSelectInput
              name="lender"
              label="Lender"
              value={allValues?.lender || ""} // lender list
              itemList={lenderList}
              isOnlyValue={true}
              changeHandler={handle.onChangeField}
              error={calculatorFieldsErrors?.finance?.lender}
            />
          }
        </Grid>
      </Grid>
    </Box>
  )
})

export default CalFinance