import React from "react";
import { Box, Stack, Typography } from "@mui/material";

const CreditScoreGradientGraph = ({ score }) => {
  let leftValue;

  if (score < -200) {
    leftValue = 0;
  } else if (score + 200 < 500) {
    leftValue = `${((score + 200) / 1400) * 100}%`;
  } else {
    leftValue = `calc(${((score + 200) / 1400) * 100}% - 70px)`;
  }

  return (
    <Box
      style={{
        width: "100%",
        position: "relative",
        height: 90,
        paddingTop: 40,
        // background: "linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,0,0,1) 21%, rgba(255,87,34,1) 31%, rgba(255,235,59,1) 45%, rgba(205,220,57,1) 61%, rgba(76,175,80,1) 75%, rgba(76,175,80,1) 100%)"
      }}
    >
      <Box
        style={{
          width: "100%",
          position: "relative",
          height: 40,
          background:
            "linear-gradient(90deg, rgba(183,0,0,1) 0%, rgba(255,0,0,1) 21%, rgba(255,87,34,1) 31%, rgba(255,235,59,1) 45%, rgba(205,220,57,1) 61%, rgba(76,175,80,1) 75%, rgb(27, 94, 32) 100%)",
        }}
      >
        <Box
          style={{
            position: "absolute",
            // left: `${(creditProfile.score + 200) / 1400 * 100}%`,
            left: leftValue,
            top: -42,
            fontSize: "28px",
          }}
        >
          <Stack direction={score + 200 < 500 ? "row-reverse" : "row"} alignItems="start">
            <Typography
              variant="body2"
              fontWeight={600}
              style={{
                fontSize: "28px",
                marginRight: "3px",
              }}
            >
              {score}
            </Typography>
            <Box
              style={{
                position: "relative",
                height: 82,
                width: 2,
                background: "#222222",
              }}
            ></Box>
          </Stack>
        </Box>
      </Box>
      <Stack direction="row" alignItems="end" justifyContent="space-between">
        <Typography
          // variant="body2"
          // fontWeight={600}
          style={{
            fontSize: "12px",
          }}
        >
          -200
        </Typography>
        <Typography
          // variant="body2"
          // fontWeight={600}
          style={{
            fontSize: "12px",
          }}
        >
          0
        </Typography>
        <Typography
          // variant="body2"
          // fontWeight={600}
          style={{
            fontSize: "12px",
          }}
        >
          200
        </Typography>
        <Typography
          // variant="body2"
          // fontWeight={600}
          style={{
            fontSize: "12px",
          }}
        >
          400
        </Typography>
        <Typography
          // variant="body2"
          // fontWeight={600}
          style={{
            fontSize: "12px",
          }}
        >
          600
        </Typography>
        <Typography
          // variant="body2"
          // fontWeight={600}
          style={{
            fontSize: "12px",
          }}
        >
          800
        </Typography>
        <Typography
          // variant="body2"
          // fontWeight={600}
          style={{
            fontSize: "12px",
          }}
        >
          1000
        </Typography>
        <Typography
          // variant="body2"
          // fontWeight={600}
          style={{
            fontSize: "12px",
          }}
        >
          1200
        </Typography>
      </Stack>
    </Box>
  );
};

export default CreditScoreGradientGraph;
