import criteria from "../../../utils/criteria";

const {
  assetType,
  creditScoreGreaterThan,
  creditScoreBetween,
  ageOfAssetBetween,
  ageOfAssetAtEndLessThan,
  assetValueBetween,
  timeOfEmploymentGreaterThan,
} = criteria;

const base = [
  {
    name: "Wallet Wizz",
    rate: 20.50,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 750000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(0, 23),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(6),
      creditScoreGreaterThan(650),
    ],
  },
  {
    name: "Wallet Wizz",
    rate: 21.50,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 750000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(24, 47),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(6),
      creditScoreGreaterThan(650),
    ],
  },
  {
    name: "Wallet Wizz",
    rate: 22.50,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 750000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(48, 95),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(6),
      creditScoreGreaterThan(650),
    ],
  },
  {
    name: "Wallet Wizz",
    rate: 25.00,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 750000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(96, 179),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(6),
      creditScoreGreaterThan(650),
    ],
  },

  {
    name: "Wallet Wizz",
    rate: 24.00,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 750000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(0, 23),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(6),
      creditScoreBetween(600, 649),
    ],
  },
  {
    name: "Wallet Wizz",
    rate: 25.00,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 750000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(24, 47),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(6),
      creditScoreBetween(600, 649),
    ],
  },
  {
    name: "Wallet Wizz",
    rate: 26.00,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 750000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(48, 95),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(6),
      creditScoreBetween(600, 649),
    ],
  },
  {
    name: "Wallet Wizz",
    rate: 27.00,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 750000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(96, 179),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(6),
      creditScoreBetween(600, 649),
    ],
  },

  {
    name: "Brainey Budgeter",
    rate: 27.00,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 650000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(0, 23),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(6),
      creditScoreBetween(550, 599),
    ],
  },
  {
    name: "Brainey Budgeter",
    rate: 27.50,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 650000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(24, 47),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(6),
      creditScoreBetween(550, 599),
    ],
  },
  {
    name: "Brainey Budgeter",
    rate: 28.00,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 650000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(48, 95),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(6),
      creditScoreBetween(550, 599),
    ],
  },
  {
    name: "Brainey Budgeter",
    rate: 30.00,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 650000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(96, 179),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(6),
      creditScoreBetween(550, 599),
    ],
  },

  {
    name: "Credit Concious",
    rate: 27.00,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 550000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(0, 23),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(500, 549),
    ],
  },
  {
    name: "Credit Concious",
    rate: 28.00,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 550000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(24, 47),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(500, 549),
    ],
  },
  {
    name: "Credit Concious",
    rate: 29.00,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 550000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(48, 95),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(500, 549),
    ],
  },
  {
    name: "Credit Concious",
    rate: 30.00,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 550000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(96, 179),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(500, 549),
    ],
  },

  {
    name: "Credit Concious",
    rate: 27.50,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 550000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(0, 23),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(450, 499),
    ],
  },
  {
    name: "Credit Concious",
    rate: 28.00,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 550000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(24, 47),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(450, 499),
    ],
  },
  {
    name: "Credit Concious",
    rate: 30.50,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 550000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(48, 95),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(450, 499),
    ],
  },
  {
    name: "Credit Concious",
    rate: 32.00,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 550000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(96, 179),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(450, 499),
    ],
  },

  {
    name: "Money Manger",
    rate: 30.00,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 40000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(0, 23),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(400, 449),
    ],
  },
  {
    name: "Money Manger",
    rate: 30.50,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 40000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(24, 47),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(400, 449),
    ],
  },
  {
    name: "Money Manger",
    rate: 31.00,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 40000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(48, 95),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(400, 449),
    ],
  },
  {
    name: "Money Manger",
    rate: 32.00,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 40000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(96, 179),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(400, 449),
    ],
  },

  {
    name: "Money Manger",
    rate: 32.50,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 40000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(0, 23),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(350, 399),
    ],
  },
  {
    name: "Money Manger",
    rate: 33.50,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 40000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(24, 47),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(350, 399),
    ],
  },
  {
    name: "Money Manger",
    rate: 35.00,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 40000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(48, 95),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(350, 399),
    ],
  },
  {
    name: "Money Manger",
    rate: 37.50,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 40000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(96, 179),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(350, 399),
    ],
  },

  {
    name: "Money Manger",
    rate: 34.50,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 40000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(0, 23),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(300, 349),
    ],
  },
  {
    name: "Money Manger",
    rate: 35.00,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 40000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(24, 47),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(300, 349),
    ],
  },
  {
    name: "Money Manger",
    rate: 36.00,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 40000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(48, 95),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(300, 349),
    ],
  },
  {
    name: "Money Manger",
    rate: 38.00,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 40000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(96, 179),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(300, 349),
    ],
  },

  {
    name: "Money Manger",
    rate: 37.50,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 40000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(0, 23),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(250, 299),
    ],
  },
  {
    name: "Money Manger",
    rate: 37.50,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 40000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(24, 47),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(250, 299),
    ],
  },
  {
    name: "Money Manger",
    rate: 38.00,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 40000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(48, 95),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(250, 299),
    ],
  },
  {
    name: "Money Manger",
    rate: 39.00,
    productType: "Standard",
    criteria: [
      assetValueBetween(5000, 40000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      ageOfAssetBetween(96, 179),
      ageOfAssetAtEndLessThan(18),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(250, 299),
    ],
  },
];

export default base;
