import React from 'react'
import { useDispatch } from 'react-redux'
import { Box, Grid } from '@mui/material'
import PrefixTextField from './common/PrefixTextField'
import CalCustomTitleBar from './common/CalCustomTitleBar'
import calculateRepayments from '../../utils/calculateRepayments'
import { setCalculatorFieldsError, updateLoanDetails } from '../../store/slices/applicationFormSlice'
import getMonthlyRate from '../../utils/getMonthlyRate'

const CalRate = React.memo(({ allValues, calculatorFieldsErrors, validateField, saveLoanDetails, setAllValue }) => {
  const dispatch = useDispatch()
  const { _id, netAssetValue = 0, effectiveRate = 0, rate = 0, term = 0, repayments = 0, balloonAmount = 0, inArrears = "", financedWithBrokerage = 0 } = allValues

  const handle = {
    onChangeField: async (e, name) => {
      let isValid = true;

      if (e === "") {
        if (name === "rate") {
          setAllValue({ ...allValues, [name]: "" });
        } else {
          setAllValue({ ...allValues, [name]: +e });
        }
        dispatch(setCalculatorFieldsError({ ...calculatorFieldsErrors, rates: { ...calculatorFieldsErrors.rates, [name]: "" } }))
        return;
      }

      if (!isValid) {
        await validateField({ fieldName: name, value: e, fieldObj: 'rates' });
        return;
      }

      // if (name === "rate") {
      //   console.log("rate==>>>", e, +e, parseFloat(e))

      //   const monthlyRate = getMonthlyRate(+e) || 0;
      //   const calRepayments = calculateRepayments(
      //     monthlyRate,
      //     term,
      //     -financedWithBrokerage,
      //     balloonAmount,
      //     inArrears === "Yes" ? 0 : 1,
      //   );
      //   // setAllValue({ ...allValues, [name]: +e, repayments: Number(calRepayments?.toFixed(2)) });
      //   setAllValue({ ...allValues, [name]: parseFloat(e), repayments: Number(calRepayments?.toFixed(2)) });
      //   return
      // }

      setAllValue({ ...allValues, [name]: e });
      await validateField({ fieldName: name, value: e, fieldObj: 'rates' });
    },
    blurFn: async (fieldName, value) => {
      try {
        if (fieldName === "rate") {
          const monthlyRate = getMonthlyRate(+value) || 0;
          const calRepayments = calculateRepayments(
            monthlyRate,
            term,
            -financedWithBrokerage,
            balloonAmount,
            inArrears === "Yes" ? 0 : 1,
          );
          setAllValue({ ...allValues, [fieldName]: parseFloat(value), repayments: Number(calRepayments?.toFixed(2)) });
        }

        if (calculatorFieldsErrors['rates'][fieldName] === "") {
          if (fieldName === "rate") {
            dispatch(updateLoanDetails({ _id: _id, rate: +value, repayments: repayments || 0 }));
          } else {
            saveLoanDetails({
              [fieldName]: +value || 0,
              // ...(fieldName === "rate" && { repayments: repayments })
            });
          }
        }
      } catch (error) {
        console.log("error", error)
      }
    },
  }

  return (
    <Box>
      <CalCustomTitleBar title={"Rates"} />

      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        mb={"20px"}
        spacing={1}
        direction="row"
      // sx={{ borderBottom: "1px solid rgba(0,0,0,0.12)" }}
      >
        <Grid item xs={12} sm={6}>
          <PrefixTextField
            name="rate"
            label="Wholesale/Base"
            disabled={!netAssetValue}
            // value={rate > 0 ? +rate?.toFixed(3) : rate}
            value={rate}
            isPercentage={true}
            error={calculatorFieldsErrors?.rates?.rate}
            changeHandler={handle.onChangeField}
            blurHandler={handle.blurFn}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PrefixTextField
            name="effectiveRate"
            label="Effective"
            value={effectiveRate?.toFixed(3)} // do not need to save to db
            isPercentage={true}
            error={calculatorFieldsErrors?.rates?.effectiveRate}
            disabled={true}
          />
        </Grid>
      </Grid>
    </Box>
  )
})

export default CalRate