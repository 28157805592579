import { livingArrangementsTypes, employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } = livingArrangementsTypes;

const { CONTRACT, CASUAL } = employmentTypes;

const {
  termIsBetween,
  assetType,
  loanAmountBetween,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
} = criteria;

const temp = [
  {
    name: "Auto",
    rate: 24.5,
    productType: "Standard",
    criteria: [
      loanAmountBetween(5000, 25000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      termIsBetween(36, 60),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([CASUAL, CONTRACT]),
      timeOfEmploymentGreaterThan(1),
      creditScoreGreaterThan(0),
    ],
  },
];
export default temp;
