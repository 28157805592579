import criteria from "../../../utils/criteria";

const {
  assetType,
  ageOfAssetBetween,
  ageOfAssetLessThan,
  ageOfAssetGreaterThan,
  loanAmountBetween,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
  dateOfBirthGreaterThan,
  dateOfBirthLessThan,
} = criteria;

const aaa = [
  {
    name: "AAA Tier Under 23 Years Old - Motor Vehicle New",
    rate: 8.99,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 35000),
      ageOfAssetLessThan(12),
      dateOfBirthLessThan(23),
      livingArrangements(["Property owner"]),
      employmentType(["Self employed", "Full-time", "Part-time", "Contract", "Casual"]),
      timeOfEmploymentGreaterThan(12),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      creditScoreGreaterThan(810),
    ],
  },
  {
    name: "AAA Tier Under 23 Years Old - Motor Vehicle 12-60 Months Old",
    rate: 9.59,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 35000),
      ageOfAssetBetween(12, 59),
      dateOfBirthLessThan(23),
      livingArrangements(["Property owner"]),
      employmentType(["Self employed", "Full-time", "Part-time", "Contract", "Casual"]),
      timeOfEmploymentGreaterThan(12),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      creditScoreGreaterThan(810),
    ],
  },
  {
    name: "AAA Tier Under 23 Years Old - Motor Vehicle 60-119 Months Old",
    rate: 10.19,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 35000),
      ageOfAssetBetween(60, 119),
      dateOfBirthLessThan(23),
      livingArrangements(["Property owner"]),
      employmentType(["Self employed", "Full-time", "Part-time", "Contract", "Casual"]),
      timeOfEmploymentGreaterThan(12),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      creditScoreGreaterThan(810),
    ],
  },
  {
    name: "AAA Tier Under 23 Years Old - Motor Vehicle >119 Months Old",
    rate: 10.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 35000),
      ageOfAssetGreaterThan(119),
      dateOfBirthLessThan(23),
      livingArrangements(["Property owner"]),
      employmentType(["Self employed", "Full-time", "Part-time", "Contract", "Casual"]),
      timeOfEmploymentGreaterThan(12),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      creditScoreGreaterThan(810),
    ],
  },

  {
    name: "AAA Tier Over 23 Years Old - Motor Vehicle New",
    rate: 8.99,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 100000),
      ageOfAssetLessThan(12),
      dateOfBirthGreaterThan(23),
      livingArrangements(["Property owner"]),
      employmentType(["Self employed", "Full-time", "Part-time", "Contract", "Casual"]),
      timeOfEmploymentGreaterThan(12),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      creditScoreGreaterThan(810),
    ],
  },
  {
    name: "AAA Tier Over 23 Years Old - Motor Vehicle 12-60 Months Old",
    rate: 8.84,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 100000),
      ageOfAssetBetween(12, 59),
      dateOfBirthGreaterThan(23),
      livingArrangements(["Property owner"]),
      employmentType(["Self employed", "Full-time", "Part-time", "Contract", "Casual"]),
      timeOfEmploymentGreaterThan(12),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      creditScoreGreaterThan(810),
    ],
  },
  {
    name: "AAA Tier Over 23 Years Old - Motor Vehicle 60-119 Months Old",
    rate: 10.19,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 100000),
      ageOfAssetBetween(60, 119),
      dateOfBirthGreaterThan(23),
      livingArrangements(["Property owner"]),
      employmentType(["Self employed", "Full-time", "Part-time", "Contract", "Casual"]),
      timeOfEmploymentGreaterThan(12),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      creditScoreGreaterThan(810),
    ],
  },
  {
    name: "AAA Tier Over 23 Years Old - Motor Vehicle >119 Months Old",
    rate: 10.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 100000),
      ageOfAssetGreaterThan(119),
      dateOfBirthGreaterThan(23),
      livingArrangements(["Property owner"]),
      employmentType(["Self employed", "Full-time", "Part-time", "Contract", "Casual"]),
      timeOfEmploymentGreaterThan(12),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      creditScoreGreaterThan(810),
    ],
  },
];

export default aaa;
