import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4caf50", // Example primary color
    },
    secondary: {
      main: "#ff9800", // Example secondary color
    },
  },
  typography: {
    fontFamily: "Arial, sans-serif", // Example font family
  },
});

const StyledTextarea = ({handle, value,minH,disabled}) => {
  const textareaStyle = {
    width: "100%",
    minHeight: minH,
    padding: "10px",
    backgroundColor: "#f5f4f2", // Grey background color
    border: "none", // No border
    resize: "none", // Disable resizing
    outline: "none", // Remove outline when focused
    fontFamily: "Arial, sans-serif", // Font family
    fontSize: "14px", // Font size
    color: "#000000", // Text color
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ paddingTop: "10px" }}>
        <textarea
        disabled={disabled}
          style={textareaStyle}
          placeholder="Type your notes here..."
          minRows={30}
          value={value}
          onChange={(event) => handle.onChange(event, "notes")}
          onBlur={(event) => handle.onBlur(event, "notes")}
        ></textarea>
      </div>
    </ThemeProvider>
  );
};

export default StyledTextarea;
