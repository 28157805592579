import { Grid, InputAdornment, TextField } from '@mui/material'
// import styled from "styled-components";

// const StyledInputAdornment = styled(InputAdornment)({
//   backgroundColor: 'grey',
//   borderRadius: '4px 0 0 4px', // Adjusts rounding to fit nicely with the TextField
//   padding: '0 8px', // Adds some padding around the adornment
//   color: 'white', // Ensures the text color is readable against the grey background
// });

const PrefixTextField = ({ name, label, value, disabled, type, error, helperText, changeHandler, blurHandler, isPercentage, placeholder }) => 
   (
    <Grid item mt={1}>
      {/* <Typography>
        {label}
      </Typography> */}
      <TextField
        fullWidth
        // required
        variant="filled"
        size="small"
        id={`${name}-id`}
        type={type || "text"}
        disabled={disabled || false}
        name={name || ""}
        label={label || ""}
        placeholder={placeholder || ""} // 0.00
        // value={value || ""}
        value={value}
        error={error || false}
        // helperText={helperText || ""}
        helperText={helperText || error || ""}
        // onChange={changeHandler}
        onChange={(event) => changeHandler(event.target?.value, name)}
        // onBlur={blurHandler}
        onBlur={(event) => blurHandler(name, event.target?.value)}
        style={{ padding: '0' }}
        InputProps={{
          startAdornment: <InputAdornment position="start" >{isPercentage ? '%' : '$'}</InputAdornment>,
        }}
      />
    </Grid>
  )


export default PrefixTextField