import criteria from "../../../utils/criteria";
import { employmentTypes } from "../../../constants";

const { FULL_TIME, PART_TIME, CASUAL, CONTRACT, SELF_EMPLOYED } = employmentTypes;

const { loanAmountBetween, employmentType, timeOfEmploymentGreaterThan, creditScoreBetween } = criteria;

const base = [
  {
    name: "Premium Tier New/Demo",
    rate: [6.75, 20.95],
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 50000), creditScoreBetween(420, 1200)],
  },
];

const selfEmployed = base.map((product) => ({
  ...product,
  criteria: [...product.criteria, employmentType([SELF_EMPLOYED]), timeOfEmploymentGreaterThan(24)],
}));

const fulltime = base.map((product) => ({
  ...product,
  criteria: [...product.criteria, employmentType([FULL_TIME]), timeOfEmploymentGreaterThan(0)],
}));

const parttime = base.map((product) => ({
  ...product,
  criteria: [...product.criteria, employmentType([PART_TIME]), timeOfEmploymentGreaterThan(6)],
}));

const casual = base.map((product) => ({
  ...product,
  criteria: [...product.criteria, employmentType([CASUAL]), timeOfEmploymentGreaterThan(12)],
}));

const contract = base.map((product) => ({
  ...product,
  criteria: [...product.criteria, employmentType([CONTRACT]), timeOfEmploymentGreaterThan(0)],
}));

const baseMain = [...selfEmployed, ...fulltime, ...parttime, ...contract, ...casual];
export default baseMain;
