import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Link, Stack, Tooltip, Typography, } from "@mui/material";
import { startCase, union } from "lodash";
import StyledTextarea from "./ApplicationNotes";
import ApplicationProgressBar from "./ApplicationProgressBar";
import { updateNotes, applicationSelector } from "../../store/slices/applicationFormSlice";
import { APPLICATION_TYPES } from "../../constants";

const assetDetailsItems = [
  "ageOfAsset",
  "assetType",
  "assetValue",
  "typeOfSale",
  "make",
  "model"
]

const assetDetailsItemsGlasses = [
  "ageOfAsset",
  "assetType",
  "assetValue",
  "typeOfSale",
  "glassesMake",
  "glassesModel",
]

const customerDetailsItems = [
  "firstName",
  "lastName",
  "dateOfBirth",
  "emails",
  "phoneNumbers",
  "addresses",
  "livingArrangements",
  "IDNumber"
]

const primaryCustomerDetailsItemsCommercial = [
  "customerAssets",
  "liabilities",
  "privacy",
]

const primaryCustomerDetailsItemsConsumer = [
  "customerAssets",
  "liabilities",
  "income",
  "expenses",
  "employers",
  "privacy",
]

const EditorNotes = React.memo(({ handleApplicationComplete }) => {

  const dispatch = useDispatch();
  const application = useSelector(applicationSelector);
  const { applicationType } = application

  const primaryCustomerDetailsItems = applicationType === APPLICATION_TYPES.COMMERCIAL ? primaryCustomerDetailsItemsCommercial : primaryCustomerDetailsItemsConsumer
  const primaryCustomerDetailsItemsCount = primaryCustomerDetailsItems.length + customerDetailsItems.length

  const notes = application?.notes?.value;

  const [noteValue, setNoteValue] = React.useState("")

  const [applicationProgress, setApplicationProgress] = React.useState(0);

  function handleAssetItemsProgress() {
    let progress = 0
    let complete = []
    let assetList = application?.asset?.model ||
      application?.asset?.make ? assetDetailsItems : assetDetailsItemsGlasses
    for (let [key, value] of Object.entries(application?.asset)) {

      if (assetList.includes(key) && value && value !== "") {
        progress++
        complete.push(key)
      }
    }

    complete = assetDetailsItems?.reduce((acc, val) => {
      if (complete?.includes(val)) {
        acc.push(<Typography style={{ textDecoration: "line-through" }}>{startCase(val)}</Typography >)
        return acc
      }
      acc.push(<Typography>{startCase(val)}</Typography >)
      return acc
    }, [])


    return { progress, complete }
  }

  function handleCustomerItemsProgress(i) {
    let progress = 0
    let complete = []
    let items = []

    if (i === 0) items = union(customerDetailsItems, primaryCustomerDetailsItems)
    else items = customerDetailsItems

    for (let [key, value] of Object.entries(application?.customers?.[i] || {})) {
      if ((items.includes(key) && value && value !== "") || key === "documents" || key === "customerProfile") {
        if (key === "emails") {
          if (application?.customers?.[i]?.emails[0]) {
            progress++
            complete.push(key)
            continue
          }
          continue
        }
        if (key === "phoneNumbers") {
          if (application?.customers?.[i]?.phoneNumbers[0]) {
            progress++
            complete.push(key)
            continue
          }
          continue
        }
        if (key === "addresses") {
          if (application?.customers?.[i]?.addresses.length > 0) {
            progress++
            complete.push(key)
            continue
          }
          continue
        }

        if (applicationType === APPLICATION_TYPES.CONSUMER && key === "employers") {
          if (application?.customers?.[i]?.employers?.[0]) {
            progress++
            complete.push("employers")
            continue
          }
          continue
        }
        if (key === "documents") {
          const hasPrivacy = application?.customers?.[i]?.documents?.length > 0 &&
            application?.customers?.[i]?.documents
              ?.filter((doc) => doc.name === "Privacy agreement")[0]

          if (i === 0) {
            if (hasPrivacy) {
              progress++
              complete.push("privacy")
            }
          }
          continue
        }

        if (key === "customerProfile") {
          if (application?.customers?.[i]?.customerProfile?.livingArrangements) {
            progress++
            complete.push("livingArrangements")
            continue
          }
          continue
        }

        if (value.items) {
          const hasValue = value.items.reduce((acc, val) => val.value ? true : acc, false)
          if (hasValue) {
            progress++
            complete.push(key)
            continue
          }
          continue
        }

        if (items.includes(key) && value && value !== "") {
          progress++
          complete.push(key)
        }
        continue
      }
    }

    const completeItems = items?.reduce((acc, val) => {
      if (complete?.includes(val)) {

        if (val === "addresses") val = "address"
        if (val === "emails") val = "email"
        if (val === "phoneNumbers") val = "phoneNumber"

        acc.push(<Typography style={{ textDecoration: "line-through" }}>{startCase(val)}</Typography >)
        return acc
      }

      if (val === "addresses") val = "address"
      if (val === "emails") val = "email"
      if (val === "phoneNumbers") val = "phoneNumber"

      acc.push(<Typography>{startCase(val)}</Typography >)
      return acc
    }, [])

    return { progress, complete: completeItems }
  }

  React.useEffect(() => {
    const assetProgress = handleAssetItemsProgress()?.progress
    const entityProgress = application?.entities?.[0] ? 1 : 0
    const customersProgress = application?.customers?.map((val, i) => handleCustomerItemsProgress(i)?.progress)
    const customersProgressTotal = customersProgress?.reduce((acc, val) => acc + val, 0)
    const totalRequiredItems = (applicationType === APPLICATION_TYPES.COMMERCIAL ? 1 : 0) + assetDetailsItems.length + primaryCustomerDetailsItems.length + (application?.customers?.length * customerDetailsItems.length)
    const totalProgress = assetProgress + entityProgress + customersProgressTotal
    setApplicationProgress(isNaN(parseInt(totalProgress / totalRequiredItems * 100)) ? 0 : parseInt(totalProgress / totalRequiredItems * 100))

    // console.log({ totalProgress, totalRequiredItems })
    handleApplicationComplete(totalProgress / totalRequiredItems >= 1)
  }, [application]);


  React.useEffect(() => {
    if (notes?.blocks) {
      setNoteValue("")

    } else {
      setNoteValue(notes || "")
    }
  }, [notes]);

  const handle = {
    onChange: (event, key) => {
      setNoteValue(event.target.value)
    },
    onBlur: (event, key) => {
      // console.log("saved click")
      dispatch(
        updateNotes({
          // value: event.target.value,
          value: noteValue,
          application: application._id,
          _id: application.notes?._id,
        }),
      );
    }
  }

  return (
    <Box style={{ marginTop: "20px", padding: "0 15px 20px 15px" }}>
      <Stack alignContent="column">
        <Typography variant="subtitle" style={{ marginBottom: "20px" }}><strong>Application ID:</strong> {application.humanId}</Typography>
      </Stack>
      <Stack alignContent="column" style={{
        marginBottom: "20px"
      }}>
        <Typography
          variant="subtitle"
          style={{
            fontWeight: 700,
          }}>
          Application progress:
        </Typography>
        <Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <ApplicationProgressBar value={applicationProgress} />
            <Typography
              variant="subtitle"
              style={{
                marginLeft: "10px"
              }}>
              {applicationProgress}%
            </Typography>
          </Stack>
          <Stack direction="column" alignItems="baseline">
            <Typography>Items remaining:</Typography>
            <Tooltip arrow title={handleAssetItemsProgress()?.complete} placement="right">
              <Link
                underline="hover"
                style={{ cursor: "pointer", textDecoration: handleAssetItemsProgress()?.progress === 6 && "line-through" }}
                onClick={() => {
                  if (document.getElementById("assetDetails")) document.getElementById("assetDetails").scrollIntoView({ behavior: 'smooth', block: 'start' })
                }}
              >
                Asset details: {handleAssetItemsProgress()?.progress}/6
              </Link>
            </Tooltip>
            {applicationType === APPLICATION_TYPES.COMMERCIAL && <Link
              underline="hover"
              style={{ cursor: "pointer", textDecoration: application?.entities?.[0] && "line-through" }}
              onClick={() => {
                if (document.getElementById("addEntity")) document.getElementById("addEntity").scrollIntoView({ behavior: 'smooth', block: 'start' })
              }}
            >
              {application?.entities?.[0] ? "Entity" : "Add entity"}</Link>
            }
            {application?.customers?.[0] ?
              application?.customers?.map((customer, i) => <>
                <Tooltip arrow title={handleCustomerItemsProgress(i)?.complete} placement="right">
                  <Link
                    underline="hover"
                    style={{
                      cursor: "pointer",
                      textDecoration:
                        handleCustomerItemsProgress(i).progress / (i === 0 ? primaryCustomerDetailsItemsCount : customerDetailsItems.length) === 1 && "line-through"
                    }}
                    onClick={() => {
                      if (document.getElementById(`applicant-${customer.firstName}-${customer.lastName}`)) document.getElementById(`applicant-${customer.firstName}-${customer.lastName}`).scrollIntoView({ behavior: 'smooth', block: 'start' })
                    }}
                  >
                    Guarantor {i + 1} details: {handleCustomerItemsProgress(i).progress}/{i === 0 ? primaryCustomerDetailsItemsCount : customerDetailsItems.length}
                  </Link>
                </Tooltip>
                {applicationType === APPLICATION_TYPES.CONSUMER && <Link
                  underline="hover"
                  style={{ cursor: "pointer", textDecoration: application?.customers?.[i].employers?.[0] && "line-through" }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (document.getElementById("addEmployer")) document.getElementById("addEmployer").scrollIntoView({ behavior: 'smooth', block: 'start' })
                  }}
                >
                  {application?.customers?.[i].employers?.[0] ? "Employer" : "Add employer"}</Link>
                }
              </>
              ) :
              <Link
                underline="hover"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (document.getElementById("addGuarantor")) document.getElementById("addGuarantor").scrollIntoView({ behavior: 'smooth', block: 'start' })
                }}
              >
                Add a guarantor
              </Link>
            }
          </Stack>
        </Stack>
      </Stack>
      <Stack style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      }}>
        <Typography style={{ fontWeight: 700, marginBottom: "5px" }}> Application Notes:</Typography>
        <Button
          size="small"
          variant="contained"
          onClick={handle.onBlur}
        >
          Save
        </Button>
      </Stack>
      <Box
        style={{ border: "1px solid rgba(0, 0, 0, 0.12)", padding: "5px" }}
      >
        <StyledTextarea handle={handle} value={noteValue} minH={"30em"} />
      </Box>
    </Box>
  );
});

export default EditorNotes;