import { livingArrangementsTypes, employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } = livingArrangementsTypes;

const { BENEFITS } = employmentTypes;

const {
  hasDeposit,
  assetType,
  ageOfAssetGreaterThan,
  loanAmountBetween,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
  creditScoreBetween,
  termIsBetween,
} = criteria;

const base = [
  {
    name: "Budget - Motor Vehicle/EV/Motorbike >95 months",
    rate: 24.0,
    productType: "Adverse",
    criteria: [
      ageOfAssetGreaterThan(95),
      hasDeposit(20),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([BENEFITS]),
      timeOfEmploymentGreaterThan(1),
      creditScoreBetween(325, 399),
    ],
  },
];

const lowLoanAmount = base.map((product) => ({
  ...product,
  criteria: [
    ...product.criteria,
    loanAmountBetween(2000, 12500),
    termIsBetween(24, 48),
    assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
  ],
}));

const budget = [...lowLoanAmount];
export default budget;
