import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Box, Typography, Grid, Stack } from "@mui/material";
import { applicationSelector, saveCustomerDetails } from "../store/slices/applicationFormSlice";
import CustomerLoginModel from "../components/Models/CustomerLoginModel";
import CustomerDetails from "../components/CustomerDetails";
import { customerSelector, getCustomerById } from "../store/slices/customerSlice";
import { HtmlTooltip } from "../components/ProductSelector/Product";

// It's consumer contact details page where use can update there record
export default function ContactDetails() {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const application = useSelector(applicationSelector);
  const { isCustomerLogin } = useSelector(customerSelector);

  const contactData = application?.customers?.[0];

  const [contactFieldErrors, setContactFieldErrors] = useState({
    customerTitle: "",
    firstName: "",
    lastName: "",
    IDNumber: "",
    dateOfBirth: "",
    IDCardNumber: "",
    phoneNumber: "",
    emailAddress: "",
    address: "",
    yearsAt: "",
    monthsAt: "",
    IDExpiryDate: "",
  });
  // const [isValidCred, setIsValidCred] = useState(false);
  const [isAcknowledgementAccept, setIsAcknowledgementAccept] = useState(
    contactData?.applications?.[0]?.customer_acknowledgement?.is_acknowledgement_accept || false,
  );
  const [openCustomerLoginModel, setOpenCustomerLoginModel] = useState(true);

  const urlSearchParams = new URLSearchParams(search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const customerId = params.cid;
  const applicationId = params.aid;
  const applicationType = params.type;


  useEffect(() => {
    sessionStorage.removeItem("token");
  }, []);

  useEffect(() => {
    // if (!customerId || !applicationId) navigate("/404")
    if (customerId && !customerId) {
      dispatch(getCustomerById({ customerId: customerId }));
    }
  }, [customerId]);

  // use contact data set to customer model

  const handle = {
    handleCloseCustomerLoginModel: (event, reason) => {
      if (reason !== "backdropClick") {
        setOpenCustomerLoginModel(false);
      }
    },
    submitCustomerDetails: () => {
      if (customerId && applicationId && applicationType && isAcknowledgementAccept) {
        dispatch(saveCustomerDetails({ customerId, applicationId, applicationType, isAcknowledgementAccept }));
      }
    },
  };

  return (
    <Stack justifyContent="center">
      <Grid container item sm={12} md={12} lg={12} xs={12}>
        {/* Customer Details */}
        {isCustomerLogin &&
          contactData &&
          customerId &&
          applicationId &&
          applicationType &&
          !contactData?.isCustomerDetailsSaved && (
            <Box>
              <CustomerDetails
                key={customerId}
                customer={contactData}
                isAcknowledgementAccept={isAcknowledgementAccept}
                setIsAcknowledgementAccept={setIsAcknowledgementAccept}
                fieldErrors={contactFieldErrors}
                setFieldErrors={setContactFieldErrors}
                applicationType={applicationType}
                applicationId={applicationId}
                index={0}
              />

              {/* Make button sticky on bottom */}
              <Box
                width={"100%"}
                display="flex"
                justifyContent="flex-end"
                pb={3}
                pr={3}
                pt={1}
                position={"fixed"}
                bottom={0}
                left={0}
                zIndex={98}
                bgcolor={"#FFFFFF"}
              >
                <HtmlTooltip
                  tooltipPadding={"20px"}
                  title={
                    !isAcknowledgementAccept && (
                      <Typography color="inherit">Please accept customer Acknowledgement</Typography>
                    )
                  }
                >
                  <span>
                    <Button
                      onClick={handle.submitCustomerDetails}
                      variant="contained"
                      disabled={!isAcknowledgementAccept}
                      style={{
                        width: "130px",
                        zIndex: 99,
                      }}
                    >
                      Save
                    </Button>
                  </span>
                </HtmlTooltip>
              </Box>
            </Box>
          )}

        {contactData?.isCustomerDetailsSaved && (
          <Stack alignItems="center" justifyContent={"center"} flexDirection="column" width={"100%"}>
            <Typography variant="h4" component="h1" gutterBottom>
              Thank you completing the application form
            </Typography>
            <Typography variant="body1">Your data has been successfully submitted.</Typography>
          </Stack>
        )}
      </Grid>

      <CustomerLoginModel
        customerId={customerId}
        applicationId={applicationId}
        openCustomerLoginModel={openCustomerLoginModel}
        handleCloseCustomerLoginModel={handle.handleCloseCustomerLoginModel}
      />
    </Stack>
  );
}
