import { employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { BENEFITS } = employmentTypes;

const { assetType, loanAmountBetween, employmentType, termIsLessThanOrEqualTo } = criteria;

const economy = [
  {
    name: "Economy",
    rate: 24.0,
    productType: "Adverse",
    info: ["100% income from government benefits", "Adverse more than 6 months", "Ex-bankkrupt more than 1 day"],
    criteria: [
      loanAmountBetween(5000, 10000),
      termIsLessThanOrEqualTo(36),
      employmentType([BENEFITS]),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan", "Trailered boat", "Motorbike"]),
    ],
  },
];
export default economy;
