import { type FC, useEffect } from "react";
import { Loader } from "../../components/Loader";
import { VITE_WORKOS_AUTHKIT_DOMAIN } from "../../constants";

const SignUp: FC = () => {
  // Redirect to the WorkOS sign-up page .
  useEffect(() => {
    window.location.replace(`${VITE_WORKOS_AUTHKIT_DOMAIN}/sign-up`);
  }, []);

  return <Loader />;
};

export { SignUp };
