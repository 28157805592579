import React from 'react'
import { useDispatch } from 'react-redux'
import { Box, Grid } from '@mui/material'
import PrefixTextField from './common/PrefixTextField'
import CalCustomTitleBar from './common/CalCustomTitleBar'
import ShowValues from './common/ShowValues'
import calculateBaseRate from '../../utils/calculateBaseRate'
import { setCalculatorFieldsError, updateLoanDetails } from '../../store/slices/applicationFormSlice'

const CalSingleRepayment = React.memo(({ allValues, calculatorFieldsErrors, validateField, saveLoanDetails, setAllValue }) => {
  const dispatch = useDispatch()
  const { _id, accountFee = 0, singleRepaymentTotal = 0, repayments = 0, repaymentStructure = "",
    financedWithBrokerage = 0, term = 0, balloonAmount = 0, inArrears } = allValues
  let { rate = 0 } = allValues

  const handle = {
    onChangeField: async (e, name) => {
      let isValid = true;

      if (e === "") {
        setAllValue({ ...allValues, [name]: "", rate: 0 });
        dispatch(setCalculatorFieldsError({ ...calculatorFieldsErrors, singleRepayment: { ...calculatorFieldsErrors.singleRepayment, [name]: "" } }))
        return;
      }

      // if (name === "repayments") {
      //   const newRate = calculateComparisonRate(
      //     term,
      //     -(+e),
      //     financedWithBrokerage,
      //     balloonAmount,
      //     inArrears === "Yes" ? 1 : 0,
      //     0.1,
      //   );

      //   setAllValue({ ...allValues, [name]: e, rate: +(newRate * 12 * 100)?.toFixed(2) });
      //   return
      //   // isValid = isValidInput(e);
      // }

      if (!isValid) {
        await validateField({ fieldName: name, value: e, fieldObj: 'singleRepayment' });
        return;
      }

      setAllValue({ ...allValues, [name]: e });
      await validateField({ fieldName: name, value: e, fieldObj: 'singleRepayment' });
    },
    blurFn: async (fieldName, value) => {
      // Use for itc value
      try {
        if (fieldName === "repayments") {
          // Rate and Effective rate functions are same just values are different
          rate = calculateBaseRate(
            term,
            (+value),
            -financedWithBrokerage,
            balloonAmount,
            inArrears === "Yes" ? 0 : 1,
            0.1
          );

          setAllValue({ ...allValues, [fieldName]: value, rate: parseFloat(rate) });
        }
        if (calculatorFieldsErrors['singleRepayment'][fieldName] === "")
          if (fieldName === "repayments") {
            dispatch(updateLoanDetails({ _id: _id, repayments: +value, rate: +rate?.toFixed(4) || 0 }));
          } else {
            saveLoanDetails({
              [fieldName]: +value || 0,
            });
          }
      } catch (error) {
        console.log("error", error)
      }
    },
  }

  return (
    <Box>
      <CalCustomTitleBar title={"Single Repayment"} />

      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        mb={"20px"}
        spacing={1}
        direction="row"
      >
        <Grid item xs={12} sm={6}>
          <PrefixTextField
            name="repayments"
            label="Net"
            value={repayments}
            disabled={repaymentStructure !== "Single Payments"}
            error={calculatorFieldsErrors?.singleRepayment?.repayments}
            changeHandler={handle.onChangeField}
            blurHandler={handle.blurFn}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PrefixTextField
            name="repaymentAccountFee"
            label="Account Fee (Net)"
            disabled={true}
            value={accountFee}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ShowValues
            label={"Total"}
            value={singleRepaymentTotal}
          />
        </Grid>
      </Grid>
    </Box>
  )
})

export default CalSingleRepayment