import React from "react";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import {
  assetSelector,
  loanDetailsSelector,
} from "../../store/slices/applicationFormSlice";

const dollarStringOptions = {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
}

const LoanSummary = React.memo(() => {

  const { assetValue = 0 } = useSelector(assetSelector);

  const {
    deposit = 0,
    tradeIn = 0,
    payout = 0
  } = useSelector(loanDetailsSelector);

  const financeAmount = (1 * (+assetValue) - (+deposit) - (+tradeIn) + (+payout)) || 0


  return (
    <>
      <Typography style={{ margin: "0 0 5px 0", fontSize: "18px" }}>Loan Summary</Typography>
      <Typography style={{ margin: "0 0 5px 0" }}>
        <strong style={{ fontSize: "14px" }}>Finance amount</strong> <br></br>
        {financeAmount.toLocaleString("en-US", dollarStringOptions)}
      </Typography>
    </>
  )
})


export default LoanSummary;