import { employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { BENEFITS } = employmentTypes;

const {
  termIsBetween,
  assetType,
  loanAmountBetween,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
} = criteria;

const benefits = [
  {
    name: "Sub Prime (lite)",
    rate: [19.49, 29.99],
    productType: "Adverse",
    criteria: [
      loanAmountBetween(7000, 10000),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
        "Motorbike",
        "ATV/Off-Road Bike",
        "Tractor",
        "Ride on mower",
        "Horse float",
        "Trailer",
        "Caravan",
        "Motor home",
        "Trailered boat",
        "Jetski/PWC",
      ]),
      termIsBetween(36, 60),
      employmentType([BENEFITS]),
      timeOfEmploymentGreaterThan(1),
      creditScoreGreaterThan(0, 450),
    ],
  },
];
export default benefits;
