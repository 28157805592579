import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Skeleton,
  Stack,
} from "@mui/material";
import moment from "moment";
import { debounce, isNumber } from "lodash";
import {
  createEntity,
  entitySelector,
  saveValue,
  userDetailsSelector,
} from "../../../store/slices/applicationFormSlice";
import { getBusinessByABN, getBusinessByName } from "../../../services/abr";
import readableABNString from "../../../utils/readableABNString";
import readableACNString from "../../../utils/readableACNString";


const QuoteEntity = ({ application , disabled }) => {
  const dispatch = useDispatch();
  const { gettingASICData, gettingEntityDetails } = useSelector(userDetailsSelector);
  const entity = useSelector(entitySelector);
  const [businessSearchValue, setBusinessSearchValue] = useState("");
  const [businessSearchInputText, setBusinessSearchInputText] = useState("");
  const [loadingBusinessSearch, setLoadingBusinessSearch] = useState("");
  const [entityOptions, setEntityOptions] = useState([]);

  const debounced = useMemo(
    () => debounce(async (inputValue) => {
      if (inputValue) {
        console.log(inputValue?.replace(/\s/g, '') * 1)
        if (isNumber(inputValue?.replace(/\s/g, '') * 1)) {

          const businessByABN = await getBusinessByABN(inputValue.replace(/\s/g, '') * 1);
          console.log({ businessByABN })
          setLoadingBusinessSearch(false);
          if (businessByABN.Abn.length > 1) {
            return setEntityOptions([businessByABN]);
          }
        }

        setLoadingBusinessSearch(true);
        const businesses = await getBusinessByName(inputValue);
        console.log(businesses)
        if (businesses?.Names?.length > 0) {
          setLoadingBusinessSearch(false);
          return setEntityOptions(
            businesses.Names.map((i) =>
              i
            )
          );
        }
        setEntityOptions([
          {
            Name: "No results found",
          },
        ]);
      }
    }, 1000), []);

  const handleEntityDetails = async (details) => {
    dispatch(saveValue({ gettingEntityDetails: true }));
    console.log("details", details);
    const abrData = await getBusinessByABN(details.Abn);
    if (abrData.EntityName !== entity?.entityName) {
      dispatch(createEntity({ abrData, applicationId: application._id }));
    } else {
      dispatch(saveValue({ gettingEntityDetails: false }));
    }
  };

  function handleGetASICData() {

    setTimeout(() => {
      dispatch(saveValue({ gotASICData: false }))
    }, 4000);
  }

  useEffect(() => {
    if (gettingASICData === false) {
      handleGetASICData();
      dispatch(saveValue({ gettingASICData: null }));
    }

  }, [gettingASICData]);

  useEffect(() => {
    debounced(businessSearchInputText);
  }, [businessSearchValue, businessSearchInputText]);

  useEffect(() => {
    if (!gettingEntityDetails) {
      setBusinessSearchInputText("");
      setBusinessSearchValue("");
    }
  }, [gettingEntityDetails]);

  return (
    <>
      {application.entities &&
        application.entities.map((entity) => (
          <Stack direction="column">
            {entity?.entityName && (
              <Grid item lg={12} md={12} sm={12} style={{ margin: "0 0 7px 0" }}>
                <Typography variant="subtitle2">Entity name</Typography>
                <Typography variant="body2">
                  {entity?.entityName}
                </Typography>
              </Grid>
            )}

            {entity?.entityType && (
              <Grid item lg={12} md={12} sm={12} style={{ margin: "0 0 7px 0" }}>
                <Typography variant="subtitle2">Entity type</Typography>
                <Typography variant="body2">
                  {entity?.entityType}
                </Typography>
              </Grid>
            )}

            <Stack direction="row">
              {entity?.abn && (
                <Grid item lg={6} md={6} sm={12} style={{ margin: "0 0 7px 0" }}>
                  <Typography variant="subtitle2">ABN</Typography>
                  <Typography variant="body2">
                    {readableABNString(entity?.abn)}
                  </Typography>
                </Grid>
              )}

              {entity?.acn && (
                <Grid item lg={6} md={6} sm={12}>
                  <Typography variant="subtitle2">ACN</Typography>
                  <Typography variant="body2">
                    {readableACNString(entity?.acn)}
                  </Typography>
                </Grid>
              )}
            </Stack>


            <Stack direction="row">
              {entity?.timeInBusiness && (
                <Grid item md={6} sm={12} xs={12} style={{ margin: "0 0 7px 0" }}>
                  <Typography variant="subtitle2">
                    Trading for
                  </Typography>
                  <Typography variant="body2">
                    {`${moment().diff(moment(entity?.timeInBusiness, "YYYY-MM-DD"),
                      "months"
                    )} months`}
                  </Typography>
                </Grid>
              )}


              {entity?.gst && (
                <Grid item md={6} sm={12} xs={12} style={{ margin: "0 0 7px 0" }}>
                  <Typography variant="subtitle2">GST</Typography>
                  <Typography variant="body2">
                    {entity?.gst
                      ? `${moment().diff(
                        moment(entity?.gst),
                        "months"
                      )} months`
                      : "No"}
                  </Typography>
                </Grid>
              )}
            </Stack>
          </Stack>
        ))
      }

      {gettingEntityDetails &&
        (
          <Grid item container spacing={2} style={{ marginBottom: "15px" }}>
            <Grid item sm={4}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Grid>
            <Grid item sm={4}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Grid>
            <Grid item sm={4}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Grid>
            <Grid item sm={4}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Grid>
            <Grid item sm={4}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Grid>
            <Grid item sm={4}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Grid>
            <Grid item container>
              <Grid item sm={4}>
                <Skeleton variant="text" />
              </Grid>
              <Grid item sm={12}>
                <Skeleton variant="text" />
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item sm={4}>
                <Skeleton variant="text" />
              </Grid>
              <Grid item sm={12}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
            </Grid>
          </Grid>

        )
      }

      {(!entity || entity?.length <= 0) &&
        <Grid container style={{ margin: "7px 0 20px 0" }}>
          <Grid item lg={12} md={12} sm={12}>
            <Autocomplete
              autoComplete
              autoSelect={true}
              name="Business Search"
              value=""
              noOptionsText="Type to start search"
              loadingText="Searching..."
              defaultValue=""
              clearOnBlur={true}
              blurOnSelect={true}
              onChange={(event, newValue, reason) => {
                console.log(newValue, entity?.entityName, reason);
                if (reason === "select-option") {
                  if (newValue) {
                    setBusinessSearchValue(
                      newValue?.Name || newValue?.entityName
                    );

                    return;
                  }
                  setBusinessSearchValue("");
                  setBusinessSearchInputText("");
                }
                if (reason === "blur") {
                  setBusinessSearchInputText("");
                  handleEntityDetails(newValue);
                }
              }}
              filterOptions={(x) => x}
              onInputChange={(event, newInputValue) => {
                const value = Number(newInputValue * 1) ? newInputValue * 1 : newInputValue
                setBusinessSearchInputText(value);
                if (newInputValue === "") setEntityOptions([]);
              }}
              getOptionLabel={(option) => {
                if (option.EntityName) {
                  return option.EntityName
                    ? `${option.EntityName} ${readableABNString(option.Abn)}`
                    : "";
                }

                return option.Name
                  ? `${option.Name} ${readableABNString(option.Abn)}`
                  : "";
              }}
              options={entityOptions}
              loading={loadingBusinessSearch}
              renderInput={(params) => (
                <TextField
                  variant="filled"
                  {...params}
                  type="text"
                  label="Add a business"
                  value=""
                  placeholder="Search with ABN or business name"
                  style={{ background: "#ffffff" }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingBusinessSearch ? <CircularProgress /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
        </Grid >
      }
    </>
  );
}

export default QuoteEntity