import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    // [`&.${linearProgressClasses.colorPrimary}`]: {
    //     backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    // },
    // [`& .${linearProgressClasses.bar}`]: {
    //     borderRadius: 5,
    //     backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    // },
}));

export default function ApplicationProgressBar({ value }) {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <BorderLinearProgress variant="determinate" value={value} color={value === 100 ? "success" : "secondary"} />
        </Box>
    );
}