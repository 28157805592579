import lightDoc from "./lightDoc";
import advserseOne from "./advserseOne";
import advserseTwo from "./advserseTwo";
import criteria from "../../../utils/criteria";
import { typeOfSaleList } from "../../../constants";

const {
  livingArrangements,
  typeOfSale,
  assetValueBetween,
  assetType,
  ageOfAssetGreaterThan,
  ageOfAssetLessThan,
  ageOfAssetBetween,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  gstRegistrationGreaterThan,
  creditScoreGreaterThan,
  ageOfAssetAtEndLessThan,
  creditScoreBetween,
} = criteria;

const liberty = {
  date: "11 Oct 2023",
  lender: "Liberty",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos%2Fliberty.gif",
  actions: ["upload"],
  lenderCredentials: [
    // {
    //   label: "Email",
    //   attribute: "email",
    // },
    {
      label: "User Name",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    // {
    //   label: "Merchant ID",
    //   attribute: "organisationId",
    // },
    // {
    //   label: "User ID",
    //   attribute: "brokerId",
    // },
    // {
    //   label: "Api Key",
    //   attribute: "apiKey",
    // },
  ],
  requiredFields: {
    entity: [
      "abn",
      "entityName",
      "entityType",
      "industryType",
      "turnover",
      // "addresses[0].streetAddress",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
    ],
    customer: [
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      "customerTitle",
      "firstName",
      "lastName",
      "dateOfBirth",
      "IDNumber",
      "IDCardNumber",
      "IDExpiryDate",
      // "addresses[0].streetAddress",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      // "emails[0].address",
      // "phoneNumbers[0].number",
      "customerProfile.livingArrangements",
      "customerProfile.numberOfDependants",
      "customerProfile.residencyStatus",
      "customerProfile.maritalStatus",
      "customerAssets.[0].assetType",
      "customerAssets.[0].value",
      "customerAssets.[0].description",
      "liabilities.[0].liabilitiesType",
      "liabilities.[0].outstandingAmount",
      "liabilities.[0].limit",
      "liabilities.[0].financier",
      "liabilities.[0].repaymentFrequency",
      "liabilities.[0].repaymentAmount",
      "liabilities.[0].description",
    ],
    loanDetails: ["rate"],
    asset: ["assetType", "typeOfSale", "ageOfAsset", "glassesMake", "glassesModel", "glassesVariant", "assetValue","condition"],
  },
  fees: [
    {
      name: "Athorised supplier",
      value: 545,
      capitalised: false,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.DEALER_SALE)],
    },
    {
      name: "Private sale fee",
      value: 545,
      capitalised: false,
      frequency: "upfront",
      criteria: [typeOfSale("Private sale")],
    },
    {
      name: "Document fee",
      value: 1450,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Equalisation fee score <500",
      value: 8,
      capitalised: true,
      frequency: "upfront",
      calc: "percent",
      criteria: [creditScoreBetween(400, 499)],
    },
    {
      name: "Equalisation fee score <600",
      value: 3,
      capitalised: true,
      frequency: "upfront",
      calc: "percent",
      criteria: [creditScoreBetween(500, 599)],
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 10,
      valueType: "percentage",
      criteria: [assetValueBetween(5000, 100000)],
    },
  ],
  loading: [
    // {
    //   name: "Non property owner",
    //   value: 2,
    //   valueType: "percentage",
    //   criteria: [livingArrangements(["Renting", "Boarding", "Living w/ parents"]),],
    // },
    {
      name: "EV discount",
      value: -0.25,
      valueType: "percentage",
      criteria: [assetType(["Electric Motor Vehicle (<4.5 tonnes)"])],
    },
  ],
  info: [
    `Start-ups accepted as long as home owner - Bank Statements and Accountant prepared projections required`,
    `Full Doc accepted - Bank Statements and Financials required if <3 yeras ABN & GST`,
  ],
  productTiers: [
    ...lightDoc,
    ...advserseOne,
    ...advserseTwo,
    {
      name: "Commercial Motor",
      rate: 7.95,
      productType: "Low doc",
      info: ["No Adverse"],
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(36),
        ageOfAssetLessThan(12),
        livingArrangements(["Property owner"]),
        ageOfAssetAtEndLessThan(21),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(750),
      ],
    },
    {
      name: "Commercial Motor",
      rate: 8.45,
      productType: "Low doc",
      info: ["No Adverse"],
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(36),
        ageOfAssetBetween(12, 48),
        livingArrangements(["Property owner"]),
        ageOfAssetAtEndLessThan(21),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(750),
      ],
    },
    {
      name: "Commercial Motor",
      rate: 8.95,
      productType: "Low doc",
      info: ["No Adverse"],
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(36),
        ageOfAssetBetween(48, 120),
        livingArrangements(["Property owner"]),
        ageOfAssetAtEndLessThan(21),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(750),
      ],
    },
    {
      name: "Commercial Motor",
      rate: 9.15,
      productType: "Low doc",
      info: ["No Adverse"],
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(36),
        ageOfAssetGreaterThan(120),
        livingArrangements(["Property owner"]),
        ageOfAssetAtEndLessThan(21),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(750),
      ],
    },

    {
      name: "Commercial Motor",
      rate: 8.45,
      productType: "Low doc",
      info: ["No Adverse"],
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        livingArrangements(["Property owner"]),
        ageOfAssetAtEndLessThan(21),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(600),
      ],
    },
    {
      name: "Commercial Motor",
      rate: 8.95,
      productType: "Low doc",
      info: ["No Adverse"],
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetBetween(12, 48),
        livingArrangements(["Property owner"]),
        ageOfAssetAtEndLessThan(21),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(600),
      ],
    },
    {
      name: "Commercial Motor",
      rate: 9.45,
      productType: "Low doc",
      info: ["No Adverse"],
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetBetween(48, 120),
        livingArrangements(["Property owner"]),
        ageOfAssetAtEndLessThan(21),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(600),
      ],
    },
    {
      name: "Commercial Motor",
      rate: 9.65,
      productType: "Low doc",
      info: ["No Adverse"],
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetGreaterThan(120),
        livingArrangements(["Property owner"]),
        ageOfAssetAtEndLessThan(21),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(600),
      ],
    },
  ],
};
export default liberty;
