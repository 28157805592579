import React from "react";

import { CardContent, CircularProgress, Stack, Typography, useMediaQuery } from "@mui/material";

import UploadFileIcon from "@mui/icons-material/UploadFile";

const CustomCardSending = (props) => {
  const { title, description, imagePath, loadingState, financialState, icon, lastTitle, disabled } = props;
  const isLargeScreen = useMediaQuery("(min-width: 768px)");
  return (
    <React.Fragment>
      <CardContent
        style={{
          cursor: disabled ? "auto" : "",
          opacity: disabled ? "0.4" : "",
          backgroundColor: disabled ? "#dfdfdf" : "",
        }}
      >
        <Stack direction="column" spacing={2} alignItems={"center"} mb={"10px"}>
          {imagePath !== "" && (
            <img height="40px" style={{ maxWidth: `${!isLargeScreen ? "160px" : "unset"}` }} src={imagePath} alt="" />
          )}
          {financialState && (
            <Typography fontWeight={800} letterSpacing={"-1px"}>
              {financialState}
            </Typography>
          )}
          {icon && <UploadFileIcon fontSize="large" />}
          <Typography fontWeight={600}>{title}</Typography>
        </Stack>
        <Typography fontSize={13}>{description}</Typography>
      </CardContent>
      {loadingState && (
        <Stack
          spacing={2}
          style={{
            background: "rgba(0,0,0, 0.5)",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            color: "#fff",
          }}
        >
          <CircularProgress />
          <Typography>{lastTitle}</Typography>
        </Stack>
      )}
    </React.Fragment>
  );
};

export default CustomCardSending;
