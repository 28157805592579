import criteria from "../../../utils/criteria";

const {
  assetType,
  ageOfAssetBetween,
  ageOfAssetLessThan,
  loanAmountBetween,
  creditScoreGreaterThan,
  livingArrangements,
} = criteria;

const a = [
  {
    name: "Tier A - Motor Vehicle New/Demo",
    rate: 9.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 125000),
      ageOfAssetLessThan(12),
      livingArrangements(["Property owner"]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      creditScoreGreaterThan(750),
    ],
  },
  {
    name: "Tier A - Motor Vehicle 12-36 months old",
    rate: 9.99,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 125000),
      ageOfAssetBetween(12, 36),
      livingArrangements(["Property owner"]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      creditScoreGreaterThan(750),
    ],
  },
  {
    name: "Tier A - Motor Vehicle 37-72 months old",
    rate: 10.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 125000),
      ageOfAssetBetween(37, 72),
      livingArrangements(["Property owner"]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      creditScoreGreaterThan(750),
    ],
  },
  {
    name: "Tier A - Motor Vehicle 73-120 months old",
    rate: 11.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 125000),
      ageOfAssetBetween(73, 120),
      livingArrangements(["Property owner"]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      creditScoreGreaterThan(750),
    ],
  },

  {
    name: "Tier A2 - Motor Vehicle New/Demo",
    rate: 10.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 125000),
      ageOfAssetLessThan(12),
      livingArrangements(["Renting"]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      creditScoreGreaterThan(750),
    ],
  },
  {
    name: "Tier A2 - Motor Vehicle 12-36 months old",
    rate: 10.99,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 125000),
      ageOfAssetBetween(12, 36),
      livingArrangements(["Renting"]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      creditScoreGreaterThan(750),
    ],
  },
  {
    name: "Tier A2 - Motor Vehicle 37-72 months old",
    rate: 11.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 125000),
      ageOfAssetBetween(37, 72),
      livingArrangements(["Renting"]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      creditScoreGreaterThan(750),
    ],
  },
  {
    name: "Tier A2 - Motor Vehicle 73-120 months old",
    rate: 13.19,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 125000),
      ageOfAssetBetween(73, 120),
      livingArrangements(["Renting"]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      creditScoreGreaterThan(750),
    ],
  },

  {
    name: "Tier A - Caravan New/Demo",
    rate: 9.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 110000),
      ageOfAssetLessThan(12),
      livingArrangements(["Property owner"]),

      assetType(["Caravan"]),
      creditScoreGreaterThan(750),
    ],
  },
  {
    name: "Tier A - Caravan 12-36 months old",
    rate: 9.99,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 110000),
      ageOfAssetBetween(12, 36),
      livingArrangements(["Property owner"]),

      assetType(["Caravan"]),
      creditScoreGreaterThan(750),
    ],
  },
  {
    name: "Tier A - Caravan 37-72 months old",
    rate: 10.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 110000),
      ageOfAssetBetween(37, 72),
      livingArrangements(["Property owner"]),

      assetType(["Caravan"]),
      creditScoreGreaterThan(750),
    ],
  },
  {
    name: "Tier A - Caravan 73-120 months old",
    rate: 11.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 110000),
      ageOfAssetBetween(73, 120),
      livingArrangements(["Property owner"]),

      assetType(["Caravan"]),
      creditScoreGreaterThan(750),
    ],
  },

  {
    name: "Tier A2 - Caravan New/Demo",
    rate: 10.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 110000),
      ageOfAssetLessThan(12),
      livingArrangements(["Renting"]),

      assetType(["Caravan"]),
      creditScoreGreaterThan(750),
    ],
  },
  {
    name: "Tier A2 - Caravan 12-36 months old",
    rate: 10.99,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 110000),
      ageOfAssetBetween(12, 36),
      livingArrangements(["Renting"]),

      assetType(["Caravan"]),
      creditScoreGreaterThan(750),
    ],
  },
  {
    name: "Tier A2 - Caravan 37-72 months old",
    rate: 11.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 110000),
      ageOfAssetBetween(37, 72),
      livingArrangements(["Renting"]),

      assetType(["Caravan"]),
      creditScoreGreaterThan(750),
    ],
  },
  {
    name: "Tier A2 - Caravan 73-120 months old",
    rate: 13.19,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 110000),
      ageOfAssetBetween(73, 120),
      livingArrangements(["Renting"]),

      assetType(["Caravan"]),
      creditScoreGreaterThan(750),
    ],
  },

  {
    name: "Tier A - Motorbike New/Demo",
    rate: 9.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 55000),
      ageOfAssetLessThan(12),
      livingArrangements(["Property owner"]),

      assetType(["Motorbike"]),
      creditScoreGreaterThan(750),
    ],
  },
  {
    name: "Tier A - Motorbike 12-36 months old",
    rate: 9.99,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 55000),
      ageOfAssetBetween(12, 36),
      livingArrangements(["Property owner"]),

      assetType(["Motorbike"]),
      creditScoreGreaterThan(750),
    ],
  },
  {
    name: "Tier A - Motorbike 37-72 months old",
    rate: 10.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 55000),
      ageOfAssetBetween(37, 72),
      livingArrangements(["Property owner"]),

      assetType(["Motorbike"]),
      creditScoreGreaterThan(750),
    ],
  },
  {
    name: "Tier A - Motorbike 73-120 months old",
    rate: 11.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 55000),
      ageOfAssetBetween(73, 120),
      livingArrangements(["Property owner"]),

      assetType(["Motorbike"]),
      creditScoreGreaterThan(750),
    ],
  },

  {
    name: "Tier A2 - Motorbike New/Demo",
    rate: 10.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 55000),
      ageOfAssetLessThan(12),
      livingArrangements(["Renting"]),

      assetType(["Motorbike"]),
      creditScoreGreaterThan(750),
    ],
  },
  {
    name: "Tier A2 - Motorbike 12-36 months old",
    rate: 10.99,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 55000),
      ageOfAssetBetween(12, 36),
      livingArrangements(["Renting"]),

      assetType(["Motorbike"]),
      creditScoreGreaterThan(750),
    ],
  },
  {
    name: "Tier A2 - Motorbike 37-72 months old",
    rate: 11.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 55000),
      ageOfAssetBetween(37, 72),
      livingArrangements(["Renting"]),

      assetType(["Motorbike"]),
      creditScoreGreaterThan(750),
    ],
  },
  {
    name: "Tier A2 - Motorbike 73-120 months old",
    rate: 13.19,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 55000),
      ageOfAssetBetween(73, 120),
      livingArrangements(["Renting"]),

      assetType(["Motorbike"]),
      creditScoreGreaterThan(750),
    ],
  },
];
export default a;
