import criteria from "../../../utils/criteria";

const {
  livingArrangements,
  assetType,
  ageOfAssetGreaterThan,
  ageOfAssetLessThan,
  ageOfAssetBetween,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  gstRegistrationGreaterThan,
  creditScoreGreaterThan,
  assetCondition
} = criteria;

function propertyOwnerAutoList(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, loanAmountBetween(2000, 180000), livingArrangements(["Property owner"])],
  }));
}

function propertyOwnerList(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, loanAmountBetween(2000, 250000), livingArrangements(["Property owner"])],
  }));
}

function nonPopertyOwnerList(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      loanAmountBetween(2000, 150000),
      livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
    ],
  }));
}

const lightDoc = [
  ...propertyOwnerAutoList(auto()),
  ...propertyOwnerList(base()),
  ...nonPopertyOwnerList(auto()),
  ...nonPopertyOwnerList(base()),
];

function auto() {
  return [
    {
      name: "Light doc - Tier 1",
      rate: 8.65,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(2000, 180000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(96),
        ageOfAssetAtEndLessThan(16),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Light doc - Tier 1",
      rate: 9.45,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(2000, 180000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetBetween(96, 144),
        ageOfAssetAtEndLessThan(16),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Low doc - Tier 1",
      rate: 10.30,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(2000, 180000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetBetween(145, 180),
        ageOfAssetAtEndLessThan(16),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(500),
      ],
    },
  ];
}

function base() {
  return [
    {
      name: "Light doc - Tier 2",
      rate: 8.65,
      productType: "Light doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(96),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Warehouse Equipment",
        ]),
        ageOfAssetAtEndLessThan(26),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Light doc - Tier 2",
      rate: 9.45,
      productType: "Light doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetBetween(96, 120),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Warehouse Equipment",
        ]),
        ageOfAssetAtEndLessThan(26),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Light doc - Tier 2",
      rate: 10.40,
      productType: "Light doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetBetween(121, 180),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Warehouse Equipment",
        ]),
        ageOfAssetAtEndLessThan(26),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Light doc - Tier 2",
      rate: 11.40,
      productType: "Light doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetBetween(181, 300),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Warehouse Equipment",
          "Trailers and Caravans",
        ]),
        ageOfAssetAtEndLessThan(26),
        creditScoreGreaterThan(500),
      ],
    },

    {
      name: "Light doc - Tier 3",
      rate: 8.65,
      productType: "Light doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(96),
        assetType(["Agricultural & Landscaping Equipment (wheeled or tracked)"]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Light doc - Tier 3",
      rate: 9.45,
      productType: "Light doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetBetween(96, 120),
        assetType(["Agricultural & Landscaping Equipment (wheeled or tracked)"]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Light doc - Tier 3",
      rate: 10.40,
      productType: "Light doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetBetween(121, 180),
        assetType(["Agricultural & Landscaping Equipment (wheeled or tracked)"]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Light doc - Tier 3",
      rate: 11.40,
      productType: "Light doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetBetween(181, 300),
        assetType(["Agricultural & Landscaping Equipment (wheeled or tracked)"]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(500),
      ],
    },

    {
      name: "Light doc - Tier 3",
      rate: 8.65,
      productType: "Light doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(96),
        assetType(["Trailers and Caravans"]),
        ageOfAssetAtEndLessThan(26),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Light doc - Tier 3",
      rate: 9.45,
      productType: "Light doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetBetween(97, 144),
        assetType(["Trailers and Caravans"]),
        ageOfAssetAtEndLessThan(26),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Light doc - Tier 3",
      rate: 10.40,
      productType: "Light doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetBetween(145, 180),
        assetType(["Trailers and Caravans"]),
        ageOfAssetAtEndLessThan(26),
        creditScoreGreaterThan(500),
      ],
    },

    {
      name: "Light doc - Tier 3",
      rate: 9.75,
      productType: "Light doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        // ageOfAssetLessThan(12),
        assetCondition(["New"]),
        assetType(["Industrial Plant & Printing"]),
        ageOfAssetAtEndLessThan(21),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Light doc - Tier 3",
      rate: 9.75,
      productType: "Light doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        // ageOfAssetLessThan(12),
        assetCondition(["New"]),
        assetType(["Medical Equipment"]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(500),
      ],
    },

    {
      name: "Light doc - Tier 3",
      rate: 12.15,
      productType: "Light doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        // ageOfAssetLessThan(12),
        assetCondition(["New"]),
        ageOfAssetAtEndLessThan(6),
        assetType(["IT, Computer & Audio Equipment", "Office Equipment"]),
        creditScoreGreaterThan(500),
      ],
    },

    {
      name: "Light doc - Tier 3",
      rate: 12.15,
      productType: "Light doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        // ageOfAssetLessThan(12),
        assetCondition(["New"]),
        assetType(["Restaurant & Catering Equipment"]),
        ageOfAssetAtEndLessThan(4),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Light doc - Tier 3",
      rate: 12.15,
      productType: "Light doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        // ageOfAssetLessThan(12),
        assetCondition(["New"]),
        assetType(["Office Furniture"]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Light doc - Tier 3",
      rate: 12.15,
      productType: "Light doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        // ageOfAssetLessThan(12),
        assetCondition(["New"]),
        assetType(["Office Fitout"]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(500),
      ],
    },

    {
      name: "Light doc - Tier 3",
      rate: 9.75,
      productType: "Light doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        // ageOfAssetLessThan(12),
        assetCondition(["New"]),
        ageOfAssetAtEndLessThan(8),
        assetType(["Solar"]),
        creditScoreGreaterThan(500),
      ],
    },

    {
      name: "Light doc - Tier 4",
      rate: 12.15,
      productType: "Light doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        // ageOfAssetLessThan(12),
        assetCondition(["New"]),
        ageOfAssetAtEndLessThan(6),
        assetType(["Other"]),
        creditScoreGreaterThan(500),
      ],
    },

    {
      name: "Light doc - Tier 3",
      rate: 10.75,
      productType: "Light doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        assetCondition(["Used"]),
        // ageOfAssetGreaterThan(11),
        assetType(["Medical Equipment"]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(500),
      ],
    },

    {
      name: "Light doc - Tier 3",
      rate: 9.45,
      productType: "Light doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetGreaterThan(11),
        assetType(["Agricultural & Landscaping Equipment (wheeled or tracked)"]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(500),
      ],
    },

    {
      name: "Light doc - Tier 3",
      rate: 10.75,
      productType: "Light doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        // ageOfAssetGreaterThan(11),
        assetCondition(["Used"]),
        assetType(["Industrial Plant & Printing"]),
        ageOfAssetAtEndLessThan(21),

        creditScoreGreaterThan(500),
      ],
    },

    {
      name: "Light doc - Tier 4",
      rate: 12.95,
      productType: "Light doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        // ageOfAssetGreaterThan(11),
        assetCondition(["Used"]),
        ageOfAssetAtEndLessThan(8),
        assetType(["Other"]),
        creditScoreGreaterThan(500),
      ],
    },

  ];
}
export default lightDoc;
