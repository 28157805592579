import base from "./base";
import { livingArrangementsTypes, employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } = livingArrangementsTypes;
const { FULL_TIME, PART_TIME, CASUAL, CONTRACT, SELF_EMPLOYED } = employmentTypes;

const {
  termIsGreaterThan,
  typeOfSale,
  assetValueBetween,
  loanAmountBetween,
  livingArrangements,
  employmentType,
  timeOfEmploymentGreaterThan,
} = criteria;

function selfEmployed(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, employmentType([SELF_EMPLOYED]), timeOfEmploymentGreaterThan(12)],
  }));
}

function permanentEmployed(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, employmentType([FULL_TIME, PART_TIME]), timeOfEmploymentGreaterThan(6)],
  }));
}

function temporaryEmployed(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, employmentType([CASUAL, CONTRACT]), timeOfEmploymentGreaterThan(4)],
  }));
}

function propertyOwnerList(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, livingArrangements([PROPERTY_OWNER])],
  }));
}

function nonPropertyOwnerList(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS])],
  }));
}

const withEmploymentCriteriaAdded = [...selfEmployed(base), ...permanentEmployed(base), ...temporaryEmployed(base)];

const wisr = {
  date: "16 May 2024",
  lender: "WISR",
  type: "consumer",
  logoURL: "https://assets.financeable.com.au/lender-logos%2FWisr-logo.png",
  actions: ["upload"],
  lenderCredentials: [
    {
      label: "Partner ID",
      attribute: "brokerId",
    },
    {
      label: "Partner Company ID",
      attribute: "organisationId",
    },
  ],
  requiredFields: {
    asset: [
      "assetValue",
      "assetType",
      "typeOfSale",
      "ageOfAsset",
      "condition",
      "glassesMake",
      "glassesModel",
      // "Make", 
      // "Model",
    ],
    customer: [
      "customerTitle",
      "firstName",
      "lastName",
      "dateOfBirth",
      "IDNumber",
      "IDCardNumber",
      "IDExpiryDate",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      // "addresses[1].fullAddress", // What if user not have previous address ?
      // "addresses[1].city",
      // "addresses[1].state",
      // "addresses[1].postCode",
      // "addresses[1].monthsAt",
      // "addresses[1].yearsAt",
      "customerProfile.livingArrangements",
      "customerProfile.numberOfDependants",
      "customerProfile.residencyStatus",
      "customerProfile.maritalStatus",
      "customerAssets.[0].assetType",
      "customerAssets.[0].value",
      "customerAssets.[0].description",
      "liabilities.[0].liabilitiesType",
      "liabilities.[0].outstandingAmount",
      "liabilities.[0].limit",
      "liabilities.[0].financier",
      "liabilities.[0].repaymentFrequency",
      "liabilities.[0].repaymentAmount",
      "liabilities.[0].description",
      "income.[0].incomeType",
      "income.[0].amount",
      "income.[0].frequency",
      "expenses.[0].expensesType",
      "expenses.[0].amount",
      "expenses.[0].frequency",
      "employers.[0].entityName",
      "employers.[0].status",
      "employers.[0].timeOfEmployment",
      "employers.[0].occupation",
      "employers.[0].manager",
      "employers.[0].phoneNumber",
      "employers.[0].industry",
      "employers.[0].address.fullAddress",
      "employers.[0].address.city",
      "employers.[0].address.state",
    ],
    loanDetails: [
      // "rate"
      // "term",
      // "balloon",
      // "deposit",
      // "tradeIn",
      // "payout",
      // "rate",
      // "rateAdjustment",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Establishment fee",
      value: 525,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale("Authorised seller")],
    },
    {
      name: "Private sale fee",
      value: 575,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale("Private sale")],
    },
    {
      name: "PPSR",
      value: 10,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Account fee",
      value: 9.9,
      capitalised: false,
      frequency: "monthly",
    },
    {
      name: "Origination fee",
      value: 990,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(20000, 80000)],
    },
    {
      name: "Origination fee",
      value: 900,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(10000, 19999)],
    },
    {
      name: "Origination fee",
      value: 750,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(7500, 9999)],
    },
    {
      name: "Origination fee",
      value: 500,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(5000, 7499)],
    },
  ],
  brokerage: [
    {
      value: 3,
      max: 3,
      valueType: "percentage",
      criteria: [assetValueBetween(0, 1000000)],
    },
  ],
  loading: [
    // {
    //   name: "Non property owner",
    //   value: 2,
    //   valueType: "percentage",
    //   criteria: [nonPropertyOwner],
    // },
    {
      name: "Private sale",
      value: 0.5,
      valueType: "percentage",
      criteria: [typeOfSale("Private sale")],
    },
    {
      name: "Term 60-84 months",
      value: 0.5,
      valueType: "percentage",
      criteria: [termIsGreaterThan(60)],
    },
  ],
  info: [
    `Multiple loans allowed up to $200,000 max loan size $80,000`,
    `Current bankrupts are not accepted. Ex-bankrupts must be discharged >2 years to be considered.`,
  ],
  productTiers: [
    ...propertyOwnerList(withEmploymentCriteriaAdded),
    ...nonPropertyOwnerList(withEmploymentCriteriaAdded),
  ],
};
export default wisr;
