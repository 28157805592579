import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { reusableFetch } from "../../utils/useAxios";

// Track the current request identifier
let currentgetAllApplicationsRequestId = null;

export const getAllApplications = createAsyncThunk("applications/get", async (data, { rejectWithValue }) => {
  // Generate a unique request ID
  const requestId = uuidv4();

  // Update the current request ID
  currentgetAllApplicationsRequestId = requestId;

  try {
    const applications = await reusableFetch(`application/all`, "POST", data, rejectWithValue);

    // Check if the request ID matches the current one
    if (currentgetAllApplicationsRequestId === requestId) {
      return applications; // Return the result if it matches
    } else {
      // If the requestId doesn't match, ignore the result and proceed to getAllApplications.rejected so the applications state doesn't update
      return rejectWithValue("Request ignored");
    }
  } catch (error) {
    return rejectWithValue("Failed to fetch data");
  }
});

export const updateApplicationStatus = createAsyncThunk("application/status", async (data, { rejectWithValue }) => {
  const application = await reusableFetch(
    `application/update/${data.applicationId}/status`,
    "POST",
    data,
    rejectWithValue,
  );
  return application;
});

export const applicationsSlice = createSlice({
  name: "applications",
  initialState: {
    isLoading: false,
    statusFilter: "Active", // Default application status filter, equivalent to Workshop status
  },
  reducers: {
    saveValue: (state, action) => _.merge(state, action.payload),
  },
  extraReducers: {
    [getAllApplications.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isLoading = false;
      state.isSuccess = true;
      state.applications = payload?.data?.data;
    },
    [getAllApplications.pending]: (state) => {
      state.isLoading = true;
      state.isFetching = true;
    },
    [getAllApplications.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isFetching = false;
      state.isError = true;
    },
  },
});

export const { saveValue } = applicationsSlice.actions;
export const applicationsSelector = (state) => state.applications;
