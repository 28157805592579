import criteria from "../../../utils/criteria";
import { livingArrangementsTypes, employmentTypes } from "../../../constants";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } = livingArrangementsTypes;
const { FULL_TIME, PART_TIME, CASUAL, CONTRACT, SELF_EMPLOYED } = employmentTypes;

const {
  assetValueBetween,
  assetType,
  ageOfAssetBetween,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
  assetCondition,
} = criteria;

const baseStandard = [
  {
    name: "Standard",
    rate: 9.09,
    productType: "Standard",
    criteria: [assetValueBetween(5000, 150000), assetCondition(["New", "Demo"])],
  },
  {
    name: "Standard",
    rate: 9.79,
    productType: "Standard",
    criteria: [assetValueBetween(5000, 150000), assetCondition(["Used"]), ageOfAssetBetween(0, 36)],
  },
  {
    name: "Standard",
    rate: 9.89,
    productType: "Standard",
    criteria: [assetValueBetween(5000, 150000), assetCondition(["Used"]), ageOfAssetBetween(37, 60)],
  },
  {
    name: "Standard",
    rate: 11.29,
    productType: "Standard",
    criteria: [assetValueBetween(5000, 150000), assetCondition(["Used"]), ageOfAssetBetween(61, 84)],
  },
  {
    name: "Standard",
    rate: 14.99,
    productType: "Standard",
    criteria: [assetValueBetween(5000, 50000), assetCondition(["Used"]), ageOfAssetBetween(84, 144)],
  },
];

const baseEdge = [
  {
    name: "Edge",
    rate: 10.59,
    productType: "Standard",
    criteria: [assetValueBetween(5000, 150000), assetCondition(["New", "Demo"])],
  },
  {
    name: "Edge",
    rate: 11.29,
    productType: "Standard",
    criteria: [assetValueBetween(5000, 150000), assetCondition(["Used"]), ageOfAssetBetween(0, 36)],
  },
  {
    name: "Edge",
    rate: 11.39,
    productType: "Standard",
    criteria: [assetValueBetween(5000, 150000), assetCondition(["Used"]), ageOfAssetBetween(37, 60)],
  },
  {
    name: "Edge",
    rate: 12.79,
    productType: "Standard",
    criteria: [assetValueBetween(5000, 150000), assetCondition(["Used"]), ageOfAssetBetween(61, 84)],
  },
  {
    name: "Edge",
    rate: 16.49,
    productType: "Standard",
    criteria: [assetValueBetween(5000, 50000), assetCondition(["Used"]), ageOfAssetBetween(84, 144)],
  },
];

const ev = [
  {
    name: "Standard",
    rate: 8.59,
    productType: "Standard",
    criteria: [assetValueBetween(5000, 150000), assetCondition(["New", "Demo"])],
  },
  {
    name: "Edge",
    rate: 9.09,
    productType: "Standard",
    criteria: [assetValueBetween(5000, 150000), assetCondition(["New", "Demo"])],
  },
];

function motorVehicles(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, assetType(["Motor Vehicle"])],
  }));
}

function evs(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, assetType(["Electric Motor Vehicle"])],
  }));
}

function standard(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      livingArrangements([PROPERTY_OWNER]),
      employmentType([FULL_TIME, PART_TIME, CASUAL, CONTRACT]),
      timeOfEmploymentGreaterThan(6),
      creditScoreGreaterThan(750),
    ],
  }));
}

function standardSE(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      livingArrangements([PROPERTY_OWNER]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(24),
      creditScoreGreaterThan(750),
    ],
  }));
}

function edge(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      livingArrangements([PROPERTY_OWNER, LIVING_WITH_PARENTS, RENTING, BOARDING]),
      employmentType([FULL_TIME, PART_TIME, CASUAL, CONTRACT]),
      timeOfEmploymentGreaterThan(6),
      creditScoreGreaterThan(550),
    ],
  }));
}

function edgeSE(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      livingArrangements([PROPERTY_OWNER, LIVING_WITH_PARENTS, RENTING, BOARDING]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(24),
      creditScoreGreaterThan(550),
    ],
  }));
}

const base = [
  ...motorVehicles(standard(baseStandard)),
  ...motorVehicles(standardSE(baseStandard)),
  ...motorVehicles(edge(baseEdge)),
  ...motorVehicles(edgeSE(baseEdge)),

  ...evs(standard(ev)),
  ...evs(standardSE(ev)),
  ...evs(edge(ev)),
  ...evs(edgeSE(ev)),
];
export default base;
