import criteria from "../../utils/criteria";

const {
  livingArrangements,
  termIsGreaterThan,
  typeOfSale,
  assetValueBetween,
  assetType,
  ageOfAssetGreaterThanOrEqualTo,
  ageOfAssetLessThan,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  gstRegistrationGreaterThan,
  creditScoreGreaterThan,
} = criteria;

const anz = {
  date: "01 Feb 2024",
  lender: "ANZ",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos%2Fanz-2-logo-png-transparent.gif",
  actions: [],
  lenderCredentials: [
    {
      label: "Email",
      attribute: "email",
    },
    {
      label: "User Name",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
    {
      label: "User ID",
      attribute: "brokerId",
    },
    {
      label: "Api Key",
      attribute: "apiKey",
    },
  ],
  requiredFields: {
    entity: [
      "abn",
      "entityName",
      "entityType",
      "industryType",
      "turnover",
      "addresses[0].fullAddress",
      // "addresses[0].streetAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
    ],
    customer: [
      "customerTitle",
      "firstName",
      "lastName",
      "dateOfBirth",
      "IDNumber",
      "IDCardNumber",
      "IDExpiryDate",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      "addresses[0].fullAddress",
      // "addresses[0].streetAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      // "emails[0].address",
      // "phoneNumbers[0].number",
      "customerProfile.livingArrangements",
      "customerProfile.numberOfDependants",
      "customerProfile.residencyStatus",
      "customerProfile.maritalStatus",
      "customerAssets.[0].assetType",
      "customerAssets.[0].value",
      "customerAssets.[0].description",
      "liabilities.[0].liabilitiesType",
      "liabilities.[0].outstandingAmount",
      "liabilities.[0].limit",
      "liabilities.[0].financier",
      "liabilities.[0].repaymentFrequency",
      "liabilities.[0].repaymentAmount",
      "liabilities.[0].description",
    ],
    loanDetails: ["rate"],
    asset: ["assetType", "typeOfSale", "ageOfAsset", "glassesMake", "glassesModel", "glassesVariant", "assetValue","condition"],
  },
  fees: [
    {
      name: "Document fee",
      value: 400,
      capitalised: true,
      frequency: "upfront",
    },
  ],
  brokerage: [
    {
      value: 3.5,
      max: 3.5,
      valueType: "percentage",
      criteria: [assetValueBetween(10000, 1000000)],
    },
  ],
  loading: [
    // {
    //   name: "Non property owner",
    //   value: 2,
    //   valueType: "percentage",
    //   criteria: [nonPropertyOwner],
    // },
    {
      name: "Private sale",
      value: 0.42,
      valueType: "percentage",
      criteria: [typeOfSale("Private sale"), assetType(["Motor Vehicle (<4.5 tonnes)"])],
    },
    {
      name: "Term >60",
      value: 0.6,
      valueType: "percentage",
      criteria: [termIsGreaterThan(60)],
    },
  ],
  info: [
    "BAS statement and ATO portal access",
    "No debts or GST outstanding",
    "Non property owners accepted if an existing ANZ customer",
    "Max aggregate exposure $500k",
  ],
  productTiers: [
    {
      name: "Auto",
      rate: 7.4,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(400),
      ],
    },
    {
      name: "Auto",
      rate: 8.1,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetGreaterThanOrEqualTo(12),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(400),
      ],
    },
    {
      name: "Primary",
      rate: 7.6,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(36),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        creditScoreGreaterThan(400),
      ],
    },

    {
      name: "Primary",
      rate: 8.4,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetGreaterThanOrEqualTo(36),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        creditScoreGreaterThan(400),
      ],
    },

    {
      name: "Secondary",
      rate: 7.7,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        assetType(["Motorbikes, ATVs", "Industrial Plant & Printing", "Medical Equipment", "Manufacturing Equipment"]),
        creditScoreGreaterThan(400),
      ],
    },

    {
      name: "Secondary",
      rate: 9.0,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetGreaterThanOrEqualTo(12),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        assetType(["Motorbikes, ATVs", "Industrial Plant & Printing", "Medical Equipment", "Manufacturing Equipment"]),
        creditScoreGreaterThan(400),
      ],
    },
  ],
};
export default anz;
