import { livingArrangementsTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { PROPERTY_OWNER } = livingArrangementsTypes;

const {
  livingArrangements,
  nonGST,
  hasDeposit,
  assetType,
  ageOfAssetGreaterThan,
  ageOfAssetLessThan,
  ageOfAssetBetween,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  gstRegistrationGreaterThan,
  ageOfAssetAtEndLessThan,
  creditScoreGreaterThan,
} = criteria;

function isGST(b) {
  return b.map((product) => ({
    ...product,
    info: ["Adverse: paid Telco/Utility <$1000"],
    criteria: [
      ...product.criteria,
      loanAmountBetween(2000, 80000),
      timeInBusinessGreaterThan(12),
      gstRegistrationGreaterThan(0),
      livingArrangements([PROPERTY_OWNER]),
      ageOfAssetAtEndLessThan(21),
      creditScoreGreaterThan(500),
    ],
  }));
}

function noGST(b) {
  return b.map((product) => ({
    ...product,
    info: ["Adverse: paid Telco/Utility <$1000"],
    criteria: [
      ...product.criteria,
      loanAmountBetween(2000, 35000),
      livingArrangements([PROPERTY_OWNER]),
      timeInBusinessGreaterThan(12),
      nonGST,
      ageOfAssetAtEndLessThan(21),
      creditScoreGreaterThan(500),
    ],
  }));
}

function nonPropertyOwner(b) {
  return b.map((product) => ({
    ...product,
    info: ["No Adverse"],
    criteria: [
      ...product.criteria,
      timeInBusinessGreaterThan(24),
      gstRegistrationGreaterThan(24),
      hasDeposit(10),
      livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
      ageOfAssetAtEndLessThan(21),
      creditScoreGreaterThan(600),
    ],
  }));
}

const lightDoc = [...isGST(base()), ...noGST(base()), ...nonPropertyOwner(base())];

function base() {
  return [
    {
      name: "Commercial Motor",
      rate: 9.15,
      productType: "Light doc",
      criteria: [
        ageOfAssetLessThan(12),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
      ],
    },
    {
      name: "Commercial Motor",
      rate: 9.65,
      productType: "Light doc",
      criteria: [
        ageOfAssetBetween(12, 48),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
      ],
    },
    {
      name: "Commercial Motor",
      rate: 10.65,
      productType: "Light doc",
      criteria: [
        ageOfAssetBetween(48, 120),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
      ],
    },
    {
      name: "Commercial Motor",
      rate: 10.95,
      productType: "Light doc",
      criteria: [
        ageOfAssetGreaterThan(120),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
      ],
    },
  ];
}

export default lightDoc;
