import criteria from "../../../utils/criteria";

const { loanAmountBetween, creditScoreBetween } = criteria;

const base = [
  {
    name: "Tier - Excellent",
    rate: [8.99, 10.49],
    productType: "Standard",
    criteria: [loanAmountBetween(2000, 50000), creditScoreBetween(751, 1200)],
  },
  {
    name: "Tier - Great",
    rate: [10.50, 13.79],
    productType: "Standard",
    criteria: [loanAmountBetween(2000, 50000), creditScoreBetween(651, 750)],
  },
  {
    name: "Tier - Good",
    rate: [13.80, 17.99],
    productType: "Standard",
    criteria: [loanAmountBetween(2000, 50000), creditScoreBetween(551, 650)],
  },
  {
    name: "Tier - Average",
    rate: [18.00, 23.99],
    productType: "Standard",
    criteria: [loanAmountBetween(2000, 50000), creditScoreBetween(425, 550)],
  },
];
export default base;
