import platinum from "./platinum";
import gold from "./gold";
import silver from "./silver";
import { typeOfSaleList, employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL } = employmentTypes;

const { typeOfSale, assetValueBetween, assetType, ageOfAssetBetween, ageOfAssetGreaterThan, employmentType } = criteria;

const acl = {
  date: "31 Aug 2023",
  lender: "Affordable Car Loans",
  type: "consumer",
  logoURL: "https://assets.financeable.com.au/lender-logos/ACL.png",
  actions: ["email"],
  lenderCredentials: [
    {
      label: "Lender applications inbox email",
      attribute: "email",
    },
  ],
  requiredFields: {
    asset: ["assetType", "typeOfSale", "ageOfAsset", "glassesMake", "glassesModel", "glassesVariant", "assetValue"],
    customer: [
      "firstName",
      "lastName",
      "dateOfBirth",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      //   "addresses[0].street",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      // "addresses[1].city",
      // "addresses[1].state",
      // "addresses[1].postCode",
      // "addresses[1].monthsAt",
      // "addresses[1].yearsAt",
      "IDNumber",
      "IDCardNumber",
      "employers.[0].entityName",
      "employers.[0].timeOfEmployment",
      "employers.[0].employmentType",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Establishment fee",
      value: 395,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.DEALER_SALE), assetType(["Motor Vehicle", "Electric Motor Vehicle"])],
    },
    {
      name: "Private sale",
      value: 995,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.PRIVATE_SALE), assetType(["Motor Vehicle", "Electric Motor Vehicle"])],
    },
    {
      name: "Origination fee",
      value: 1100,
      capitalised: true,
      frequency: "upfront",
      criteria: [employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL])],
    },
    // {
    //   name: "Risk fee",
    //   value: 2,
    //   calc: "percent",
    //   capitalised: true,
    //   frequency: "upfront",
    //   criteria: [assetValueBetween(60001, 100000)],
    // },
    {
      name: "Risk fee",
      value: 2,
      calc: "percent",
      capitalised: true,
      frequency: "upfront",
      criteria: [assetValueBetween(50001, 60000)],
    },
    {
      name: "Risk fee",
      value: 4,
      calc: "percent",
      capitalised: true,
      frequency: "upfront",
      criteria: [assetValueBetween(40001, 50000)],
    },
    {
      name: "Risk fee",
      value: 6,
      calc: "percent",
      capitalised: true,
      frequency: "upfront",
      criteria: [assetValueBetween(30001, 40000)],
    },
    {
      name: "Risk fee",
      value: 8,
      calc: "percent",
      capitalised: true,
      frequency: "upfront",
      criteria: [assetValueBetween(25001, 30000)],
    },
    {
      name: "Risk fee",
      value: 10,
      calc: "percent",
      capitalised: true,
      frequency: "upfront",
      criteria: [assetValueBetween(10000, 25000)],
    },
  ],
  brokerage: [
    {
      value: 2,
      max: 2,
      valueType: "percentage",
      criteria: [assetValueBetween(80001, 100000)],
    },
    {
      value: 4,
      max: 4,
      valueType: "percentage",
      criteria: [assetValueBetween(60000, 80000.99)],
    },
    {
      value: 5,
      max: 5,
      valueType: "percentage",
      criteria: [assetValueBetween(30001, 60000.99)],
    },
    {
      value: 4,
      max: 4,
      valueType: "percentage",
      criteria: [assetValueBetween(25001, 30000.99)],
    },
    {
      value: 3,
      max: 3,
      valueType: "percentage",
      criteria: [assetValueBetween(10000, 25000.99)],
    },
  ],
  loading: [
    {
      name: "Age of asset at start 36-95 mths",
      value: 3,
      valueType: "percentage",
      criteria: [ageOfAssetBetween(36, 95)],
    },
    {
      name: "Age of asset at start >95 mths",
      value: 3,
      valueType: "percentage",
      criteria: [ageOfAssetGreaterThan(95)],
    },
  ],
  info: [
    `All income types accepted - cannot be 100 % benefits only`,
    `Bank Statements required for<500 score`,
    `Repayment Amount<20% net income per month`,
    `Total loans liability(monthly) < 50 % of net income`,
    `EXCLUSIONS`,
    `Bankruptcy Undischarged or multiple`,
    `Equifax Apply Negative credit score less than 300`,
    `Combined income less than $600 net per week`,
    `1 active payday loan`,
  ],
  productTiers: [...platinum, ...gold, ...silver],
};
export default acl;
