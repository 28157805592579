import React from 'react'
import { Typography } from '@mui/material'

const CalCustomTitleBar = ({ title }) => (
  <Typography
    variant="subtitle1"
    sx={{
      fontWeight: "bold",
      position: "relative",
      mb: "10px",
      mt: "10px",
      '::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: -2,  // Adjust as needed
        height: '2px',  // Adjust as needed
        backgroundColor: '#A18DEB',  // Adjust color as needed
        borderRadius: '2px',  // Optional for rounded underline
      }
    }}
  >
    {title || ""}
  </Typography>
)

export default CalCustomTitleBar