import criteria from "../../../utils/criteria";

const {
  livingArrangements,
  hasDeposit,
  assetType,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  gstRegistrationGreaterThan,
  ageOfAssetAtEndLessThan,
  creditScoreGreaterThan,
  ageOfAssetBetween,
  ageOfAssetGreaterThan,
  
} = criteria;

const lowDoc = [...propertyOwnerLowDoc(base()), ...nonPropertyOwnerLowDoc(base())];

function propertyOwnerLowDoc(l) {
  return l.map((product) => ({
    ...product,
    productType: "Low doc",
    criteria: [
      ...product.criteria,
      timeInBusinessGreaterThan(24),
      gstRegistrationGreaterThan(24),
      livingArrangements(["Property owner"]),
    ],
  }));
}

function nonPropertyOwnerLowDoc(l) {
  return l.map((product, i) => ({
    ...product,
    productType: "Low doc",
    criteria: [
      ...product.criteria,
      timeInBusinessGreaterThan(24),
      gstRegistrationGreaterThan(24),
      i === 0 ? hasDeposit(10) : hasDeposit(20),
      livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
    ],
  }));
}

function base() {
  return [
    {
      name: "Motor vehicle",
      rate: 7.49,
      criteria: [
        loanAmountBetween(4999, 150000),
        ageOfAssetBetween(0, 36),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)", "Classic car"]),
        creditScoreGreaterThan(600),
      ],
    },
    {
      name: "Motor vehicle",
      rate: 7.99,
      criteria: [
        loanAmountBetween(4999, 150000),
        ageOfAssetGreaterThan(36),
        ageOfAssetAtEndLessThan(21),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)", "Classic car"]),
        creditScoreGreaterThan(600),
      ],
    },
    {
      name: "Motor vehicle",
      rate: 9.99,
      criteria: [
        loanAmountBetween(4999, 150000),
        ageOfAssetAtEndLessThan(21),
        assetType(["Classic car"]),
        creditScoreGreaterThan(600),
      ],
    },



    {
      name: "Primary asset",
      rate: 8.29,
      criteria: [
        loanAmountBetween(4999, 150000),
        ageOfAssetBetween(0, 36),
        ageOfAssetAtEndLessThan(13),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Trailers and Caravans",
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Primary asset",
      rate: 8.79,
      criteria: [
        loanAmountBetween(4999, 150000),
        ageOfAssetGreaterThan(36),
        ageOfAssetAtEndLessThan(13),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Trailers and Caravans",
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        creditScoreGreaterThan(550),
      ],
    },

    {
      name: "Primary asset",
      rate: 8.79,
      criteria: [
        loanAmountBetween(150000, 250000),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Trailers and Caravans",
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        creditScoreGreaterThan(550),
      ],
    },

    {
      name: "Primary mover asset",
      rate: 8.99,
      criteria: [
        loanAmountBetween(4999, 150000),
        ageOfAssetAtEndLessThan(13),
        assetType(["Commercial (Heavy) Vehicles (>12 Tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },

    {
      name: "Primary mover asset",
      rate: 8.99,
      criteria: [
        loanAmountBetween(150000, 250000),
        ageOfAssetAtEndLessThan(21),
        assetType(["Commercial (Heavy) Vehicles (>12 Tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },

    {
      name: "Secondary asset",
      rate: 11.39,
      criteria: [
        loanAmountBetween(4999, 50000),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
        ]),
        creditScoreGreaterThan(600),
      ],
    },

    {
      name: "Tertiary asset",
      rate: 13.39,
      criteria: [
        loanAmountBetween(4999, 50000),
        ageOfAssetAtEndLessThan(21),
        assetType(["IT, Computer & Audio Equipment", "Office Equipment"]),

        creditScoreGreaterThan(600),
      ],
    },
  ];
}

export default lowDoc;
