import criteria from "../../../utils/criteria";

const {
  assetType,
  ageOfAssetBetween,
  ageOfAssetLessThan,
  loanAmountBetween,
  creditScoreGreaterThan,
  livingArrangements,
  creditScoreBetween,
} = criteria;

const c = [
  {
    name: "Tier C - Motor Vehicle New/Demo",
    rate: 11.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 40000),
      ageOfAssetLessThan(12),
      livingArrangements(["Boarding", "Living w/ parents"]),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),

      creditScoreGreaterThan(525),
    ],
  },
  {
    name: "Tier C - Motor Vehicle 12-36 months old",
    rate: 11.99,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 40000),
      ageOfAssetBetween(12, 36),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      livingArrangements(["Boarding", "Living w/ parents"]),

      creditScoreGreaterThan(525),
    ],
  },
  {
    name: "Tier C - Motor Vehicle 37-72 months old",
    rate: 12.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 40000),
      ageOfAssetBetween(37, 72),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      livingArrangements(["Boarding", "Living w/ parents"]),

      creditScoreGreaterThan(525),
    ],
  },
  {
    name: "Tier C - Motor Vehicle 73-120 months old",
    rate: 14.19,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 40000),
      ageOfAssetBetween(73, 120),
      livingArrangements(["Boarding", "Living w/ parents"]),

      creditScoreGreaterThan(525),
    ],
  },

  {
    name: "Tier C2 - Motor Vehicle New/Demo",
    rate: 11.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 30000),
      ageOfAssetLessThan(12),
      livingArrangements(["Property owner", "Renting"]),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),

      creditScoreBetween(526, 599),
    ],
  },
  {
    name: "Tier C2 - Motor Vehicle 12-36 months old",
    rate: 11.99,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 30000),
      ageOfAssetBetween(12, 36),
      livingArrangements(["Property owner", "Renting"]),

      creditScoreBetween(526, 599),
    ],
  },
  {
    name: "Tier C2 - Motor Vehicle 37-72 months old",
    rate: 12.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 30000),
      ageOfAssetBetween(37, 72),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      livingArrangements(["Property owner", "Renting"]),

      creditScoreBetween(526, 599),
    ],
  },
  {
    name: "Tier C2 - Motor Vehicle 73-120 months old",
    rate: 14.19,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 30000),
      ageOfAssetBetween(73, 120),
      livingArrangements(["Property owner", "Renting"]),

      creditScoreBetween(526, 599),
    ],
  },

  {
    name: "Tier C - Motorbike New/Demo",
    rate: 11.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 40000),
      ageOfAssetLessThan(12),
      livingArrangements(["Property owner", "Renting"]),
      assetType(["Motorbike"]),

      creditScoreBetween(526, 599),
    ],
  },
  {
    name: "Tier C - Motorbike 12-36 months old",
    rate: 11.99,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 40000),
      ageOfAssetBetween(12, 36),
      assetType(["Motorbike"]),
      livingArrangements(["Property owner", "Renting"]),

      creditScoreBetween(526, 599),
    ],
  },
  {
    name: "Tier C - Motorbike 37-72 months old",
    rate: 12.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 40000),
      ageOfAssetBetween(37, 72),
      assetType(["Motorbike"]),
      livingArrangements(["Property owner", "Renting"]),

      creditScoreBetween(526, 599),
    ],
  },
  {
    name: "Tier C - Motorbike 73-120 months old",
    rate: 14.19,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 40000),
      ageOfAssetBetween(73, 120),
      assetType(["Motorbike"]),
      livingArrangements(["Property owner", "Renting"]),

      creditScoreBetween(526, 599),
    ],
  },

  {
    name: "Tier C2 - Motorbike New/Demo",
    rate: 11.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 30000),
      ageOfAssetLessThan(12),
      livingArrangements(["Boarding", "Living w/ parents"]),
      assetType(["Motorbike"]),

      creditScoreBetween(526, 599),
    ],
  },
  {
    name: "Tier C2 - Motorbike 12-36 months old",
    rate: 11.99,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 30000),
      ageOfAssetLessThan(12),
      assetType(["Motorbike"]),
      livingArrangements(["Boarding", "Living w/ parents"]),

      creditScoreBetween(526, 599),
    ],
  },
  {
    name: "Tier C2 - Motorbike 36-72 months old",
    rate: 12.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 30000),
      ageOfAssetBetween(36, 72),
      assetType(["Motorbike"]),
      livingArrangements(["Boarding", "Living w/ parents"]),

      creditScoreBetween(526, 599),
    ],
  },
  {
    name: "Tier C2 - Motorbike 73-120 months old",
    rate: 14.19,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 30000),
      ageOfAssetBetween(73, 120),
      assetType(["Motorbike"]),
      livingArrangements(["Boarding", "Living w/ parents"]),

      creditScoreBetween(526, 599),
    ],
  },
];
export default c;
