import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { List, ListItem, ListItemButton, ListItemText, Box } from "@mui/material";
import _ from "lodash";
import { userSelector } from "../store/slices/userSlice";

export default function BillingSidebar({ path }) {
  const navigate = useNavigate();
  const userDetails = useSelector(userSelector);

  // const primaryTypographyProps = {
  //   // color: 'primary',
  //   fontWeight: "400",
  //   fontSize: "13px",
  //   // variant: 'body2',
  // };

  const billingRouter = userDetails?.is_billing_access ? ["services", "transactions", "creditCard"] : ["services"];

  // const billingRouter = ["services"];
  return (
    <>
      <Box sx={{ width: "100%", fontSize: "10px" }}>
        <List>
          {billingRouter.map((route, i) => (
            <ListItem disablePadding dense key={i}>
              <ListItemButton selected={path === route} onClick={() => navigate(`/billing/${route}`)}>
                <ListItemText
                  primary={_.startCase(route)}
                  primaryTypographyProps={{
                    fontSize: "13px",
                    fontWeight: path === route ? 700 : 400,
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  );
}
