import React from 'react'
import { useDispatch } from 'react-redux'
import { Box, Grid } from '@mui/material'
import PrefixTextField from './common/PrefixTextField'
import CalCustomTitleBar from './common/CalCustomTitleBar'
import ShowValues from './common/ShowValues'
import { setCalculatorFieldsError } from '../../store/slices/applicationFormSlice'
import { isValidInput } from '../Utils/helperFunction'

const CalDeposit = React.memo(({ allValues, calculatorFieldsErrors, validateField, saveLoanDetails, setAllValue }) => {
  const dispatch = useDispatch()
  const { cash = 0, tradeIn = 0, depositTotal = 0 } = allValues

  const handle = {
    onChangeField: async (e, name) => {
      let isValid = true;

      if (e === "") {
        setAllValue({ ...allValues, [name]: "" });
        dispatch(setCalculatorFieldsError({ ...calculatorFieldsErrors, deposit: { ...calculatorFieldsErrors.deposit, [name]: "" } }))
        return;
      }

      if (name === "cash" || name === "tradeIn") {
        isValid = isValidInput(e);
      }
      if (!isValid) {
        await validateField({ fieldName: name, value: e, fieldObj: 'deposit' });
        return;
      }

      setAllValue({ ...allValues, [name]: +e });
      await validateField({ fieldName: name, value: e, fieldObj: 'deposit' });
    },
    blurFn: async (fieldName, value) => {
      // Use for itc value
      try {
        if (calculatorFieldsErrors['deposit'][fieldName] === "")
          saveLoanDetails({
            [fieldName]: +value || 0,
          });
      } catch (error) {
        console.log("error", error)
        // const newErrors = {};
        // error.inner.forEach((validationError) => {
        //   newErrors[validationError.path] = validationError.message;
        // });
        // // setFieldErrors(newErrors);
      }
    },
  }

  return (
    <Box>
      <CalCustomTitleBar title={"Deposit"} />

      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        mb={"20px"}
        spacing={1}
        direction="row"
      >

        <Grid item xs={12} sm={6}>
          <PrefixTextField
            name="cash"
            label="Cash"
            value={cash}
            error={calculatorFieldsErrors?.deposit?.cash}
            changeHandler={handle.onChangeField}
            blurHandler={handle.blurFn}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <PrefixTextField
            name="tradeIn"
            label="Trade-in"
            value={tradeIn}
            error={calculatorFieldsErrors?.deposit?.tradeIn}
            changeHandler={handle.onChangeField}
            blurHandler={handle.blurFn}
          />

        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
        >
          <ShowValues
            label={"Total"}
            value={depositTotal}
          />
        </Grid>
      </Grid>
    </Box>
  )
})

export default CalDeposit