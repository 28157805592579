import React, { useState, useEffect } from "react";
import moment from "moment";

const TimeAgoComponent = ({ timestamp }) => {
  const [timeAgo, setTimeAgo] = useState("");

  useEffect(() => {
    const updateTimeAgo = () => {
      const time = moment(timestamp).fromNow();
      setTimeAgo(time);
    };

    updateTimeAgo(); // Initial update
    const interval = setInterval(updateTimeAgo, 60000); // Update every minute

    return () => clearInterval(interval);
  }, [timestamp]);

  return <span>{timeAgo}</span>;
};

export default TimeAgoComponent;
