const color = {
  white: "#ffffff",
  black: "#06050a",
  lightGrey: "#f9f9f9",
  lightLightOrange: "#f5f4f2",
  grey: "#C1BFBF",
  mediumGrey: "#9F9F9F",
  darkGrey: "#404040",
  yellow: "fff700",
  green: "#14C532",
  fuschia: "#FF00A1",
  darkBlue: "#071A48",
  babyBlue: "#8FCEFF",
  pink: "#FF9FDC",
  babyPink: "#ffe4f2",
  orange: "#ffa500",
  blue: "#1a0dab",
  lightPink: "#fff8fc",
  lime: "#a0ff8f",
  lightBlue: "#f5fbff",
};
export default color;
