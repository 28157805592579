import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import InputMask from "react-input-mask";
import { Box, Grid, Typography, Button, TextField } from '@mui/material'
import { round } from 'lodash';
// import PrefixTextField from './common/PrefixTextField'
import CustomSelectInput from './common/CustomSelectInput'
import AmortisationModal from './AmortisationModal';
import CalCustomTitleBar from './common/CalCustomTitleBar';
import { amortizationSchedule } from './common/CalculateAmortSchedule'
import { calculatorSelector, saveCalculatorValue } from '../../store/slices/calculatorSlice';
import { REPAYMENT_METHOD_OPTIONS, RepaymentStructure, validFrequencyList } from '../../constants'
import RadioButtonGroup from '../Utils/RadioButtonGroup'
import { setCalculatorFieldsError } from '../../store/slices/applicationFormSlice';


const CalRepaymentSchedule = React.memo(({ allValues, calculatorFieldsErrors, validateField, saveLoanDetails, setAllValue }) => {
  const dispatch = useDispatch()
  const { openAmortisationModal } = useSelector(calculatorSelector);
  const { financedAmount = 0, repayments = 0, totalINCRepayments = 0, term = 60, amortStartDate, accountFee = 0, inArrears, effectiveRate = 0 } = allValues
  
  const AmortScheduleList =
    +effectiveRate > 0 && round(+term / 12) > 0 && amortStartDate
      && financedAmount > 0 ? amortizationSchedule(financedAmount, round(+term / 12), (+effectiveRate || 0), (amortStartDate), inArrears, accountFee, repayments, totalINCRepayments) : []

  const isAmortScheduleDisabled = !effectiveRate || !term || !amortStartDate || !financedAmount

  const handle = {
    onChangeField: async (e, name) => {
      let isValid = true;

      if (e === "") {
        if (name === "term") {
          setAllValue({ ...allValues, [name]: "" });
        } else {
          setAllValue({ ...allValues, [name]: +e });
        }
        dispatch(setCalculatorFieldsError({ ...calculatorFieldsErrors, repaymentSchedule: { ...calculatorFieldsErrors.repaymentSchedule, [name]: "" } }))
        return;
      }

      if (!isValid) {
        await validateField({ fieldName: name, value: e, fieldObj: 'repaymentSchedule' });
        return;
      }

      if (name === 'inArrears'
        || name === 'repaymentFrequency'
        || name === 'repaymentStructure'
      ) {
        setAllValue({ ...allValues, [name]: e });
        saveLoanDetails({
          [name]: e,
        });
        return
      }

      setAllValue({ ...allValues, [name]: e });
      await validateField({ fieldName: name, value: e, fieldObj: 'repaymentSchedule' });
    },
    blurFn: async (fieldName, value) => {
      try {
        if (calculatorFieldsErrors['repaymentSchedule'][fieldName] === "")
          if (fieldName === "amortStartDate") {
            saveLoanDetails({
              [fieldName]: value,
            });
          } else {
            saveLoanDetails({
              [fieldName]: +value || 0,
            });
          }
      } catch (error) {
        console.log("error", error)
      }
    },
    handleOpenAmortisationModal: () => {
      dispatch(saveCalculatorValue({ openAmortisationModal: true }))
    },
    handleCloseAmortisationModal: (event, reason) => {
      // Make email empty if user try to cancel model
      if (reason !== 'backdropClick') {
        dispatch(saveCalculatorValue({
          openAmortisationModal: false
        }))
      }
    },
  }

  return (
    <Box>
      <CalCustomTitleBar title={"Repayment Schedule"} />

      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        mb={"20px"}
        spacing={1}
        direction="row"
      >
        <Grid item xs={12} sm={12} mt={1}>
          <Typography variant="subtitle2">Repayment Method</Typography>
          <RadioButtonGroup
            required
            name="inArrears"
            value={allValues?.inArrears === "Yes" ? "In Arrears" : "In Advance"}
            handleValue={(value) => {
              const newValue = value === "In Arrears" ? "Yes" : "No"
              handle.onChangeField(newValue, "inArrears");
            }}
            style={{
              margin: "0 0 0 8px",
            }}
            options={REPAYMENT_METHOD_OPTIONS}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomSelectInput
            name="repaymentFrequency"
            label="Repayment period"
            value={allValues?.repaymentFrequency}
            isOnlyValue={true}
            itemList={validFrequencyList}
            error={calculatorFieldsErrors?.repaymentSchedule?.repaymentFrequency}
            changeHandler={handle.onChangeField}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          {/* <PrefixTextField
            name="term"
            label="Term (Months)"
            value={allValues?.term}
            error={calculatorFieldsErrors?.repaymentSchedule?.term}
            changeHandler={handle.onChangeField}
            blurHandler={handle.blurFn}
          /> */}
          <Grid item mt={1}>
            <TextField
              fullWidth
              variant="filled"
              size="small"
              id="term-id"
              type="text"
              disabled={false}
              name="term"
              label="Term (Months)"
              value={term === null ? 60 : term}
              error={calculatorFieldsErrors?.repaymentSchedule?.term}
              helperText={calculatorFieldsErrors?.repaymentSchedule?.term}
              onChange={(event) => handle.onChangeField(event.target?.value, "term")}
              onBlur={(event) => handle.blurFn("term", event.target?.value)}
              style={{ padding: '0' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <CustomSelectInput
            name="repaymentStructure"
            label="Structure"
            value={allValues?.repaymentStructure}
            isOnlyValue={true}
            itemList={RepaymentStructure}
            error={calculatorFieldsErrors?.repaymentSchedule?.repaymentStructure}
            changeHandler={handle.onChangeField}
          />
        </Grid>

        <Grid item xs={12} sm={6} mt={1}>
          <InputMask
            mask="99-99-9999"
            value={allValues?.amortStartDate || null}
            disabled={false}
            maskChar={null}
            onChange={(event) => {
              handle.onChangeField(event?.target.value || null, "amortStartDate");
            }}
            onBlur={(e) => handle.blurFn("amortStartDate", e.target.value)}
          >
            {() => (
              <TextField
                label={"Amort Start Date"}
                size="small"
                fullWidth
                placeholder="DD-MM-YYYY"
                variant="filled"
                name="amortStartDate"
                error={calculatorFieldsErrors?.repaymentSchedule?.amortStartDate}
                helperText={calculatorFieldsErrors?.repaymentSchedule?.amortStartDate}
                InputLabelProps={{ shrink: true }}
              />
            )}
          </InputMask>
        </Grid>
        <Grid item xs={12} sm={12} mt={2}>
          <Button
            onClick={() => handle.handleOpenAmortisationModal()}
            disabled={isAmortScheduleDisabled}
            variant="outlined"
            size="small"
          >
            Amortisation Schedule
          </Button>
        </Grid>
      </Grid>
      {/* </Grid> */}

      <AmortisationModal
        AmortisationScheduleList={AmortScheduleList || []}
        openAmortisationModal={openAmortisationModal}
        handleCloseAmortisationModal={handle.handleCloseAmortisationModal}
      />
    </Box>
  )
})

export default CalRepaymentSchedule