import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Box, TextField, InputAdornment, MenuItem, List } from "@mui/material";
import { Search } from "@mui/icons-material";
import { deepPurple } from "@mui/material/colors";
import TemplateListItem from "./TemplateListItem";
import PageLoader from "../../../components/Application/PageLoader";
import { templatesSelector } from "../../../store/slices/templatesSlice";

const Templates = ({ templates, onEditTemplate }) => {
  const [sortBy, setSortBy] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const { isFetchingTemplates } = useSelector(templatesSelector);

  // Function to handle sorting change
  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  // Function to handle search query change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const sortedTemplates = useMemo(
    () =>
      templates
        .filter((template) => template.name.toLowerCase().includes(searchQuery.toLowerCase())) // Filter by search query
        .sort((a, b) => {
          if (sortBy === "last-modified") {
            return new Date(b.updatedAt) - new Date(a.updatedAt); // Sort by last modified date (descending)
          } else if (sortBy === "name-az") {
            return (a.name || "").localeCompare(b.name || ""); // Sort by template name (A-Z)
          } else if (sortBy === "name-za") {
            return (b.name || "").localeCompare(a.name || ""); // Sort by template name (Z-A)
          }
          return 0; // Default: no sorting if no sort is selected
        }),
    [templates, searchQuery, sortBy],
  );
  return (
    <Box sx={{ padding: "24px", width: "100%" }}>
      {/* Search Bar and Sort By */}
      <Box sx={{ display: "flex", marginBottom: "24px" }}>
        {/* Search Bar */}
        <TextField
          placeholder="Find a template"
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search htmlColor={deepPurple[500]} />
              </InputAdornment>
            ),
          }}
          sx={{ width: "60%", marginRight: "24px" }}
        />

        {/* Sort By Dropdown */}
        <TextField select value={sortBy} onChange={handleSortChange} label="Sort By" sx={{ width: "40%" }}>
          <MenuItem value="last-modified">Last Modified</MenuItem>
          <MenuItem value="name-az">Template Name (A-Z)</MenuItem>
          <MenuItem value="name-za">Template Name (Z-A)</MenuItem>
        </TextField>
      </Box>

      {/* Template List */}
      {!isFetchingTemplates && templates.length > 0 ? (
        <List sx={{ width: "100%" }}>
          {sortedTemplates.map((template) => (
            <TemplateListItem key={template._id} template={template} onEdit={onEditTemplate} />
          ))}
        </List>
      ) : (
        <PageLoader text={"Templates loading..."} />
      )}
    </Box>
  );
};

export default Templates;
