import { livingArrangementsTypes } from "../../constants";
import criteria from "../../utils/criteria";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } = livingArrangementsTypes;

const {
  livingArrangements,
  termIsLessThanOrEqualTo,
  assetValueBetween,
  assetType,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  ageOfAssetAtEndGreaterThan,
  loanAmountLessThan,
  brokerageIsEqualTo,
} = criteria;

const financeOne = {
  date: "1 Feb 2024",
  lender: "Finance One",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos/Finance-One.gif",
  actions: ["upload"],
  lenderCredentials: [
    {
      label: "Broker ID",
      attribute: "brokerId",
    },
  ],
  requiredFields: {
    entity: [
      "abn",
      "entityName",
      "entityType",
      "industryType",
      "turnover",
      // "addresses[0].streetAddress",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
    ],
    customer: [
      "customerTitle",
      "firstName",
      "lastName",
      "dateOfBirth",
      "IDNumber",
      "IDExpiryDate",
      "IDCardNumber",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      // "addresses[0].streetAddress",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "customerProfile.livingArrangements",
      "customerProfile.numberOfDependants",
      "customerProfile.residencyStatus",
      "customerProfile.maritalStatus",
      "customerAssets.[0].assetType",
      "customerAssets.[0].value",
      "customerAssets.[0].description",
      "liabilities.[0].liabilitiesType",
      "liabilities.[0].outstandingAmount",
      "liabilities.[0].limit",
      "liabilities.[0].financier",
      "liabilities.[0].repaymentFrequency",
      "liabilities.[0].repaymentAmount",
      "liabilities.[0].description",
      // "emails[0].address",
      // "phoneNumbers[0].number",
    ],
    loanDetails: ["rate"],
    asset: ["assetType", "typeOfSale", "ageOfAsset", "glassesMake", "glassesModel", "glassesVariant", "assetValue", "condition"],
  },
  email: "afapplications@azora.com.au",
  fees: [
    {
      name: "Document fee",
      value: 995,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Origination fee",
      value: 900,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(0, 14000)],
    },
    {
      name: "Origination fee",
      value: 1000,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(14000, 200000)],
    },
    {
      name: "Monthly account fee",
      value: 27.0,
      capitalised: false,
      frequency: "monthly",
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 8,
      valueType: "percentage",
      criteria: [assetValueBetween(0, 2500000)],
    },
  ],
  loading: [
    {
      name: "Loan amount <$10,000",
      value: 2,
      valueType: "percentage",
      criteria: [loanAmountLessThan(10000)],
    },
    {
      name: "Age of asset at end >25 years",
      value: 2,
      valueType: "percentage",
      criteria: [
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Trailers and Caravans",
          "Warehouse Equipment",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
        ]),
        ageOfAssetAtEndGreaterThan(25),
      ],
    },
    {
      name: "Secondary assets",
      value: 2,
      valueType: "percentage",
      criteria: [
        assetType([
          "Manufacturing Equipment",
          "Industrial Plant & Printing",
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Medical Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
          "Motorbikes, ATVs",
        ]),
      ],
    },
    {
      name: "Brokerage 5%",
      value: 0.5,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(5)],
    },
    {
      name: "Brokerage 6%",
      value: 1,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(6)],
    },
    {
      name: "Brokerage 7%",
      value: 1.5,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(7)],
    },
    {
      name: "Brokerage 8%",
      value: 2,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(8)],
    },
  ],
  info: [],
  productTiers: [...primaryAssets(base()), ...secondaryAssets(base())],
};

function primaryAssets(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      termIsLessThanOrEqualTo(72),
      assetType([
        "Motor Vehicle (<4.5 tonnes)",
        "Electric Motor Vehicle (<4.5 tonnes)",
        "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
        "Commercial (Heavy) Vehicles (>12 Tonnes)",
        "Trailers and Caravans",
        "Warehouse Equipment",
        "Earth Moving & Construction (wheeled or tracked)",
        "Agricultural & Landscaping Equipment (wheeled or tracked)",
      ]),
    ],
  }));
}

function secondaryAssets(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      termIsLessThanOrEqualTo(72),
      assetType([
        "Manufacturing Equipment",
        "Industrial Plant & Printing",
        "IT, Computer & Audio Equipment",
        "Office Equipment",
        "Medical Equipment",
        "Restaurant & Catering Equipment",
        "Office Furniture",
        "Health & Beauty Equipment",
        "Solar",
        "Motorbikes, ATVs",
      ]),
      ageOfAssetAtEndLessThan(8),
    ],
  }));
}

function base() {
  return [
    {
      name: "Platinum",
      rate: 14.45,
      info: [
        `No finance defaults, no other defaults listed within 12 months.Unpaid 
        defaults considered if listed > 12 months.`,
      ],
      productType: "Adverse",
      criteria: [
        loanAmountBetween(8000, 75000),
        timeInBusinessGreaterThan(18),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      ],
    },
    {
      name: "Platinum",
      rate: 14.45,
      info: [
        `No finance defaults, no other defaults listed within 12 months.Unpaid 
        defaults considered if listed > 12 months.`,
      ],
      productType: "Adverse",
      criteria: [loanAmountBetween(8000, 150000), timeInBusinessGreaterThan(18), livingArrangements([PROPERTY_OWNER])],
    },

    {
      name: "Gold",
      rate: 17.45,
      productType: "Adverse",
      info: [
        `No unpaid finance defaults within 24 months, no
        other defaults listed within 12 months, no unpaid
        finance defaults >$3k`,
      ],
      criteria: [
        loanAmountBetween(8000, 75000),
        timeInBusinessGreaterThan(12),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      ],
    },
    {
      name: "Gold",
      rate: 17.45,
      productType: "Adverse",
      info: [
        `No unpaid finance defaults within 24 months, no
other defaults listed within 12 months, no unpaid
finance defaults >$3k`,
      ],
      criteria: [loanAmountBetween(8000, 150000), timeInBusinessGreaterThan(12), livingArrangements([PROPERTY_OWNER])],
    },

    {
      name: "Silver",
      rate: 20.45,
      info: [
        `No unpaid finance defaults within 12 months, any 
other finance default greater than $5k must be paid 
or in payment arrangement.`,
      ],
      productType: "Adverse",
      criteria: [loanAmountBetween(8000, 50000), timeInBusinessGreaterThan(6), ageOfAssetAtEndLessThan(26)],
    },
  ];
}
export default financeOne;
