import a from "./a";
import aa from "./aa";
import { employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { FULL_TIME, PART_TIME, CASUAL, CONTRACT, SELF_EMPLOYED } = employmentTypes;

const {
  typeOfSale,
  assetValueBetween,
  employmentType,
  dateOfBirthBetween,
  dateOfBirthGreaterThan,
  timeOfEmploymentGreaterThan,
  ageOfAssetAtEndGreaterThan,
} = criteria;

function selfEmployed(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, employmentType([SELF_EMPLOYED, CONTRACT]), timeOfEmploymentGreaterThan(12)],
  }));
}

function fullTimeEmployed(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, employmentType([FULL_TIME]), timeOfEmploymentGreaterThan(3)],
  }));
}

function partTimeEmployed(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, employmentType([PART_TIME]), timeOfEmploymentGreaterThan(6)],
  }));
}

function temporaryEmployed(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, employmentType([CASUAL]), timeOfEmploymentGreaterThan(12)],
  }));
}

function dateOfBirth1821(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, assetValueBetween(10000, 20000), dateOfBirthBetween(18, 21)],
  }));
}

function dateOfBirth2226(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, assetValueBetween(10000, 20000), dateOfBirthBetween(22, 26)],
  }));
}

function dateOfBirthAllA(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, assetValueBetween(10000, 75000), dateOfBirthGreaterThan(26)],
  }));
}

function dateOfBirthAllAA(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, assetValueBetween(10000, 100000), dateOfBirthGreaterThan(26)],
  }));
}

const withEmploymentCriteria = [
  ...selfEmployed(a),
  ...fullTimeEmployed(a),
  ...partTimeEmployed(a),
  ...selfEmployed(aa),
  ...fullTimeEmployed(aa),
  ...partTimeEmployed(aa),
  ...temporaryEmployed(aa),
];

const withDateOfBirthCriteria = [
  ...dateOfBirth1821(withEmploymentCriteria),
  ...dateOfBirth2226(withEmploymentCriteria),
  ...dateOfBirthAllA(withEmploymentCriteria),
  ...dateOfBirthAllAA(withEmploymentCriteria),
];

const autopay = {
  date: "27 Dec 2023",
  lender: "Autopay",
  type: "consumer",
  logoURL: "https://assets.financeable.com.au/lender-logos/autopay-logo.png",
  actions: ["upload"],
  lenderCredentials: [
    // {
    //   label: "Lead source ID",
    //   attribute: "brokerId",
    // },
    // {
    //   label: "Client ID",
    //   attribute: "organisationId",
    // },
    // {
    //   label: "Client Secret",
    //   attribute: "apiKey",
    // },
    // {
    //   label: "Scope",
    //   attribute: "scope",
    // },
    {
      label: "Username",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
  ],
  requiredFields: {
    asset: ["assetType", "typeOfSale", "ageOfAsset", "glassesMake", "glassesModel", "glassesVariant", "assetValue","condition"],
    customer: [
      "customerTitle",
      "firstName",
      "lastName",
      "dateOfBirth",
      "IDNumber",
      "IDCardNumber",
      "IDExpiryDate",
      "customerProfile.livingArrangements",
      "customerProfile.numberOfDependants",
      "customerProfile.residencyStatus",
      "customerProfile.maritalStatus",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      // "addresses[0].street",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "customerAssets.[0].assetType",
      "customerAssets.[0].value",
      "customerAssets.[0].description",
      "liabilities.[0].liabilitiesType",
      "liabilities.[0].outstandingAmount",
      "liabilities.[0].limit",
      "liabilities.[0].financier",
      "liabilities.[0].repaymentFrequency",
      "liabilities.[0].repaymentAmount",
      "liabilities.[0].description",
      "income.[0].incomeType",
      "income.[0].amount",
      "income.[0].frequency",
      "expenses.[0].expensesType",
      "expenses.[0].amount",
      "expenses.[0].frequency",
      "employers.[0].entityName",
      "employers.[0].status",
      "employers.[0].timeOfEmployment",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Establishment fee",
      value: 550,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale("Authorised seller")],
    },
    {
      name: "Origination fee",
      value: 1490,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Account fee",
      value: 10,
      capitalised: false,
      frequency: "monthly",
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 4,
      valueType: "percentage",
      criteria: [assetValueBetween(0, 1000000)],
    },
  ],
  loading: [
    {
      name: "Age of asset at end >16 years",
      value: 1.5,
      valueType: "percentage",
      criteria: [ageOfAssetAtEndGreaterThan(16)],
    },
  ],
  info: [
    `Minimum Equifax score of 650`,
    `Minimum net income $30,000 pa`,
    `No paid or unpaid financial default judgements, court actions, or bankruptcy recorded`,
    `Unpaid utility and telco defaults cannot exceed $500`,
    `No current arrears on any active credit contract`,
    `RHI ≥ 2 in the last 3 months not accepted`,
    `Only one active Autopay loan allowed per client`,
    `Credit files less than 12months old capped at $20,000`,
  ],
  productTiers: withDateOfBirthCriteria,
};
export default autopay;
