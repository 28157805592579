import { employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { FULL_TIME, PART_TIME, CONTRACT, SELF_EMPLOYED } = employmentTypes;

const {
  assetType,
  creditScoreBetween,
  ageOfAssetBetween,
  ageOfAssetAtEndLessThan,
  ageOfAssetGreaterThan,
  assetCondition,
  hasDeposit,
  employmentType,
} = criteria;

const a = [
  {
    name: "A Tier",
    rate: 10.45,
    productType: "Low doc",
    criteria: [
      assetCondition(["New"]),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      employmentType([FULL_TIME, PART_TIME, SELF_EMPLOYED, CONTRACT]),
      hasDeposit(20),
      creditScoreBetween(650, 799),
    ],
  },
  {
    name: "A Tier",
    rate: 10.45,
    productType: "Low doc",
    criteria: [
      assetCondition(["Demo", "Used"]),
      ageOfAssetBetween(0, 71),
      ageOfAssetAtEndLessThan(21),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      employmentType([FULL_TIME, PART_TIME, SELF_EMPLOYED, CONTRACT]),
      hasDeposit(20),
      creditScoreBetween(650, 799),
    ],
  },
  {
    name: "A Tier",
    rate: 10.65,
    productType: "Low doc",
    criteria: [
      assetCondition(["Demo", "Used"]),
      ageOfAssetBetween(72, 107),
      ageOfAssetAtEndLessThan(21),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      employmentType([FULL_TIME, PART_TIME, SELF_EMPLOYED, CONTRACT]),
      hasDeposit(20),
      creditScoreBetween(650, 799),
    ],
  },
  {
    name: "A Tier",
    rate: 11.75,
    productType: "Low doc",
    criteria: [
      assetCondition(["Demo", "Used"]),
      ageOfAssetGreaterThan(107),
      ageOfAssetAtEndLessThan(21),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      employmentType([FULL_TIME, PART_TIME, SELF_EMPLOYED, CONTRACT]),
      hasDeposit(20),
      creditScoreBetween(650, 799),
    ],
  },

  {
    name: "A Tier",
    rate: 10.55,
    productType: "Low doc",
    criteria: [
      assetCondition(["New"]),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      employmentType([FULL_TIME, PART_TIME, SELF_EMPLOYED, CONTRACT]),
      hasDeposit(10),
      creditScoreBetween(650, 799),
    ],
  },
  {
    name: "A Tier",
    rate: 10.55,
    productType: "Low doc",
    criteria: [
      assetCondition(["Demo", "Used"]),
      ageOfAssetBetween(0, 71),
      ageOfAssetAtEndLessThan(21),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      employmentType([FULL_TIME, PART_TIME, SELF_EMPLOYED, CONTRACT]),
      hasDeposit(10),
      creditScoreBetween(650, 799),
    ],
  },
  {
    name: "A Tier",
    rate: 10.75,
    productType: "Low doc",
    criteria: [
      assetCondition(["Demo", "Used"]),
      ageOfAssetBetween(72, 107),
      ageOfAssetAtEndLessThan(21),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      employmentType([FULL_TIME, PART_TIME, SELF_EMPLOYED, CONTRACT]),
      hasDeposit(10),
      creditScoreBetween(650, 799),
    ],
  },
  {
    name: "A Tier",
    rate: 11.85,
    productType: "Low doc",
    criteria: [
      assetCondition(["Demo", "Used"]),
      ageOfAssetGreaterThan(107),
      ageOfAssetAtEndLessThan(21),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      employmentType([FULL_TIME, PART_TIME, SELF_EMPLOYED, CONTRACT]),
      hasDeposit(10),
      creditScoreBetween(650, 799),
    ],
  },

  {
    name: "A Tier",
    rate: 10.75,
    productType: "Low doc",
    criteria: [
      assetCondition(["New"]),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      employmentType([FULL_TIME, PART_TIME, SELF_EMPLOYED, CONTRACT]),
      creditScoreBetween(650, 799),
    ],
  },
  {
    name: "A Tier",
    rate: 10.75,
    productType: "Low doc",
    criteria: [
      assetCondition(["Demo", "Used"]),
      ageOfAssetBetween(0, 71),
      ageOfAssetAtEndLessThan(21),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      employmentType([FULL_TIME, PART_TIME, SELF_EMPLOYED, CONTRACT]),
      creditScoreBetween(650, 799),
    ],
  },
  {
    name: "A Tier",
    rate: 10.95,
    productType: "Low doc",
    criteria: [
      assetCondition(["Demo", "Used"]),
      ageOfAssetBetween(72, 107),
      ageOfAssetAtEndLessThan(21),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      employmentType([FULL_TIME, PART_TIME, SELF_EMPLOYED, CONTRACT]),
      creditScoreBetween(650, 799),
    ],
  },
  {
    name: "A Tier",
    rate: 12.05,
    productType: "Low doc",
    criteria: [
      assetCondition(["Demo", "Used"]),
      ageOfAssetGreaterThan(107),
      ageOfAssetAtEndLessThan(21),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      employmentType([FULL_TIME, PART_TIME, SELF_EMPLOYED, CONTRACT]),
      creditScoreBetween(650, 799),
    ],
  },
];

export default a;
