import pepper from "./pepper";
import plenti from "./plenti";
import now from "./now";
import wisr from "./wisr";
import liberty from "./liberty";
import money3 from "./money3";
import macquarie from "./macquarie";
import moneyplace from "./moneyplace";
import greenlight from "./greenlight";
import financeOne from "./financeOne";
import acl from "./acl";
import apf from "./apf";
import carStart from "./carStart";
import alex from "./alex";
import branded from "./branded";
import ammf from "./ammf";
import firstmac from "./firstMac";
import autopay from "./autopay";
import saltLime from "./saltLime";
import latitude from "./latitude";

const consumer = [
  acl,
  alex,
  ammf,
  apf,
  autopay,
  branded,
  carStart,
  financeOne,
  firstmac,
  greenlight,
  latitude,
  liberty,
  macquarie,
  money3,
  moneyplace,
  now,
  pepper,
  plenti,
  saltLime,
  wisr,
];
export default consumer;