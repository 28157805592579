import React from "react";
import { Box, CircularProgress } from "@mui/material";

const AutoCompleteLoader = () => (
  <Box
    sx={{
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
    }}
  >
    <CircularProgress size={30} />
  </Box>
);

export default AutoCompleteLoader;
