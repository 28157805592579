import { livingArrangementsTypes, employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } = livingArrangementsTypes;

const { SELF_EMPLOYED } = employmentTypes;

const {
  hasDeposit,
  termIsBetween,
  assetType,
  ageOfAssetLessThan,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
  creditScoreBetween,
} = criteria;

const silver = [
  {
    name: "ACL - Auto",
    rate: 9.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(60001, 100000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(6),
      creditScoreGreaterThan(399),
    ],
  },
  {
    name: "ACL - Auto",
    rate: 13.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(50001, 60000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(6),
      creditScoreGreaterThan(399),
    ],
  },
  {
    name: "ACL - Auto",
    rate: 15.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(40001, 50000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(6),
      creditScoreGreaterThan(399),
    ],
  },
  {
    name: "ACL - Auto",
    rate: 17.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(30001, 40000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(6),
      creditScoreGreaterThan(399),
    ],
  },
  {
    name: "ACL - Auto",
    rate: 20.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(25001, 30000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(6),
      creditScoreGreaterThan(399),
    ],
  },
  {
    name: "ACL - Auto",
    rate: 24.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(20001, 25000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(6),
      creditScoreGreaterThan(399),
    ],
  },
  {
    name: "ACL - Auto",
    rate: 28.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(10000, 20000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(6),
      creditScoreGreaterThan(399),
    ],
  },

  {
    name: "ACL - Auto",
    rate: 9.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(60001, 100000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      hasDeposit(30),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(6),
      creditScoreBetween(300, 399),
    ],
  },
  {
    name: "ACL - Auto",
    rate: 13.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(50001, 60000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      hasDeposit(30),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(6),
      creditScoreBetween(300, 399),
    ],
  },
  {
    name: "ACL - Auto",
    rate: 15.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(40001, 50000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      hasDeposit(30),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(6),
      creditScoreBetween(300, 399),
    ],
  },
  {
    name: "ACL - Auto",
    rate: 17.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(30001, 40000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      hasDeposit(30),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(6),
      creditScoreBetween(300, 399),
    ],
  },
  {
    name: "ACL - Auto",
    rate: 20.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(25001, 30000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      hasDeposit(30),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(6),
      creditScoreBetween(300, 399),
    ],
  },
  {
    name: "ACL - Auto",
    rate: 24.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(20001, 25000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      hasDeposit(30),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(6),
      creditScoreBetween(300, 399),
    ],
  },
  {
    name: "ACL - Auto",
    rate: 28.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(10000, 20000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      hasDeposit(30),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(6),
      creditScoreBetween(300, 399),
    ],
  },
];
export default silver;
