import React from "react";
import { CircularProgress, TableCell, TableRow } from "@mui/material";
import { cellAlign } from "../../constants";

export const TableLoader = ({ columnSpan }) => (
  <TableRow>
    <TableCell align={cellAlign} colSpan={columnSpan}>
      <CircularProgress size={30} />
    </TableCell>
  </TableRow>
);

export const TableNotFound = ({ columnSpan }) => (
  <TableRow>
    <TableCell align={cellAlign} colSpan={columnSpan}>
      No Record Found
    </TableCell>
  </TableRow>
);
