import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Grid, InputAdornment, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import CalCustomTitleBar from './common/CalCustomTitleBar'
import { setCalculatorFieldsError } from '../../store/slices/applicationFormSlice'
import { NumericFormatCustom } from '../../utils/currencyMaskFormat'

const CalBalloon = React.memo(({ allValues, calculatorFieldsErrors, validateField, saveLoanDetails, setAllValue }) => {
  const dispatch = useDispatch()
  const [mode, setMode] = useState("percentage");
  const isPercentageCheck = mode === "percentage";

  const handle = {
    onChangeField: async (e, name) => {
      let isValid = true;

      if (e === "") {
        if (name === "balloon") {
          setAllValue({ ...allValues, [name]: "" });
        } else {
          setAllValue({ ...allValues, [name]: +e });
        }
        dispatch(setCalculatorFieldsError({ ...calculatorFieldsErrors, balloon: { ...calculatorFieldsErrors.balloon, [name]: "" } }))
        return;
      }

      if (!isValid) {
        await validateField({ fieldName: name, value: e, fieldObj: 'balloon' });
        return;
      }

      if (name === "balloon") {
        if (mode === "percentage") {
          if (+e < 0) {
            await validateField({ fieldName: name, value: e, fieldObj: 'balloon' });
            return;
          }
          setAllValue({ ...allValues, [name]: +e, balloonAmount: handle.calcBalloonAmount(e) });
        } else {
          setAllValue({ ...allValues, [name]: handle.calcBalloonPercentage(e), balloonAmount: Math.abs(e) });
        }
      } else {
        setAllValue({ ...allValues, [name]: e });
      }

      await validateField({ fieldName: name, value: e, fieldObj: 'balloon' });
    },
    calcBalloonAmount: (balloon) => {
      const balloonAmount = allValues?.financedAmount * balloon / 100
      return balloonAmount;
    },
    calcBalloonPercentage: (balloonAmount) => {
      const balloonPercentage = balloonAmount / allValues?.financedAmount * 100;
      return balloonPercentage && !isNaN(balloonPercentage) && isFinite(balloonPercentage)
        ? balloonPercentage
        : 0;
    },
    handleModeChange: (event, newMode) => {
      if (newMode !== null) {
        if (mode !== "percentage") {
          validateField("balloon", allValues.balloon);
        }
        setMode(newMode);
      }
    }
  }

  return (
    <Box>
      <CalCustomTitleBar title={"Residual/Balloon"} />

      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        mb={"20px"}
        spacing={3}
        direction="row"
      >
        <Grid item xs={12} sm={12}>
          <Box display="flex" alignItems="center">
            <TextField
              label="Balloon"
              name="balloon"
              disabled={!allValues?.netAssetValue}
              value={isPercentageCheck ? allValues?.balloon : allValues?.balloonAmount}
              error={isPercentageCheck ? calculatorFieldsErrors?.balloon?.balloon : calculatorFieldsErrors?.balloon?.balloonAmount}
              helperText={isPercentageCheck ? calculatorFieldsErrors?.balloon?.balloon : calculatorFieldsErrors?.balloon?.balloonAmount}
              onChange={(event) => {
                const value = event.target.value;
                // Handle clearing the value properly
                handle.onChangeField(value, "balloon");
              }}
              onBlur={() => {
                saveLoanDetails({
                  balloon: allValues?.balloon || 0,
                  balloonAmount: allValues?.balloonAmount || 0,
                });
              }}
              InputProps={{
                inputComponent: NumericFormatCustom,
                inputProps: {
                  prefix: "",
                  isAllowedFlag: true,
                  maxDigits: 7,
                  maxPlaces: 2,
                },
                startAdornment: allValues?.balloonAmount > 0 && mode !== "percentage" && (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <ToggleButtonGroup
                      value={mode}
                      exclusive
                      color="primary"
                      onChange={handle.handleModeChange}
                      sx={{
                        m: '0 -12px 0 0',
                        // height: "36px",
                      }}
                    >
                      <ToggleButton value="percentage">
                        <PercentIcon fontSize='small' />
                      </ToggleButton>
                      <ToggleButton value="dollar">
                        <AttachMoneyIcon fontSize='small' />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </InputAdornment>
                ),
              }}
              type="text"
              size="small"
              variant="filled"
              fullWidth
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
})

export default CalBalloon