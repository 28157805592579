import { employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { FULL_TIME, PART_TIME, CONTRACT, CASUAL } = employmentTypes;

const {
  termIsBetween,
  assetType,
  loanAmountBetween,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
} = criteria;

const subPrime = [
  {
    name: "Sub Prime",
    rate: [19.49, 27.9],
    productType: "Adverse",
    criteria: [
      loanAmountBetween(7000, 50000),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
        "Motorbike",
        "ATV/Off-Road Bike",
        "Tractor",
        "Ride on mower",
        "Horse float",
        "Trailer",
        "Caravan",
        "Motor home",
        "Trailered boat",
        "Jetski/PWC",
      ]),
      termIsBetween(36, 60),
      employmentType([FULL_TIME, PART_TIME, CASUAL, CONTRACT]),
      timeOfEmploymentGreaterThan(1),
      creditScoreGreaterThan(0, 450),
    ],
  },
];
export default subPrime;
