import base from "./base";
import { employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { FULL_TIME, PART_TIME, CASUAL, CONTRACT, SELF_EMPLOYED } = employmentTypes;

const { assetValueBetween, loanAmountBetween, employmentType, timeOfEmploymentGreaterThan } = criteria;

function selfEmployed(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, employmentType([SELF_EMPLOYED, CONTRACT]), timeOfEmploymentGreaterThan(12)],
  }));
}

function permanentEmployed(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, employmentType([FULL_TIME, PART_TIME]), timeOfEmploymentGreaterThan(3)],
  }));
}

function temporaryEmployed(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, employmentType([CASUAL]), timeOfEmploymentGreaterThan(6)],
  }));
}

const withEmploymentCriteria = [...selfEmployed(base), ...permanentEmployed(base), ...temporaryEmployed(base)];

const alex = {
  date: "27 Feb 2024",
  lender: "Alex Bank",
  type: "consumer",
  logoURL: "https://assets.financeable.com.au/lender-logos/alex-bank.png",
  actions: ["upload"],
  lenderCredentials: [
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
  ],
  requiredFields: {
    asset: ["assetType", "typeOfSale", "ageOfAsset", "glassesMake", "glassesModel", "glassesVariant", "assetValue"],
    customer: [
      "firstName",
      "lastName",
      "dateOfBirth",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      // "addresses[0].street",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "IDNumber",
      "IDCardNumber",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "employers.[0].entityName",
      "employers.[0].timeOfEmployment",
      "employers.[0].employmentType",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Origination fee",
      value: 1990,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(10000, 100000)],
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 4,
      valueType: "percentage",
      criteria: [assetValueBetween(10000, 100000)],
    },
  ],
  loading: [],
  info: [
    `Serviceability assessment for applications based on either 2 last payslips or Bank Statements (90 days)`,
    `No private sales allowed (Authorised dealers only)`,
    `Broker has to provide a copy of the soft score credit file as part of the lodgement`,
  ],
  productTiers: withEmploymentCriteria,
};
export default alex;
