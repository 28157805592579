import criteria from "../../utils/criteria";
import { typeOfSaleList, livingArrangementsTypes } from "../../constants";

const { PROPERTY_OWNER, RENTING, LIVING_WITH_PARENTS, BOARDING } = livingArrangementsTypes;

const {
  livingArrangements,
  hasDeposit,
  typeOfSale,
  assetValueBetween,
  assetValueGreaterThan,
  assetType,
  ageOfAssetBetween,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  ageOfAssetAtEndGreaterThan,
  gstRegistrationGreaterThan,
  termIsLessThan,
  termIsGreaterThan,
  creditScoreGreaterThan,
  brokerageIsEqualTo,
} = criteria;

const flexi = {
  date: "1 Feb 2024",
  lender: "Flexi",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos%2Fflexicommercial_TM_20210301_Orange.webp",
  actions: ["upload"],
  lenderCredentials: [
    {
      label: "Username",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
  ],
  requiredFields: {
    asset: [
      "assetValue",
      "assetType",
      "typeOfSale",
      "ageOfAsset",
      "condition",
      "glassesMake",
      "glassesModel",
      // "Make", 
      // "Model",
    ],
    loanDetails: [
      "rate"
      // "term",
      // "balloon",
      // "deposit",
      // "tradeIn",
      // "payout",
      // "rate",
      // "brokerageAmount",
    ],
    entity: [
      "abn",
      "entityName",
      "entityType",
      "industryType",
      "turnover",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      // "addresses[0].monthsAt",
      // "addresses[0].yearsAt",
    ],
    customer: [
      "customerTitle",
      "firstName",
      "lastName",
      "dateOfBirth",
      "IDNumber",
      "IDCardNumber",
      "IDExpiryDate",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "customerProfile.livingArrangements",
      "customerProfile.numberOfDependants",
      "customerProfile.residencyStatus",
      "customerProfile.maritalStatus",
      "customerAssets.[0].assetType",
      "customerAssets.[0].value",
      "customerAssets.[0].description",
      "liabilities.[0].liabilitiesType",
      "liabilities.[0].outstandingAmount",
      "liabilities.[0].limit",
      "liabilities.[0].financier",
      "liabilities.[0].repaymentFrequency",
      "liabilities.[0].repaymentAmount",
      "liabilities.[0].description",
    ],
  },
  fees: [
    {
      name: "Document fee",
      value: 495,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Private sale doc fee",
      value: 695,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.PRIVATE_SALE)],
    },
  ],
  brokerage: [
    {
      value: 5,
      max: 8,
      valueType: "percentage",
      criteria: [assetValueBetween(10000, 49999.99)],
    },
    {
      value: 4,
      max: 6,
      valueType: "percentage",
      criteria: [assetValueGreaterThan(49999.99)],
    },
  ],
  loading: [
    {
      name: "Private sale",
      value: 1,
      valueType: "percentage",
      criteria: [typeOfSale(typeOfSaleList.PRIVATE_SALE)],
    },
    {
      name: "Non property owner",
      value: 1,
      valueType: "percentage",
      criteria: [livingArrangements(["Renting", "Boarding", "Living w/ parents"])],
    },
    {
      name: "Term <24",
      value: 1,
      valueType: "percentage",
      criteria: [termIsLessThan(25)],
    },
    {
      name: "Term >60",
      value: 1.25,
      valueType: "percentage",
      criteria: [termIsGreaterThan(60)],
    },
    {
      name: "Commercial (Heavy) Vehicles >12T",
      value: 1,
      valueType: "percentage",
      criteria: [assetType(["Commercial (Heavy) Vehicles (>12 Tonnes)"])],
    },
    {
      name: "Asset age >10 years at start",
      value: 1,
      valueType: "percentage",
      criteria: [
        ageOfAssetBetween(120, 180),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
      ],
    },
    {
      name: "Asset age at end >15 years",
      value: 2,
      valueType: "percentage",
      criteria: [
        ageOfAssetAtEndGreaterThan(15),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
      ],
    },

    {
      name: "Brokerage 6% for <$50000",
      value: 0.5,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(6), assetValueBetween(10000, 49999.99)],
    },
    {
      name: "Brokerage 7% for <$50000",
      value: 1,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(7), assetValueBetween(10000, 49999.99)],
    },
    {
      name: "Brokerage 8% for <$50000",
      value: 1.5,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(8), assetValueBetween(10000, 49999.99)],
    },

    {
      name: "Brokerage 5% for >$50000",
      value: 0.5,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(5), assetValueGreaterThan(49999.99)],
    },
    {
      name: "Brokerage 6% for >$50000",
      value: 1,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(6), assetValueGreaterThan(49999.99)],
    },
  ],
  info: [
    `Direct Debit*`,
    `Director’s Guarantees*`,
    `Used assets and Private Sale acceptable for
    Primary. Secondary assets on exception basis`,
    `Borrowers/Guarantors for transport operators/
    sub-contractors are to be asset backed with
    sufficient equity or ABN > 5 years
    (20% deposit required)`,
    `Maximum exposure $400K`,
    `*Not required for Public companies or Private companies that are required to lodge annual financial statements with ASIC.`,
  ],
  productTiers: [
    // {
    //   name: "Auto",
    //   rate: 10.000,
    //   productType: "Low doc",
    //   criteria: [
    //     loanAmountBetween(10000, 50000),
    //     timeInBusinessGreaterThan(23),
    //     gstRegistrationGreaterThan(23),
    //     ageOfAssetAtEndLessThan(20),
    //     assetType([
    //       "Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)",
    //     ]),
    //     creditScoreGreaterThan(549)
    //   ],
    // },
    // {
    //   name: "Auto",
    //   rate: 9.60,
    //   productType: "Low doc",
    //   criteria: [
    //     loanAmountBetween(50001, 150000),
    //     timeInBusinessGreaterThan(23),
    //     gstRegistrationGreaterThan(23),
    //     ageOfAssetAtEndLessThan(20),
    //     assetType([
    //       "Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)",
    //     ]),
    //     livingArrangements(["Property owner"]),
    //     creditScoreGreaterThan(549)
    //   ],
    // },
    // {
    //   name: "Auto",
    //   rate: 9.60,
    //   productType: "Low doc",
    //   criteria: [
    //     loanAmountBetween(50001, 150000),
    //     timeInBusinessGreaterThan(23),
    //     gstRegistrationGreaterThan(23),
    //     ageOfAssetAtEndLessThan(20),
    //     assetType([
    //       "Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)",
    //     ]),
    //     hasDeposit(20),
    //     livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
    //     creditScoreGreaterThan(549)
    //   ],
    // },
    {
      name: "Primary Asset",
      rate: 13.5,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 13.5,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(30),
        assetType(["Trailers and Caravans"]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 11.25,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20001, 50000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 11.25,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20001, 50000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(30),
        assetType(["Trailers and Caravans"]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 9.5,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        livingArrangements(["Property owner"]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 9.5,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(30),
        assetType(["Trailers and Caravans"]),
        livingArrangements(["Property owner"]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 9.5,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        hasDeposit(20),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 9.5,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(30),
        assetType(["Trailers and Caravans"]),
        hasDeposit(20),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 9.6,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(150000, 300000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        livingArrangements(["Property owner"]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 9.6,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(150000, 300000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(30),
        assetType(["Trailers and Caravans"]),
        livingArrangements(["Property owner"]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 9.6,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(150000, 300000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        hasDeposit(20),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 9.6,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(150000, 300000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(30),
        assetType(["Trailers and Caravans"]),
        hasDeposit(20),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },

    {
      name: "Primary Asset",
      rate: 14.5,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 14.5,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(30),
        assetType(["Trailers and Caravans"]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 12.05,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20001, 50000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 12.05,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20001, 50000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(30),
        assetType(["Trailers and Caravans"]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 11.1,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 11.1,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(30),
        assetType(["Trailers and Caravans"]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },

    {
      name: "Primary Asset",
      rate: 10.0,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(150000, 300000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 10.0,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(150000, 300000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(30),
        assetType(["Trailers and Caravans"]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },

    {
      name: "Secondary Asset",
      rate: 14.0,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType(["Industrial Plant & Printing", "Medical Equipment", "Manufacturing Equipment"]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Secondary Asset",
      rate: 11.25,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20001, 50000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType(["Industrial Plant & Printing", "Medical Equipment", "Manufacturing Equipment"]),
        livingArrangements(["Property owner"]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Secondary Asset",
      rate: 11.25,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20001, 50000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType(["Industrial Plant & Printing", "Medical Equipment", "Manufacturing Equipment"]),
        hasDeposit(20),
        livingArrangements([RENTING, LIVING_WITH_PARENTS, BOARDING]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Secondary Asset",
      rate: 9.5,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType(["Industrial Plant & Printing", "Medical Equipment", "Manufacturing Equipment"]),
        livingArrangements(["Property owner"]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Secondary Asset",
      rate: 9.5,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType(["Industrial Plant & Printing", "Medical Equipment", "Manufacturing Equipment"]),
        hasDeposit(20),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Secondary Asset",
      rate: 9.0,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(150000, 300000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType(["Industrial Plant & Printing", "Medical Equipment", "Manufacturing Equipment"]),
        livingArrangements(["Property owner"]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Secondary Asset",
      rate: 9.0,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(150000, 300000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType(["Industrial Plant & Printing", "Medical Equipment", "Manufacturing Equipment"]),
        hasDeposit(20),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },

    {
      name: "Secondary Asset",
      rate: 10.5,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType(["Industrial Plant & Printing", "Medical Equipment", "Manufacturing Equipment"]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Secondary Asset",
      rate: 10.0,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(150000, 300000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType(["Industrial Plant & Printing", "Medical Equipment", "Manufacturing Equipment"]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },

    {
      name: "Tertiary Asset",
      rate: 16.5,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Tertiary Asset",
      rate: 15.5,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20001, 50000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        livingArrangements(["Property owner"]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Tertiary Asset",
      rate: 14.5,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        livingArrangements(["Property owner"]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    // {
    //   name: "Tertiary Asset",
    //   rate: 14.10,
    //   productType: "Low doc",
    //   criteria: [
    //     loanAmountBetween(150000, 250000),
    //     timeInBusinessGreaterThan(23),
    //     gstRegistrationGreaterThan(23),
    //     ageOfAssetAtEndLessThan(5),
    //     assetType([
    //       "IT, Computer & Audio Equipment",
    //       "Office Equipment",
    //       "Restaurant & Catering Equipment",
    //       "Office Furniture",
    //       "Health & Beauty Equipment",
    //       "Solar",
    //     ]),
    //     livingArrangements(["Property owner"]),
    //     termIsLessThan(61),
    //     creditScoreGreaterThan(549)
    //   ],
    // },
    {
      name: "Tertiary Asset",
      rate: 16.5,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Tertiary Asset",
      rate: 15.5,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20001, 50000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        hasDeposit(20),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Tertiary Asset",
      rate: 14.5,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        hasDeposit(20),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Tertiary Asset",
      rate: 15.5,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },

    {
      name: "Primary Asset - Special",
      rate: 8.28,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(100000, 500000),
        timeInBusinessGreaterThan(47),
        gstRegistrationGreaterThan(47),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        livingArrangements([PROPERTY_OWNER]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset - Special",
      rate: 8.28,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(100000, 500000),
        timeInBusinessGreaterThan(47),
        gstRegistrationGreaterThan(47),
        ageOfAssetAtEndLessThan(30),
        assetType(["Trailers and Caravans"]),
        livingArrangements([PROPERTY_OWNER]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Secondary Asset - Special",
      rate: 8.68,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(100000, 500000),
        timeInBusinessGreaterThan(47),
        gstRegistrationGreaterThan(47),
        ageOfAssetAtEndLessThan(5),
        assetType(["Industrial Plant & Printing", "Medical Equipment", "Manufacturing Equipment"]),
        hasDeposit(20),
        livingArrangements([PROPERTY_OWNER]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },

    {
      name: "Primary Asset - Special",
      rate: 9.5,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(100000, 300000),
        timeInBusinessGreaterThan(47),
        gstRegistrationGreaterThan(47),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset - Special",
      rate: 9.5,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(100000, 300000),
        timeInBusinessGreaterThan(47),
        gstRegistrationGreaterThan(47),
        ageOfAssetAtEndLessThan(30),
        assetType(["Trailers and Caravans"]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Secondary Asset - Special",
      rate: 8.68,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(100000, 500000),
        timeInBusinessGreaterThan(47),
        gstRegistrationGreaterThan(47),
        ageOfAssetAtEndLessThan(5),
        assetType(["Industrial Plant & Printing", "Medical Equipment", "Manufacturing Equipment"]),
        hasDeposit(20),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
  ],
};
export default flexi;
