import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, Grid, IconButton, Input, InputAdornment, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { makeStyles } from "@mui/styles";
import _, { debounce } from "lodash";
import moment from "moment";
import clsx from "clsx";
import { calculatorsListSelector, getCalculatorList } from "../../store/slices/calculatorSlice";
import { getApplicationTab } from "../../constants";
import { saveValue } from "../../store/slices/applicationsSlice";
import DataGridTable from "../dataGridTable/DataGridTable";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "61ch",
  },
}));

const dollarStringOptions = {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
};

const masterViewColumns = [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: "humanId",
    headerName: "ID",
    type: "string",
    width: 100,
    editable: false,
  },
  {
    field: "startDate",
    headerName: "Date",
    type: "string",
    width: 90,
    editable: false,
    renderCell: (params) => (
      <div>
        <Typography style={{ fontSize: "14px" }}>{params.value.date}</Typography>
        <Typography style={{ fontSize: "11px", color: "#999" }}>{params.value.time}</Typography>
      </div>
    ),
  },
  // {
  //   field: "time",
  //   headerName: "Time",
  //   type: "string",
  //   width: 80,
  //   editable: false,
  // },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    width: 110,
    editable: false,
  },
  // {
  //   field: "applicationType",
  //   headerName: "Application Type",
  //   type: "string",
  //   width: 110,
  //   editable: false,
  // },
  {
    field: "broker",
    headerName: "Broker",
    type: "string",
    width: 180,
    editable: false,
    renderCell: (params) => (
      <div>
        <Typography style={{ fontSize: "14px" }}>
          {params.value.firstName} {params.value.lastName}
        </Typography>
        <Typography style={{ fontSize: "11px", color: "#999" }}>{params.value.org}</Typography>
      </div>
    ),
  },
  {
    field: "assetType",
    headerName: "Asset type",
    type: "string",
    width: 200,
    editable: false,
    renderCell: (params) => (
      <div>
        <Typography style={{ fontSize: "14px", textWrap: "initial" }}>{params.value}</Typography>
      </div>
    ),
  },
  {
    field: "assetValue",
    headerName: "Asset value",
    type: "string",
    width: 110,
    editable: false,
  },
  // {
  //   field: "customer",
  //   headerName: "Customer",
  //   width: 220,
  //   editable: false,
  //   renderCell: (params) => (
  //     <div>
  //       <Typography style={{ fontSize: "14px", textWrap: "initial" }}>{params.value}</Typography>
  //     </div>
  //   ),
  // },
];

export default function CalculatorList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = React.useState("");
  const calculators = useSelector(calculatorsListSelector);
  // const { applications, page, isLoading } = calculators
  const { page, isLoading } = calculators;
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  const [params, setParams] = useState({ offset: 1 });

  const handle = {
    getAllApplicationsFn: (cursor, search) => {
      // dispatch(getAllApplications({ userId, cursor, search, isQuoteList: false }));
      dispatch(getCalculatorList({ cursor, search, requestedList: getApplicationTab.calculator }));
    },
    handleApplicationSearch: (searchTerm) => {
      (searchTerm.length >= 2 || searchTerm.length <= 0) && handle.getAllApplicationsFn(params?.offset, searchTerm);
    },
  };

  useEffect(() => {
    if (params?.offset) {
      handle.getAllApplicationsFn(params?.offset, searchTerm);
      dispatch(saveValue({ page: params?.offset }));
    }
  }, [params?.offset]);

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = useState(calculators?.totalDocs || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) => calculators?.totalDocs ?? prevRowCountState);
  }, [calculators?.totalDocs]);

  // useEffect(() => {
  //   if (user) handle.getAllApplicationsFn(user, page || 1, searchTerm);
  // }, [user]);

  useEffect(() => {
    const transformApplication = (doc) => {
      const assetValueCheck = doc?.asset?.netAssetValue
        ? _.get(doc, "asset.netAssetValue").toLocaleString("en-US", dollarStringOptions)
        : "No Value";

      const date = new Date(_.get(doc, "createdAt"));
      const entityName = _.get(doc, "entities[0].entityName");
      const firstName = _.get(doc, "customers[0].firstName", "No");
      const lastName = _.get(doc, "customers[0].lastName", "customer");
      const assetType = _.get(doc, "asset.assetType", "Type not entered");
      const assetValue = assetValueCheck;

      const capitalizedStatus = _.get(doc, "status", "").charAt(0).toUpperCase() + _.get(doc, "status", "").slice(1);
      const capitalizedApplicationType =
        _.get(doc, "applicationType", "").charAt(0).toUpperCase() + _.get(doc, "applicationType", "").slice(1);

      return _.pickBy(
        {
          humanId: _.get(doc, "humanId"),
          customer: entityName ? entityName : `${firstName} ${lastName}`,
          applicationType: capitalizedApplicationType,
          assetType,
          assetValue,
          loanAmount: _.get(doc, "loanDetails.loanAmount"),
          term: _.get(doc, "loanDetails.term"),
          id: _.get(doc, "_id"),
          startDate: {
            date: moment(date).format("D-M-YY"),
            time: moment(date).format("h:mm a"),
          },
          broker: {
            firstName: _.get(doc, "user.firstName"),
            lastName: _.get(doc, "user.lastName"),
            org: _.get(doc, "user.organisation[0].name"),
          },
          status: capitalizedStatus,
        },
        _.identity,
      );
    };

    const transformedRows = _.map(_.get(calculators, "docs"), transformApplication);
    setRows(transformedRows);
    // time: moment(date).format("h:mm a"),
  }, [calculators]);

  const debounced = useCallback(debounce(handle.handleApplicationSearch, 500), []);

  return (
    <Grid container item direction="column" spacing={0} style={{ padding: "20px" }}>
      <Grid
        item
        style={{
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
          display: "flex",
          alignItems: "center",
          columnGap: "40px",
        }}
      >
        <Typography
          variant="h1"
          style={{
            fontSize: "24px",
            fontWeight: 800,
            letterSpacing: "-0.5px",
          }}
        >
          Calculators
        </Typography>
        <div className={classes.root}>
          <FormControl className={clsx(classes.margin, classes.textField)}>
            <Input
              className="input-border-bottom"
              id="application-search"
              autoComplete={false}
              variant="filled"
              placeholder="Search…"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                debounced(e.target.value, 1000);
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              endAdornment={
                searchTerm && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setSearchTerm("");
                        searchTerm !== "" && debounced("", 1000);
                      }}
                      color="secondary"
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </FormControl>
        </div>
      </Grid>

      <Grid item>
        <DataGridTable
          data={rows || []}
          columns={masterViewColumns}
          onRowClick={(event) => {
            navigate(`/calculator/${event.id}`);
          }}
          rowsPerPageOptions={[10]}
          pageSize={10}
          rowCount={rowCountState}
          params={params}
          setParams={setParams}
          page={page}
          isLoading={isLoading}
        />
      </Grid>
    </Grid>
  );
}
