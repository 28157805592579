import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MenuItem, Select } from "@mui/material";
import { updateApplication } from "../../store/slices/applicationFormSlice";
import { CLIENT_APPLICATION_STATUS } from "../../constants";
import { CURRENT_CLIENT_NAME } from "../../utils/config";

export default function StatusCell({ params, updateApplicationStatus }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState(params.row.status)
  const ApplicationStatusList = CLIENT_APPLICATION_STATUS[CURRENT_CLIENT_NAME]

  useEffect(() => { setValue(params.row.status) }, [params.row.status])

  const handleStatusUpdate = async event => {
    setValue(event.target.value)
    await dispatch(updateApplication({ _id: params.row.id, status: event.target.value })).unwrap()
    updateApplicationStatus({ _id: params.row.id, status: event.target.value })
  }

  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={value}
      onChange={handleStatusUpdate}
    >
      {ApplicationStatusList?.map((status) => (
        <MenuItem value={status.value} key={status.value}>
          {status.label}
        </MenuItem>
      ))}
    </Select >
  );
}