import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Radio,
  Typography,
} from "@mui/material";
import { saveValue, updateContactFromEmail } from "../../store/slices/applicationFormSlice";
import DataGridTable from "../dataGridTable/DataGridTable";
// import { round, merge } from "lodash";
// import { sendDisclosure, updateLoanDetails } from "../../store/slices/applicationFormSlice";
// import { dollarStringOptions } from "../../constants";

const ConnectiveContactModel = ({
  handleCloseConnectiveContactDialog,
  applicationId,
  customerId,
  connectiveContactList,
  openConnectiveContactModel
}) => {
  const dispatch = useDispatch();
  const [params, setParams] = useState({ offset: 1 });
  // const [isSaveDataToCustomer, setIsSaveDataToCustomer] = useState(true);
  const [selectedRow, setSelectedRow] = useState({
    id: '',
    data: null
  });

  const handleRowSelection = ({ id, data }) => {
    setSelectedRow({
      id: id,
      data: data,
    });
  };

  const handle = {
    linkContactHandler: async () => {
      dispatch(saveValue({
        openConnectiveContactModel: false
      }))
      await dispatch(
        updateContactFromEmail({
          applicationId,
          customerId,
          email: selectedRow.data?.email,
          connectiveContactId: selectedRow.id,
          contactData: selectedRow.data,
          isSaveDataToCustomer: true // isSaveDataToCustomer
        }),
      ).unwrap();
      setSelectedRow({
        id: '',
        data: null
      })

    }
  };


  const connectiveContactColumns = [
    {
      field: 'select',
      headerName: 'Select',
      width: 80,
      renderCell: (params) => (
        <Radio
          checked={params.row.uniqueId === selectedRow?.id}
        // onChange={() => handleRowSelection(params.row.uniqueId)}
        />
      ),
    },
    {
      field: "firstName",
      headerName: "First name",
      type: "string",
      width: 130,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Last name",
      type: "string",
      width: 130,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      type: "string",
      width: 220,
      editable: false,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      type: "string",
      width: 160,
      editable: false,
    }
  ];

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={openConnectiveContactModel}
        onClose={handleCloseConnectiveContactDialog}
        disableEscapeKeyDown={true}
      >

        <DialogTitle style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <Typography>
            Contact List
          </Typography>

          {/* <Stack>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={isSaveDataToCustomer} />}
                label="Use user all data"
                onClick={(event) => setIsSaveDataToCustomer(event.target.checked)}
              />
            </FormGroup>
          </Stack> */}
        </DialogTitle>

        <DialogContent>
          <DialogContentText>Please select contact</DialogContentText>
          <Grid item style={{ maxHeight: 420, width: '100%' }}>
            <DataGridTable
              data={connectiveContactList || []}
              columns={connectiveContactColumns}
              onRowClick={(data) => {
                handleRowSelection({
                  id: data?.uniqueId,
                  data: data
                })
              }}
              rowsPerPageOptions={[10]}
              pageSize={50}
              rowCount={connectiveContactList?.length || 0} // length
              params={params}
              setParams={setParams}
              page={1}
              // isLoading={isLoading}
              getRowId={true}
              isHideTableFooter={true}
            />
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: '0 20px 20px 0' }}>
          <Button
            onClick={handleCloseConnectiveContactDialog}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            disabled={!selectedRow?.id}
            onClick={() => handle.linkContactHandler()}
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConnectiveContactModel;