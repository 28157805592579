import criteria from "../../../utils/criteria";

const {
  livingArrangements,
  assetType,
  ageOfAssetGreaterThan,
  ageOfAssetLessThan,
  ageOfAssetBetween,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  gstRegistrationGreaterThan,
  creditScoreGreaterThan,
} = criteria;

function propertyOwnerList(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, loanAmountBetween(2000, 500000), livingArrangements(["Property owner"])],
  }));
}

function propertyOwnerTier2List(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, loanAmountBetween(2000, 2000000), livingArrangements(["Property owner"])],
  }));
}

function nonPopertyOwnerTier2List(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      loanAmountBetween(2000, 250000),
      livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
    ],
  }));
}

function nonPopertyOwnerList(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      loanAmountBetween(2000, 150000),
      livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
    ],
  }));
}

const fullDoc = [
  ...propertyOwnerList(base()),
  ...propertyOwnerTier2List(tier2()),
  ...nonPopertyOwnerList(base()),
  ...nonPopertyOwnerTier2List(tier2()),
];

function tier2() {
  return [
    {
      name: "Full doc - Tier 2",
      rate: 8.55,
      productType: "Full doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(96),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Warehouse Equipment",
        ]),
        ageOfAssetAtEndLessThan(26),
        creditScoreGreaterThan(600),
      ],
    },
    {
      name: "Full doc - Tier 2",
      rate: 10.25,
      productType: "Full doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetBetween(96, 180),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Warehouse Equipment",
        ]),
        ageOfAssetAtEndLessThan(26),
        creditScoreGreaterThan(600),
      ],
    },
    {
      name: "Full doc - Tier 2",
      rate: 11.45,
      productType: "Full doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetBetween(181, 300),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Warehouse Equipment",
        ]),
        ageOfAssetAtEndLessThan(26),
        creditScoreGreaterThan(600),
      ],
    },
  ];
}

function base() {
  return [
    {
      name: "Full doc - Tier 3",
      rate: 8.95,
      productType: "Full doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        assetType(["Agricultural & Landscaping Equipment (wheeled or tracked)"]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(600),
      ],
    },
    {
      name: "Full doc - Tier 3",
      rate: 8.95,
      productType: "Full doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        assetType(["Trailers and Caravans"]),
        ageOfAssetAtEndLessThan(26),
        creditScoreGreaterThan(600),
      ],
    },
    {
      name: "Full doc - Tier 3",
      rate: 8.95,
      productType: "Full doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        assetType(["Industrial Plant & Printing"]),
        ageOfAssetAtEndLessThan(21),
        creditScoreGreaterThan(600),
      ],
    },
    {
      name: "Full doc - Tier 3",
      rate: 8.95,
      productType: "Full doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        assetType(["Medical Equipment"]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(600),
      ],
    },

    {
      name: "Full doc - Tier 3",
      rate: 10.25,
      productType: "Full doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(6),
        assetType(["IT, Computer & Audio Equipment", "Office Equipment"]),
        creditScoreGreaterThan(600),
      ],
    },

    {
      name: "Full doc - Tier 3",
      rate: 14.95,
      productType: "Full doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        assetType(["Restaurant & Catering Equipment"]),
        ageOfAssetAtEndLessThan(4),
        creditScoreGreaterThan(600),
      ],
    },
    {
      name: "Full doc - Tier 3",
      rate: 14.95,
      productType: "Full doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        assetType(["Office Furniture"]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(600),
      ],
    },
    {
      name: "Full doc - Tier 3",
      rate: 14.95,
      productType: "Full doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        assetType(["Office Fitout"]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(600),
      ],
    },

    {
      name: "Full doc - Tier 3",
      rate: 8.9,
      productType: "Full doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(8),
        assetType(["Solar"]),
        creditScoreGreaterThan(600),
      ],
    },

    {
      name: "Full doc - Tier 4",
      rate: 11.1,
      productType: "Full doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(8),
        assetType(["Other"]),
        creditScoreGreaterThan(600),
      ],
    },

    {
      name: "Full doc - Tier 3",
      rate: 9.95,
      productType: "Full doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetGreaterThan(11),
        assetType(["Agricultural & Landscaping Equipment (wheeled or tracked)"]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(600),
      ],
    },
    {
      name: "Full doc - Tier 3",
      rate: 9.95,
      productType: "Full doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetGreaterThan(11),
        assetType(["Trailers and Caravans"]),
        ageOfAssetAtEndLessThan(26),
        creditScoreGreaterThan(600),
      ],
    },
    {
      name: "Full doc - Tier 3",
      rate: 9.95,
      productType: "Full doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetGreaterThan(11),
        assetType(["Industrial Plant & Printing"]),
        ageOfAssetAtEndLessThan(21),
        creditScoreGreaterThan(600),
      ],
    },
    {
      name: "Full doc - Tier 3",
      rate: 9.95,
      productType: "Full doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetGreaterThan(11),
        assetType(["Medical Equipment"]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(600),
      ],
    },

    {
      name: "Full doc - Tier 4",
      rate: 12.1,
      productType: "Full doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetGreaterThan(11),
        ageOfAssetAtEndLessThan(8),
        assetType(["Other"]),
        creditScoreGreaterThan(600),
      ],
    },
    {
      name: "Full doc - Tier 3",
      rate: 14.95,
      productType: "Full doc",
      criteria: [
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetGreaterThan(11),
        assetType(["Office Fitout"]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(600),
      ],
    },
  ];
}

export default fullDoc;
