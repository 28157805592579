export default function parseJSONP(jsonp) {
  const callbackNameMatch = jsonp.match(/callback\((.*)\)/);

  if (callbackNameMatch) {
    const jsonData = callbackNameMatch[1];

    try {
      return JSON.parse(jsonData);
    } catch (error) {
      console.error("Error parsing JSONP:", error);
      return null;
    }
  } else {
    console.error("Invalid JSONP format");
    return null;
  }
}
