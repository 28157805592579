import React from "react";
// import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, TextField } from "@mui/material";
import moment from "moment";
import checkAlphabetRestrict from "../../../utils/checkAlphabetRestrict";
import generateArrayOfYears from "../../../utils/generateArrayOfYears";
import {
  updateAsset,
  assetSelector,
  saveAsset,
  loanDetailsSelector,
} from "../../../store/slices/applicationFormSlice";

export default function AgeOfAsset({ disabled }) {
  const dispatch = useDispatch();
  // const { applicationId } = useParams();
  const { ageOfAsset, _id } = useSelector(assetSelector);
  const { term } = useSelector(loanDetailsSelector);

  const handleAgeOfAsset = (event, newValue) => {
    console.log(newValue);
    const termInYears = term / 12;
    // console.log("termInYears", termInYears)
    const ageOfAssetMoment = moment(newValue, "YYYY");
    const endOfTermYear = moment().add(termInYears, "year");
    console.log(ageOfAssetMoment);
    console.log(endOfTermYear);
    // console.log(endOfTermYear.diff(ageOfAssetMoment, "years"));
    const ageOfAssetAtEnd = endOfTermYear.diff(ageOfAssetMoment, "years");
    console.log("ageOfAssetAtEnd", ageOfAssetAtEnd);
    dispatch(saveAsset({ ageOfAsset: newValue, ageOfAssetAtEnd }));
    dispatch(updateAsset({ _id, ageOfAsset: newValue, ageOfAssetAtEnd }));
  };

  return (
    <Autocomplete
      value={ageOfAsset?.toString() || ""}
      options={generateArrayOfYears()}
      onChange={handleAgeOfAsset}
      onKeyPress={(e) => checkAlphabetRestrict(e, "ageOfAssetValue")}
      size="small"
      getOptionLabel={(option) => option || ""}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          size="small"
          {...params}
          label="Year of manufacture"
          variant="filled"
        />
      )}
    />
  )
}