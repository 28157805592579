import criteria from "../../utils/criteria";
import { typeOfSaleList } from "../../constants";

const {
  livingArrangements,
  typeOfSale,
  assetValueBetween,
  assetValueGreaterThan,
  assetType,
  ageOfAssetAtEndGreaterThan,
  ageOfAssetAtEndLessThan,
  ageOfAssetAtEndBetween,
  loanAmountLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  gstRegistrationGreaterThan,
  creditScoreGreaterThan,
  brokerageIsEqualTo,
} = criteria;

function specialised(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      assetType([
        "IT, Computer & Audio Equipment",
        "Office Equipment",
        "Restaurant & Catering Equipment",
        "Office Furniture",
        "Health & Beauty Equipment",
        "Solar",
        "Manufacturing Equipment",
      ]),
      ageOfAssetAtEndLessThan(6),
    ],
  }));
}

function trailersCaravans(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, assetType("Trailers and Caravans"), ageOfAssetAtEndLessThan(26)],
  }));
}

function primary(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      assetType([
        "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
        "Commercial (Heavy) Vehicles (>12 Tonnes)",
        "Earth Moving & Construction (wheeled or tracked)",
        "Agricultural & Landscaping Equipment (wheeled or tracked)",
      ]),
      ageOfAssetAtEndLessThan(26),
    ],
  }));
}

function warehouse(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, assetType(["Warehouse Equipment"]), ageOfAssetAtEndLessThan(21)],
  }));
}

function others(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      assetType([
        "Motor Vehicle (<4.5 tonnes)",
        "Electric Motor Vehicle (<4.5 tonnes)",
        "Industrial Plant & Printing",
        "Medical Equipment",
      ]),
      ageOfAssetAtEndLessThan(16),
    ],
  }));
}

const selfco = {
  date: "11 Sept 2023",
  lender: "Selfco",
  productName: "Fast doc",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos%2Fselfco_logo.gif",
  actions: ["email"],
  lenderCredentials: [
    {
      label: "Email",
      attribute: "email",
    }
  ],
  requiredFields: {
    entity: [
      "abn",
      // "addresses[0].streetAddress",
      "entityName",
      "entityType",
      "industryType",
      "turnover",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
    ],
    customer: [
      "customerTitle",
      "firstName",
      "lastName",
      "dateOfBirth",
      "IDNumber",
      "IDCardNumber",
      "IDExpiryDate",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "customerProfile.livingArrangements",
      "customerProfile.numberOfDependants",
      "customerProfile.residencyStatus",
      "customerProfile.maritalStatus",
      "customerAssets.[0].assetType",
      "customerAssets.[0].value",
      "customerAssets.[0].description",
      "liabilities.[0].liabilitiesType",
      "liabilities.[0].outstandingAmount",
      "liabilities.[0].limit",
      "liabilities.[0].financier",
      "liabilities.[0].repaymentFrequency",
      "liabilities.[0].repaymentAmount",
      "liabilities.[0].description",
    ],
    loanDetails: ["rate"],
    asset: ["assetType", "typeOfSale", "ageOfAsset", "glassesMake", "glassesModel", "glassesVariant", "assetValue","condition"],
  },
  email: "leasing@selfco.com.au",
  fees: [
    {
      name: "Document fee",
      value: 660,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale("Private sale")],
    },
    {
      name: "Document fee",
      value: 440,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale("Authorised seller")],
    },
    {
      name: "Origination fee",
      value: 1000,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Monthly fee",
      value: 8,
      capitalised: true,
      frequency: "monthly",
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 8,
      valueType: "percentage",
      criteria: [assetValueBetween(2000, 50000)],
    },
    {
      value: 4,
      max: 6,
      valueType: "percentage",
      criteria: [assetValueGreaterThan(50000)],
    },
  ],
  loading: [
    {
      name: "Less than 25k",
      value: 2,
      valueType: "percentage",
      criteria: [loanAmountLessThan(25000)],
    },
    {
      name: "Secondary Grade Asset",
      value: 2,
      valueType: "percentage",
      criteria: [
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Solar",
          "Office Equipment",
          "Industrial Plant & Printing",
        ]),
      ],
    },
    {
      name: "Specialised Asset",
      value: 6,
      valueType: "percentage",
      criteria: [assetType(["Restaurant & Catering Equipment", "Health & Beauty Equipment", "Office Furniture"])],
    },
    {
      name: "Asset age at EOT >10 yrs",
      value: 1,
      valueType: "percentage",
      criteria: [
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Trailers and Caravans",
          "Industrial Plant & Printing",
          "Medical Equipment",
        ]),
        ageOfAssetAtEndBetween(10, 15),
      ],
    },
    {
      name: "Asset age at EOT >15 yrs",
      value: 2,
      valueType: "percentage",
      criteria: [
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Trailers and Caravans",
          "Warehouse Equipment (wheeled or tracked)",
        ]),
        ageOfAssetAtEndBetween(16, 19),
      ],
    },
    {
      name: "Asset age at EOT >20 yrs",
      value: 3,
      valueType: "percentage",
      criteria: [
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Trailers and Caravans",
          "Warehouse Equipment (wheeled or tracked)",
        ]),
        ageOfAssetAtEndGreaterThan(19),
      ],
    },
    {
      name: "Private sale",
      value: 1,
      valueType: "percentage",
      criteria: [typeOfSale(typeOfSaleList.PRIVATE_SALE)],
    },
    {
      name: "Equity Raise/Sale & Buyback",
      value: 2,
      valueType: "percentage",
      criteria: [typeOfSale(typeOfSaleList.SALE_BUYBACK_EQUITY)],
    },
    {
      name: "Mid Term Refinance",
      value: 2,
      valueType: "percentage",
      criteria: [typeOfSale(typeOfSaleList.MID_TERM_REFINANCE)],
    },
    {
      name: "Brokerage 6% for <$50000",
      value: 0.5,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(6), assetValueBetween(10000, 49999.99)],
    },
    {
      name: "Brokerage 7% for <$50000",
      value: 1,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(7), assetValueBetween(10000, 49999.99)],
    },
    {
      name: "Brokerage 8% for <$50000",
      value: 1.5,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(8), assetValueBetween(10000, 49999.99)],
    },

    {
      name: "Brokerage 5% for >$50000",
      value: 0.5,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(5), assetValueGreaterThan(49999.99)],
    },
    {
      name: "Brokerage 6% for >$50000",
      value: 1,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(6), assetValueGreaterThan(49999.99)],
    },
  ],
  info: [
    `Bank Statements for non property owners`,
    `Credit reference for non property >40K`,
    `Equity Lending is maxed at 100K for >3 yrs ABN/GST`,
  ],
  productTiers: [
    ...specialised(base()),
    ...trailersCaravans(base()),
    ...primary(base()),
    ...warehouse(base()),
    ...others(base()),
  ],
};

function base() {
  return [
    {
      name: "FAST",
      rate: 9.45,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(2000, 150000),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(36),
        livingArrangements(["Property owner"]),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "FAST",
      rate: 10.95,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(2000, 75000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        livingArrangements(["Property owner"]),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "FAST",
      rate: 13.95,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(2000, 40000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Property owner"]),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "FAST",
      rate: 10.45,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(2000, 75000),
        timeInBusinessGreaterThan(36),
        gstRegistrationGreaterThan(36),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "FAST",
      rate: 11.95,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(2000, 50000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "FAST",
      rate: 14.95,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(2000, 25000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        creditScoreGreaterThan(500),
      ],
    },
  ];
}

export default selfco;
