// import { Buffer } from "buffer";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
// import * as XLSX from 'xlsx';
import _ from "lodash";
import { cellAlign, serviceChargesHeader } from "../../../constants";
import {
  getServicesCharges,
  getServicesDataExport,
  getServicesDetail,
  saveValue,
  userSelector,
} from "../../../store/slices/userSlice";
import { TableLoader, TableNotFound } from "../../../components/customComponents/TableLoader";

const ServiceCharges = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { servicesCharges, servicesDetail, serviceDetailLoader, servicesChargesLoader, exportLoader } =
    useSelector(userSelector);
  const [expand, setExpand] = useState({});
  const [filterDate, setFilterDate] = useState({ fromDate: null, toDate: null });

  const handleDetailView = (name, id) => {
    const fromDate = moment(filterDate?.fromDate).format("YYYY-MM-DD");
    const toDate = moment(filterDate?.toDate).format("YYYY-MM-DD");

    dispatch(
      getServicesDetail({
        serviceId: id,
        fromDate: fromDate === "Invalid date" ? "" : fromDate,
        toDate: toDate === "Invalid date" ? "" : toDate,
      }),
    );
    setExpand({
      [name]: !expand[name],
    });
  };

  useEffect(() => {
    handle.getServices({ ...filterDate });
  }, []);

  const handle = {
    navigateToApplication: (id) => {
      navigate(`/application/${id}`);
    },
    onChangeDatePicker: (dates) => {
      const fromDate = moment(dates?.[0]).format("YYYY-MM-DD");
      const toDate = moment(dates?.[1]).format("YYYY-MM-DD");

      setFilterDate({ fromDate: dates?.[0], toDate: dates?.[1] });
      setExpand({});

      if ((dates[0] && dates[1]) || (!dates[0] && !dates[1])) {
        handle.getServices({
          fromDate: fromDate === "Invalid date" ? "" : fromDate,
          toDate: toDate === "Invalid date" ? "" : toDate,
        });
      }
    },
    getServices: ({ fromDate = "", toDate = "" }) => {
      dispatch(getServicesCharges({ fromDate, toDate }));
    },

    getDownloadData: () => {
      const fromDate = moment(filterDate?.fromDate).format("YYYY-MM-DD");
      const toDate = moment(filterDate?.toDate).format("YYYY-MM-DD");
      dispatch(
        getServicesDataExport({
          url:
            fromDate !== "Invalid date" && toDate !== "Invalid date"
              ? `?fromDate=${fromDate || ""}&toDate=${toDate || ""}`
              : "",
        }),
      ).then((res) => {
        if (!res.error) {
          const dataBlob = new Blob([res?.payload] ,{
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });

          const downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(dataBlob);
          downloadLink.download = "service-report.xlsx";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          dispatch(saveValue({ exportLoader: false }));
        }
      });
    },
  };

  return (
    <Grid container spacing={1.5} direction={"column"} width={"100%"}>
      <Grid item style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <DatePicker
          selected={filterDate?.fromDate}
          onChange={handle.onChangeDatePicker}
          startDate={filterDate?.fromDate}
          endDate={filterDate?.toDate}
          selectsRange
          isClearable
          name="Select Date"
          monthsShown={2}
          customInput={<TextField label="Select Date" />}
        />
        <LoadingButton
          // fullWidth
          loading={exportLoader}
          loadingPosition="center"
          variant="contained"
          disabled={exportLoader}
          onClick={() => handle.getDownloadData()}
          // style={{ marginTop: "20px" }}
        >
          Export
        </LoadingButton>
      </Grid>

      <Grid item style={{ display: "flex", justifyContent: "center" }}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {serviceChargesHeader?.map((header) => (
                  <TableCell align={cellAlign} style={{ fontWeight: "bold" }} key={header}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {servicesChargesLoader && <TableLoader columnSpan={5} />}
              {!servicesCharges || (servicesCharges?.length === 0 && !servicesChargesLoader) ? (
                <TableNotFound columnSpan={5} />
              ) : (
                servicesCharges?.map((row) => (
                  <>
                    <TableRow
                      key={row?._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row" align="left">
                        {row?.name}
                      </TableCell>
                      <TableCell align={"right"}>{row?.useageCount}&nbsp;&nbsp;</TableCell>
                      <TableCell align={"right"}>
                        ${Number(row?.fee / 100).toFixed(2)}
                        &nbsp;&nbsp;
                      </TableCell>
                      <TableCell align={"right"}>
                        ${Number((row?.useageCount * row?.fee) / 100).toFixed(2)}
                        &nbsp;&nbsp;
                      </TableCell>
                      <TableCell align={"right"}>
                        {expand[row?.name] ? (
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              setExpand({
                                [row.name]: !expand[row?.name],
                              });
                            }}
                          >
                            Hide
                          </Button>
                        ) : (
                          row?.useageCount !== 0 && (
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => {
                                handleDetailView(row?.name, row?._id);
                              }}
                            >
                              View
                            </Button>
                          )
                        )}
                      </TableCell>
                    </TableRow>
                    {expand[row.name] && serviceDetailLoader && <TableLoader columnSpan={5} />}

                    {expand[row.name] && servicesDetail?.length === 0 && !serviceDetailLoader ? (
                      <TableNotFound columnSpan={5} />
                    ) : (
                      expand[row.name] && (
                        <>
                          {servicesDetail?.length !== 0 && (
                            <TableRow>
                              <TableCell align={"justify"}>
                                <Typography fontWeight={"bold"} fontSize={"0.75rem"}>
                                  Date
                                </Typography>
                              </TableCell>
                              <TableCell align={"justify"}>
                                <Typography fontWeight={"bold"} fontSize={"0.75rem"}>
                                  User
                                </Typography>
                              </TableCell>
                              <TableCell align={"justify"}>
                                {" "}
                                <Typography fontWeight={"bold"} fontSize={"0.75rem"}>
                                  Application
                                </Typography>
                              </TableCell>
                              <TableCell colSpan={2} align={"justify"}>
                                <Typography fontWeight={"bold"} fontSize={"0.75rem"}>
                                  Customer
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                          {servicesDetail?.map((serviceDetail) => {
                            const entityName = _.get(serviceDetail, "entitie[0].entityName");
                            const firstName = _.get(serviceDetail, "customer[0].firstName", "No");
                            const lastName = _.get(serviceDetail, "customer[0].lastName", "customer");

                            return (
                              <TableRow key={serviceDetail?._id}>
                                <TableCell align={"justify"} sx={{ fontSize: "0.75rem" }}>
                                  <Typography fontSize={"0.75rem"}>
                                    {moment(serviceDetail?.createdAt).format("DD-MM-YYYY")}
                                  </Typography>
                                </TableCell>
                                <TableCell align={"justify"} sx={{ fontSize: "0.75rem" }}>
                                  <Typography fontSize={"0.75rem"}>
                                    {serviceDetail?.user?.lastName
                                      ? `${serviceDetail?.user?.firstName} ${serviceDetail?.user?.lastName}`
                                      : `${serviceDetail?.user?.firstName}`}
                                  </Typography>
                                </TableCell>
                                <TableCell align={"justify"} sx={{ fontSize: "0.75rem" }}>
                                  <Typography
                                    fontSize={"0.75rem"}
                                    component={"a"}
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => handle.navigateToApplication(serviceDetail?.application?._id)}
                                  >
                                    {serviceDetail?.application?.humanId} -{" "}
                                    {serviceDetail?.application?.applicationType}
                                  </Typography>
                                </TableCell>
                                <TableCell colSpan={2} align={"justify"} sx={{ fontSize: "0.75rem" }}>
                                  <Typography fontSize={"0.75rem"}>
                                    {entityName ? entityName : `${firstName} ${lastName}`}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </>
                      )
                    )}
                  </>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ServiceCharges;
