import base from "./base.js";
import criteria from "../../../utils/criteria";
import { livingArrangementsTypes } from "../../../constants";

const { LIVING_WITH_PARENTS, RENTING, BOARDING } = livingArrangementsTypes;

const { livingArrangements, assetValueBetween, typeOfSale, termIsGreaterThan } = criteria;

const firstmac = {
  date: "11 Apr 2024",
  lender: "FirstMac",
  type: "consumer",
  logoURL: "https://assets.financeable.com.au/lender-logos/FMC-black_logo.png",
  actions: [],
  lenderCredentials: [
    {
      label: "Email",
      attribute: "email",
    },
    {
      label: "User Name",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
    {
      label: "User ID",
      attribute: "brokerId",
    },
    {
      label: "Api Key",
      attribute: "apiKey",
    },
  ],
  requiredFields: {
    asset: ["assetType", "typeOfSale", "ageOfAsset", "glassesMake", "glassesModel", "glassesVariant", "assetValue","condition"],
    customer: [
      "firstName",
      "lastName",
      "dateOfBirth",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      // "addresses[0].street",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "IDNumber",
      "IDCardNumber",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "employers.[0].entityName",
      "employers.[0].timeOfEmployment",
      "employers.[0].employmentType",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Establishment fee",
      value: 400,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(["Dealer"])],
    },
    {
      name: "Private sale fee",
      value: 6,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(["Private"])],
    },
    {
      name: "Account fee",
      value: 8,
      capitalised: false,
      frequency: "monthly",
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 4,
      valueType: "percentage",
      criteria: [assetValueBetween(0, 1000000)],
    },
  ],
  loading: [
    {
      name: "Term greater than 60 mths",
      value: 0.5,
      valueType: "percentage",
      criteria: [termIsGreaterThan(60)],
    },
    {
      name: "Non-home owner",
      value: 1.2,
      valueType: "percentage",
      criteria: [livingArrangements([LIVING_WITH_PARENTS, RENTING, BOARDING])],
    },
  ],
  info: [
    `Debt servicing calculator -(https://brokertools.firstmac.com.au/debt-service`,
    `Eligibility calculator (edge profiling) – (https://brokertools.firstmac.com.au/firstmac-eligibility`,
    `Quoting tool – (https://brokertools.firstmac.com.au/quotes)`,
  ],
  productTiers: base,
};
export default firstmac;
