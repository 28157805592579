import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import { submitTerms, userSelector } from "../store/slices/userSlice";

const useStyles = makeStyles({
  tableHeaderCell: {
    backgroundColor: "white",
    color: "purple",
    fontSize: "18px",
    fontWeight: 600,
  },
  boldCell: {
    fontWeight: "bold",
  },
});

export default function Terms() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const [canSubmit, setCanSubmit] = useState(true);
  const [termCheckMark, setTermCheckMark] = useState({
    isPriceTermAccept: false,
    isFinPrivacyTermAccept: false,
    isFinTermAccept: true,
  });
  const isTermAcceptable =
    termCheckMark?.isPriceTermAccept &&
    termCheckMark?.isFinPrivacyTermAccept &&
    termCheckMark?.isFinTermAccept &&
    canSubmit;

  const user = useSelector(userSelector);

  useEffect(() => {
    if (user?.terms) navigate("/applications");
  }, [user]);

  async function handleSubmitTerms() {
    setCanSubmit(false);
    if (isTermAcceptable) {
      await dispatch(submitTerms({ userId: user._id })).unwrap();
    }
    setCanSubmit(true);
  }

  const handle = {
    handleCheckTerm: (key, value) => {
      setTermCheckMark({
        ...termCheckMark,
        [key]: value,
      });
    },
  };

  const tableData = [
    { service: "Glasses Guide", description: "Vehicle search", notes: "Make/Model based search", amount: "$0.00" },
    { service: "Blue flag", description: "Vehicle search", notes: "VIN/Rego based search", amount: "$0.70" },
    { service: "ASIC Extract", description: "Existing file", notes: "Majority of requests", amount: "$4.64" },
    { service: "ASIC Extract", description: "New file", notes: "20-25% of requests", amount: "$14.73" },
    { service: "PPSR", description: "Commercial", notes: "Must be requested with ASIC extract", amount: "$6.00" },
    { service: "ID verification", description: "", notes: "Negotiating new price", amount: "$4.20" },
    { service: "Equifax report", description: "Access Seeker", notes: "Basic report", amount: "$10.03" },
    { service: "Equifax report", description: "Access Seeker", notes: "With OneScore", amount: "$11.03" },
    { service: "Equifax report", description: "Commercial", notes: "Awaiting API access", amount: "$16.81" },
    { service: "Bank statements", description: "illion", notes: "", amount: "$3.50" },
  ];

  const boldAmountList = [1, 2, 3, 4, 5, 9];

  return (
    <Grid
      container
      item
      xs={12}
      style={{
        padding: "30px 30px 0",
        background: "#efefef",
        minHeight: "calc(100vh - 64px)",
      }}
    >
      <Grid container item xs={12} justifyContent="center">
        <Grid item direction="column" sm={12} md={10}>
          <>
            <Typography
              variant="h1"
              style={{
                fontSize: "24px",
                marginBottom: "20px",
              }}
            >
              General terms
            </Typography>
            <Typography
              variant="h2"
              style={{
                fontSize: "18px",
                marginBottom: "30px",
              }}
            >
              Please read and acknowledge all terms and conditions.
            </Typography>

            <Accordion style={{ marginBottom: "40px" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ padding: "5px 30px" }}
              >
                <Typography>Show Terms and Conditions</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "20px 30px" }}>
                <Typography style={{ marginBottom: "5px", fontWeight: 700 }}>Introduction</Typography>
                <Typography style={{ margin: "0 0 10px" }}>
                  Welcome to Financeable. These Terms and Conditions (Agreement) apply to Your use of the Services and
                  are a legally binding agreement between Financeable and You. Please read this Agreement carefully and
                  keep a copy for future reference. By accessing the Services, you agree to this Agreement. If you do
                  not agree to this Agreement, you must cease using the Services immediately.
                </Typography>

                <Typography style={{ marginBottom: "5px", fontWeight: 700 }}>Definitions</Typography>
                <Typography style={{ marginBottom: "10px" }}>Agreement means these Terms and Conditions.</Typography>

                <Typography style={{ marginBottom: "10px" }}>
                  Business Account means the account a Customer has opened with Us to access and use the Services.
                </Typography>

                <Typography style={{ marginBottom: "10px" }}>
                  Confidential Information includes all information exchanged between the parties to this Agreement,
                  whether in writing, electronically or orally but does not include information which is, or becomes,
                  publicly available other than through unauthorised disclosure by the other party.
                </Typography>

                <Typography style={{ marginBottom: "10px" }}>
                  Consumer Protection Law means Schedule 2 of the Competition and Consumer Act 2010 (Cth) and Part 2 of
                  the Australian Securities and Investments Commission Act 2001 (Cth), as applicable.
                </Typography>

                <Typography style={{ fontWeight: 500 }}>Your information - Collection and Disclosure</Typography>
                <Typography style={{ marginBottom: "10px" }}>
                  Content means any information or collateral prepared or issued by Financeable.
                </Typography>

                <Typography style={{ marginBottom: "10px" }}>
                  Corporations Act means Corporations Act 2001 (Cth).
                </Typography>

                <Typography style={{ marginBottom: "10px" }}>
                  Customer or You or Your means the business entity that has entered into this Agreement.
                </Typography>

                <Typography style={{ marginBottom: "10px" }}>
                  Customer Authorised User means any person or entity that uses the Service with the authorisation of
                  the Customer from time to time.
                </Typography>

                <Typography style={{ marginBottom: "10px" }}>
                  Direct Debit Request Provider means Stripe Inc. (https://stripe.com/au/privacy) or some other third
                  party payment processor.
                </Typography>

                <Typography style={{ fontWeight: 500 }}>Credit Providers</Typography>
                <Typography style={{ marginBottom: "10px" }}>
                  Direct Debit Request Services means the services provided by the Direct Debit Request Provider.
                </Typography>

                <Typography style={{ marginBottom: "10px" }}>
                  Financial Product means the products as described in the Product Disclosure Statement.
                </Typography>

                <Typography style={{ marginBottom: "10px" }}>
                  Financial Product Issuer means the issuer of the Financial Product.
                </Typography>

                <Typography style={{ marginBottom: "10px" }}>
                  Insolvency Event means:
                  <ol style={{ marginLeft: "20px", padding: 0, marginTop: 0 }}>
                    <li>
                      being an insolvent under administration or insolvent (each as defined in the Corporations Act);
                    </li>
                    <li>having a controller (as defined in the Corporations Act) appointed;</li>
                    <li>
                      any step being taken by a mortgagee to take possession, or dispose, of the whole or any part of a
                      party’s assets, operations or business;
                    </li>
                    <li>
                      any step being taken to appoint a receiver, a receiver and manager, a trustee in bankruptcy, a
                      liquidator, a provisional liquidator, an administrator, a statutory manager or other like person
                      in respect of the whole or any part of the party’s assets or business;
                    </li>
                    <li>
                      any step being taken which could result in being subject to any arrangement, assignment or
                      composition, protected from creditors under any statute or dissolved (other than to carry out a
                      reconstruction while solvent);
                    </li>
                    <li>being otherwise unable to pay debts when they fall due;</li>
                    <li>
                      having something with the same or a similar effect happen under the laws of any jurisdiction;
                    </li>
                    <li>ceasing to carry on business.</li>
                  </ol>
                </Typography>

                <Typography style={{ marginBottom: "10px" }}>
                  Intellectual Property Right means any patent, trade mark, service mark, copyright, moral right, right
                  in a design, know-how and any other intellectual or industrial property rights, anywhere in the world
                  whether or not registered.
                </Typography>

                <Typography style={{ marginBottom: "10px" }}>
                  Platform Fees means the fees (excluding any taxes) payable by You in accordance with the Platform Fee
                  Schedule.
                </Typography>

                <Typography style={{ marginBottom: "10px" }}>
                  Platform Fee Schedule means the information relating to fees for the Platform Service available at
                  https://financeable.com/pricing as amended from time to time.
                </Typography>

                <Typography style={{ marginBottom: "10px" }}>
                  Platform Service means the Service, other than the Financial Product.
                </Typography>

                <Typography style={{ marginBottom: "10px" }}>
                  Privacy Policy means the Privacy Policy available at https://Financeable.com.au/privacy as amended
                  from time to time.
                </Typography>

                <Typography style={{ marginBottom: "10px" }}>
                  Privacy Policy means the Privacy Policy available at https://Financeable.com.au/privacy as amended
                  from time to time.
                </Typography>

                <Typography style={{ marginBottom: "10px" }}>
                  Related Body Corporate has the meaning given in the Corporations Act.
                </Typography>

                <Typography style={{ marginBottom: "10px" }}>
                  Service means any services provided by Financeable or a Third Party Provider to You via the Website
                  Application.
                </Typography>

                <Typography style={{ marginBottom: "10px" }}>
                  Software means any software that is provided by Financeable and that is necessary for use in
                  connection with the Services.
                </Typography>

                <Typography style={{ marginBottom: "10px" }}>
                  Third Party Provider means any third party that provides products or services in relation to the
                  Platform Service (including the Financial Product Issuer).
                </Typography>

                <Typography style={{ marginBottom: "10px" }}>
                  User Account means the account a Customer or a Customer Authorised User has opened with Us to access
                  and use the Services for a Business Account.
                </Typography>

                <Typography style={{ marginBottom: "10px" }}>
                  Website means the internet site at the domain Financeable.com or any other site or Mobile Application
                  operated by Financeable.
                </Typography>

                <Typography style={{ marginBottom: "10px" }}>
                  Financeable or Us or We means Financeable Pty Ltd, ABN 24 646 900 736
                </Typography>

                <Typography style={{ fontWeight: 500 }}>1. Use of Service and Software</Typography>
                <Typography style={{ marginBottom: "10px" }}>
                  Financeable grants the Customer and Customer Authorised Users the right to access and use the Service
                  and Software via the Website This right is non-exclusive, non-transferable, and limited by and subject
                  to this Agreement. You acknowledge and agree that, subject to any applicable written agreement between
                  the Customer and the Customer Authorised Users, or any other applicable laws:
                  <ol style={{ marginLeft: "20px", padding: 0, marginTop: 0 }}>
                    <li>
                      the Customer determines who is a Customer Authorised User and what level of access the Customer
                      Authorised User has to the Business Account;
                    </li>
                    <li>
                      the Customer will notify Financeable of each Customer Authorised User and the level of access to
                      the Business Account;
                    </li>
                    <li>
                      the Customer is responsible for all Customer Authorised Users’ use of the Service and Software;
                    </li>
                  </ol>
                  Use of the Service may be subject to limitations. Financeable will communicate any limitations to you
                  with reasonable prior written notice.
                </Typography>

                <Typography style={{ fontWeight: 500 }}>2. Your Account</Typography>
                <Typography style={{ marginBottom: "10px" }}>
                  2.1 Eligibility<br></br>
                  To be eligible to open a Business Account, the Customer must be a member of Connective Broker Services
                  Pty Ltd ACN 161 731 111 (Connective)
                </Typography>

                <Typography style={{ marginBottom: "10px" }}>
                  2.2 Registration<br></br>A Customer will need to setup a Business Account to access and use the
                  Service.
                </Typography>

                <Typography style={{ marginBottom: "10px" }}>
                  2.3 Registration Details Each Customer and Customer Authorised User registration information will be
                  displayed at the time of registration and may change from time to time.
                </Typography>
                <Typography style={{ marginBottom: "10px" }}>
                  2.4 Authority to enter into Agreement If You are a company or other non-natural person entity and
                  require that a natural person who is not a director (Authorised Signatory) enters into this Agreement
                  on Your behalf, You: a) warrant that the Authorised Signatory is duly authorised to enter into this
                  Agreement on Your behalf of;
                </Typography>
                <Typography style={{ marginBottom: "10px" }}>
                  Financeable may (in its absolute discretion) request further evidence that an Authorised Signatory is
                  authorised to act on behalf of a Customer and the person must provide Financeable with any information
                  that Financeable reasonably requests for this purpose.
                </Typography>
                <Typography style={{ marginBottom: "10px" }}>
                  2.5 Log-in credentials a) You are responsible for keeping Your log-in details secure and confidential
                  and must not disclose them to any other person. b) Financeable will not be liable for any loss, cost,
                  damage, or expense to You or any other person arising out of, or in connection with, Your failure to
                  maintain the security and confidentiality of Your log-in details unless Financeable caused or
                  contributed to such loss, cost, damage or expense. f) Financeable is not responsible for any
                  unauthorised access to, or use of, Your User Account or Business Account unless Financeable has failed
                  to take reasonable steps to prevent such access or use. g) You are fully responsible for all
                  activities carried out under or using Your log-in details (whether or not authorised by You) and any
                  loss, cost, damage, or expense to You or any other person that may arise as a result of that activity.
                  Financeable will take such action it deems necessary to maintain or enhance of Your User Account or
                  Business Account or log-in details and You must take all actions reasonably requested by Financeable.
                  h) You must notify Financeable as soon as reasonably practicable of any unauthorised use of Your login
                  details or any other breaches of security. 2.6 Closure, suspension and cancellation Financeable may,
                  where it has reasonable cause to do so, restrict, suspend, close or cancel a User Account or Business
                  Account, including, but not limited to: a) where Financeable reasonably suspects or becomes aware that
                  You are in breach of this Agreement; b) where Financeable reasonably considers Your User Account or
                  Business Account activity to be suspicious; c) where Financeable considers it reasonably necessary to
                  prevent fraud or limit or otherwise protect Financeable against any legal, regulatory or commercial
                  risk; d) if You provide any inaccurate or incomplete information to Financeable, or Financeable has
                  reasonable grounds to suspect You have; e) You fail to continue to meet the eligibility criteria in
                  clause 2.1; f) where required by law or regulatory requirement; g) there is a discontinuance or
                  material modification to the Services (or any part thereof); h) unexpected technical or security
                  issues or problems arise; or i) extended periods of inactivity by You. Financeable will endeavour to
                  give You reasonable prior written notice of any restriction, suspension, closure or cancellation of
                  Your User Account or Business Account, unless not permitted by law. Where Financeable takes action
                  under this clause 2.6: a) any transactions or requests submitted but not yet processed will be
                  cancelled and withdrawn for the applicable account; b) You will not be able to make any new requests
                  or transactions in respect of Your User Account or Business Account c) if Financeable closes or
                  cancels your Business Account, this Agreement will terminate pursuant to clause 11.
                </Typography>

                <Typography style={{ marginBottom: "5px", fontWeight: 700 }}>3. Verification</Typography>
                <Typography style={{ marginBottom: "10px" }}>
                  {" "}
                  Your opening and ongoing use of an Account and use of the Services is subject to Connective’s
                  verification of the identity of any associated individuals or entities that is considered necessary,
                  including the Customer and the Customer Authorised Users.
                </Typography>

                <Typography style={{ marginBottom: "5px", fontWeight: 700 }}>
                  4. Appointment of Financeable as Your agent
                </Typography>
                <Typography style={{ marginBottom: "10px" }}>
                  You appoint Financeable as Your agent and authorise and consent to Financeable doing any of the
                  following: a) Collecting and delivering to the Financial Product Issuer all information,
                  communications and instructions submitted by You, any payments or other transaction instructions in
                  connection with the Financial Product. You are responsible for ensuring the accuracy and completeness
                  of such information, communications and instructions. b) Receiving, storing and using any information,
                  communications and data belonging to You that is held by the Financial Product Issuer. c) Receiving
                  and passing on to You all information, communications, instructions provided by the Financial Product
                  Issuer in connection with the Financial Product. The agency appointment, authorisation and consent
                  provided in this clause will continue until the Agreement is terminated.
                </Typography>

                <Typography style={{ marginBottom: "5px", fontWeight: 700 }}>
                  5. Product Disclosure Statement
                </Typography>
                <Typography style={{ marginBottom: "10px" }}>
                  You should read the Product Disclosure Statement before making a decision to access and use your
                  Account and the Services.
                </Typography>

                <Typography style={{ marginBottom: "5px", fontWeight: 700 }}>6. Fees</Typography>
                <Typography style={{ marginBottom: "10px" }}>
                  Platform Fees are set out on the Website at Financeable.com/pricing. You should regularly review the
                  Website to confirm any applicable fees. Financial Product fees, including billing details for each
                  fee, are set out in https://Financeable.com/pricing. Platform Fees and applicable Financial Product
                  fees will be charged to You in accordance with Your usage of the services. If you have not been
                  notified otherwise, your plan type is Post Paid. Plan Type Post Paid Invoice cycle Monthly Monthly
                  Invoice date Anniversary date Anniversary date Invoice period You will be billed at the start of each
                  period. You will be billed at the end of each period. Additional charges during an invoice period (eg
                  overages) Added to the invoice for the next period. Added to the invoice for the current period. All
                  Financeable invoices will be sent to You by email. You are responsible for payment of all taxes.
                  Financeable will deduct Your invoice amount directly from Your Account on Your invoice due date. If
                  You have an outstanding invoice that has not been paid in full on the invoice due date, We will
                  attempt to deduct the outstanding amount directly from Your Account Balance until fully paid. Your
                  anniversary date is the day of the month when Your Business Account was first activated. Financeable
                  may change Your anniversary date by providing notice. If so, You will be charged a pro-rata amount. If
                  Your invoice payment is overdue, Your Account may be suspended and You will not be able to access Your
                  Account. From time to time, Financeable may offer discounts. These discounts are not perpetual and may
                  be withdrawn with 30 days written notice. Financeable may amend any fee or charge in its in accordance
                  with the process for making changes to this Agreement set out in clause 15.
                </Typography>

                <Typography style={{ marginBottom: "5px", fontWeight: 700 }}>7. Privacy Policy</Typography>
                <Typography style={{ marginBottom: "10px" }}>
                  Registration data and certain other information about you is subject to Financeable's Privacy Policy
                  and you acknowledge and consent to Financeable using and disclosing Your personal information in
                  accordance with the Privacy Policy.
                </Typography>

                <Typography style={{ marginBottom: "5px", fontWeight: 700 }}>8. Your obligations</Typography>
                <Typography style={{ marginBottom: "10px" }}>
                  In connection with Your use of Your Account, or in the course of Your interactions with Financeable,
                  another Financeable user, or a third party in connection with the Services, You agree that You will:
                  a) comply with this Agreement; b) not use the Services for any unlawful, fraudulent or improper
                  activity; c) not infringe Financeable's Intellectual Property Rights; d) not act in a manner that is
                  defamatory, threatening or harassing to Financeable; e) not provide false, inaccurate, misleading or
                  deceptive information to Us, and You must ensure that any information you provide Us is true, current
                  and complete. If the information changes, you must update it in a timely manner via Website or Mobile
                  Application; f) cooperate fully with Us to investigate any suspected unlawful, fraudulent or improper
                  activity on the Business Account or User Account; g) not permit others, that are not authorised, to
                  use the Business Account, User Account, or Services; h) not use any manual process, technology
                  (device, software or hardware) or any other means to damage, intercept, interfere or copy the
                  Software, Website, the Services or the Mobile Application; i) promptly contact Us if You believe that
                  the Business Account or User Account may be subject to an unauthorised transaction or other type of
                  fraudulent activity or security breach; j) provide any information reasonably requested by Financeable
                  in order to assist Financeable to comply with applicable legal requirements; k) not control a User
                  Account that is linked to another User Account that has engaged in any activity that breaches this
                  Agreement; l) not use the Services in a manner that results in or may result in complaints, disputes,
                  claims, reversals, chargebacks, fees, fines, penalties and other liability to Us, another user, a
                  third party or You; and m) not take any action that may cause Financeable to lose any services
                  provided by any Third Party Providers that support the provision of the Services.
                </Typography>

                <Typography style={{ marginBottom: "5px", fontWeight: 700 }}>9. No Resale of Service</Typography>
                <Typography style={{ marginBottom: "10px" }}>
                  You agree not to reproduce, duplicate, copy, sell, trade, resell or exploit for any commercial
                  purposes,any portion of the Services (including Your Business Account and User Account).
                </Typography>

                <Typography style={{ marginBottom: "5px", fontWeight: 700 }}>10. Termination</Typography>
                <Typography style={{ marginBottom: "10px" }}>
                  You or Financeable may terminate this Agreement with or without cause by providing 30 days’ written
                  notice. The date of termination will be the day after the expiry of that notice period. If You elect
                  to terminate this Agreement by providing written notice, You shall be liable to pay all outstanding
                  fees including any Platform Fees and any Financial Product fees due and payable at the date of
                  termination. You will continue to have access to Your Business Account until the date of termination.
                  Financeable shall not be liable to You for any loss or costs you might suffer as a result of
                  Financeable terminating your Account in accordance with this Agreement. c) each party will, as soon as
                  reasonably possible, return or destroy the other party’s Confidential Information in its possession or
                  control, unless it is required to retain a copy of that information under the law.
                </Typography>

                <Typography style={{ marginBottom: "5px", fontWeight: 700 }}>
                  11. Financeable's Proprietary Rights
                </Typography>
                <Typography style={{ marginBottom: "10px" }}>
                  You acknowledge and agree that the Services and the Software contain proprietary and Confidential
                  Information that is protected by applicable intellectual property and other laws. You further
                  acknowledge and agree that Content provided as part of the Services is protected by copyright,
                  trademarks, service marks, patents or other proprietary rights and laws. Except as expressly
                  authorised by Financeable, you agree not to modify, rent, lease, loan, sell, distribute or create
                  derivative works based on the Services, Content or the Software, in whole or in part. Financeable
                  grants you a personal, non-transferable and non-exclusive right and licence to use its Software and
                  Content provided that you do not (and do not allow any third party to) copy, modify, create a
                  derivative work of, reverse engineer, reverse assemble or otherwise attempt to discover any source
                  code, sell, assign, sublicense, grant a security interest in or otherwise transfer any right in the
                  Software or Content. You agree not to modify the Software in any manner or form, or to use modified
                  versions of the Software, including (without limitation) for the purpose of obtaining unauthorised
                  access to the Services. You agree not to access the Service by any means other than through the
                  interfaces that are provided by Financeable for use in accessing the Services.
                </Typography>

                <Typography style={{ marginBottom: "5px", fontWeight: 700 }}>12. Disclaimer of Warranties</Typography>
                <Typography style={{ marginBottom: "10px" }}>
                  12.1 Services subject to the Consumer Protection Law If the Services We provide to You are subject to
                  the Consumer Protection Law, the Services come with guarantees that cannot be excluded under that Law.
                  For major failures with the Services Financeable provides, you are entitled to: a) terminate this
                  Agreement with Us; and b) a refund for any unused portion, or compensation for its reduced value. You
                  are also entitled to be compensated for any other reasonably foreseeable loss or damage. If the
                  failure does not amount to a major failure, you are entitled to have problems with the Service
                  rectified within a reasonable time and, if this is not done, to cancel and obtain a refund for any
                  unused portion of the Services (if ascertainable). You understand and agree that, if any guarantee,
                  warranty, term or condition is implied or imposed under this Agreement under the Consumer Protection
                  Law, or any other applicable legislation and cannot be excluded (Non-Excludable Provision), and We are
                  able to limit your remedy for a breach of the Non-Excludable Provision, then Financeable’s liability
                  for breach of the Non-Excludable Provision is limited to the supply of the Services again or the
                  payment of the cost of having Services supplied again. 12.2 Services not subject to the Consumer
                  Protection Law If the Services We provide to You are not subject to the Consumer Protection Law or any
                  other applicable legislation and can be excluded (Excludable Provision), you understand and agree
                  that, to the maximum extent permitted by law: a) the Service is provided on an “as is” and “as
                  available” basis and Financeable expressly disclaims all warranties of any kind, whether express or
                  implied, including, but not limited to the implied warranties of merchantability, fitness for a
                  particular purpose and non-infringement; b) no advice or information, whether oral or written,
                  obtained by You from Financeable or through or from the Services shall create any warranty not
                  expressly stated in this Agreement; and c) Financeable has relied on Your representations and
                  warranties to open the Account and provide You with the Services.
                </Typography>

                <Typography style={{ marginBottom: "5px", fontWeight: 700 }}>13. Changes to Agreement</Typography>
                <Typography style={{ marginBottom: "10px" }}>
                  We may change the terms of this Agreement. We will give you at least 30 days’ prior written notice of
                  any changes to the Agreement, unless the changes do not impact your rights or obligations under the
                  Agreement. We will provide you with notice by displaying the updated Agreement the first time you log
                  into your Account after the change or by other written notice. If you do not agree to a change, you
                  can terminate this Agreement in accordance with clause 11 which permits You to terminate this
                  Agreement with or without cause on 30 days’ notice. Any changes will apply to any transactions or
                  requests initiated after the expiration of the notice period, but will not apply to any transaction
                  that is currently in progress.
                </Typography>

                <Typography style={{ marginBottom: "5px", fontWeight: 700 }}>14. Indemnity and liability</Typography>
                <Typography style={{ marginBottom: "10px" }}>
                  14.1 Indemnity (a) Subject to paragraph (b), each party (the Indemnifying Party) to this Agreement
                  indemnifies the other (the Indemnified Party) against, and must pay on demand, all amounts in respect
                  of any action, claim, demand, loss, liability, and expense (including legal costs) of any nature, and
                  arising under tort, contract, statute or equity or some other cause of action, that is incurred by the
                  Indemnified Party in connection with a breach of this Agreement by the Indemnifying Party. (b)
                  Notwithstanding paragraph (a), the Indemnifying Party’s liability to the Indemnified Party will be
                  reduced proportionately to the extent that any act or omission of the Indemnified Party contributed to
                  the relevant loss and the Indemnified Party must use reasonable endeavours to mitigate such loss. 14.2
                  Liability Cap: To the maximum extent permitted by law, the Indemnifying Party’s liability to the
                  Indemnified Party will not exceed the total value of any Access Fees collected by Financeable in the
                  12 months prior to the incident giving rise to the liability, regardless of whether the liability
                  arises under any breach of contract, tort (including negligence), statute, equity, contract or any
                  other cause of action. 14.3 Consequential loss: Neither party is liable to the other for any
                  consequential or indirect loss including, but not limited to, loss of profit, lost opportunity cost,
                  loss of enjoyment.
                </Typography>

                <Typography style={{ marginBottom: "5px", fontWeight: 700 }}>15. Third Party Products</Typography>
                <Typography style={{ marginBottom: "10px" }}>
                  15.1 Customer Third Party Products In providing the Services, Financeable may allow You to select and
                  connect or interact with one or more third party software products (Customer Third Party Product). In
                  order to connect or interact with a specific Customer Third Party Product, You will need to have, and
                  actually be signed-in to, an active user account for that Customer Third Party Product (Customer Third
                  Party Product Account). You may need to contact the third party for additional support. You are solely
                  responsible for: i.) choosing a Customer Third Party Product provider; ii.) registering a Customer
                  Third Party Product Account; iii.) keeping Your Customer Third Party Product Account active; iv.)
                  ensuring Your Customer Third Party Product Account is up to date and accurate; and v.) the payment of
                  any Customer Third Party Product fees to the provider of Your Customer Third Party Product in
                  connection with use of that Customer Third Party Product. Financeable is not affiliated with, has no
                  control over, and assumes no responsibility for the operation of any Customer Third Party Product or
                  actions of any Customer Third Party Product provider. You agree to indemnify and hold Financeable
                  harmless, and expressly release Financeable, from any and all liability arising from Your use of,
                  and/or connection to, any Customer Third Party Product. This indemnity is reduced to the extent that
                  Financeable cause or contributed to any loss or harm. If You choose to connect or interact with a
                  Customer Third Party Product, then You: i.) grant Financeable authorisation to access Your Customer
                  Third Party Product Account in accordance with the rules for doing so prescribed by the Customer Third
                  Party Product provider; and ii.) agree to cooperate with Financeable and provide any information that
                  Financeable or the Customer Third Party Product provider may request in order to enable Financeable to
                  access Your Customer Third Party Product Account. Financeable will only access and use Your Customer
                  Third Party Product Account in order to provide the Services and You expressly consent to Financeable
                  accessing and using Your Customer Third Party Product Account for this purpose. Financeable may retain
                  records of the information accessed and used in accordance with this clause and You expressly consent
                  to Financeable's retention of these records. If at any time Financeable’s access to Your Customer
                  Third Party Product Account is limited or denied, then You acknowledge and agree that Financeable may
                  not be able to provide some or all of the Services. 15.2 Automatically Integrated Third Party Products
                  When setting up Your Account or at any time after, Financeable may enrol You in one or more third
                  party products that are built-into the Service offering (Automatically Integrated Third Party
                  Product). You consent to Financeable providing any necessary information to an Automatically
                  Integrated Third Party Product provider for the purposes of Financeable being able to provide You
                  access to the Automatically Integrated Third Party Product through the Services. You acknowledge and
                  agree that the fees or charges for an Automatically Integrated Third Party Products are included in
                  the fees for the Services as set out in clause 6. You acknowledge and agree that the use of an
                  Automatically Integrated Third Party Product as part of the Services means that You: i.) grant
                  Financeable authorisation to access an Automatically Integrated Third Party Product in accordance with
                  the rules for doing so prescribed by the Automatically Integrated Third Party Product provider; and
                  ii.) will cooperate with Financeable and provide any information that Financeable or an Automatically
                  Integrated Third Party Product provider may request in order to enable Financeable to access any
                  information held by an Automatically Integrated Third Party Product provider. Financeable will only
                  access and use information from an Automatically Integrated Third Party Product in order to provide
                  the Services and You expressly consent to Financeable accessing and using information from an
                  Automatically Integrated Third Party Productfor this purpose. Financeable may retain records of the
                  information accessed and used in accordance with this clause and You expressly consent to
                  Financeable's retention of these records in accordance with the law. If at any time Financeable does
                  not have access to an Automatically Integrated Third Party Product, then You acknowledge and agree
                  that Financeable may not be able to provide some or all of the Services to You.
                </Typography>

                <Typography style={{ marginBottom: "5px", fontWeight: 700 }}>16. Complaints</Typography>
                <Typography style={{ marginBottom: "10px" }}>
                  If you are dissatisfied or have a complaint about the Service, please contact Financeable at
                  help@financeable.com.au When We receive Your complaint We’ll aim to acknowledge your complaint within
                  one Business Day or as soon as practicable within five Business Days. Where we’re unable to resolve
                  your complaint within five Business Days, we’ll consider and provide a response to your complaint
                  within 30 days.
                </Typography>

                <Typography style={{ marginBottom: "5px", fontWeight: 700 }}>17. General Information</Typography>
                <Typography style={{ marginBottom: "10px" }}>
                  Entire Agreement – This Agreement constitutes the entire agreement between You and Financeable and
                  governs your use of the Service, superseding any prior agreements between You and Financeable.
                  However, nothing in the Agreement limits any liability either party may have in connection with any
                  representations or other communications (either oral or written) made before or after entering into
                  the Agreement, where such liability cannot be excluded by law. System Outage - Access to the Services
                  or the Website may occasionally be unavailable or limited due to hardware or software failure or
                  defects, overloading of system capacity, pandemic, act of war, revolution, strikes, lockouts, acts of
                  government, damage from natural events or disasters or disruptive human activity, interruption of
                  power systems, labour shortages or stoppages, legal or regulatory restrictions as well as other causes
                  outside of Financeable’s control. Financeable will not be liable for any loss or damage which you may
                  incur as a result of the Services being unavailable due to causes outside of Financeable’s control.
                  Governing Law and Jurisdiction – This Agreement and the relationship between You and Financeable shall
                  be governed by the laws of the State of New South Wales without regard to its conflict of law
                  provisions. You and Financeable agree to submit to the personal and exclusive jurisdiction of the
                  courts of New South Wales. Waiver and Severability of Terms – The failure of Financeable to exercise
                  or enforce any right or provision of this Agreement shall not constitute a waiver of such right or
                  provision. If any provision of this Agreement is found by a court of competent jurisdiction to be
                  invalid, the parties nevertheless agree that the court should endeavour to give effect to the parties’
                  intentions as reflected in the provision, and the other provisions of this Agreement remain in full
                  force and effect. Statute of Limitations – You agree that regardless of any statute or law to the
                  contrary, any claim or cause of action arising out of or related to use of the Service or this
                  Agreement must be filed within one (1) year after such a claim or cause of action arose or be forever
                  barred. Headings - The section titles in this Agreement are for convenience only and have no legal or
                  contractual effect.
                </Typography>

                <Typography style={{ marginBottom: "5px", fontWeight: 700 }}>AUTHORISATION</Typography>
                <Typography style={{ marginBottom: "20px" }}>
                  You hereby declare that we agree to engage with Financeable Pty Ltd in a commercial relationship for
                  which we authorise to act on our behalf and in our name to do the following: 1. Conduct all matters in
                  relation to establishing and maintaining services with Financeable Pty Ltd (including any of its
                  associated entities, any of its third party providers, and any third parties on behalf of which it
                  acts as an authorised representative to provide financial services). 2. This authorisation will be
                  effective with immediate effect until further notice. 3. I guarantee that I am duly authorised to
                  make, and will be responsible for, these authorisations.
                </Typography>

                <Typography style={{ marginBottom: "20px" }}>Updated: 21 December 2023</Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ marginBottom: "40px" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ padding: "5px 30px" }}
              >
                <Typography>Show Services Pricing</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "20px 30px" }}>
                <Typography style={{ margin: "0 0 20px 0" }}>
                  The below are base pricing. It is listed on a per request basis. Some services will reduce in cost
                  with volume and it's price is highlighted in bold.
                </Typography>

                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeaderCell}>Service</TableCell>
                        <TableCell className={classes.tableHeaderCell}>Description</TableCell>
                        <TableCell className={classes.tableHeaderCell}>Notes</TableCell>
                        <TableCell className={classes.tableHeaderCell}>Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell className={classes.boldCell}>{row.service}</TableCell>
                          <TableCell>{row.description}</TableCell>
                          <TableCell>{row.notes}</TableCell>
                          <TableCell className={boldAmountList.includes(index) ? classes.boldCell : ""}>
                            {row.amount}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>

                <Typography style={{ margin: "10px 0 10px 0", fontWeight: 600 }}>
                  Note: All prices are exclusive of GST
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Grid container item xs={12} justifyContent="center">
              <Grid item sm={12}>
                <Typography>
                  By checking the boxes below I acknowledge that I have read and accept each of the terms and policies:
                </Typography>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="Financeable Terms and Conditions, including the Letter of Authority (updated 21 December 2023)"
                    onChange={(event, value) => {
                      handle.handleCheckTerm("isFinTermAccept", value);
                    }}
                  />
                  <FormControlLabel
                    required
                    control={<Checkbox />}
                    // label={<a href="https://financeable.com.au/privacy-policy" target="_blank"> Pricing Schedule</a>}
                    label="Pricing Schedule"
                    onChange={(event, value) => {
                      handle.handleCheckTerm("isPriceTermAccept", value);
                    }}
                  />
                  <FormControlLabel
                    required
                    control={<Checkbox />}
                    label={
                      <a href="https://financeable.com.au/privacy-policy" target="_blank" rel="noreferrer">
                        Financeable Privacy Policy
                      </a>
                    }
                    onChange={(event, value) => {
                      handle.handleCheckTerm("isFinPrivacyTermAccept", value);
                    }}
                  />
                </FormGroup>
              </Grid>
            </Grid>

            <Grid container item xs={12} justifyContent="center">
              <Grid item sm={12}>
                <Grid item sm={12}>
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    onClick={() => handleSubmitTerms()}
                    disabled={!isTermAcceptable}
                    // onClick={() => createPrivacy({ customerId, applicationId, signature: sigCanvas.toDataUrl() })}
                    style={{ margin: "30px 0 50px" }}
                  >
                    {!canSubmit ? "Submitting..." : "Accept"}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </>
        </Grid>
      </Grid>
    </Grid>
  );
}
