import { livingArrangementsTypes, employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } = livingArrangementsTypes;

const { SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL } = employmentTypes;

const {
  hasDeposit,
  dateOfBirthBetween,
  dateOfBirthGreaterThan,
  assetValueBetween,
  assetType,
  ageOfAssetLessThan,
  ageOfAssetAtEndLessThan,
  ageOfAssetGreaterThan,
  loanAmountBetween,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
} = criteria;

const base = [
  {
    name: "Auto <12 months",
    rate: 6.4,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(20),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499),
    ],
  },
  {
    name: "Auto >12 months",
    rate: 7.1,
    productType: "Standard",
    criteria: [
      ageOfAssetGreaterThan(11),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(20),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499),
    ],
  },
  // {
  //   name: "Auto <12 months",
  //   rate: 7.7,
  //   productType: "Standard",
  //   criteria: [
  //     ageOfAssetBetween(36, 155),
  //     ageOfAssetAtEndLessThan(13),
  //     hasDeposit(20),
  //     livingArrangements([PROPERTY_OWNER]),
  //     creditScoreGreaterThan(499),
  //   ],
  // },

  // {
  //   name: "Auto <12 months",
  //   rate: 7.15,
  //   productType: "Standard",
  //   criteria: [
  //     ageOfAssetLessThan(12),
  //     ageOfAssetAtEndLessThan(13),
  //     hasDeposit(10),
  //     livingArrangements([PROPERTY_OWNER]),
  //     creditScoreGreaterThan(499),
  //   ],
  // },
  // {
  //   name: "Auto <12 months",
  //   rate: 7.75,
  //   productType: "Standard",
  //   criteria: [
  //     ageOfAssetBetween(12, 36),
  //     ageOfAssetAtEndLessThan(13),
  //     hasDeposit(10),
  //     livingArrangements([PROPERTY_OWNER]),
  //     creditScoreGreaterThan(499),
  //   ],
  // },
  // {
  //   name: "Auto <12 months",
  //   rate: 8.3,
  //   productType: "Standard",
  //   criteria: [
  //     ageOfAssetBetween(36, 155),
  //     ageOfAssetAtEndLessThan(13),
  //     hasDeposit(10),
  //     livingArrangements([PROPERTY_OWNER]),
  //     creditScoreGreaterThan(499),
  //   ],
  // },

  {
    name: "Auto <12 months",
    rate: 6.7,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499),
    ],
  },
  {
    name: "Auto >12 months",
    rate: 7.4,
    productType: "Standard",
    criteria: [
      ageOfAssetGreaterThan(11),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499),
    ],
  },
  // {
  //   name: "Auto <12 months",
  //   rate: 9.05,
  //   productType: "Standard",
  //   criteria: [
  //     ageOfAssetBetween(36, 155),
  //     ageOfAssetAtEndLessThan(13),
  //     livingArrangements([PROPERTY_OWNER]),
  //     creditScoreGreaterThan(499),
  //   ],
  // },

  {
    name: "Auto <12 months",
    rate: 7.25,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(20),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499),
    ],
  },
  {
    name: "Auto >12 months",
    rate: 9.25,
    productType: "Standard",
    criteria: [
      ageOfAssetGreaterThan(11),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(20),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499),
    ],
  },
  // {
  //   name: "Auto <12 months",
  //   rate: 9.95,
  //   productType: "Standard",
  //   criteria: [
  //     ageOfAssetBetween(36, 155),
  //     ageOfAssetAtEndLessThan(13),
  //     hasDeposit(20),
  //     livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
  //     creditScoreGreaterThan(499),
  //   ],
  // },

  // {
  //   name: "Auto <12 months",
  //   rate: 8.6,
  //   productType: "Standard",
  //   criteria: [
  //     ageOfAssetLessThan(12),
  //     ageOfAssetAtEndLessThan(13),
  //     hasDeposit(10),
  //     livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
  //     creditScoreGreaterThan(499),
  //   ],
  // },
  // {
  //   name: "Auto <12 months",
  //   rate: 10.0,
  //   productType: "Standard",
  //   criteria: [
  //     ageOfAssetBetween(12, 36),
  //     ageOfAssetAtEndLessThan(13),
  //     hasDeposit(10),
  //     livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
  //     creditScoreGreaterThan(499),
  //   ],
  // },
  // {
  //   name: "Auto <12 months",
  //   rate: 10.55,
  //   productType: "Standard",
  //   criteria: [
  //     ageOfAssetBetween(36, 155),
  //     ageOfAssetAtEndLessThan(13),
  //     hasDeposit(10),
  //     livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
  //     creditScoreGreaterThan(499),
  //   ],
  // },

  {
    name: "Auto <12 months",
    rate: 7.7,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499),
    ],
  },
  {
    name: "Auto >12 months",
    rate: 8.4,
    productType: "Standard",
    criteria: [
      ageOfAssetGreaterThan(11),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499),
    ],
  },
  // {
  //   name: "Auto <12 months",
  //   rate: 11.3,
  //   productType: "Standard",
  //   criteria: [
  //     ageOfAssetBetween(36, 155),
  //     ageOfAssetAtEndLessThan(13),
  //     livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
  //     creditScoreGreaterThan(499),
  //   ],
  // },
];

// const otherAssetTypes = [
//   {
//     name: "Auto <12 months",
//     rate: 6.8,
//     productType: "Standard",
//     criteria: [
//       ageOfAssetLessThan(12),
//       ageOfAssetAtEndLessThan(13),
//       livingArrangements([PROPERTY_OWNER]),
//       creditScoreGreaterThan(499),
//     ],
//   },
//   {
//     name: "Auto >12 months",
//     rate: 7.4,
//     productType: "Standard",
//     criteria: [
//       ageOfAssetGreaterThan(11),
//       ageOfAssetAtEndLessThan(13),
//       livingArrangements([PROPERTY_OWNER]),
//       creditScoreGreaterThan(499),
//     ],
//   },
//   // {
//   //   name: "Auto <12 months",
//   //   rate: 7.95,
//   //   productType: "Standard",
//   //   criteria: [
//   //     ageOfAssetBetween(36, 155),
//   //     ageOfAssetAtEndLessThan(13),
//   //     livingArrangements([PROPERTY_OWNER]),
//   //     creditScoreGreaterThan(499),
//   //   ],
//   // },

//   {
//     name: "Auto <12 months",
//     rate: 8.25,
//     productType: "Standard",
//     criteria: [
//       ageOfAssetLessThan(12),
//       ageOfAssetAtEndLessThan(13),
//       livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
//       creditScoreGreaterThan(499),
//     ],
//   },
//   {
//     name: "Auto >12 months",
//     rate: 9.65,
//     productType: "Standard",
//     criteria: [
//       ageOfAssetGreaterThan(11),
//       ageOfAssetAtEndLessThan(13),
//       livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
//       creditScoreGreaterThan(499),
//     ],
//   },
//   // {
//   //   name: "Auto <12 months",
//   //   rate: 10.2,
//   //   productType: "Standard",
//   //   criteria: [
//   //     ageOfAssetBetween(36, 155),
//   //     ageOfAssetAtEndLessThan(13),
//   //     livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
//   //     creditScoreGreaterThan(499),
//   //   ],
//   // },
// ];

const luxuryBase = [
  {
    name: "Auto <12 months",
    rate: 6.4,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(20),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499),
    ],
  },

  // {
  //   name: "Auto <12 months",
  //   rate: 7.15,
  //   productType: "Standard",
  //   criteria: [
  //     ageOfAssetLessThan(12),
  //     ageOfAssetAtEndLessThan(13),
  //     hasDeposit(10),
  //     livingArrangements([PROPERTY_OWNER]),
  //     creditScoreGreaterThan(499),
  //   ],
  // },

  {
    name: "Auto <12 months",
    rate: 6.7,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499),
    ],
  },

  {
    name: "Auto <12 months",
    rate: 7.25,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(20),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499),
    ],
  },

  // {
  //   name: "Auto <12 months",
  //   rate: 8.6,
  //   productType: "Standard",
  //   criteria: [
  //     ageOfAssetLessThan(12),
  //     ageOfAssetAtEndLessThan(13),
  //     hasDeposit(10),
  //     livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
  //     creditScoreGreaterThan(499),
  //   ],
  // },

  {
    name: "Auto <12 months",
    rate: 7.7,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499),
    ],
  },
];

const auto18To21 = base.map((product) => ({
  ...product,
  criteria: [
    ...product.criteria,
    dateOfBirthBetween(18, 21),
    loanAmountBetween(10000, 40000),
    assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
  ],
}));

const auto21To24 = base.map((product) => ({
  ...product,
  criteria: [
    ...product.criteria,
    dateOfBirthBetween(21, 24),
    loanAmountBetween(10000, 50000),
    assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
  ],
}));

const auto24Up = base.map((product) => ({
  ...product,
  criteria: [
    ...product.criteria,
    dateOfBirthGreaterThan(24),
    loanAmountBetween(10000, 100000),
    assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
  ],
}));

const luxury = luxuryBase.map((product) => ({
  ...product,
  criteria: [
    ...product.criteria,
    dateOfBirthGreaterThan(24),
    loanAmountBetween(10000, 250000),
    assetType(["Luxury Vehicle"]),
  ],
}));

// const otherAssetTypes18To21 = otherAssetTypes.map((product) => ({
//   ...product,
//   criteria: [
//     ...product.criteria,
//     dateOfBirthBetween(18, 21),
//     loanAmountBetween(10000, 40000),
//     assetType(["Trailer", "Caravan", "Motor home"]),
//   ],
// }));

// const otherAssetTypes21To24 = otherAssetTypes.map((product) => ({
//   ...product,
//   criteria: [
//     ...product.criteria,
//     dateOfBirthBetween(21, 24),
//     loanAmountBetween(10000, 50000),
//     assetType(["Trailer", "Caravan", "Motor home"]),
//   ],
// }));

// const otherAssetTypes24Up = otherAssetTypes.map((product) => ({
//   ...product,
//   criteria: [
//     ...product.criteria,
//     dateOfBirthGreaterThan(24),
//     loanAmountBetween(10000, 100000),
//     assetType(["Trailer", "Caravan", "Motor home"]),
//   ],
// }));

const fullTimePartTime = [
  ...auto18To21,
  ...auto21To24,
  ...auto24Up,
  ...luxury,
  // ...otherAssetTypes18To21,
  // ...otherAssetTypes21To24,
  // ...otherAssetTypes24Up
].map((product) => ({
  ...product,
  criteria: [...product.criteria, employmentType([FULL_TIME, PART_TIME])],
}));

const casual = [
  ...auto18To21,
  ...auto21To24,
  ...auto24Up,
  ...luxury,
  // ...otherAssetTypes18To21,
  // ...otherAssetTypes21To24,
  // ...otherAssetTypes24Up
].map((product) => ({
  ...product,
  criteria: [...product.criteria, employmentType([CASUAL]), timeOfEmploymentGreaterThan(6)],
}));

const SEContractor = [
  ...auto18To21,
  ...auto21To24,
  ...auto24Up,
  ...luxury,
  // ...otherAssetTypes18To21,
  // ...otherAssetTypes21To24,
  // ...otherAssetTypes24Up
].map((product) => {
  product.criteria.push();

  return {
    ...product,
    criteria: [...product.criteria, employmentType([SELF_EMPLOYED, CONTRACT]), timeOfEmploymentGreaterThan(24)],
  };
});

const macquarie = {
  date: "01st Feb 2024",
  lender: "Macquarie",
  type: "consumer",
  logoURL: "https://assets.financeable.com.au/lender-logos%2Fmacquarie_logo.gif",
  actions: ["upload"],
  lenderCredentials: [
    {
      label: "Email",
      attribute: "email",
    },
    // {
    //   label: "User Name",
    //   attribute: "username",
    // },
    // {
    //   label: "Password",
    //   attribute: "password",
    // },
    // {
    //   label: "Merchant ID",
    //   attribute: "organisationId",
    // },
    // {
    //   label: "User ID",
    //   attribute: "brokerId",
    // },
    {
      label: "Api Key",
      attribute: "apiKey",
    },
  ],
  requiredFields: {
    asset: [
      "assetValue",
      "assetType",
      "typeOfSale",
      "ageOfAsset",
      "condition",
      "glassesMake",
      "glassesModel",
      // "Make", 
      // "Model",
    ],
    customer: [
      "customerTitle",
      "firstName",
      "lastName",
      "dateOfBirth",
      "IDNumber",
      "IDCardNumber",
      "IDExpiryDate",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      // "addresses[1].fullAddress", // What if user not have previous address ?
      // "addresses[1].city",
      // "addresses[1].state",
      // "addresses[1].postCode",
      // "addresses[1].monthsAt",
      // "addresses[1].yearsAt",
      "customerProfile.livingArrangements",
      "customerProfile.numberOfDependants",
      "customerProfile.residencyStatus",
      "customerProfile.maritalStatus",
      "customerAssets.[0].assetType",
      "customerAssets.[0].value",
      "customerAssets.[0].description",
      "liabilities.[0].liabilitiesType",
      "liabilities.[0].outstandingAmount",
      "liabilities.[0].limit",
      "liabilities.[0].financier",
      "liabilities.[0].repaymentFrequency",
      "liabilities.[0].repaymentAmount",
      "liabilities.[0].description",
      "income.[0].incomeType",
      "income.[0].amount",
      "income.[0].frequency",
      "expenses.[0].expensesType",
      "expenses.[0].amount",
      "expenses.[0].frequency",
      "employers.[0].entityName",
      "employers.[0].status",
      "employers.[0].timeOfEmployment",
      "employers.[0].occupation",
      "employers.[0].manager",
      "employers.[0].phoneNumber",
      "employers.[0].industry",
      "employers.[0].address.fullAddress",
      "employers.[0].address.city",
      "employers.[0].address.state",
    ],
    loanDetails: [
      // "rate"
      // "term",
      // "balloon",
      // "deposit",
      // "tradeIn",
      // "payout",
      // "rate",
      // "rateAdjustment",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Establishment fee",
      value: 250,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Monthly account fee",
      value: 15,
      capitalised: true,
      frequency: "monthly",
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 4,
      valueType: "percentage",
      criteria: [assetValueBetween(0, 1000000)],
    },
  ],
  loading: [],
  info: [
    `
     Maximum net amount financed within a 6 month period of the 
     settlement date (or approval date if not settled) - $100,000 
    `,
    ` Maximum net amount financed $200,000 for Existing customer 
      with current account including non property owner) to be eligible:
    `,
    `Must have a minimum of 24 months history, with gaps of no more than 6 months between contracts and; 
      must have current exposure or have finalised a contract no more than 6 months prior to submission `,
    `Luxury motor vehicles: new/demo Ferrari, Maserati, McLaren, Porsche, Bentley, 
Lamborghini, Tesla Motor Vehicles and Mercedes)`,
  ],
  productTiers: [...fullTimePartTime, ...casual, ...SEContractor],
};

export default macquarie;
