import { livingArrangementsTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { PROPERTY_OWNER } = livingArrangementsTypes;

const {
  livingArrangements,
  nonGST,
  hasDeposit,
  assetType,
  ageOfAssetGreaterThan,
  ageOfAssetLessThan,
  ageOfAssetBetween,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  gstRegistrationGreaterThan,
  creditScoreGreaterThan,
  ageOfAssetAtEndLessThan,
} = criteria;

function isGSTPropertyOwner(b) {
  return b.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      loanAmountBetween(5000, 50000),
      timeInBusinessGreaterThan(24),
      gstRegistrationGreaterThan(0),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(400),
    ],
  }));
}

function noGSTPropertyOwner(b) {
  return b.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      loanAmountBetween(5000, 35000),
      timeInBusinessGreaterThan(24),
      nonGST,
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(400),
    ],
  }));
}

function isGSTNonPropertyOwner(b) {
  return b.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      loanAmountBetween(5000, 50000),
      timeInBusinessGreaterThan(24),
      gstRegistrationGreaterThan(0),
      hasDeposit(10),
      livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
      creditScoreGreaterThan(500),
    ],
  }));
}

function noGSTNonPropertyOwner(b) {
  return b.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      loanAmountBetween(5000, 35000),
      timeInBusinessGreaterThan(24),
      nonGST,
      hasDeposit(10),
      livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
      creditScoreGreaterThan(500),
    ],
  }));
}

const advserseOne = [
  ...isGSTPropertyOwner(base()),
  ...noGSTPropertyOwner(base()),
  ...isGSTNonPropertyOwner(base()),
  ...noGSTNonPropertyOwner(base()),
];

function base() {
  return [
    {
      name: "Commercial Motor",
      rate: 11.95,
      productType: "Adverse",
      criteria: [
        ageOfAssetLessThan(12),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        ageOfAssetAtEndLessThan(21),
      ],
    },
    {
      name: "Commercial Motor",
      rate: 12.45,
      productType: "Adverse",
      criteria: [
        ageOfAssetBetween(12, 48),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        ageOfAssetAtEndLessThan(21),
      ],
    },
    {
      name: "Commercial Motor",
      rate: 12.95,
      productType: "Adverse",
      criteria: [
        ageOfAssetBetween(48, 120),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        ageOfAssetAtEndLessThan(21),
      ],
    },
    {
      name: "Commercial Motor",
      rate: 13.45,
      productType: "Adverse",
      criteria: [
        ageOfAssetGreaterThan(120),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        ageOfAssetAtEndLessThan(21),
      ],
    },
  ];
}

export default advserseOne;
