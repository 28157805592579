import base from "./base";
import { employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { FULL_TIME, PART_TIME, CASUAL, CONTRACT, SELF_EMPLOYED } = employmentTypes;

const { loanAmountBetween, employmentType } = criteria;

function employment(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, employmentType([FULL_TIME, PART_TIME, SELF_EMPLOYED, CONTRACT, CASUAL])],
  }));
}

// const withEmploymentCriteria = [...selfEmployed(base), ...permanentEmployed(base), ...temporaryEmployed(base)];

const saltLime = {
  date: "01 Mar 2024",
  lender: "Salt & Lime",
  type: "consumer",
  logoURL: "https://assets.financeable.com.au/lender-logos/S&L%20Logo%20Green.svg",
  actions: ["upload"],
  lenderCredentials: [
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
  ],
  requiredFields: {
    loanDetails: ["loanAmount"],
    customer: [
      "firstName",
      "lastName",
      "dateOfBirth",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      // "addresses[0].street",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "IDNumber",
      "IDCardNumber",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "employers.[0].entityName",
      "employers.[0].timeOfEmployment",
      "employers.[0].employmentType",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Origination fee",
      value: 550,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(5000, 10000)],
    },
    {
      name: "Origination fee",
      value: 990,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(10000.01, 20000)],
    },
    {
      name: "Origination fee",
      value: 1199,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(20000.01, 20000)],
    },
    {
      name: "Origination fee",
      value: 1375,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(30000.01, 40000)],
    },
    {
      name: "Origination fee",
      value: 1650,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(40000.01, 50000)],
    },
    {
      name: "Origination fee",
      value: 2189,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(50000.01, 75000)],
    },
  ],
  brokerage: [],
  loading: [],
  info: [
    `We charge $0 in fees. This includes $0 risk fee, $0 application fee, $0 monthly or account keeping fee, $0 early repayment fee and $0 late payment or dishonour fees – none!    `,
    `Unpaid Defaults – Finance** - No more than 1 on file`,
    `Unpaid Defaults – Non-Finance** - - No more than 2 on file`,
    `Unpaid Defaults – Finance & Non-Finance** -No more than $6,000`,
    `Bankruptcies – Discharged or Undischarged - Not applicable`,
    `Part IX / X Debt Agreements - Not applicable`,
  ],
  productTiers: [...employment(base)],
};
export default saltLime;
