import { employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { CASUAL } = employmentTypes;

const {
  assetType,
  loanAmountBetween,
  creditScoreGreaterThan,
  ageOfAssetBetween,
  ageOfAssetAtEndLessThan,
  ageOfAssetGreaterThan,
  assetCondition,
  hasDeposit,
  employmentType,
} = criteria;

const aa = [
  {
    name: "AA Tier",
    rate: 8.25,
    productType: "Low doc",
    criteria: [
      assetCondition(["New"]),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      employmentType([CASUAL]),
      hasDeposit(20),
      creditScoreGreaterThan(849),
    ],
  },
  {
    name: "AA Tier",
    rate: 8.45,
    productType: "Low doc",
    criteria: [
      assetCondition(["Demo", "Used"]),
      ageOfAssetBetween(0, 71),
      ageOfAssetAtEndLessThan(21),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      employmentType([CASUAL]),
      hasDeposit(20),
      creditScoreGreaterThan(849),
    ],
  },
  {
    name: "AA Tier",
    rate: 9.25,
    productType: "Low doc",
    criteria: [
      assetCondition(["Demo", "Used"]),
      ageOfAssetBetween(72, 107),
      ageOfAssetAtEndLessThan(21),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      employmentType([CASUAL]),
      hasDeposit(20),
      creditScoreGreaterThan(849),
    ],
  },
  {
    name: "AA Tier",
    rate: 10.85,
    productType: "Low doc",
    criteria: [
      assetCondition(["Demo", "Used"]),
      ageOfAssetGreaterThan(107),
      ageOfAssetAtEndLessThan(21),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      employmentType([CASUAL]),
      hasDeposit(20),
      creditScoreGreaterThan(849),
    ],
  },

  {
    name: "AA Tier",
    rate: 8.45,
    productType: "Low doc",
    criteria: [
      assetCondition(["New"]),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      employmentType([CASUAL]),
      hasDeposit(10),
      creditScoreGreaterThan(849),
    ],
  },
  {
    name: "AA Tier",
    rate: 8.65,
    productType: "Low doc",
    criteria: [
      assetCondition(["Demo", "Used"]),
      ageOfAssetBetween(0, 71),
      ageOfAssetAtEndLessThan(21),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      employmentType([CASUAL]),
      hasDeposit(10),
      creditScoreGreaterThan(849),
    ],
  },
  {
    name: "AA Tier",
    rate: 9.45,
    productType: "Low doc",
    criteria: [
      assetCondition(["Demo", "Used"]),
      ageOfAssetBetween(72, 107),
      ageOfAssetAtEndLessThan(21),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      employmentType([CASUAL]),
      hasDeposit(10),
      creditScoreGreaterThan(849),
    ],
  },
  {
    name: "AA Tier",
    rate: 10.95,
    productType: "Low doc",
    criteria: [
      assetCondition(["Demo", "Used"]),
      ageOfAssetGreaterThan(107),
      ageOfAssetAtEndLessThan(21),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      employmentType([CASUAL]),
      hasDeposit(10),
      creditScoreGreaterThan(849),
    ],
  },

  {
    name: "AA Tier",
    rate: 8.75,
    productType: "Low doc",
    criteria: [
      assetCondition(["New"]),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      employmentType([CASUAL]),
      creditScoreGreaterThan(849),
    ],
  },
  {
    name: "AA Tier",
    rate: 8.95,
    productType: "Low doc",
    criteria: [
      assetCondition(["Demo", "Used"]),
      ageOfAssetBetween(0, 71),
      ageOfAssetAtEndLessThan(21),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      employmentType([CASUAL]),
      creditScoreGreaterThan(849),
    ],
  },
  {
    name: "AA Tier",
    rate: 9.75,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 10000),
      assetCondition(["Demo", "Used"]),
      ageOfAssetBetween(72, 107),
      ageOfAssetAtEndLessThan(21),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      employmentType([CASUAL]),
      creditScoreGreaterThan(849),
    ],
  },
  {
    name: "AA Tier",
    rate: 11.15,
    productType: "Low doc",
    criteria: [
      assetCondition(["Demo", "Used"]),
      ageOfAssetGreaterThan(107),
      ageOfAssetAtEndLessThan(21),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      employmentType([CASUAL]),
      creditScoreGreaterThan(849),
    ],
  },
];

export default aa;
