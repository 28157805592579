import { employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL } = employmentTypes;

const { assetType, loanAmountBetween, employmentType, timeOfEmploymentGreaterThan, termIsLessThanOrEqualTo } = criteria;

const bronze = [
  {
    name: "Bronze",
    rate: 28.45,
    info: ["Adverse more than 6 months", "Ex-bankkrupt more than 1 day"],
    productType: "Adverse",
    criteria: [
      loanAmountBetween(8000, 25000),
      termIsLessThanOrEqualTo(60),
      employmentType([FULL_TIME, PART_TIME, SELF_EMPLOYED, CASUAL, CONTRACT]),
      timeOfEmploymentGreaterThan(2),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan", "Trailered boat", "Motorbike"]),
    ],
  },
];
export default bronze;
