import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { debounce } from "lodash";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import regex from "../../utils/regex";
import { fieldRequiredMessage, STATUS_CODE } from "../../constants";
import { addOrgBrand, getOrgBrand, updateOrgBrand } from "../../store/slices/organisationSlice";

const validationSchema = Yup.object({
  emailAuthor: Yup.string()
    .required(fieldRequiredMessage.brandErrMsg.emailAuthor)
    .matches(regex.email, "Please enter valid email address")
    .min(2, "Maximum of 5 characters")
    .max(50, "Maximum of 50 characters"),
  smsAuthor: Yup.string()
    .required(fieldRequiredMessage.brandErrMsg.smsAuthor)
    .matches(regex.phoneNumber, "Enter a valid Australian landline number."),
  authenticationMethods: Yup.string().required(fieldRequiredMessage.brandErrMsg.authenticationMethods),
});

const BrandModel = ({ brandDetail, setBrandDetail, btnTextNo, btnTextYes, title }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [brandField, setBrandField] = useState({
    emailAuthor: "",
    smsAuthor: "",
    authenticationMethods: "",
  });
  const [fieldErrors, setFieldErrors] = useState({});

  useEffect(() => {
    if (brandDetail?.organisationId) {
      handle.getBrandDetail();
    }
  }, [brandDetail?.organisationId]);

  const handle = {
    onChangeField: async (e, nameField) => {
      setBrandField({ ...brandField, [nameField]: e });
      await debouncedValidation(nameField, e);
    },
    confirmation: () => {
      if (brandField?.brandId) {
        dispatch(updateOrgBrand(brandField)).then(({ payload }) => {
          if (payload?.status === STATUS_CODE.success) {
            enqueueSnackbar(payload?.data?.status_message, {
              variant: "success",
              autoHideDuration: 5000,
            });
            setBrandDetail({ isOpen: false });
          } else {
            enqueueSnackbar(payload?.status_message, {
              variant: "error",
              autoHideDuration: 5000,
            });
          }
        });
      } else {
        dispatch(addOrgBrand(brandField)).then(({ payload }) => {
          if (payload?.status === STATUS_CODE.success) {
            enqueueSnackbar(payload?.data?.status_message, {
              variant: "success",
              autoHideDuration: 5000,
            });
            setBrandDetail({ isOpen: false });
          } else {
            enqueueSnackbar(payload?.status_message, {
              variant: "error",
              autoHideDuration: 5000,
            });
          }
        });
      }
    },
    closeDialog: () => {
      setBrandField(null);
      setBrandDetail({ isOpen: false });
    },
    getBrandDetail: async () => {
      dispatch(getOrgBrand(brandDetail?.organisationId)).then(async({ payload }) => {
        setBrandField({
          emailAuthor: payload?.data?.emailAuthor || "",
          smsAuthor: payload?.data?.smsAuthor || "",
          authenticationMethods: payload?.data?.authenticationMethods || "",
          organisationId: payload?.data?.ownerOrgId || brandDetail?.organisationId || "",
          brandId: payload?.data?._id,
        });
      
       
      });
    },
  };

  const debouncedValidation = useCallback(
    debounce(async (fieldName, value) => {
      try {
        await validationSchema.validateAt(fieldName, { [fieldName]: value });
        setFieldErrors({ ...fieldErrors, [fieldName]: "" });
      } catch (error) {
        setFieldErrors({
          ...fieldErrors,
          [fieldName]: error.message,
        });
      }
    }, 300),
    [],
  );

  return (
    <>
      <Dialog open={brandDetail?.isOpen || false} fullWidth maxWidth={"sm"}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            shrink
            required
            type="email"
            name="emailAuthor"
            label="Email Author"
            variant="filled"
            size="small"
            value={brandField?.emailAuthor}
            onChange={(event) => handle.onChangeField(event.target.value, "emailAuthor")}
            margin="dense"
            helperText={fieldErrors.emailAuthor}
            error={fieldErrors.emailAuthor}
          />

          <TextField
            fullWidth
            required
            type="text"
            name="smsAuthor"
            label="SMS Author"
            autoComplete="tel"
            variant="filled"
            size="small"
            value={brandField?.smsAuthor}
            onChange={(event) => handle.onChangeField(event.target.value, "smsAuthor")}
            margin="dense"
            helperText={fieldErrors.smsAuthor}
            error={fieldErrors.smsAuthor}
          />

          <FormControl fullWidth sx={{ marginTop: "8px", marginBottom: "4px" }}>
            <InputLabel id="demo-simple-select-label" required>
              Authentication Methods
            </InputLabel>
            <Select
              variant="filled"
              id="demo-simple-select"
              value={brandField?.authenticationMethods || ""}
              label="Authentication Methods"
              error={fieldErrors.authenticationMethods}
              onChange={(event) => handle.onChangeField(event.target.value, "authenticationMethods")}
            >
              {["password", "phone", "magicLink"].map((item) => (
                <MenuItem value={item} key={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            {fieldErrors.authenticationMethods && (
              <FormHelperText sx={{ color: "#d32f2f" }}>{fieldErrors.authenticationMethods}</FormHelperText>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handle.closeDialog} variant="outlined">
            {btnTextNo || "Cancel"}
          </Button>
          <Button
            onClick={handle.confirmation}
            disabled={!(brandField?.emailAuthor && brandField?.smsAuthor && brandField?.authenticationMethods)}
            variant="outlined"
          >
            {btnTextYes || "Okay"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BrandModel;
