import { employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL } = employmentTypes;

const { assetType, loanAmountBetween, employmentType, timeOfEmploymentGreaterThan, termIsLessThanOrEqualTo } = criteria;

function permanentEmployment(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, employmentType([FULL_TIME, PART_TIME]), timeOfEmploymentGreaterThan(5)],
  }));
}

function temporaryEmployment(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, employmentType([SELF_EMPLOYED, CASUAL, CONTRACT]), timeOfEmploymentGreaterThan(11)],
  }));
}

const silver = [...permanentEmployment(base()), ...temporaryEmployment(base())];

function base() {
  return [
    {
      name: "Silver",
      rate: 23.95,
      info: ["Adverse more than 12 months", "Ex-bankkrupt more than 1 day"],
      productType: "Adverse",
      criteria: [
        loanAmountBetween(8000, 75000),
        termIsLessThanOrEqualTo(72),
        assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan", "Trailered boat", "Motorbike"]),
      ],
    },
  ];
}
export default silver;
