import criteria from "../../../utils/criteria";

const { loanAmountBetween, creditScoreBetween } = criteria;

const base = [
  {
    name: `Diamond`,
    rate: [9.04, 12.39],
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 65000), creditScoreBetween(835, 1200)],
  },
  {
    name: `Platinum`,
    rate: [12.40, 14.49],
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 65000), creditScoreBetween(730, 834)],
  },
  {
    name: `Gold`,
    rate: [14.50, 17.58],
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 65000), creditScoreBetween(640, 729)],
  },
  {
    name: `Silver`,
    rate: [17.59, 20.99],
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 45000), creditScoreBetween(540, 639)],
  },
  {
    name: `Bronze`,
    rate: [21.00, 22.79],
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 45000), creditScoreBetween(500, 539)],
  },
];

export default base;
