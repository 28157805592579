export const employerIndustryListOptions = [
  "ACCOMMODATION AND FOOD SERVICES",
  "ADMINISTRATIVE AND SUPPORT SERVICES",
  "AGRICULTURE, FORESTRY AND FISHING",
  "ARTS AND RECREATION SERVICES",
  "CONSTRUCTION",
  "EDUCATION AND TRAINING",
  "ELECTRICITY, GAS, WATER AND WASTE SERVICES",
  "FINANCIAL AND INSURANCE SERVICES",
  "HEALTH CARE AND SOCIAL ASSISTANCE",
  "INFORMATION MEDIA AND TELECOMMUNICATIONS",
  "MANUFACTURING",
  "MINING",
  "OTHER SERVICES",
  "PROFESSIONAL, SCIENTIFIC AND TECHNICAL SERVICES",
  "PUBLIC ADMINISTRATION AND SAFETY",
  "RENTAL HIRING AND REAL ESTATE SERVICES",
  "RETAIL TRADE",
  "TRANSPORT, POSTAL AND WAREHOUSING",
  "WHOLESALE TRADE",
];

// export const employerIndustryListOptions = [
//   "AGRICULTURE FORESTRY AND FISHING",
//   "MINING",
//   "MANUFACTURING",
//   "CONSTRUCTION",
//   "WHOLESALE TRADE",
//   "RETAIL TRADE",
//   "TRANSPORT POSTAL AND WAREHOUSING",
//   "FINANCIAL AND INSURANCE SERVICES",
//   "RENTAL HIRING AND REAL ESTATE SERVICES",
//   "ACCOMMODATION AND FOOD SERVICES",
//   "ADMINISTRATIVE AND SUPPORT SERVICES",
//   "HEALTH CARE AND SOCIAL ASSISTANCE",
//   "OTHER SERVICES",
//   "ELECTRICITY GAS WATER AND WASTE SERVICES",
//   "INFORMATION MEDIA AND TELECOMMUNICATIONS",
//   "PROFESSIONAL SCIENTIFIC AND TECHNICAL SERVICES",
//   "PUBLIC ADMINISTRATION AND SAFETY",
//   "EDUCATION AND TRAINING",
//   "ARTS AND RECREATION SERVICES",
//   "Nursery Production (Under Cover)",
//   "Nursery Production (Outdoors)",
//   "Turf Growing",
//   "Floriculture Production (Under Cover)",
//   "Floriculture Production (Outdoors)",
//   "Mushroom Growing",
//   "Vegetable Growing (Under Cover)",
//   "Vegetable Growing (Outdoors)",
//   "Grape Growing",
//   "Kiwifruit Growing",
//   "Berry Fruit Growing",
//   "Apple and Pear Growing",
//   "Stone Fruit Growing",
//   "Citrus Fruit Growing",
//   "Olive Growing",
//   "Other Fruit and Tree Nut Growing",
//   "Sheep Farming (Specialised)",
//   "Beef Cattle Farming (Specialised)",
//   "Beef Cattle Feedlots (Specialised)",
//   "Sheep-Beef Cattle Farming",
//   "Grain-Sheep or Grain-Beef Cattle Farming",
//   "Rice Growing",
//   "Other Grain Growing",
//   "Sugar Cane Growing",
//   "Cotton Growing",
//   "Other Crop Growing n.e.c.",
//   "Dairy Cattle Farming",
//   "Automotive Electrical Services",
//   "Automotive Body, Paint and Interior Repair",
//   "Other Automotive Repair and Maintenance",
//   "Domestic Appliance Repair and Maintenance",
//   "Electronic (except Domestic Appliance) and Precision Equipment Repair and Maintenance",
//   "Other Machinery and Equipment Repair and Maintenance",
//   "Clothing and Footwear Repair",
//   "Other Repair and Maintenance n.e.c.",
//   "Hairdressing and Beauty Services",
//   "Diet and Weight Reduction Centre Operation",
//   "Poultry Farming (Meat)",
//   "Poultry Farming (Eggs)",
//   "Deer Farming",
//   "Horse Farming",
//   "Pig Farming",
//   "Beekeeping",
//   "Other Livestock Farming n.e.c.",
//   "Offshore Longline and Rack Aquaculture",
//   "Offshore Caged Aquaculture",
//   "Onshore Aquaculture",
//   "Forestry",
//   "Logging",
//   "Rock Lobster and Crab Potting",
//   "Prawn Fishing",
//   "Line Fishing",
//   "Fish Trawling, Seining and Netting",
//   "Other Fishing",
//   "Hunting and Trapping",
//   "Forestry Support Services",
//   "Cotton Ginning",
//   "Shearing Services",
//   "Other Agriculture and Fishing Support Services",
//   "Coal Mining",
//   "Oil and Gas Extraction",
//   "Iron Ore Mining",
//   "Bauxite Mining",
//   "Copper Ore Mining",
//   "Gold Ore Mining",
//   "Mineral Sand Mining",
//   "Nickel Ore Mining",
//   "Silver-Lead-Zinc Ore Mining",
//   "Other Metal Ore Mining",
//   "Gravel and Sand Quarrying",
//   "Other Construction Material Mining",
//   "Other Non-Metallic Mineral Mining and Quarrying",
//   "Petroleum Exploration",
//   "Mineral Exploration",
//   "Funeral, Crematorium and Cemetery Services",
//   "Other Mining Support Services",
//   "Laundry and Dry-Cleaning Services",
//   "Photographic Film Processing",
//   "Parking Services",
//   "Brothel Keeping and Prostitution Services",
//   "Meat Processing",
//   "Other Personal Services n.e.c.",
//   "Poultry Processing",
//   "Cured Meat and Smallgoods Manufacturing",
//   "Seafood Processing",
//   "Milk and Cream Processing",
//   "Ice Cream Manufacturing",
//   "Cheese and Other Dairy Product Manufacturing",
//   "Fruit and Vegetable Processing",
//   "Oil and Fat Manufacturing",
//   "Grain Mill Product Manufacturing",
//   "Cereal, Pasta and Baking Mix Manufacturing",
//   "Bread Manufacturing (Factory based)",
//   "Religious Services",
//   "Business and Professional Association Services",
//   "Cake and Pastry Manufacturing (Factory based)",
//   "Labour Association Services",
//   "Biscuit Manufacturing (Factory based)",
//   "Bakery Product Manufacturing (Non-factory based)",
//   "Other Interest Group Services n.e.c.",
//   "Private Households Employing Staff",
//   "Undifferentiated Goods-Producing Activities of Private Households for Own Use",
//   "Sugar Manufacturing",
//   "Undifferentiated Service-Producing Activities of Private Households for Own Use",
//   "Confectionery Manufacturing",
//   "Potato, Corn and Other Crisp Manufacturing",
//   "Prepared Animal and Bird Feed Manufacturing",
//   "Other Food Product Manufacturing n.e.c.",
//   "Museum Operation",
//   "Soft Drink, Cordial and Syrup Manufacturing",
//   "Zoological and Botanical Gardens Operation",
//   "Beer Manufacturing",
//   "Spirit Manufacturing",
//   "Nature Reserves and Conservation Parks Operation",
//   "Wine and Other Alcoholic Beverage Manufacturing",
//   "Performing Arts Operation",
//   "Cigarette and Tobacco Product Manufacturing",
//   "Creative Artists, Musicians, Writers and Performers",
//   "Performing Arts Venue Operation",
//   "Wool Scouring",
//   "Natural Textile Manufacturing",
//   "Synthetic Textile Manufacturing",
//   "Leather Tanning, Fur Dressing and Leather Product Manufacturing",
//   "Textile Floor Covering Manufacturing",
//   "Rope, Cordage and Twine Manufacturing",
//   "Cut and Sewn Textile Product Manufacturing",
//   "Textile Finishing and Other Textile Product Manufacturing",
//   "Knitted Product Manufacturing",
//   "Clothing Manufacturing",
//   "Footwear Manufacturing",
//   "Health and Fitness Centres and Gymnasia Operation",
//   "Sports and Physical Recreation Clubs and Sports Professionals",
//   "Sports and Physical Recreation Venues, Grounds and Facilities Operation",
//   "Sports and Physical Recreation Administrative Service",
//   "Horse and Dog Racing Administration and Track Operation",
//   "Other Horse and Dog Racing Activities",
//   "Amusement Parks and Centres Operation",
//   "Amusement and Other Recreational Activities n.e.c.",
//   "Casino Operation",
//   "Lottery Operation",
//   "Other Gambling Activities",
//   "Log Sawmilling",
//   "Wood Chipping",
//   "Timber Resawing and Dressing",
//   "Prefabricated Wooden Building Manufacturing",
//   "Wooden Structural Fitting and Component Manufacturing",
//   "Veneer and Plywood Manufacturing",
//   "Reconstituted Wood Product Manufacturing",
//   "Other Wood Product Manufacturing n.e.c.",
//   "Pulp, Paper and Paperboard Manufacturing",
//   "Corrugated Paperboard and Paperboard Container Manufacturing",
//   "Paper Bag Manufacturing",
//   "Paper Stationery Manufacturing",
//   "Sanitary Paper Product Manufacturing",
//   "Other Converted Paper Product Manufacturing",
//   "Printing",
//   "Printing Support Services",
//   "Reproduction of Recorded Media",
//   "Petroleum Refining and Petroleum Fuel Manufacturing",
//   "Other Petroleum and Coal Product Manufacturing",
//   "Industrial Gas Manufacturing",
//   "Basic Organic Chemical Manufacturing",
//   "Basic Inorganic Chemical Manufacturing",
//   "Synthetic Resin and Synthetic Rubber Manufacturing",
//   "Other Basic Polymer Manufacturing",
//   "Fertiliser Manufacturing",
//   "Pesticide Manufacturing",
//   "Human Pharmaceutical and Medicinal Product Manufacturing",
//   "Veterinary Pharmaceutical and Medicinal Product Manufacturing",
//   "Cleaning Compound Manufacturing",
//   "Cosmetic and Toiletry Preparation Manufacturing",
//   "Photographic Chemical Product Manufacturing",
//   "Explosive Manufacturing",
//   "Other Basic Chemical Product Manufacturing n.e.c.",
//   "Polymer Film and Sheet Packaging Material Manufacturing",
//   "Rigid and Semi-Rigid Polymer Product Manufacturing",
//   "Polymer Foam Product Manufacturing",
//   "Tyre Manufacturing",
//   "Adhesive Manufacturing",
//   "Paint and Coatings Manufacturing",
//   "Other Polymer Product Manufacturing",
//   "Natural Rubber Product Manufacturing",
//   "Glass and Glass Product Manufacturing",
//   "Clay Brick Manufacturing",
//   "Other Ceramic Product Manufacturing",
//   "Cement and Lime Manufacturing",
//   "Plaster Product Manufacturing",
//   "Ready-Mixed Concrete Manufacturing",
//   "Concrete Product Manufacturing",
//   "Other Non-Metallic Mineral Product Manufacturing",
//   "Iron Smelting and Steel Manufacturing",
//   "Iron and Steel Casting",
//   "Steel Pipe and Tube Manufacturing",
//   "Alumina Production",
//   "Aluminium Smelting",
//   "Copper, Silver, Lead and Zinc Smelting and Refining",
//   "Other Basic Non-Ferrous Metal Manufacturing",
//   "Non-Ferrous Metal Casting",
//   "Aluminium Rolling, Drawing, Extruding",
//   "Other Basic Non-Ferrous Metal Product Manufacturing",
//   "Iron and Steel Forging",
//   "Structural Steel Fabricating",
//   "Prefabricated Metal Building Manufacturing",
//   "Architectural Aluminium Product Manufacturing",
//   "Metal Roof and Guttering Manufacturing (except Aluminium)",
//   "Other Structural Metal Product Manufacturing",
//   "Boiler, Tank and Other Heavy Gauge Metal Container Manufacturing",
//   "Other Metal Container Manufacturing",
//   "Sheet Metal Product Manufacturing (except Metal Structural and Container Products)",
//   "Spring and Wire Product Manufacturing",
//   "Nut, Bolt, Screw and Rivet Manufacturing",
//   "Metal Coating and Finishing",
//   "Other Fabricated Metal Product Manufacturing n.e.c.",
//   "Motor Vehicle Manufacturing",
//   "Motor Vehicle Body and Trailer Manufacturing",
//   "Automotive Electrical Component Manufacturing",
//   "Other Motor Vehicle Parts Manufacturing",
//   "Shipbuilding and Repair Services",
//   "Boatbuilding and Repair Services",
//   "Railway Rolling Stock Manufacturing and Repair Services",
//   "Aircraft Manufacturing and Repair Services",
//   "Other Transport Equipment Manufacturing n.e.c.",
//   "Photographic, Optical and Ophthalmic Equipment Manufacturing",
//   "Medical and Surgical Equipment Manufacturing",
//   "Other Professional and Scientific Equipment Manufacturing",
//   "Computer and Electronic Office Equipment Manufacturing",
//   "Communications Equipment Manufacturing",
//   "Other Electronic Equipment Manufacturing",
//   "Electric Cable and Wire Manufacturing",
//   "Electric Lighting Equipment Manufacturing",
//   "Other Electrical Equipment Manufacturing",
//   "Whiteware Appliance Manufacturing",
//   "Other Domestic Appliance Manufacturing",
//   "Pump and Compressor Manufacturing",
//   "Fixed Space Heating, Cooling and Ventilation Equipment Manufacturing",
//   "Agricultural Machinery and Equipment Manufacturing",
//   "Mining and Construction Machinery Manufacturing",
//   "Machine Tool and Parts Manufacturing",
//   "Other Specialised Machinery and Equipment Manufacturing",
//   "Lifting and Material Handling Equipment Manufacturing",
//   "Other Machinery and Equipment Manufacturing n.e.c.",
//   "Wooden Furniture and Upholstered Seat Manufacturing",
//   "Metal Furniture Manufacturing",
//   "Mattress Manufacturing",
//   "Other Furniture Manufacturing",
//   "Jewellery and Silverware Manufacturing",
//   "Toy, Sporting and Recreational Product Manufacturing",
//   "Other Manufacturing n.e.c.",
//   "Fossil Fuel Electricity Generation",
//   "Hydro-Electricity Generation",
//   "Other Electricity Generation",
//   "Electricity Transmission",
//   "Electricity Distribution",
//   "On Selling Electricity and Electricity Market Operation",
//   "Gas Supply",
//   "Water Supply",
//   "Sewerage and Drainage Services",
//   "Solid Waste Collection Services",
//   "Other Waste Collection Services",
//   "Waste Treatment and Disposal Services",
//   "Waste Remediation and Materials Recovery Services",
//   "House Construction",
//   "Other Residential Building Construction",
//   "Non-Residential Building Construction",
//   "Road and Bridge Construction",
//   "Other Heavy and Civil Engineering Construction",
//   "Land Development and Subdivision",
//   "Site Preparation Services",
//   "Concreting Services",
//   "Bricklaying Services",
//   "Roofing Services",
//   "Structural Steel Erection Services",
//   "Plumbing Services",
//   "Electrical Services",
//   "Air Conditioning and Heating Services",
//   "Fire and Security Alarm Installation Services",
//   "Other Building Installation Services",
//   "Plastering and Ceiling Services",
//   "Carpentry Services",
//   "Tiling and Carpeting Services",
//   "Painting and Decorating Services",
//   "Glazing Services",
//   "Landscape Construction Services",
//   "Hire of Construction Machinery with Operator",
//   "Other Construction Services n.e.c.",
//   "Wool Wholesaling",
//   "Cereal Grain Wholesaling",
//   "Other Agricultural Product Wholesaling",
//   "Petroleum Product Wholesaling",
//   "Metal and Mineral Wholesaling",
//   "Industrial and Agricultural Chemical Product Wholesaling",
//   "Timber Wholesaling",
//   "Plumbing Goods Wholesaling",
//   "Other Hardware Goods Wholesaling",
//   "Agricultural and Construction Machinery Wholesaling",
//   "Other Specialised Industrial Machinery and Equipment Wholesaling",
//   "Professional and Scientific Goods Wholesaling",
//   "Computer and Computer Peripheral Wholesaling",
//   "Telecommunication Goods Wholesaling",
//   "Other Electrical and Electronic Goods Wholesaling",
//   "Other Machinery and Equipment Wholesaling n.e.c.",
//   "Car Wholesaling",
//   "Commercial Vehicle Wholesaling",
//   "Trailer and Other Motor Vehicle Wholesaling",
//   "Motor Vehicle New Parts Wholesaling",
//   "Motor Vehicle Dismantling and Used Parts Wholesaling",
//   "General Line Grocery Wholesali",
//   "Meat, Poultry and Smallgoods Wholesaling",
//   "Dairy Produce Wholesaling",
//   "Fish and Seafood Wholesaling",
//   "Fruit and Vegetable Wholesaling",
//   "Liquor and Tobacco Product Wholesaling",
//   "Other Grocery Wholesaling",
//   "Textile Product Wholesaling",
//   "Clothing and Footwear Wholesaling",
//   "Pharmaceutical and Toiletry Goods Wholesaling",
//   "Furniture and Floor Covering Wholesaling",
//   "Jewellery and Watch Wholesaling",
//   "Kitchen and Diningware Wholesaling",
//   "Toy and Sporting Goods Wholesaling",
//   "Book and Magazine Wholesaling",
//   "Paper Product Wholesaling",
//   "Other Goods Wholesaling n.e.c.",
//   "Commission-Based Wholesaling",
//   "Car Retailing",
//   "Motor Cycle Retailing",
//   "Trailer and Other Motor Vehicle Retailing",
//   "Motor Vehicle Parts Retailing",
//   "Tyre Retailing",
//   "Fuel Retailing",
//   "Fresh Meat, Fish and Poultry Retailing",
//   "Fruit and Vegetable Retailing",
//   "Liquor Retailing",
//   "Other Specialised Food Retailing",
//   "Furniture Retailing",
//   "Floor Coverings Retailing",
//   "Houseware Retailing",
//   "Manchester and Other Textile Goods Retailing",
//   "Electrical, Electronic and Gas Appliance Retailing",
//   "Computer and Computer Peripheral Retailing",
//   "Other Electrical and Electronic Goods Retailing",
//   "Hardware and Building Supplies Retailing",
//   "Garden Supplies Retailing",
//   "Sport and Camping Equipment Retailing",
//   "Entertainment Media Retailing",
//   "Toy and Game Retailing",
//   "Newspaper and Book Retailing",
//   "Marine Equipment Retailing",
//   "Clothing Retailing",
//   "Footwear Retailing",
//   "Watch and Jewellery Retailing",
//   "Other Personal Accessory Retailing",
//   "Department Stores",
//   "Pharmaceutical, Cosmetic and Toiletry Goods Retailing",
//   "Stationery Goods Retailing",
//   "Antique and Used Goods Retailing",
//   "Flower Retailing",
//   "Other Store-Based Retailing n.e.c.",
//   "Non-Store Retailing",
//   "Retail Commission-Based Buying and/or Selling",
//   "Accommodation",
//   "Cafes and Restaurants",
//   "Takeaway Food Services",
//   "Catering Services",
//   "Pubs, Taverns and Bars",
//   "Clubs (Hospitality)",
//   "Road Freight Transport",
//   "Interurban and Rural Bus Transport",
//   "Urban Bus Transport (Including Tramway)",
//   "Taxi and Other Road Transport",
//   "Rail Freight Transport",
//   "Rail Passenger Transport",
//   "Water Freight Transport",
//   "Water Passenger Transport",
//   "Air and Space Transport",
//   "Scenic and Sightseeing Transport",
//   "Pipeline Transport",
//   "Other Transport n.e.c.",
//   "Postal Services",
//   "Courier Pick-up and Delivery Services",
//   "Stevedoring Services",
//   "Port and Water Transport Terminal Operations",
//   "Other Water Transport Support Services",
//   "Airport Operations and Other Air Transport Support Services",
//   "Customs Agency Services",
//   "Freight Forwarding Services",
//   "Other Transport Support Services n.e.c.",
//   "Grain Storage Services",
//   "Other Warehousing and Storage Services",
//   "Newspaper Publishing",
//   "Magazine and Other Periodical Publishing",
//   "Book Publishing",
//   "Directory and Mailing List Publishing",
//   "Other Publishing (except Software, Music and Internet)",
//   "Software Publishing",
//   "Motion Picture and Video Production",
//   "Motion Picture and Video Distribution",
//   "Motion Picture Exhibition",
//   "Post-production Services and Other Motion Picture and Video Activities",
//   "Music Publishing",
//   "Music and Other Sound Recording Activities",
//   "Radio Broadcasting",
//   "Free-to-Air Television Broadcasting",
//   "Cable and Other Subscription Broadcasting",
//   "Internet Publishing and Broadcasting",
//   "Wired Telecommunications Network Operation",
//   "Other Telecommunications Network Operation",
//   "Other Telecommunications Services",
//   "Internet Service Providers and Web Search Portals",
//   "Data Processing and Web Hosting Services",
//   "Electronic Information Storage Services",
//   "Libraries and Archives",
//   "Other Information Services",
//   "Central Banking",
//   "Banking",
//   "Building Society Operation",
//   "Credit Union Operation",
//   "Other Depository Financial Intermediation",
//   "Non-Depository Financing",
//   "Financial Asset Investing",
//   "Life Insurance",
//   "Health Insurance",
//   "General Insurance",
//   "Superannuation Funds",
//   "Financial Asset Broking Services",
//   "Other Auxiliary Finance and Investment Services",
//   "Auxiliary Insurance Services",
//   "Passenger Car Rental and Hiring",
//   "Other Motor Vehicle and Transport Equipment Rental and Hiring",
//   "Farm Animal and Bloodstock Leasing",
//   "Heavy Machinery and Scaffolding Rental and Hiring",
//   "Video and Other Electronic Media Rental and Hiring",
//   "Other Goods and Equipment Rental and Hiring n.e.c.",
//   "Non-Financial Intangible Assets (Except Copyrights) Leasing",
//   "Residential Property Operators",
//   "Non-Residential Property Operators",
//   "Real Estate Services",
//   "Scientific Research Services",
//   "Architectural Services",
//   "Surveying and Mapping Services",
//   "Engineering Design and Engineering Consulting Services",
//   "Other Specialised Design Services",
//   "Scientific Testing and Analysis Services",
//   "Legal Services",
//   "Accounting Services",
//   "Advertising Services",
//   "Market Research and Statistical Services",
//   "Corporate Head Office Management Services",
//   "Management Advice and Related Consulting Services",
//   "Veterinary Services",
//   "Professional Photographic Services",
//   "Other Professional, Scientific and Technical Services n.e.c.",
//   "Computer System Design and Related Services",
//   "Employment Placement and Recruitment Services",
//   "Labour Supply Services",
//   "Travel Agency and Tour Arrangement Services",
//   "Office Administrative Services",
//   "Document Preparation Services",
//   "Credit Reporting and Debt Collection Services",
//   "Call Centre Operation",
//   "Other Administrative Services n.e.c.",
//   "Building and Other Industrial Cleaning Services",
//   "Building Pest Control Services",
//   "Gardening Services",
//   "Packaging Services",
//   "Central Government Administration",
//   "State Government Administration",
//   "Local Government Administration",
//   "Justice",
//   "Domestic Government Representation",
//   "Foreign Government Representation",
//   "Defence",
//   "Police Services",
//   "Investigation and Security Services",
//   "Fire Protection and Other Emergency Services",
//   "Correctional and Detention Services",
//   "Other Public Order and Safety Services",
//   "Regulatory Services",
//   "Preschool Education",
//   "Primary Education",
//   "Secondary Education",
//   "Combined Primary and Secondary Education",
//   "Special School Education",
//   "Technical and Vocational Education and Training",
//   "Higher Education",
//   "Sports and Physical Recreation Instruction",
//   "Arts Education",
//   "Adult, Community and Other Education n.e.c.",
//   "Educational Support Services",
//   "Hospitals (Except Psychiatric Hospitals)",
//   "Psychiatric Hospitals",
//   "General Practice Medical Services",
//   "Specialist Medical Services",
//   "Pathology and Diagnostic Imaging Services",
//   "Dental Services",
//   "Optometry and Optical Dispensing",
//   "Physiotherapy Services",
//   "Chiropractic and Osteopathic Services",
//   "Other Allied Health Services",
//   "Ambulance Services",
//   "Other Health Care Services n.e.c.",
//   "Aged Care Residential Services",
//   "Other Residential Care Services",
//   "Child Care Services",
//   "Other Social Assistance Services",
// ]
