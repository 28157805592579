import { livingArrangementsTypes, employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } = livingArrangementsTypes;

const { BENEFITS } = employmentTypes;

const { termIsBetween, assetType, loanAmountBetween, creditScoreGreaterThan, employmentType, livingArrangements } =
  criteria;

const benefits = [
  {
    name: "Auto",
    rate: 24.5,
    productType: "Standard",
    criteria: [
      loanAmountBetween(5000, 25000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
      termIsBetween(36, 60),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([BENEFITS]),
      creditScoreGreaterThan(0),
    ],
  },
];
export default benefits;
