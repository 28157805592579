import { Stack } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { green, red } from "@mui/material/colors";
// lime, orange,

export default function setTicksAndCrosses(text) {
  if (text === "Greatly Increases Risk" || text === "SIGNIFICANTLYINCREASES")
    return (
      <Stack direction="row">
        <CancelIcon sx={{ color: red[900], fontSize: 16 }} />
        <CancelIcon sx={{ color: red[900], fontSize: 16 }} />
        <CancelIcon sx={{ color: red[900], fontSize: 16 }} />
      </Stack>
    );
  if (text === "Moderately Increases Risk" || text === "MODERATELYINCREASES")
    return (
      <Stack direction="row">
        <CancelIcon sx={{ color: red[900], fontSize: 16 }} />
        <CancelIcon sx={{ color: red[900], fontSize: 16 }} />
      </Stack>
    );
  if (text === "Marginally Increases Risk" || text === "MARGINALLYINCREASES")
    return (
      <Stack direction="row">
        <CancelIcon sx={{ color: red[900], fontSize: 16 }} />
      </Stack>
    );

  if (text === "Greatly Decreases Risk" || text === "SIGNIFICANTLYDECREASES")
    return (
      <Stack direction="row">
        <CheckCircleIcon sx={{ color: green[900], fontSize: 16 }} />
        <CheckCircleIcon sx={{ color: green[900], fontSize: 16 }} />
        <CheckCircleIcon sx={{ color: green[900], fontSize: 16 }} />
      </Stack>
    );
  if (text === "Moderately Decreases Risk" || text === "MODERATELYDECREASES")
    return (
      <Stack direction="row">
        <CheckCircleIcon sx={{ color: green[900], fontSize: 16 }} />
        <CheckCircleIcon sx={{ color: green[900], fontSize: 16 }} />
      </Stack>
    );
  if (text === "Marginally Decreases Risk" || text === "MARGINALLYDECREASES")
    return (
      <Stack direction="row">
        <CheckCircleIcon sx={{ color: green[900], fontSize: 16 }} />
      </Stack>
    );
}
