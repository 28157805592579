import platinum from "./platinum";
import gold from "./gold";
import silver from "./silver";
import bronze from "./bronze";
import economy from "./economy";
import { typeOfSaleList, employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL, BENEFITS } = employmentTypes;

const { typeOfSale, assetValueBetween, loanAmountBetween, employmentType } = criteria;

const financeOne = {
  date: "05 May 2024",
  lender: "Finance One",
  type: "comsumer",
  logoURL: "https://assets.financeable.com.au/lender-logos/Finance-One.gif",
  actions: ["upload"],
  lenderCredentials: [
    {
      label: "Broker ID",
      attribute: "brokerId",
    },
  ],
  requiredFields: {
    asset: ["assetType", "typeOfSale", "ageOfAsset", "glassesMake", "glassesModel", "glassesVariant", "assetValue", "condition"],
    customer: [
      "firstName",
      "lastName",
      "dateOfBirth",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      //   "addresses[0].street",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "IDNumber",
      "IDCardNumber",
      "IDExpiryDate",
      "customerProfile.livingArrangements",
      "customerProfile.numberOfDependants",
      "customerProfile.residencyStatus",
      "customerProfile.maritalStatus",
      "customerAssets.[0].assetType",
      "customerAssets.[0].value",
      "customerAssets.[0].description",
      "liabilities.[0].liabilitiesType",
      "liabilities.[0].outstandingAmount",
      "liabilities.[0].limit",
      "liabilities.[0].financier",
      "liabilities.[0].repaymentFrequency",
      "liabilities.[0].repaymentAmount",
      "liabilities.[0].description",
      "income.[0].incomeType",
      "income.[0].amount",
      "income.[0].frequency",
      "expenses.[0].expensesType",
      "expenses.[0].amount",
      "expenses.[0].frequency",
      "employers.[0].entityName",
      "employers.[0].status",
      "employers.[0].timeOfEmployment",
      "employers.[0].occupation",
      "employers.[0].manager",
      "employers.[0].phoneNumber",
      "employers.[0].industry",
      "employers.[0].address.fullAddress",
      "employers.[0].address.city",
      "employers.[0].address.state",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Establishment fee",
      value: 695,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        typeOfSale(typeOfSaleList.DEALER_SALE),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CASUAL, CONTRACT]),
      ],
    },
    {
      name: "Establishment fee",
      value: 995,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.DEALER_SALE), employmentType([BENEFITS]), loanAmountBetween(5000, 8000)],
    },
    {
      name: "Establishment fee",
      value: 1495,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.DEALER_SALE), employmentType([BENEFITS]), loanAmountBetween(8000, 10000)],
    },
    {
      name: "Private sale",
      value: 995,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.PRIVATE_SALE)],
    },
    {
      name: "Origination fee",
      value: 900,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        loanAmountBetween(5000, 14000),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      ],
    },
    {
      name: "Origination fee",
      value: 1000,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        loanAmountBetween(14000, 75000),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      ],
    },
    {
      name: "Origination fee",
      value: 500,
      capitalised: true,
      frequency: "upfront",
      criteria: [employmentType([BENEFITS])],
    },
    {
      name: "Monthly account fee",
      value: 27.0,
      capitalised: false,
      frequency: "monthly",
    },
  ],
  brokerage: [
    {
      value: 5,
      max: 5,
      valueType: "percentage",
      criteria: [assetValueBetween(0, 1000000)],
    },
  ],
  loading: [],
  info: [
    `Defaults: Unpaid vehicle finance defaults >6mths (NO unpaid in <6months)`,
    `Unpaid court judgements >6mths`,
    `Paid court judgements to a financier >6mths`,
  ],
  productTiers: [...platinum, ...gold, ...silver, ...bronze, ...economy],
};
export default financeOne;
