import { livingArrangementsTypes, employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } = livingArrangementsTypes;

const { SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL } = employmentTypes;

const {
  hasDeposit,
  assetType,
  ageOfAssetBetween,
  ageOfAssetLessThan,
  ageOfAssetGreaterThan,
  loanAmountBetween,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
  termIsBetween,
} = criteria;

const base = [
  {
    name: "Platinum - Motor Vehicle New/Demo",
    rate: 12.99,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      hasDeposit(20),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([FULL_TIME, PART_TIME]),
      timeOfEmploymentGreaterThan(18),
      creditScoreGreaterThan(650),
    ],
  },
  {
    name: "Platinum - Motor Vehicle <47 months",
    rate: 12.99,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(12, 47),
      hasDeposit(20),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([FULL_TIME, PART_TIME]),
      timeOfEmploymentGreaterThan(18),
      creditScoreGreaterThan(650),
    ],
  },
  {
    name: "Platinum - Motor Vehicle 48-95 months",
    rate: 13.99,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(48, 95),
      hasDeposit(20),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([FULL_TIME, PART_TIME]),
      timeOfEmploymentGreaterThan(18),
      creditScoreGreaterThan(650),
    ],
  },
  {
    name: "Platinum - Motor Vehicle >95 months",
    rate: 14.99,
    productType: "Standard",
    criteria: [
      ageOfAssetGreaterThan(95),
      hasDeposit(20),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([FULL_TIME, PART_TIME]),
      timeOfEmploymentGreaterThan(18),
      creditScoreGreaterThan(650),
    ],
  },

  {
    name: "Platinum - Motor Vehicle New/Demo",
    rate: 12.99,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      hasDeposit(20),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(24),
      creditScoreGreaterThan(650),
    ],
  },
  {
    name: "Platinum - Motor Vehicle <47 months",
    rate: 12.99,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(12, 47),
      hasDeposit(20),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(24),
      creditScoreGreaterThan(650),
    ],
  },
  {
    name: "Platinum - Motor Vehicle 48-95 months",
    rate: 13.99,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(48, 95),
      hasDeposit(20),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(24),
      creditScoreGreaterThan(650),
    ],
  },
  {
    name: "Platinum - Motor Vehicle 48-95 months",
    rate: 14.99,
    productType: "Standard",
    criteria: [
      ageOfAssetGreaterThan(95),
      hasDeposit(20),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(24),
      creditScoreGreaterThan(650),
    ],
  },

  {
    name: "Platinum - Motor Vehicle New/Demo",
    rate: 13.99,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      hasDeposit(10),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([FULL_TIME, PART_TIME]),
      timeOfEmploymentGreaterThan(18),
      creditScoreGreaterThan(650),
    ],
  },
  {
    name: "Platinum - Motor Vehicle <47 months",
    rate: 13.99,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(12, 47),
      hasDeposit(10),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([FULL_TIME, PART_TIME]),
      timeOfEmploymentGreaterThan(18),
      creditScoreGreaterThan(650),
    ],
  },
  {
    name: "Platinum - Motor Vehicle 48-95 months",
    rate: 14.99,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(48, 95),
      hasDeposit(10),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([FULL_TIME, PART_TIME]),
      timeOfEmploymentGreaterThan(18),
      creditScoreGreaterThan(650),
    ],
  },
  {
    name: "Platinum - Motor Vehicle >95 months",
    rate: 15.99,
    productType: "Standard",
    criteria: [
      ageOfAssetGreaterThan(95),
      hasDeposit(10),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([FULL_TIME, PART_TIME]),
      timeOfEmploymentGreaterThan(18),
      creditScoreGreaterThan(650),
    ],
  },

  {
    name: "Platinum - Motor Vehicle New/Demo",
    rate: 13.99,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      hasDeposit(10),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(24),
      creditScoreGreaterThan(650),
    ],
  },
  {
    name: "Platinum - Motor Vehicle <47 months",
    rate: 13.99,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(12, 47),
      hasDeposit(10),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(24),
      creditScoreGreaterThan(650),
    ],
  },
  {
    name: "Platinum - Motor Vehicle 48-95 months",
    rate: 14.99,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(48, 95),
      hasDeposit(10),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(24),
      creditScoreGreaterThan(650),
    ],
  },
  {
    name: "Platinum - Motor Vehicle 48-95 months",
    rate: 15.99,
    productType: "Standard",
    criteria: [
      ageOfAssetGreaterThan(95),
      hasDeposit(10),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(24),
      creditScoreGreaterThan(650),
    ],
  },

  {
    name: "Platinum - Motor Vehicle New/Demo",
    rate: 14.99,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([FULL_TIME, PART_TIME]),
      timeOfEmploymentGreaterThan(18),
      creditScoreGreaterThan(650),
    ],
  },
  {
    name: "Platinum - Motor Vehicle <47 months",
    rate: 14.99,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(12, 47),

      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([FULL_TIME, PART_TIME]),
      timeOfEmploymentGreaterThan(18),
      creditScoreGreaterThan(650),
    ],
  },
  {
    name: "Platinum - Motor Vehicle 48-95 months",
    rate: 15.99,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(48, 95),

      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([FULL_TIME, PART_TIME]),
      timeOfEmploymentGreaterThan(18),
      creditScoreGreaterThan(650),
    ],
  },
  {
    name: "Platinum - Motor Vehicle >95 months",
    rate: 16.99,
    productType: "Standard",
    criteria: [
      ageOfAssetGreaterThan(95),

      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([FULL_TIME, PART_TIME]),
      timeOfEmploymentGreaterThan(18),
      creditScoreGreaterThan(650),
    ],
  },

  {
    name: "Platinum - Motor Vehicle New/Demo",
    rate: 14.99,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),

      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(24),
      creditScoreGreaterThan(650),
    ],
  },
  {
    name: "Platinum - Motor Vehicle <47 months",
    rate: 14.99,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(12, 47),

      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(24),
      creditScoreGreaterThan(650),
    ],
  },
  {
    name: "Platinum - Motor Vehicle 48-95 months",
    rate: 15.99,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(48, 95),

      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(24),
      creditScoreGreaterThan(650),
    ],
  },
  {
    name: "Platinum - Motor Vehicle 48-95 months",
    rate: 16.99,
    productType: "Standard",
    criteria: [
      ageOfAssetGreaterThan(95),

      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(24),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      creditScoreGreaterThan(650),
    ],
  },
];

const lowLoanAmount = base.map((product) => ({
  ...product,
  criteria: [
    ...product.criteria,
    loanAmountBetween(5000, 12500),
    termIsBetween(24, 48),
    assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"]),
  ],
}));

const highLoanAmountBikes = base.map((product) => ({
  ...product,
  name: "Platinum - Motorbike",
  criteria: [...product.criteria, loanAmountBetween(12500, 25000), termIsBetween(36, 60), assetType(["Motorbike"])],
}));

const highLoanAmountMotor = base.map((product) => ({
  ...product,
  criteria: [
    ...product.criteria,
    loanAmountBetween(12500, 45000),
    termIsBetween(36, 60),
    assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
  ],
}));

const platinum = [...lowLoanAmount, ...highLoanAmountBikes, ...highLoanAmountMotor];
export default platinum;
