import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";
import { MenuItem, TextField } from "@mui/material";
import { employmentTypes } from "../../../constants";
import { customersSelector, saveEmployer } from "../../../store/slices/applicationFormSlice";
import { saveValue, userSelector } from "../../../store/slices/userSlice";

const EmploymentType = ({ disabled }) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector)
  const { applicationId } = useParams();
  const customers = useSelector(customersSelector);


  function handleUpdateEmployment(event) {
    dispatch(
      saveEmployer({
        data: {
          employerId: customers[0]?.employers[0]?._id,
          customer: customers[0]?._id,
          employmentType: event.target.value
        }
      })
    );
    if (user?.status === "new" && event.target.value === "Full-time") {
      dispatch(saveValue({ walkthroughIndex: 8 }))
      setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 400)
    }
  };

  return (
    <TextField
      fullWidth
      className="ninth-step"
      style={{ background: "#ffffff" }}
      select
      variant="filled"
      value={customers?.[0]?.employers[0]?.employmentType || ""}
      label="Employment type"
      size="small"
      onChange={handleUpdateEmployment}
      disabled={disabled}
    >
      {Object.keys(employmentTypes).map((type, i) => (
        <MenuItem
          key={nanoid()}
          value={employmentTypes[type]}
        >
          {employmentTypes[type]}
        </MenuItem>
      ))
      }
    </TextField >
  )
}

export default EmploymentType