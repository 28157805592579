import criteria from "../../utils/criteria";
import { typeOfSaleList } from "../../constants";

const { PRIVATE_SALE, DEALER_SALE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE, BALLOON_RESIDUAL_REFINANCE } =
  typeOfSaleList;

const {
  livingArrangements,
  nonGST,
  hasDeposit,
  gstRegistrationBetween,
  gstRegistrationGreaterThan,
  termIsGreaterThan,
  assetValueBetween,
  assetType,
  ageOfAssetBetween,
  ageOfAssetLessThan,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  creditScoreGreaterThan,
  typeOfSale,
  brokerageIsEqualTo,
} = criteria;

const plenti = {
  date: "1 March 2024",
  lender: "Plenti",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos%2Fplenti-logo.gif",
  actions: ["upload"],
  lenderCredentials: [
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
  ],
  requiredFields: {
    entity: [
      "abn",
      "entityName",
      "entityType",
      "industryType",
      "turnover",
      "addresses[0].fullAddress",
      // "addresses[0].streetAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
    ],
    customer: [
      "customerTitle",
      "firstName",
      "lastName",
      "dateOfBirth",
      "IDNumber",
      "IDCardNumber",
      "IDExpiryDate",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      // "addresses[1].fullAddress", // What if user not have previous address ?
      // "addresses[1].city",
      // "addresses[1].state",
      // "addresses[1].postCode",
      // "addresses[1].monthsAt",
      // "addresses[1].yearsAt",
      "customerProfile.livingArrangements",
      "customerProfile.numberOfDependants",
      "customerProfile.residencyStatus",
      "customerProfile.maritalStatus",
      "customerAssets.[0].assetType",
      "customerAssets.[0].value",
      "customerAssets.[0].description",
      "liabilities.[0].liabilitiesType",
      "liabilities.[0].outstandingAmount",
      "liabilities.[0].limit",
      "liabilities.[0].financier",
      "liabilities.[0].repaymentFrequency",
      "liabilities.[0].repaymentAmount",
      "liabilities.[0].description",
      // "emails[0].address",
      // "phoneNumbers[0].number",
    ],
    loanDetails: ["rate"],
    asset: ["assetType", "typeOfSale", "ageOfAsset", "glassesMake", "glassesModel", "glassesVariant", "assetValue", "condition"],
  },
  fees: [
    {
      name: "Authorised supplier",
      value: 499,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(DEALER_SALE)],
    },
    {
      name: "Private sale",
      value: 599,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(PRIVATE_SALE)],
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 8,
      valueType: "percentage",
      criteria: [assetValueBetween(5000, 1000000)],
    },
  ],
  loading: [
    {
      name: "EV discount",
      value: -0.5,
      valueType: "percentage",
      criteria: [assetType(["Electric Motor Vehicle (<4.5 tonnes)"])],
    },
    {
      name: "Term >60",
      value: 0.5,
      valueType: "percentage",
      criteria: [termIsGreaterThan(60)],
    },
    {
      name: "Brokerage 5%",
      value: 0.5,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(5)],
    },
    {
      name: "Brokerage 6%",
      value: 1,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(6)],
    },
    {
      name: "Brokerage 7%",
      value: 1.5,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(7)],
    },
    {
      name: "Brokerage 8%",
      value: 2,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(8)],
    },
  ],
  info: [
    `
      Property equity amount is greater than the amount being borrowed
    `,
    `
      Non asset backed, the same amount as 
      current repayments of all directors’ 
      personal and car loans, that can be used for 
      comparable credit must been running for 12 months or,
      that have been paid out in the last 6 months
    `,
  ],
  productTiers: [
    {
      name: "Auto",
      rate: 8.19,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 125000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(13),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        typeOfSale([DEALER_SALE, SALE_BUYBACK_EQUITY, BALLOON_RESIDUAL_REFINANCE, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 8.19,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 125000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(12, 48),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        typeOfSale([DEALER_SALE, SALE_BUYBACK_EQUITY, BALLOON_RESIDUAL_REFINANCE, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 8.19,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(125000, 150000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(12),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType(["Commercial (Small to Medium) Vehicles <12T"]),
        typeOfSale([DEALER_SALE, SALE_BUYBACK_EQUITY, BALLOON_RESIDUAL_REFINANCE, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 8.19,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(125000, 150000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(12, 48),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType(["Commercial (Small to Medium) Vehicles <12T"]),
        typeOfSale([DEALER_SALE, SALE_BUYBACK_EQUITY, BALLOON_RESIDUAL_REFINANCE, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 9.69,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(125000, 150000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(48, 96),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType(["Commercial (Small to Medium) Vehicles <12T"]),
        typeOfSale([DEALER_SALE, SALE_BUYBACK_EQUITY, BALLOON_RESIDUAL_REFINANCE, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 9.84,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(125000, 150000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(96, 144),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType(["Commercial (Small to Medium) Vehicles <12T"]),
        typeOfSale([DEALER_SALE, SALE_BUYBACK_EQUITY, BALLOON_RESIDUAL_REFINANCE, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 9.69,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 125000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(48, 96),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        typeOfSale([DEALER_SALE, SALE_BUYBACK_EQUITY, BALLOON_RESIDUAL_REFINANCE, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 9.84,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 125000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(96, 144),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        typeOfSale([DEALER_SALE, SALE_BUYBACK_EQUITY, BALLOON_RESIDUAL_REFINANCE, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(550),
      ],
    },

    {
      name: "Auto",
      rate: 8.19,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(13),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        typeOfSale([PRIVATE_SALE]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 8.19,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(12, 48),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        typeOfSale([PRIVATE_SALE]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 9.69,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(48, 96),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        typeOfSale([PRIVATE_SALE]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 9.84,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(96, 144),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        typeOfSale([PRIVATE_SALE]),
        creditScoreGreaterThan(550),
      ],
    },

    {
      name: "Auto",
      rate: 8.69,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationBetween(12, 24),
        ageOfAssetLessThan(13),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 8.69,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationBetween(12, 24),
        ageOfAssetBetween(12, 48),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 10.09,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationBetween(12, 24),
        ageOfAssetBetween(48, 96),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 10.24,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationBetween(12, 24),
        ageOfAssetBetween(96, 144),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },

    {
      name: "Auto",
      rate: 9.19,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 40000),
        timeInBusinessGreaterThan(12),
        gstRegistrationBetween(0, 12),
        ageOfAssetLessThan(13),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 9.19,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 40000),
        timeInBusinessGreaterThan(12),
        ageOfAssetBetween(12, 48),
        gstRegistrationBetween(0, 12),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 10.59,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 40000),
        timeInBusinessGreaterThan(12),
        gstRegistrationBetween(0, 12),
        ageOfAssetBetween(48, 96),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 10.74,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 40000),
        timeInBusinessGreaterThan(12),
        gstRegistrationBetween(0, 12),
        ageOfAssetBetween(96, 144),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },

    {
      name: "Auto",
      rate: 9.19,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 30000),
        timeInBusinessGreaterThan(12),
        nonGST,
        ageOfAssetLessThan(13),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 9.19,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 30000),
        timeInBusinessGreaterThan(12),
        nonGST,
        ageOfAssetBetween(12, 48),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 10.59,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 30000),
        timeInBusinessGreaterThan(24),
        nonGST,
        ageOfAssetBetween(48, 96),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 10.74,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 30000),
        timeInBusinessGreaterThan(12),
        nonGST,
        ageOfAssetBetween(96, 144),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },

    {
      name: "Auto",
      rate: 9.39,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 125000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(13),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 9.39,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 125000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(12, 48),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 10.54,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 125000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(48, 96),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 10.69,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 125000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(96, 144),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },

    {
      name: "Auto",
      rate: 9.89,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationBetween(12, 24),
        ageOfAssetLessThan(13),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 9.89,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationBetween(12, 24),
        ageOfAssetBetween(12, 48),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 11.04,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationBetween(12, 24),
        ageOfAssetBetween(48, 96),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 11.19,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationBetween(12, 24),
        ageOfAssetBetween(96, 144),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },

    {
      name: "Auto",
      rate: 10.39,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 40000),
        timeInBusinessGreaterThan(24),
        gstRegistrationBetween(0, 12),
        ageOfAssetLessThan(13),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 10.39,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 40000),
        timeInBusinessGreaterThan(24),
        gstRegistrationBetween(0, 12),
        ageOfAssetBetween(12, 48),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 11.54,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 40000),
        timeInBusinessGreaterThan(24),
        gstRegistrationBetween(0, 12),
        ageOfAssetBetween(48, 96),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 11.69,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 40000),
        timeInBusinessGreaterThan(24),
        gstRegistrationBetween(0, 12),
        ageOfAssetBetween(96, 144),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },

    {
      name: "Auto",
      rate: 10.39,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 30000),
        timeInBusinessGreaterThan(24),
        nonGST,
        ageOfAssetLessThan(13),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 10.39,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 30000),
        timeInBusinessGreaterThan(24),
        nonGST,
        ageOfAssetBetween(12, 48),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 11.54,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 30000),
        timeInBusinessGreaterThan(24),
        nonGST,
        ageOfAssetBetween(48, 96),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "Auto",
      rate: 11.69,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 30000),
        timeInBusinessGreaterThan(24),
        nonGST,
        ageOfAssetBetween(96, 144),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(550),
      ],
    },
  ],
};
export default plenti;
