import { livingArrangementsTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } = livingArrangementsTypes;

const {
  assetType,
  loanAmountBetween,
  creditScoreGreaterThan,
  livingArrangements,
  creditScoreBetween,
  ageOfAssetBetween,
} = criteria;

const base = [
  {
    name: `Property Owner - Motor Vehicle New/Demo/Up To 24 Months`,
    rate: 9.59,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 150000),
      ageOfAssetBetween(0, 12),
      livingArrangements([PROPERTY_OWNER]),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreGreaterThan(699),
    ],
  },
  {
    name: "Property Owner - Motor Vehicle 24-95 months old",
    rate: 10.09,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 150000),
      ageOfAssetBetween(13, 59),
      livingArrangements([PROPERTY_OWNER]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreGreaterThan(699),
    ],
  },
  {
    name: "Property Owner - Motor Vehicle 24-95 months old",
    rate: 11.09,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 150000),
      ageOfAssetBetween(60, 95),
      livingArrangements([PROPERTY_OWNER]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreGreaterThan(699),
    ],
  },
  {
    name: "Property Owner - Motor Vehicle 95-144 months old",
    rate: 12.09,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 150000),
      ageOfAssetBetween(95, 144),
      livingArrangements([PROPERTY_OWNER]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreGreaterThan(699),
    ],
  },


  {
    name: "Property Owner - Motor Vehicle New/Demo/Up To 24 Months",
    rate: 12.19,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 150000),
      ageOfAssetBetween(0, 12),
      livingArrangements([PROPERTY_OWNER]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(640, 699),
    ],
  },
  {
    name: "Property Owner - Motor Vehicle 24-95 months old",
    rate: 12.69,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 100000),
      ageOfAssetBetween(13, 59),
      livingArrangements([PROPERTY_OWNER]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(640, 699),
    ],
  },
  {
    name: "Property Owner - Motor Vehicle 24-95 months old",
    rate: 13.69,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 100000),
      ageOfAssetBetween(60, 95),
      livingArrangements([PROPERTY_OWNER]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(640, 699),
    ],
  },
  {
    name: "Property Owner - Motor Vehicle 95-144 months old",
    rate: 14.69,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 100000),
      ageOfAssetBetween(95, 144),
      livingArrangements([PROPERTY_OWNER]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(640, 699),
    ],
  },


  {
    name: "Property Owner - Motor Vehicle New/Demo/Up To 24 Months",
    rate: 12.19,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(0, 12),
      livingArrangements([PROPERTY_OWNER]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(500, 639),
    ],
  },
  {
    name: "Property Owner - Motor Vehicle 24-95 months old",
    rate: 12.69,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(13, 59),
      livingArrangements([PROPERTY_OWNER]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(500, 639),
    ],
  },
  {
    name: "Property Owner - Motor Vehicle 24-95 months old",
    rate: 13.69,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(60, 95),
      livingArrangements([PROPERTY_OWNER]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(500, 639),
    ],
  },
  {
    name: "Property Owner - Motor Vehicle 95-144 months old",
    rate: 14.69,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(96, 144),
      livingArrangements([PROPERTY_OWNER]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(500, 639),
    ],
  },



  {
    name: "Non Property Owner - Motor Vehicle New/Demo/Up To 24 Months",
    rate: 10.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(0, 12),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreGreaterThan(914),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 24-95 months old",
    rate: 10.99,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(13, 59),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreGreaterThan(914),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 24-95 months old",
    rate: 11.99,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(60, 95),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreGreaterThan(914),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 95-144 months old",
    rate: 12.99,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(95, 144),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreGreaterThan(914),
    ],
  },
  

  {
    name: "Non Property Owner - Motor Vehicle New/Demo/Up To 24 Months",
    rate: 10.99,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 150000),
      ageOfAssetBetween(0, 12),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(835, 914),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 24-95 months old",
    rate: 11.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 150000),
      ageOfAssetBetween(12, 59),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(835, 914),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 24-95 months old",
    rate: 12.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 150000),
      ageOfAssetBetween(60, 95),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(835, 914),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 95-144 months old",
    rate: 13.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 150000),
      ageOfAssetBetween(95, 144),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(835, 914),
    ],
  },


  {
    name: "Non Property Owner - Motor Vehicle New/Demo/Up To 24 Months",
    rate: 11.59,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 150000),
      ageOfAssetBetween(0, 12),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(783, 834),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 24-95 months old",
    rate: 12.09,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 150000),
      ageOfAssetBetween(13, 95),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(783, 834),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 24-95 months old",
    rate: 13.09,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 150000),
      ageOfAssetBetween(60, 95),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(783, 834),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 95-144 months old",
    rate: 14.09,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 150000),
      ageOfAssetBetween(95, 144),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(783, 834),
    ],
  },


  {
    name: "Non Property Owner - Motor Vehicle New/Demo/Up To 24 Months",
    rate: 12.09,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(0, 12),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(730, 782),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 24-95 months old",
    rate: 12.59,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(13, 95),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(730, 782),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 24-95 months old",
    rate: 13.59,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(60, 95),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(730, 782),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 95-144 months old",
    rate: 14.59,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(95, 144),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(730, 782),
    ],
  },


  {
    name: "Non Property Owner - Motor Vehicle New/Demo/Up To 24 Months",
    rate: 12.09,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(0, 12),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(730, 782),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 24-95 months old",
    rate: 12.59,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(13, 95),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(730, 782),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 24-95 months old",
    rate: 13.59,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(60, 95),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(730, 782),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 95-144 months old",
    rate: 14.59,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(95, 144),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(730, 782),
    ],
  },


  {
    name: "Non Property Owner - Motor Vehicle New/Demo/Up To 24 Months",
    rate: 12.74,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(0, 12),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(685, 729),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 24-95 months old",
    rate: 13.24,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(13, 95),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(685, 729),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 24-95 months old",
    rate: 14.24,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(60, 95),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(685, 729),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 95-144 months old",
    rate: 15.24,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(95, 144),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(685, 729),
    ],
  },


  {
    name: "Non Property Owner - Motor Vehicle New/Demo/Up To 24 Months",
    rate: 12.74,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(0, 12),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(640, 684),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 24-95 months old",
    rate: 13.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(13, 95),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(640, 684),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 24-95 months old",
    rate: 14.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(60, 95),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(640, 684),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 95-144 months old",
    rate: 15.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(95, 144),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(640, 684),
    ],
  },


  {
    name: "Non Property Owner - Motor Vehicle New/Demo/Up To 24 Months",
    rate: 13.24,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(0, 12),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(590, 639),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 24-95 months old",
    rate: 13.74,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(13, 95),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(590, 639),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 24-95 months old",
    rate: 14.74,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(60, 95),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(590, 639),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 95-144 months old",
    rate: 15.74,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(95, 144),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(590, 639),
    ],
  },


  {
    name: "Non Property Owner - Motor Vehicle New/Demo/Up To 24 Months",
    rate: 13.74,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(0, 12),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(540, 589),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 24-95 months old",
    rate: 14.24,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(13, 95),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(540, 589),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 24-95 months old",
    rate: 15.24,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(60, 95),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(540, 589),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 95-144 months old",
    rate: 16.24,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(95, 144),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(540, 589),
    ],
  },


  {
    name: "Non Property Owner - Motor Vehicle New/Demo/Up To 24 Months",
    rate: 13.94,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(0, 12),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(500, 539),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 24-95 months old",
    rate: 14.44,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(13, 95),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(500, 539),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 24-95 months old",
    rate: 15.44,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(60, 95),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(500, 539),
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 95-144 months old",
    rate: 16.44,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(10000, 68000),
      ageOfAssetBetween(95, 144),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),

      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      creditScoreBetween(500, 539),
    ],
  },
];

export default base;
