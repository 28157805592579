import { livingArrangementsTypes, employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } = livingArrangementsTypes;

const { FULL_TIME, PART_TIME, CASUAL, CONTRACT, SELF_EMPLOYED } = employmentTypes;

const {
  loanAmountBetween,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
  creditScoreBetween,
} = criteria;

const base = [
  {
    name: "Prime Plus",
    rate: 9.45,
    productType: "Standard",
    criteria: [
      loanAmountBetween(1500, 34999.99),
      livingArrangements([PROPERTY_OWNER, RENTING]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(12),
      creditScoreGreaterThan(1080),
    ],
  },
  {
    name: "Prime Plus",
    rate: 9.00,
    productType: "Standard",
    criteria: [
      loanAmountBetween(35000, 499999.99),
      livingArrangements([PROPERTY_OWNER, RENTING]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(12),
      creditScoreGreaterThan(1080),
    ],
  },
  {
    name: "Prime Plus",
    rate: 9.35,
    productType: "Standard",
    criteria: [
      loanAmountBetween(500000, 999999.99),
      livingArrangements([PROPERTY_OWNER, RENTING]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(12),
      creditScoreGreaterThan(1080),
    ],
  },

  {
    name: "Prime",
    rate: 10.95,
    productType: "Standard",
    criteria: [
      loanAmountBetween(1500, 34999.99),
      livingArrangements([PROPERTY_OWNER, RENTING]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(12),
      creditScoreGreaterThan(800),
    ],
  },
  {
    name: "Prime",
    rate: 10.45,
    productType: "Standard",
    criteria: [
      loanAmountBetween(35000, 499999.99),
      livingArrangements([PROPERTY_OWNER, RENTING]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(12),
      creditScoreGreaterThan(800),
    ],
  },
  {
    name: "Prime",
    rate: 10.35,
    productType: "Standard",
    criteria: [
      loanAmountBetween(500000, 999999.99),
      livingArrangements([PROPERTY_OWNER, RENTING]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(12),
      creditScoreGreaterThan(800),
    ],
  },

  {
    name: "Standard",
    rate: 12.95,
    productType: "Standard",
    criteria: [
      loanAmountBetween(1500, 34999.99),
      livingArrangements([BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(12),
      creditScoreGreaterThan(800),
    ],
  },
  {
    name: "Standard",
    rate: 12.45,
    productType: "Standard",
    criteria: [
      loanAmountBetween(35000, 499999.99),
      livingArrangements([BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(12),
      creditScoreGreaterThan(800),
    ],
  },
  {
    name: "Standard",
    rate: 12.35,
    productType: "Standard",
    criteria: [
      loanAmountBetween(500000, 999999.99),
      livingArrangements([BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(12),
      creditScoreGreaterThan(800),
    ],
  },

  {
    name: "Limited",
    rate: 14.95,
    productType: "Standard",
    criteria: [
      loanAmountBetween(1500, 34999.99),
      livingArrangements([PROPERTY_OWNER, RENTING, LIVING_WITH_PARENTS, BOARDING]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(12),
      creditScoreBetween(659, 799),
    ],
  },
  {
    name: "Limited",
    rate: 13.75,
    productType: "Standard",
    criteria: [
      loanAmountBetween(35000, 499999.99),
      livingArrangements([PROPERTY_OWNER, RENTING, LIVING_WITH_PARENTS, BOARDING]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(12),
      creditScoreBetween(659, 799),
    ],
  },
  {
    name: "Limited",
    rate: 12.75,
    productType: "Standard",
    criteria: [
      loanAmountBetween(500000, 999999.99),
      livingArrangements([PROPERTY_OWNER, RENTING, LIVING_WITH_PARENTS, BOARDING]),
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(12),
      creditScoreBetween(500, 799),
    ],
  },

  {
    name: "Prime Plus",
    rate: 9.45,
    productType: "Standard",
    criteria: [
      loanAmountBetween(1500, 34999.99),
      livingArrangements([PROPERTY_OWNER, RENTING]),
      employmentType([FULL_TIME, PART_TIME, CONTRACT]),
      timeOfEmploymentGreaterThan(1),
      creditScoreGreaterThan(1080),
    ],
  },
  {
    name: "Prime Plus",
    rate: 9.00,
    productType: "Standard",
    criteria: [
      loanAmountBetween(35000, 499999.99),
      livingArrangements([PROPERTY_OWNER, RENTING]),
      employmentType([FULL_TIME, PART_TIME, CONTRACT]),
      timeOfEmploymentGreaterThan(1),
      creditScoreGreaterThan(1080),
    ],
  },
  {
    name: "Prime Plus",
    rate: 9.35,
    productType: "Standard",
    criteria: [
      loanAmountBetween(500000, 999999.99),
      livingArrangements([PROPERTY_OWNER, RENTING]),
      employmentType([FULL_TIME, PART_TIME, CONTRACT]),
      timeOfEmploymentGreaterThan(1),
      creditScoreGreaterThan(1080),
    ],
  },

  {
    name: "Prime",
    rate: 10.95,
    productType: "Standard",
    criteria: [
      loanAmountBetween(1500, 34999.99),
      livingArrangements([PROPERTY_OWNER, RENTING]),
      employmentType([FULL_TIME, PART_TIME, CONTRACT]),
      timeOfEmploymentGreaterThan(1),
      creditScoreGreaterThan(800),
    ],
  },
  {
    name: "Prime",
    rate: 10.45,
    productType: "Standard",
    criteria: [
      loanAmountBetween(35000, 499999.99),
      livingArrangements([PROPERTY_OWNER, RENTING]),
      employmentType([FULL_TIME, PART_TIME, CONTRACT]),
      timeOfEmploymentGreaterThan(1),
      creditScoreGreaterThan(800),
    ],
  },
  {
    name: "Prime",
    rate: 10.35,
    productType: "Standard",
    criteria: [
      loanAmountBetween(500000, 999999.99),
      livingArrangements([PROPERTY_OWNER, RENTING]),
      employmentType([FULL_TIME, PART_TIME, CONTRACT]),
      timeOfEmploymentGreaterThan(1),
      creditScoreGreaterThan(800),
    ],
  },

  {
    name: "Standard",
    rate: 12.95,
    productType: "Standard",
    criteria: [
      loanAmountBetween(1500, 34999.99),
      livingArrangements([BOARDING, LIVING_WITH_PARENTS]),
      employmentType([FULL_TIME, PART_TIME, CONTRACT]),
      timeOfEmploymentGreaterThan(1),
      creditScoreGreaterThan(800),
    ],
  },
  {
    name: "Standard",
    rate: 12.45,
    productType: "Standard",
    criteria: [
      loanAmountBetween(35000, 499999.99),
      livingArrangements([BOARDING, LIVING_WITH_PARENTS]),
      employmentType([FULL_TIME, PART_TIME, CONTRACT]),
      timeOfEmploymentGreaterThan(1),
      creditScoreGreaterThan(800),
    ],
  },
  {
    name: "Standard",
    rate: 12.35,
    productType: "Standard",
    criteria: [
      loanAmountBetween(500000, 999999.99),
      livingArrangements([BOARDING, LIVING_WITH_PARENTS]),
      employmentType([FULL_TIME, PART_TIME, CONTRACT]),
      timeOfEmploymentGreaterThan(1),
      creditScoreGreaterThan(800),
    ],
  },

  {
    name: "Limited",
    rate: 14.25,
    productType: "Standard",
    criteria: [
      loanAmountBetween(1500, 34999.99),
      livingArrangements([PROPERTY_OWNER, RENTING, LIVING_WITH_PARENTS, BOARDING]),
      employmentType([FULL_TIME, PART_TIME, CONTRACT]),
      timeOfEmploymentGreaterThan(1),
      creditScoreBetween(659, 799),
    ],
  },
  {
    name: "Limited",
    rate: 13.75,
    productType: "Standard",
    criteria: [
      loanAmountBetween(35000, 499999.99),
      livingArrangements([PROPERTY_OWNER, RENTING, LIVING_WITH_PARENTS, BOARDING]),
      employmentType([FULL_TIME, PART_TIME, CONTRACT]),
      timeOfEmploymentGreaterThan(1),
      creditScoreBetween(659, 799),
    ],
  },
  {
    name: "Limited",
    rate: 12.75,
    productType: "Standard",
    criteria: [
      loanAmountBetween(500000, 999999.99),
      livingArrangements([PROPERTY_OWNER, RENTING, LIVING_WITH_PARENTS, BOARDING]),
      employmentType([FULL_TIME, PART_TIME, CONTRACT]),
      timeOfEmploymentGreaterThan(1),
      creditScoreBetween(500, 799),
    ],
  },

  {
    name: "Prime Plus",
    rate: 9.45,
    productType: "Standard",
    criteria: [
      loanAmountBetween(1500, 34999.99),
      livingArrangements([PROPERTY_OWNER, RENTING]),
      employmentType([CASUAL]),
      timeOfEmploymentGreaterThan(6),
      creditScoreGreaterThan(1080),
    ],
  },
  {
    name: "Prime Plus",
    rate: 9.00,
    productType: "Standard",
    criteria: [
      loanAmountBetween(35000, 499999.99),
      livingArrangements([PROPERTY_OWNER, RENTING]),
      employmentType([CASUAL]),
      timeOfEmploymentGreaterThan(6),
      creditScoreGreaterThan(1080),
    ],
  },
  {
    name: "Prime Plus",
    rate: 9.35,
    productType: "Standard",
    criteria: [
      loanAmountBetween(500000, 999999.99),
      livingArrangements([PROPERTY_OWNER, RENTING]),
      employmentType([CASUAL]),
      timeOfEmploymentGreaterThan(6),
      creditScoreGreaterThan(1080),
    ],
  },

  {
    name: "Prime",
    rate: 10.95,
    productType: "Standard",
    criteria: [
      loanAmountBetween(1500, 34999.99),
      livingArrangements([PROPERTY_OWNER, RENTING]),
      employmentType([CASUAL]),
      timeOfEmploymentGreaterThan(6),
      creditScoreGreaterThan(800),
    ],
  },
  {
    name: "Prime",
    rate: 10.45,
    productType: "Standard",
    criteria: [
      loanAmountBetween(35000, 499999.99),
      livingArrangements([PROPERTY_OWNER, RENTING]),
      employmentType([CASUAL]),
      timeOfEmploymentGreaterThan(6),
      creditScoreGreaterThan(800),
    ],
  },
  {
    name: "Prime",
    rate: 10.35,
    productType: "Standard",
    criteria: [
      loanAmountBetween(500000, 999999.99),
      livingArrangements([PROPERTY_OWNER, RENTING]),
      employmentType([CASUAL]),
      timeOfEmploymentGreaterThan(6),
      creditScoreGreaterThan(800),
    ],
  },

  {
    name: "Standard",
    rate: 12.95,
    productType: "Standard",
    criteria: [
      loanAmountBetween(1500, 34999.99),
      livingArrangements([BOARDING, LIVING_WITH_PARENTS]),
      employmentType([CASUAL]),
      timeOfEmploymentGreaterThan(6),
      creditScoreGreaterThan(800),
    ],
  },
  {
    name: "Standard",
    rate: 12.45,
    productType: "Standard",
    criteria: [
      loanAmountBetween(35000, 499999.99),
      livingArrangements([BOARDING, LIVING_WITH_PARENTS]),
      employmentType([CASUAL]),
      timeOfEmploymentGreaterThan(6),
      creditScoreGreaterThan(800),
    ],
  },
  {
    name: "Standard",
    rate: 12.35,
    productType: "Standard",
    criteria: [
      loanAmountBetween(500000, 999999.99),
      livingArrangements([BOARDING, LIVING_WITH_PARENTS]),
      employmentType([CASUAL]),
      timeOfEmploymentGreaterThan(6),
      creditScoreGreaterThan(800),
    ],
  },

  {
    name: "Limited",
    rate: 14.25,
    productType: "Standard",
    criteria: [
      loanAmountBetween(1500, 34999.99),
      livingArrangements([PROPERTY_OWNER, RENTING, LIVING_WITH_PARENTS, BOARDING]),
      employmentType([CASUAL]),
      timeOfEmploymentGreaterThan(6),
      creditScoreBetween(659, 799),
    ],
  },
  {
    name: "Limited",
    rate: 13.75,
    productType: "Standard",
    criteria: [
      loanAmountBetween(35000, 499999.99),
      livingArrangements([PROPERTY_OWNER, RENTING, LIVING_WITH_PARENTS, BOARDING]),
      employmentType([CASUAL]),
      timeOfEmploymentGreaterThan(6),
      creditScoreBetween(659, 799),
    ],
  },
  {
    name: "Limited",
    rate: 12.75,
    productType: "Standard",
    criteria: [
      loanAmountBetween(500000, 999999.99),
      livingArrangements([PROPERTY_OWNER, RENTING, LIVING_WITH_PARENTS, BOARDING]),
      employmentType([CASUAL]),
      timeOfEmploymentGreaterThan(6),
      creditScoreBetween(500, 799),
    ],
  },
];
export default base;
