import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack, Typography, TextField, InputAdornment, ToggleButtonGroup, ToggleButton } from "@mui/material";
import * as Yup from "yup";
import regex from "../../../utils/regex";
import { dollarStringOptions } from "../../../constants";
import { NumericFormatCustom } from "../../../utils/currencyMaskFormat";
import {
  loanDetailsSelector,
  updateLoanDetails,
  saveLoanDetails,
  assetSelector
} from "../../../store/slices/applicationFormSlice";


const Brokerage = () => {
  const dispatch = useDispatch();
  const loanDetails = useSelector(loanDetailsSelector);
  const { _id, brokerage, brokerageAmount, deposit } = loanDetails
  const { assetValue, tradeIn = 0, payout = 0 } = useSelector(assetSelector);

  const [mode, setMode] = useState("percentage");
  const isPercentageCheck = mode === "percentage";
  const netAssetValue = assetValue * 1 - deposit * 1 - tradeIn * 1 + payout * 1;
  const [allValues, setAllValue] = useState({
    brokerage: brokerage || 0,
    brokerageAmount: brokerageAmount || 0,
  });

  const [fieldErrors, setFieldErrors] = useState({
    brokerage: "",
    brokerageAmount: "",
  });

  const validationSchema = Yup.object({
    brokerage: Yup.string()
      .optional()
      .matches(regex.percentageRegex, "Please enter valid percentage.")
      .matches(regex.percentageRegex, {
        message: "Percentage should be upto 100%.",
      }),
    brokerageAmount: Yup.string()
      .optional()
      .matches(regex.allowNumberWithZero, "Only numbers and decimal points allowed.")
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      .max(10, "Maximum of 7 digits")
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  const handle = {
    onChangeField: async (e, name) => {
      // let isValid = true;

      if (e === "") {
        setAllValue({ ...allValues, [name]: e });
        setFieldErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        return;
      }

      if (name === "brokerage") {
        if (mode === "percentage") {
          if (e < 0) {
            await validateField(name, e);
            return;
          }
          setAllValue({ ...allValues, [name]: e, brokerageAmount: handle.calcBrokerageAmount(e) });
        } else {
          setAllValue({ ...allValues, [name]: handle.calcBrokeragePercentage(e), brokerageAmount: Math.abs(e) });
        }
      } else if (name === "balloon") {
        if (mode === "percentage") {
          if (+e < 0) {
            await validateField(name, e);
            return;
          }
          setAllValue({ ...allValues, [name]: +e, balloonAmount: handle.calcBalloonAmount(e) });
        } else {
          setAllValue({ ...allValues, [name]: handle.calcBalloonPercentage(e), balloonAmount: Math.abs(e) });
        }
      }
      await validateField(name, e);
    },
    calcBrokerageAmount: (brokerage) => {
      const brokerageAmount = netAssetValue * (brokerage / 100);
      return brokerageAmount;
    },
    calcBrokeragePercentage: (brokerageAmount) => {
      // const netAssetValue = assetValue * 1 - deposit * 1 - tradeIn * 1 + payout * 1;
      const brokeragePercentage = (brokerageAmount / netAssetValue) * 100;
      return brokeragePercentage && !isNaN(brokeragePercentage) && isFinite(brokeragePercentage)
        ? brokeragePercentage
        : 0;
    },
    loanDetailsFn: (value) => {
      const keys = Object.keys(value);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (loanDetails[key] !== value[key]) {
          dispatch(saveLoanDetails(value));
          dispatch(updateLoanDetails({ _id, ...value }));
        }
      }
    },
    handleModeChange: (event, newMode) => {
      if (newMode !== null) {
        if (mode !== "percentage") {
          validateField("brokerage", allValues.brokerage);
        } else {
          validateField("brokerage", allValues.brokerageAmount);
        }
        setMode(newMode);
      }
    }
  }

  return (
    <Stack direction="column">
      <Typography variant="caption">
        Brokerage {!isNaN(allValues?.brokerage) ? Math.round(allValues?.brokerage * 100) / 100 : 0}% |{" "}
        {(allValues?.brokerageAmount ? allValues?.brokerageAmount : 0).toLocaleString(
          "en-US",
          dollarStringOptions,
        )}
      </Typography>
      <Box display="flex" alignItems="center">
        <TextField
          label="Brokerage"
          name="brokerage"
          value={isPercentageCheck ? allValues?.brokerage : allValues?.brokerageAmount}
          error={isPercentageCheck ? fieldErrors?.brokerage : fieldErrors?.brokerageAmount}
          helperText={isPercentageCheck ? fieldErrors?.brokerage : fieldErrors?.brokerageAmount}
          onChange={(event) => {
            const value = event.target.value;
            // Handle clearing the value properly
            handle.onChangeField(value || 0, "brokerage");
          }}
          onBlur={() => {
            handle.loanDetailsFn({
              brokerage: allValues?.brokerage || 0,
              brokerageAmount: allValues?.brokerageAmount || 0,
            });
          }}
          InputProps={{
            inputComponent: NumericFormatCustom,
            inputProps: {
              prefix: "",
              isAllowedFlag: true,
              maxDigits: 7,
              maxPlaces: 2,
            },
            startAdornment: allValues?.brokerageAmount > 0 && mode !== "percentage" && (
              <InputAdornment position="start">$</InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <ToggleButtonGroup
                  value={mode}
                  exclusive
                  color="primary"
                  onChange={handle.handleModeChange}
                  sx={{
                    mr: -1,
                    height: "36px",
                  }}
                >
                  <ToggleButton value="percentage">%</ToggleButton>
                  <ToggleButton value="dollar">$</ToggleButton>
                </ToggleButtonGroup>
              </InputAdornment>
            ),
          }}
          type="text"
          size="small"
          variant="filled"
          fullWidth
        />
      </Box>
    </Stack>
  )
}

export default Brokerage