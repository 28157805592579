import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { List, ListItem, ListItemButton, ListItemText, Box, Collapse } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { deepPurple } from "@mui/material/colors";
import { emailTemplatePath, smsTemplatePath, userType } from "../constants";
import { userSelector } from "../store/slices/userSlice";

export default function SettingsSidebar({ path }) {
  const navigate = useNavigate();
  const location = useLocation();
  const userState = useSelector(userSelector);
  const permissions = userState?.permissions
  // const primaryTypographyProps = {
  //   // color: 'primary',
  //   fontWeight: "400",
  //   fontSize: "13px",
  //   // variant: 'body2',
  // };

  const [open, setOpen] = React.useState(true);

  const handleListClick = () => {
    setOpen(!open);
  };

  const listCollapseSelectorStyle = {
    position: "relative",
    paddingLeft: 0,
    "&.Mui-selected": {
      paddingLeft: "10px",
      backgroundColor: "transparent", // Prevent background color
      "&:before": {
        content: '""',
        position: "absolute",
        left: 0,
        top: 0,
        bottom: 0,
        width: "4px",
        backgroundColor: deepPurple[500], // Vertical bar color
      },
    },
  };

  return (
    <>
      <Box sx={{ width: "100%", fontSize: "10px" }}>
        <List>
          <ListItem disablePadding dense>
            <ListItemButton selected={path === "general"} onClick={() => navigate("/settings/general")}>
              <ListItemText
                primary="Profile"
                primaryTypographyProps={{
                  fontSize: "13px",
                  fontWeight: path === "general" ? "700" : 400,
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding dense>
            <ListItemButton selected={path === "lenders"} onClick={() => navigate("/settings/lenders")}>
              <ListItemText
                primary="Lenders"
                primaryTypographyProps={{
                  fontSize: "13px",
                  fontWeight: path === "lenders" ? "700" : 400,
                }}
              />
            </ListItemButton>
          </ListItem>
          {[userType.owner, userType.master].includes(userState?.userType) && (
            <>
              {/* <ListItem disablePadding dense>
                <ListItemButton selected={path === "userRolesList"} onClick={() => navigate("/settings/user")}>
                  <ListItemText
                    primary="User"
                    primaryTypographyProps={{
                      fontSize: "13px",
                      fontWeight: path === "User" ? 700 : 400,
                    }}
                  />
                </ListItemButton>
              </ListItem> */}
              <ListItem disablePadding dense>
                <ListItemButton
                  selected={path === "rolepermissions"}
                  onClick={() => navigate("/settings/rolepermissions")}
                >
                  <ListItemText
                    primary="Role & Permissions"
                    primaryTypographyProps={{
                      fontSize: "13px",
                      fontWeight: path === "rolepermissions" ? 700 : 400,
                    }}
                  />
                </ListItemButton>
              </ListItem>
              {permissions?.templates?.view &&
                <>
                  <ListItem disablePadding dense>
                    <ListItemButton
                      selected={path === "templates"}
                      onClick={handleListClick}
                    //  onClick={() => navigate("/settings/templates")}
                    >
                      <ListItemText
                        primary="Templates"
                        primaryTypographyProps={{
                          fontSize: "13px",
                          fontWeight: path === "templates" ? 700 : 400,
                        }}
                      />
                      {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  </ListItem>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{
                          pl: 2,
                          ml: 1.5,
                          ...listCollapseSelectorStyle,
                        }}
                        selected={location.pathname === emailTemplatePath}
                        onClick={() => navigate(emailTemplatePath)}
                      >
                        <ListItemText
                          primary="Email"
                          primaryTypographyProps={{
                            pl: 2,
                            fontSize: "13px",
                            fontWeight: location.pathname === emailTemplatePath ? 700 : 400,
                            color: "inherit",
                          }}
                        />
                      </ListItemButton>
                      <ListItemButton
                        sx={{
                          pl: 2,
                          ml: 1.5,
                          ...listCollapseSelectorStyle,
                        }}
                        onClick={() => navigate(smsTemplatePath)}
                        selected={location.pathname === smsTemplatePath}
                      >
                        <ListItemText
                          primary="SMS"
                          primaryTypographyProps={{
                            pl: 2,
                            fontSize: "13px",
                            fontWeight: location.pathname === smsTemplatePath ? 700 : 400,
                            color: "inherit",
                          }}
                        />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </>
              }
            </>
          )}
        </List>
      </Box>
    </>
  );
}
