import {
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { reusableFetch } from "../../utils/useAxios";

export const getAllReferralApplications = createAsyncThunk(
  "Quote/getAllReferralApplications",
  async (data, { rejectWithValue }) => {
    const quoteApplications = await reusableFetch(`application/referral`, "POST", data, rejectWithValue);
    return quoteApplications;
  },
);

export const getQuoteList = createAsyncThunk(
  "Quote/getQuoteList",
  async (data, { rejectWithValue }) => {
    // const quoteApplications = await reusableFetch(`application/quote`, "POST", data, rejectWithValue);
    const quoteApplications = await reusableFetch(`application/all`, "POST", data, rejectWithValue);
    return quoteApplications;
  },
);

const initialState = {
  isLoading: false,
  applications: {},
  quotes: {}
}

export const quoteSlice = createSlice({
  name: "Quote",
  initialState,
  reducers: {
    saveValue: (state, action) => _.merge(state, action.payload),
    resetState: () => initialState,
  },
  extraReducers: {
    [getAllReferralApplications.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isLoading = false
      state.isSuccess = true;
      state.applications = payload.data
      console.log(JSON.stringify(state, null, 4));
    },
    [getAllReferralApplications.pending]: (state) => {
      state.isLoading = true
      state.isFetching = true;
    },
    [getAllReferralApplications.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.isFetching = false;
      state.isError = true;
    },
    [getQuoteList.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isLoading = false
      state.isSuccess = true;
      state.quotes = payload.data?.data
    },
    [getQuoteList.pending]: (state) => {
      state.isLoading = true
      state.isFetching = true;
    },
    [getQuoteList.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.isFetching = false;
      state.isError = true;
    },
  },


});

export const { saveValue, resetState } = quoteSlice.actions;

export const applicationsSelector = (state) => state.Quotes.applications;
export const quotesSelector = (state) => state.Quotes.quotes;