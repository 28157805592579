import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Grid, InputAdornment, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import CalCustomTitleBar from './common/CalCustomTitleBar'
import ShowValues from './common/ShowValues'
import { setCalculatorFieldsError } from '../../store/slices/applicationFormSlice'
import { NumericFormatCustom } from '../../utils/currencyMaskFormat'
import RadioButtonGroup from '../Utils/RadioButtonGroup'
import { YES_NO_OPTIONS } from '../../constants'

const CalBrokerage = React.memo(({ allValues, calculatorFieldsErrors, validateField, saveLoanDetails, setAllValue }) => {
  const dispatch = useDispatch()
  const [mode, setMode] = useState("percentage");
  const isPercentageCheck = mode === "percentage";
  const { brokerageGST = 0, financedAmount = 0, netAssetValue = 0 } = allValues

  const handle = {
    onChangeField: async (e, name) => {
      let isValid = true;

      if (e === "") {
        if (name === "brokerage") {
          setAllValue({ ...allValues, [name]: "", brokerageAmount: "" });
        } else {
          setAllValue({ ...allValues, [name]: +e });
        }
        dispatch(setCalculatorFieldsError({ ...calculatorFieldsErrors, brokerage: { ...calculatorFieldsErrors.brokerage, [name]: "" } }))
        return;
      }

      if (!isValid) {
        await validateField({ fieldName: name, value: e, fieldObj: 'brokerage' });
        return;
      }

      if (name === 'brokerageFinanced') {
        setAllValue({ ...allValues, [name]: e });
        saveLoanDetails({
          [name]: e,
        });
        return
      }

      if (name === "brokerage") {
        if (mode === "percentage") {
          if (+e < 0) {
            await validateField({ fieldName: name, value: e, fieldObj: 'brokerage' });
            return;
          }
          setAllValue({ ...allValues, [name]: +e, brokerageAmount: handle.calcBrokerageAmount(e) });
        } else {
          setAllValue({ ...allValues, [name]: handle.calcBrokeragePercentage(e), brokerageAmount: Math.abs(e) });
        }
      } else {
        setAllValue({ ...allValues, [name]: e });
      }

      await validateField({ fieldName: name, value: e, fieldObj: 'brokerage' });
    },
    calcBrokerageAmount: (brokerage) => {
      const brokerageAmount = (financedAmount * brokerage) / 100;
      return brokerageAmount;
    },
    calcBrokeragePercentage: (brokerageAmount) => {
      const brokeragePercentage = (brokerageAmount / financedAmount) * 100;
      return brokeragePercentage && !isNaN(brokeragePercentage) && isFinite(brokeragePercentage)
        ? brokeragePercentage
        : 0;
    },
    handleModeChange: (event, newMode) => {
      if (newMode !== null) {
        if (mode !== "percentage") {
          validateField("brokerage", allValues.brokerage);
        } else {
          validateField("brokerage", allValues.brokerageAmount);
        }
        setMode(newMode);
      }
    }
  }

  return (
    <Box>
      <CalCustomTitleBar title={"Brokerage"} />

      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        mb={"20px"}
        spacing={1}
        direction="row"
      >
        <Grid container item xs={12} sm={12} md={12} spacing={1} direction="row">
          <Grid item xs={8} sm={8} mt={1}>
            <Typography variant="subtitle2">Value</Typography>
            <Box display="flex" alignItems="center" mt={1}>
              <TextField
                label="Brokerage"
                name="brokerage"
                disabled={!netAssetValue}
                value={isPercentageCheck ? allValues?.brokerage : allValues?.brokerageAmount}
                error={isPercentageCheck ? calculatorFieldsErrors?.brokerage?.brokerage : calculatorFieldsErrors?.brokerage?.brokerageAmount}
                helperText={isPercentageCheck ? calculatorFieldsErrors?.brokerage?.brokerage : calculatorFieldsErrors?.brokerage?.brokerageAmount}
                onChange={(event) => {
                  const value = event.target.value;
                  // Handle clearing the value properly
                  handle.onChangeField(value, "brokerage");
                }}
                onBlur={() => {
                  saveLoanDetails({
                    brokerage: allValues?.brokerage || 0,
                    brokerageAmount: allValues?.brokerageAmount || 0,
                  });
                }}
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  inputProps: {
                    prefix: "",
                    isAllowedFlag: true,
                    maxDigits: 7,
                    maxPlaces: 2,
                  },
                  startAdornment: allValues?.brokerageAmount > 0 && mode !== "percentage" && (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <ToggleButtonGroup
                        value={mode}
                        exclusive
                        color="primary"
                        onChange={handle.handleModeChange}
                        sx={{
                          m: '0 -12px 0 0',
                          // height: "36px",
                        }}
                      >
                        <ToggleButton value="percentage">
                          <PercentIcon fontSize='small' />
                        </ToggleButton>
                        <ToggleButton value="dollar">
                          <AttachMoneyIcon fontSize='small' />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </InputAdornment>
                  ),
                }}
                type="text"
                size="small"
                variant="filled"
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={4} sm={4} mt={1}>
            <Typography variant="subtitle2">Financed</Typography>
            <RadioButtonGroup
              required
              value={allValues?.brokerageFinanced}
              name="brokerageFinanced"
              handleValue={(value) => {
                handle.onChangeField(value, "brokerageFinanced");
              }}
              options={YES_NO_OPTIONS}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <ShowValues
            label={"GST"}
            value={brokerageGST}
          />
        </Grid>
      </Grid>
    </Box>
  )
})

export default CalBrokerage