import { useDispatch, useSelector } from "react-redux";
import { Box, Stack, Typography, Slider } from "@mui/material";
import {
  loanDetailsSelector,
  updateLoanDetails,
  saveLoanDetails
} from "../../../store/slices/applicationFormSlice";

const RateAdjustment = () => {
  const dispatch = useDispatch();
  const { _id, rateAdjustment } = useSelector(loanDetailsSelector);

  const handle = {
    onChangeField: async (e, name) => {

      if (e === "") {
        dispatch(saveLoanDetails({ [name]: 0 }));
        return;
      }

      dispatch(saveLoanDetails({ [name]: e }));
      // await validateField(name, e);
    },
    loanDetailsFn: (key, value) => {
      dispatch(saveLoanDetails({ [key]: value }));
      dispatch(updateLoanDetails({ _id, [key]: value }));
    },
  }

  return (
    <Stack direction="column">
      <Typography variant="caption">Rate adjustment {rateAdjustment}%</Typography>
      <Box style={{ padding: "0 7px" }}>
        <Slider
          color="primary"
          size="small"
          marks={[
            { value: -2, label: "-2" },
            { value: -1.5, label: "-1.5" },
            { value: -1, label: "-1" },
            { value: -0.5, label: "-0.5" },
            { value: 0, label: "0" },
            // { value: 0.5, label: "0.5" },
            // { value: 1, label: "1" },
            // { value: 1.5, label: "1.5" },
            // { value: 2, label: "2" },
          ]}
          step={0.5}
          min={-2}
          max={0}
          value={rateAdjustment}
          name="rateAdjustment"
          onChange={(event) => handle.onChangeField(event?.target?.value, "rateAdjustment")}
          onChangeCommitted={() =>
            handle.loanDetailsFn('rateAdjustment', rateAdjustment)
          }
          aria-label="Default"
          valueLabelDisplay="auto"
        />
      </Box>
    </Stack>
  )
}

export default RateAdjustment