import criteria from "../../../utils/criteria";

const {
  livingArrangements,
  assetType,
  ageOfAssetLessThan,
  ageOfAssetBetween,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  gstRegistrationGreaterThan,
  creditScoreGreaterThan,
  hasDeposit,
} = criteria;

function propertyOwnerList(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      loanAmountBetween(2000, 150000),
      livingArrangements(["Property owner"])
    ],
  }));
}

function nonPropertyOwnerList(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      loanAmountBetween(2000, 100000),
      livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
    ],
  }));
}


function nonPropertyOwnerWithDepositList(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      loanAmountBetween(2000, 150000),
      hasDeposit(20),
      livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
    ],
  }));
}

const lowDocTierTwo = [
  ...propertyOwnerList(base()),
  ...nonPropertyOwnerList(base()),
  ...nonPropertyOwnerWithDepositList(base())
];

function base() {
  return [
    {
      name: "Low doc - Tier 2",
      rate: 8.65,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(96),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Warehouse Equipment",
        ]),
        ageOfAssetAtEndLessThan(26),
        creditScoreGreaterThan(599),
      ],
    },
    {
      name: "Low doc - Tier 2",
      rate: 9.45,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(97, 120),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Warehouse Equipment",
        ]),
        ageOfAssetAtEndLessThan(26),
        creditScoreGreaterThan(599),
      ],
    },
    {
      name: "Low doc - Tier 2",
      rate: 10.40,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(121, 180),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Warehouse Equipment",
        ]),
        ageOfAssetAtEndLessThan(26),
        creditScoreGreaterThan(599),
      ],
    },
    {
      name: "Low doc - Tier 2",
      rate: 11.40,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(181, 300),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Warehouse Equipment",
        ]),
        ageOfAssetAtEndLessThan(26),
        creditScoreGreaterThan(599),
      ],
    },
  ];
}

export default lowDocTierTwo;
