import { livingArrangementsTypes, employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } = livingArrangementsTypes;

const { SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL, BENEFITS } = employmentTypes;

const {
  assetType,
  loanAmountBetween,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
  creditScoreBetween,
  termIsLessThanOrEqualTo,
} = criteria;

const bronze = [
  {
    name: "Bronze - New/Demo",
    rate: 24.95,
    productType: "Adverse",
    info: ["Min score>100 allows for unlimited defaults and ex-bankrupts "],
    criteria: [
      loanAmountBetween(5000, 6000),

      termIsLessThanOrEqualTo(24),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(6),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
        "Caravan",
        "Trailered boat",
        "Motorbike",
        "Horse float",
        "Ride on mower",
        "Jetski/PWC",
        "ATV/Off-Road Bike",
      ]),

      creditScoreBetween(100, 499),
    ],
  },

  {
    name: "Bronze - New/Demo",
    rate: 24.95,
    productType: "Adverse",
    info: ["Min score>100 allows for unlimited defaults and ex-bankrupts "],
    criteria: [
      loanAmountBetween(6000, 8000),

      termIsLessThanOrEqualTo(36),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(6),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
        "Caravan",
        "Trailered boat",
        "Motorbike",
        "Horse float",
        "Ride on mower",
        "Jetski/PWC",
        "ATV/Off-Road Bike",
      ]),

      creditScoreBetween(100, 499),
    ],
  },

  {
    name: "Bronze - New/Demo",
    rate: 24.95,
    productType: "Adverse",
    info: ["Min score>100 allows for unlimited defaults and ex-bankrupts "],
    criteria: [
      loanAmountBetween(8000, 15000),

      termIsLessThanOrEqualTo(48),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(6),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
        "Caravan",
        "Trailered boat",
        "Motorbike",
        "Horse float",
        "Ride on mower",
        "Jetski/PWC",
        "ATV/Off-Road Bike",
      ]),

      creditScoreBetween(100, 499),
    ],
  },

  {
    name: "Bronze - New/Demo",
    rate: 24.95,
    productType: "Adverse",
    info: ["Min score>100 allows for unlimited defaults and ex-bankrupts "],
    criteria: [
      loanAmountBetween(15000, 30000),

      termIsLessThanOrEqualTo(60),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(6),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
        "Caravan",
        "Trailered boat",
        "Motorbike",
        "Horse float",
        "Ride on mower",
        "Jetski/PWC",
        "ATV/Off-Road Bike",
      ]),

      creditScoreBetween(100, 499),
    ],
  },

  {
    name: "Bronze - New/Demo",
    rate: 24.95,
    productType: "Adverse",
    info: ["Min score>100 allows for unlimited defaults and ex-bankrupts "],
    criteria: [
      loanAmountBetween(30000, 55000),

      termIsLessThanOrEqualTo(72),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(6),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
        "Caravan",
        "Trailered boat",
        "Motorbike",
        "Horse float",
        "Ride on mower",
        "Jetski/PWC",
        "ATV/Off-Road Bike",
      ]),

      creditScoreBetween(100, 499),
    ],
  },

  {
    name: "Bronze Micro - New/Demo",
    rate: 24.95,
    productType: "Adverse",
    info: ["Min score>100 allows for unlimited defaults and ex-bankrupts "],
    criteria: [
      loanAmountBetween(2000, 6000),

      termIsLessThanOrEqualTo(24),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([BENEFITS]),
      timeOfEmploymentGreaterThan(6),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
        "Caravan",
        "Trailered boat",
        "Motorbike",
        "Horse float",
        "Ride on mower",
        "Jetski/PWC",
        "ATV/Off-Road Bike",
      ]),

      creditScoreBetween(100, 499),
    ],
  },

  {
    name: "Bronze Micro - New/Demo",
    rate: 24.95,
    productType: "Adverse",
    info: ["Min score>100 allows for unlimited defaults and ex-bankrupts "],
    criteria: [
      loanAmountBetween(6000, 8000),

      termIsLessThanOrEqualTo(36),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([BENEFITS]),
      timeOfEmploymentGreaterThan(6),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
        "Caravan",
        "Trailered boat",
        "Motorbike",
        "Horse float",
        "Ride on mower",
        "Jetski/PWC",
        "ATV/Off-Road Bike",
      ]),

      creditScoreBetween(100, 499),
    ],
  },

  {
    name: "Bronze Micro - New/Demo",
    rate: 24.95,
    productType: "Adverse",
    info: ["Min score>100 allows for unlimited defaults and ex-bankrupts "],
    criteria: [
      loanAmountBetween(8000, 12000),

      termIsLessThanOrEqualTo(48),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType([BENEFITS]),
      timeOfEmploymentGreaterThan(6),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
        "Caravan",
        "Trailered boat",
        "Motorbike",
        "Horse float",
        "Ride on mower",
        "Jetski/PWC",
        "ATV/Off-Road Bike",
      ]),

      creditScoreBetween(100, 499),
    ],
  },
];
export default bronze;
