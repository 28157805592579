import criteria from "../../../utils/criteria";

const {
  livingArrangements,
  assetType,
  ageOfAssetGreaterThan,
  ageOfAssetLessThan,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  gstRegistrationGreaterThan,
  creditScoreGreaterThan,
  hasDeposit,
  ageOfAssetBetween
} = criteria;

function propertyOwnerList(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      loanAmountBetween(2000, 150000),
      livingArrangements(["Property owner"])
    ],
  }));
}

function nonPropertyOwnerList(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      loanAmountBetween(2000, 100000),
      livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
    ],
  }));
}


function nonPropertyOwnerWithDepositList(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      loanAmountBetween(2000, 150000),
      hasDeposit(20),
      livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
    ],
  }));
}
const lowDocTierthree = [
  ...propertyOwnerList(base()),
  ...nonPropertyOwnerList(base()),
  ...nonPropertyOwnerWithDepositList(base())
];

function base() {
  return [
    {
      name: "Low doc - Tier 3",
      rate: 8.65,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(96),
        assetType([
          "Agricultural & Landscaping Equipment (wheeled or tracked)"
        ]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(599),
      ],
    },
    {
      name: "Low doc - Tier 3",
      rate: 9.45,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(97, 120),
        assetType([
          "Agricultural & Landscaping Equipment (wheeled or tracked)"
        ]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(599),
      ],
    },
    {
      name: "Low doc - Tier 3",
      rate: 10.40,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(121, 180),
        assetType([
          "Agricultural & Landscaping Equipment (wheeled or tracked)"
        ]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(599),
      ],
    },
    {
      name: "Low doc - Tier 3",
      rate: 11.40,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(181, 300),
        assetType([
          "Agricultural & Landscaping Equipment (wheeled or tracked)"
        ]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(599),
      ],
    },

    {
      name: "Low doc - Tier 3",
      rate: 8.65,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(96),
        ageOfAssetAtEndLessThan(26),
        assetType(["Trailers and Caravans"]),
        creditScoreGreaterThan(599),
      ],
    },
    {
      name: "Low doc - Tier 3",
      rate: 9.45,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(97, 144),
        ageOfAssetAtEndLessThan(26),
        assetType(["Trailers and Caravans"]),
        creditScoreGreaterThan(599),
      ],
    },
    {
      name: "Low doc - Tier 3",
      rate: 10.40,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(145, 180),
        ageOfAssetAtEndLessThan(26),
        assetType(["Trailers and Caravans"]),
        creditScoreGreaterThan(599),
      ],
    },

    {
      name: "Low doc - Tier 3",
      rate: 9.75,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(12),
        assetType(["Industrial Plant & Printing"]),
        ageOfAssetAtEndLessThan(21),
        creditScoreGreaterThan(599),
      ],
    },
    {
      name: "Low doc - Tier 3",
      rate: 9.75,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(12),
        assetType(["Medical Equipment"]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(599),
      ],
    },

    {
      name: "Low doc - Tier 3",
      rate: 12.15,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(6),
        assetType(["IT, Computer & Audio Equipment", "Office Equipment"]),
        creditScoreGreaterThan(599),
      ],
    },

    {
      name: "Low doc - Tier 3",
      rate: 12.15,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(12),
        assetType(["Restaurant & Catering Equipment"]),
        ageOfAssetAtEndLessThan(4),
        creditScoreGreaterThan(599),
      ],
    },
    {
      name: "Low doc - Tier 3",
      rate: 12.15,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(12),
        assetType(["Office Furniture"]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(599),
      ],
    },
    {
      name: "Low doc - Tier 3",
      rate: 12.15,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(12),
        assetType(["Office Fitout"]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(599),
      ],
    },

    {
      name: "Low doc - Tier 3",
      rate: 9.75,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(8),
        assetType(["Solar"]),
        creditScoreGreaterThan(599),
      ],
    },

    {
      name: "Low doc - Tier 4",
      rate: 12.15,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(8),
        assetType(["Other"]),
        creditScoreGreaterThan(599),
      ],
    },
    {
      name: "Low doc - Tier 3",
      rate: 9.45,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetGreaterThan(11),
        assetType(["Agricultural & Landscaping Equipment (wheeled or tracked)"]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(599),
      ],
    },
    {
      name: "Low doc - Tier 3",
      rate: 10.45,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetGreaterThan(11),
        assetType(["Trailers and Caravans"]),
        ageOfAssetAtEndLessThan(26),
        creditScoreGreaterThan(599),
      ],
    },
    {
      name: "Low doc - Tier 3",
      rate: 9.45,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetGreaterThan(11),
        assetType(["Industrial Plant & Printing"]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(599),
      ],
    },
    {
      name: "Low doc - Tier 3",
      rate: 9.45,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetGreaterThan(11),
        assetType(["Medical Equipment"]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(599),
      ],
    },

    {
      name: "Low doc - Tier 4",
      rate: 12.1,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetGreaterThan(11),
        ageOfAssetAtEndLessThan(8),
        assetType(["Other"]),
        creditScoreGreaterThan(599),
      ],
    },
  ];
}

export default lowDocTierthree;
