import { type FC } from "react";
import { Stack, CircularProgress } from "@mui/material";

interface LoaderProps {
  text?: string;
}

const Loader: FC = ({ text }: LoaderProps) => (
    <Stack alignItems="center" justifyContent="center" height="100vh" rowGap={2}>
      <CircularProgress color="secondary" />
      <span>{text ?? 'Loading'}</span>
    </Stack>
  );

export { Loader };
