import midPrime from "./midPrime";
import subPrime from "./subPrime";
import benefits from "./benefits";
import { employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { FULL_TIME, PART_TIME, CONTRACT, CASUAL, BENEFITS } = employmentTypes;

const { creditScoreGreaterThan, employmentType, assetValueBetween } = criteria;

const carStart = {
  date: "31 Aug 2023",
  lender: "Car Start",
  type: "consumer",
  logoURL: "https://assets.financeable.com.au/lender-logos/Car-Start.png",
  actions: ["email"],
  lenderCredentials: [
    {
      label: "Lender applications inbox email",
      attribute: "email",
    },
  ],
  requiredFields: {
    asset: ["assetType", "typeOfSale", "ageOfAsset", "glassesMake", "glassesModel", "glassesVariant", "assetValue"],
    customer: [
      "firstName",
      "lastName",
      "dateOfBirth",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      //   "addresses[0].street",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "IDNumber",
      "IDCardNumber",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "employers.[0].entityName",
      "employers.[0].timeOfEmployment",
      "employers.[0].employmentType",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Establishment fee",
      value: 450,
      capitalised: true,
      frequency: "upfront",
      criteria: [creditScoreGreaterThan(450, 700)],
    },
    {
      name: "Origination fee",
      value: 1250,
      capitalised: true,
      frequency: "upfront",
      criteria: [employmentType([FULL_TIME, PART_TIME, CASUAL, CONTRACT])],
    },
    {
      name: "Establishment fee",
      value: 700,
      capitalised: true,
      frequency: "upfront",
      criteria: [creditScoreGreaterThan(0, 449)],
    },
    {
      name: "Origination fee",
      value: 1050,
      capitalised: true,
      frequency: "upfront",
      criteria: [employmentType([BENEFITS])],
    },

    {
      name: "Monthly account fee",
      value: 14.0,
      capitalised: false,
      frequency: "monthly",
    },
    {
      name: "Risk fee",
      value: 830,
      capitalised: true,
      frequency: "upfront",
      criteria: [creditScoreGreaterThan(0, 450)],
    },
    {
      name: "Risk fee",
      value: 830,
      capitalised: true,
      frequency: "upfront",
      criteria: [employmentType([BENEFITS])],
    },
    {
      name: "Monthly account fee",
      value: 4.95,
      capitalised: false,
      frequency: "monthly",
    },
    // {
    //   name: "Risk fee",
    //   value: 3,
    //   calc: "percent",
    //   capitalised: true,
    //   frequency: "upfront",
    //   criteria: [creditScoreBetween(550, 600)],
    // },
    // {
    //   name: "Risk fee",
    //   value: 4,
    //   calc: "percent",
    //   capitalised: true,
    //   frequency: "upfront",
    //   criteria: [creditScoreBetween(500, 550)],
    // },
    // {
    //   name: "Risk fee",
    //   value: 4.5,
    //   calc: "percent",
    //   capitalised: true,
    //   frequency: "upfront",
    //   criteria: [creditScoreBetween(100, 499)],
    // },
  ],
  brokerage: [
    {
      value: 5,
      max: 5,
      valueType: "percentage",
      criteria: [assetValueBetween(80001, 100000)],
    },
  ],
  loading: [
    // {
    //   name: "Age of asset at start 36-95 mths",
    //   value: 3,
    //   valueType: "percentage",
    //   criteria: [ageOfAssetBetween(36, 95)],
    // },
    // {
    //   name: "Age of asset at start >95 mths",
    //   value: 3,
    //   valueType: "percentage",
    //   criteria: [ageOfAssetGreaterThan(95)],
    // },
  ],
  info: [
    `Deposit matching`,
    `Increase loan amount by 100% deposit match`,
    `Trade in matching`,
    `Increase loan amount by 50% trade-in amount`,
    `All income types accepted`,
    `Bank Statements required`,
    `EXCLUSIONS:`,
    `Undischarged Part IX Debt Agreements  with 10+ direct debit reversals on bank statements in the past 90 days`,
    `Financial default listed on credit file in past 90 days`,
    `Written-off or in arrears CCFS accounts i.e. Wallet Wizard, ClearCash, WizPay`,
    `Defaults listed in past six (6) months`,
  ],
  productTiers: [...midPrime, ...subPrime, ...benefits],
};
export default carStart;
