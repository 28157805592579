import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    Grid,
    Typography,
    TextField,
    Stack,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { CLIENT_NAMES, VITE_PLATFORM_NAME } from "../../constants";
import { inviteAccept } from "../../store/slices/organisationSlice";
import regex from "../../utils/regex";
import {
    registerUser,
    saveValue,
    userSelector,
    validateEmail,
    validatePhone,
} from "../../store/slices/userSlice";

export default function AcceptInvite() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [data, setData] = useState({})
    const { key } = useParams();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [fullName, setFullName] = useState("");
    const [firstNameError, setFirstNameError] = useState(false);
    // const [lastNameError, setLastNameError] = useState(false);
    const [firstNameErrorText, setFirstNameErrorText] = useState("");
    // const [lastNameErrortext, setLastNameErrorText] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [phoneNumberErrorText, setPhoneNumberErrorText] = useState("");
    const [email, setEmail] = useState("");
    const [organisationId, setOrganisationId] = useState("");
    const [userType, setUserType] = useState("");
    const [canSubmit, setCanSubmit] = useState(false);
    const [acceptedConditions, setAcceptedConditions] = useState(false);
    // const [hasOrganisation, setHasOrganisation] = useState(null);

    const { phoneInUse, isCreatingAccount, createAccountSuccess } = useSelector(userSelector);

    useEffect(() => {
        try {
            if (key) {
                dispatch(inviteAccept(key)).then((res) => {
                    if (res.payload.data.status_code !== 200) {
                        navigate('/');
                    }
                    localStorage.removeItem("tokenExpired");
                    setData(res.payload.data.data);
                }).catch((error) => {
                    console.log({ error });
                    navigate('/');
                });
            }
        } catch (error) {
            navigate('/');
        }
    }, [key]);


    useEffect(() => {
        if (data && data.email && data.organisationId && data.userType) {
            setEmail(data.email);
            setOrganisationId(data.organisationId);
            setUserType(data.userType);
        }
    }, [data])



    async function handleRegistration() {
        // dispatch(saveValue({ isCreatingAccount: true }));

        await dispatch(
            registerUser({
                firstName,
                lastName,
                phoneNumber,
                email,
                organisationId,
                userType,
                redisKey: key
            })
        ).unwrap();
    }

    function handleFullName(event) {
        setFullName(event.target.value);
        if (regex.name.test(event.target.value)) {
            setFirstNameError(false);

            return setFirstNameErrorText("");
        }

        setFirstNameError(true);
        setFirstNameErrorText(
            "Name can contain only letters, dashes (-) or spaces."
        );
    }

    // function handleLastName(event) {
    //     setLastName(event.target.value);

    //     if (regex.name.test(event.target.value)) {
    //         setLastNameError(false);

    //         // return setLastNameErrorText("");
    //     }

    //     setLastNameError(true);
    //     // setLastNameErrorText(
    //     //     "Name can contain only letters, dashes (-) or spaces."
    //     // );
    // }

    function handlePhoneNumber(event) {
        setPhoneNumber(event.target.value.trim());

        if (/^0[45]\d{8}$/.test(event.target.value.toLowerCase())) {
            setPhoneNumberError(false);
            dispatch(saveValue({ phoneInUse: false }));
            return setPhoneNumberErrorText("");
        }

        setPhoneNumberError(true);
        setPhoneNumberErrorText("Not a valid Australia mobile phone number.");
    }

    const handle = {
        blurFn: (fieldName) => {
            if (fieldName === "fullname") {
                if (fullName !== "") {
                    const fullNameValue = fullName.split(" ");
                    setFirstName(fullNameValue[0]);
                    setLastName(fullNameValue[1]);
                } else {
                    setFirstNameError(true);
                    setFirstNameErrorText("Full name is required!");
                }
                return;
            } else if (fieldName === "phoneNumber") {
                if (phoneNumber !== "") {
                    handleCheckPhoneNumber()
                } else {
                    setPhoneNumberError(true);
                    setPhoneNumberErrorText("Phone Number is required!");
                }
                return;
            } else if (fieldName === "email") {
                if (email !== "") {
                    handleCheckEmail()
                }
                // else {
                //     setEmailError(true);
                //     setEmailErrorText("Email is required!");
                // }
                return;
            }
        }
    }


    async function handleCheckEmail() {
        await dispatch(validateEmail(email)).unwrap();
    }

    useEffect(() => {
        if (
            fullName &&
            email &&
            acceptedConditions &&
            !firstNameError &&
            // !lastNameError &&
            !phoneNumberError
        )
            return setCanSubmit(true);

        setCanSubmit(false);
    }, [
        fullName,
        email,
        firstNameError,
        // lastNameError,
        phoneNumberError,
        acceptedConditions,
    ]);

    useEffect(() => {
        console.log({ createAccountSuccess });
        // if (hasOrganisation) return navigate("/dashboard/quotes");

        if (createAccountSuccess) navigate("/organisation");
    }, [createAccountSuccess]);

    // useEffect(() => {
    //   console.log("query.get(oid)", query.get("oid"))
    //   if (query.get("oid")) setHasOrganisation(query.get("oid"));
    // });

    useEffect(() => {
        async function handleKeyDown(e) {
            if (e.keyCode === 13 && canSubmit) await handleRegistration();
        }

        document.addEventListener("keydown", handleKeyDown);

        // Don't forget to clean up
        return function cleanup() {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    async function handleCheckPhoneNumber() {
        if (phoneNumber && phoneNumber.length && VITE_PLATFORM_NAME !== CLIENT_NAMES.BMA) {
            await dispatch(validatePhone(phoneNumber)).unwrap();
        }
    }

    useEffect(() => {
        if (phoneInUse) {
            setPhoneNumberError(true);
            return setPhoneNumberErrorText("Phone number already in use.");
        }

        setPhoneNumberError(false);
        return setPhoneNumberErrorText("");
    }, [phoneInUse]);

    return (
        <>
            <Grid
                container
                item
                height="100%"
                width="100%"
                // style={{ padding: "30px 50px 100px" }}
                justifyContent="center"
                alignItems="center"
            // direction="row-reverse"
            >
                <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    height="100vh"
                    justifyContent="start"
                    alignItems="start"
                    direction="column"
                    textAlign="left"
                    style={{
                        padding: "3vh 60px 50px",
                        flexFlow: "column",
                        overflow: "scroll",
                    }}
                >
                    <a
                        href="https://financeable.com.au"
                        style={{
                            textDecoration: "none",
                            color: "rgba(0, 0, 0, 0.87)",
                        }}
                    >
                        <Typography
                            variant="h4"
                            fontWeight="800"
                            style={{
                                letterSpacing: "-1.8px",
                            }}
                        >
                            Financeable
                        </Typography>
                    </a>
                    {/* <br />
          <br /> */}
                    {/* <Typography variant="h1" fontWeight="500" style={{ textAlign: "left" }}>
            This is it.
          </Typography> */}
                    <br />
                    {/* <br /> */}
                    <Typography variant="h4" fontWeight="300">
                        Broker Registration
                    </Typography>
                    <br />
                    <Typography variant="caption" fontWeight="300">
                        All fields required.
                    </Typography>
                    <TextField
                        fullWidth
                        // id="outlined-basic"
                        type="text"
                        name="fulltName*"
                        label="Full name"
                        autoComplete="full-name"
                        variant="filled"
                        // variant="outlined"
                        size="small"
                        value={fullName}
                        inputProps={{ maxLength: 24 }}
                        onChange={handleFullName}
                        margin="dense"
                        error={firstNameError}
                        helperText={firstNameErrorText}
                        placeholder="First and last name"
                        onBlur={() => handle.blurFn('fullname')}
                    />

                    {/* <TextField
            fullWidth
            // id="outlined-basic"
            type="text"
            name="lastName"
            label="Last name"
            autoComplete="last-name"
            // variant="filled"
            // variant="outlined"
            // size="small"
            value={lastName}
            inputProps={{ maxLength: 24 }}
            onChange={handleLastName}
            margin="dense"
            helperText={lastNameErrortext}
            error={lastNameError}
          // onBlur={() => {}}
          /> */}

                    <TextField
                        fullWidth
                        // id="outlined-basic"
                        type="text"
                        name="phoneNumber"
                        label="Mobile number*"
                        autoComplete="tel"
                        variant="filled"
                        // variant="outlined"
                        size="small"
                        value={phoneNumber}
                        onChange={handlePhoneNumber}
                        margin="dense"
                        helperText={phoneNumberErrorText}
                        error={phoneNumberError}
                        onBlur={() => handle.blurFn('phoneNumber')}
                        inputProps={{ maxLength: 12 }}
                        placeholder="0400123123"
                    />

                    <TextField
                        fullWidth
                        shrink
                        // id="outlined-basic"
                        type="email"
                        name="email"
                        label="Email*"
                        autoComplete="email"
                        variant="filled"
                        // variant="outlined"
                        size="small"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={true}
                        margin="dense"
                        onBlur={() => handle.blurFn('email')}
                        inputProps={{ maxLength: 50 }}
                    />

                    <FormControlLabel
                        style={{ margin: "20px 0 10px" }}
                        control={
                            <Checkbox
                                checked={acceptedConditions}
                                onChange={(event) => {
                                    setAcceptedConditions(event.target.checked);
                                }}
                            />
                        }
                        label={
                            <Typography style={{ fontSize: "14px" }}>
                                I accept the{" "}
                                <a
                                    href="https://financeable.com.au/terms-conditions"
                                >
                                    Terms & Conditions
                                </a>{" "}
                                and{" "}
                                <a
                                    href="https://financeable.com.au/privacy-policy"
                                >
                                    Privacy Policy.
                                </a>
                            </Typography>
                        }
                    />

                    <LoadingButton
                        fullWidth
                        // endIcon={<SendOutlinedIcon />}
                        loading={isCreatingAccount}
                        // loadingIndicator="Generating..."
                        loadingPosition="end"
                        variant="contained"
                        disabled={!canSubmit}
                        color="secondary"
                        style={{ margin: "20px 0 10px" }}
                        onClick={async () => await handleRegistration()}
                    >
                        Register
                    </LoadingButton>
                    <Typography style={{ fontSize: "14px" }}>
                        Already using Financeable? <Link to="/login">Login</Link>
                    </Typography>
                </Grid>

                <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    spacing={2}
                    height="100vh"
                    justifyContent="start"
                    alignItems="center"
                    direction="column"
                    style={{
                        marginTop: 0,
                        padding: "3vh 0 0",
                        // background: "linear-gradient(rgba(106,11,227,0.7), rgba(223,0,227,0.4))",
                        background: "rgba(10,18,30,1)",
                        // background: "rgb(10,18,30)",
                        color: "#ffffff",
                        textAlign: "left",
                        overflow: "hidden",
                    }}
                >
                    <Stack
                        // justifyContent="start"
                        alignItems="start"
                        width="80%"
                    // height="100%"
                    >
                        {/* <Typography variant="h4" fontWeight="800" style={{ letterSpacing: "-2px", textAlign: "left", marginBottom: "30px" }}>
            Financeable
          </Typography> */}

                        {/* <Typography variant="h1" fontWeight="500" style={{ textAlign: "left" }}>
            This is it.
          </Typography> */}
                    </Stack>
                </Grid>
            </Grid>
        </>
    );

}