import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { reusableFetch } from "../../utils/useAxios";

export const getTemplates = createAsyncThunk("templates/list", async (data, { rejectWithValue }) => {
  const templates = await reusableFetch(
    `template/all?limit=${data.limit}&cursor=${data.cursor}&search=${data.search ?? ""}&messageType=${data.messageType ?? ""}`,
    "GET",
    {},
    false, // isCustomer
    rejectWithValue,
  );
  return templates;
});

export const getTemplatesById = createAsyncThunk("templates/getTemplatesById", async (data, { rejectWithValue }) => {
  let url = `template/${data.templateId}`;

  if (data?.templateVersionId) {
    url = url + `?templateVersionId=${data.templateVersionId}`;
  }

  const template = await reusableFetch(url, "GET", {}, false, rejectWithValue);

  return template;
});

export const getTemplatesVersions = createAsyncThunk(
  "templates/getTemplatesVersions",
  async (data, { rejectWithValue }) => {
    const versions = await reusableFetch(`template/versions/${data.templateId}`, "GET", {}, false, rejectWithValue);
    return versions;
  },
);

export const getDefaultTemplate = createAsyncThunk(
  "templates/getDefaultTemplate",
  async (data, { rejectWithValue }) => {
    const defaultTemplate = await reusableFetch(
      `template/default/${data.messageType}/${data.templateType}`,
      "GET",
      {},
      false,
      rejectWithValue,
    );
    return defaultTemplate;
  },
);

export const updateTemplate = createAsyncThunk("templates/updateTemplate", async (data, { rejectWithValue }) => {
  const { templateId, ...rest } = data;
  const updatedTemplate = await reusableFetch(
    `template/${data.messageType}/${templateId}`,
    "PUT",
    rest,
    false,
    rejectWithValue,
  );
  return updatedTemplate;
});

const initialState = {
  templates: [],
  templateVersions: [],
  isFetchingTemplates: false,
  isFetchingVersion: false,
  // currentlyEditingTemplate: null,
  currentlyEditingTemplate: {
    name: "",
    subject: "",
    messageType: "",
    templateType: "",
    variables: [],
    body: "",
  },
  allowedVariables: {},
  // currentlyEditingTemplate: {
  //   // _id: "66f3d4e0a3eaa1220f7dafc9",
  //   name: "User invite email",
  //   subject: "Test User invite email update",
  //   messageType: "email",
  //   templateType: "UserInvite",
  //   variables: ["customer.firstName", "customer.lastName", "organisation.name"],
  //   brand: "66dfccae9c40846702f405fe",
  //   createdBy: "66c9acf79d6fe326c143188e",
  //   body: "Hello {{customer.firstName}} {{customer.lastName}}. please use test link to fill application form data",
  //   // createdAt: "2024-09-25T09:16:18.009Z",
  //   // updatedAt: "2024-09-25T09:53:21.512Z",
  //   // __v: 0,
  //   // updatedBy: "66c9acf79d6fe326c143188e",
  // },
  isFetchingTemplate: false,
};

export const templatesSlice = createSlice({
  name: "templates",
  initialState: initialState,
  reducers: {
    saveValue: (state, action) => _.merge(state, action.payload),
  },
  extraReducers: {
    [getTemplates.fulfilled]: (state, { payload }) => {
      state.templates = payload.data.data.getAllTemplateData.docs;
      state.isFetchingTemplates = false;
    },
    [getTemplates.pending]: (state) => {
      state.isFetchingTemplates = true;
    },
    [getTemplates.rejected]: (state) => {
      state.templates = [];
      state.isFetchingTemplates = false;
    },
    [getTemplatesById.pending]: (state) => {
      state.allowedVariables = {};
      state.currentlyEditingTemplate = initialState.currentlyEditingTemplate;
      state.isFetchingTemplate = true;
    },
    [getTemplatesById.fulfilled]: (state, { payload }) => {
      state.currentlyEditingTemplate = payload.data.data?.template;
      state.allowedVariables = payload.data.data?.allowedVariables ?? {};
      state.isFetchingTemplate = false;
    },
    [getTemplatesById.rejected]: (state) => {
      state.allowedVariables = {};
      state.currentlyEditingTemplate = null;
      state.isFetchingTemplate = false;
    },
    [getDefaultTemplate.pending]: (state) => {
      state.isFetchingTemplate = true;
    },
    [getDefaultTemplate.fulfilled]: (state, { payload }) => {
      state.currentlyEditingTemplate.body = payload.data?.data;
      state.isFetchingTemplate = false;
    },
    [getDefaultTemplate.rejected]: (state, { payload }) => {
      state.isFetchingTemplate = false;
    },
    [getTemplatesVersions.pending]: (state) => {
      state.templateVersions = [];
      state.isFetchingVersion = true;
    },
    [getTemplatesVersions.fulfilled]: (state, { payload }) => {
      state.templateVersions = payload.data.data;
      state.isFetchingVersion = false;
    },
    [getTemplatesVersions.rejected]: (state) => {
      state.templateVersions = [];
      state.isFetchingVersion = false;
    },
  },
});

export const { saveValue } = templatesSlice.actions;

export const templatesSelector = (state) => state.templates;
