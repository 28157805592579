import { employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL, BENEFITS } = employmentTypes;

const { loanAmountBetween, creditScoreGreaterThan, employmentType, timeOfEmploymentGreaterThan, creditScoreBetween } =
  criteria;

const base = [
  {
    name: "Platinum",
    rate: 16.95,
    productType: "Standard",
    criteria: [
      loanAmountBetween(2000, 15000),
      employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(6),
      creditScoreGreaterThan(600),
    ],
  },
  {
    name: "Gold",
    rate: 19.95,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(2000, 15000),
      employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(6),
      creditScoreBetween(550, 599),
    ],
  },
  {
    name: "Silver",
    rate: 22.95,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(2000, 15000),
      employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(6),
      creditScoreBetween(500, 549),
    ],
  },
  {
    name: "Bronze",
    rate: 24.95,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(2000, 15000),
      employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
      timeOfEmploymentGreaterThan(6),
      creditScoreBetween(100, 499),
    ],
  },

  {
    name: "Platinum",
    rate: 16.95,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(2000, 5000),
      employmentType([BENEFITS]),
      timeOfEmploymentGreaterThan(6),
      creditScoreGreaterThan(600),
    ],
  },
  {
    name: "Gold",
    rate: 19.95,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(2000, 5000),
      employmentType([BENEFITS]),
      timeOfEmploymentGreaterThan(6),
      creditScoreBetween(550, 599),
    ],
  },
  {
    name: "Silver",
    rate: 22.95,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(2000, 5000),
      employmentType([BENEFITS]),
      timeOfEmploymentGreaterThan(6),
      creditScoreBetween(500, 549),
    ],
  },
  {
    name: "Bronze",
    rate: 24.95,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(2000, 5000),
      employmentType([BENEFITS]),
      timeOfEmploymentGreaterThan(6),
      creditScoreBetween(100, 499),
    ],
  },
];
export default base;
