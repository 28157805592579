import criteria from "../../../utils/criteria";

const {
  livingArrangements,
  hasDeposit,
  assetType,
  ageOfAssetLessThan,
  ageOfAssetBetween,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  gstRegistrationGreaterThan,
  creditScoreGreaterThan,
} = criteria;

function propertyOwnerList(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, loanAmountBetween(2000, 150000), livingArrangements(["Property owner"])],
  }));
}

function nonPropertyOwnerList(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      loanAmountBetween(2000, 75000),
      livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
    ],
  }));
}

function nonPropertyOwnerWithDepositList(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      loanAmountBetween(75001, 150000),
      livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
      hasDeposit(20),
    ],
  }));
}

const lowDocTierOne = [
  ...propertyOwnerList(base()),
  ...nonPropertyOwnerList(base()),
  ...nonPropertyOwnerWithDepositList(base()),
];

function base() {
  return [
    {
      name: "Low doc - Tier 1",
      rate: 8.65,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(96),
        ageOfAssetAtEndLessThan(16),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(599),
      ],
    },
    {
      name: "Low doc - Tier 1",
      rate: 9.45,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(97, 144),
        ageOfAssetAtEndLessThan(16),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(599),
      ],
    },
    {
      name: "Low doc - Tier 1",
      rate: 10.30,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(145, 180),
        ageOfAssetAtEndLessThan(16),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        creditScoreGreaterThan(599),
      ],
    },
  ];
}

export default lowDocTierOne;
