import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Grid } from '@mui/material'
import PrefixTextField from './common/PrefixTextField'
import CalCustomTitleBar from './common/CalCustomTitleBar'
import { setCalculatorFieldsError } from '../../store/slices/applicationFormSlice'
import { isValidInput } from '../Utils/helperFunction'

const CalCostOfGoods = React.memo(({ allValues, calculatorFieldsErrors, validateField, saveAssetDetails, setAllValue }) => {
  const dispatch = useDispatch()
  // const { costOfGoodTotal = 0, netAssetValue = 0, assetGst = 0, additionalTaxes = 0, additionalFees = 0 } = allValues

  const [newAllValues, setNewAllValues] = useState({
    netAssetValue: 0,
    costOfGoodTotal: 0,
    assetGst: 0,
    additionalTaxes: 0,
    additionalFees: 0
  })

  useEffect(() => {
    setNewAllValues({
      netAssetValue: allValues?.netAssetValue,
      costOfGoodTotal: allValues?.costOfGoodTotal,
      assetGst: allValues?.assetGst,
      additionalTaxes: allValues?.additionalTaxes,
      additionalFees: allValues?.additionalFees,
    })
  }, [
    allValues?.netAssetValue,
    // allValues?.costOfGoodTotal,
    // allValues?.assetGst,
    // allValues?.additionalTaxes,
    // allValues?.additionalFees,
  ])

  const { costOfGoodTotal = 0, netAssetValue = 0, assetGst = 0, additionalTaxes = 0, additionalFees = 0 } = newAllValues


  const handle = {
    onChangeField: async (e, name) => {
      let isValid = true;
      if (e === "") {
        if (name === "costOfGoodTotal") {
          // setAllValue({ ...allValues, [name]: e, netAssetValue: "", assetGst: "" });
          setNewAllValues({ ...allValues, [name]: e, netAssetValue: "", assetGst: "" });
        } else {
          // setAllValue({ ...allValues, [name]: "" });
          setNewAllValues({ ...allValues, [name]: "" });
        }
        dispatch(setCalculatorFieldsError({ ...calculatorFieldsErrors, costOfGoods: { ...calculatorFieldsErrors.costOfGoods, [name]: "" } }))
        return;
      }

      isValid = isValidInput(e);

      if (!isValid) {
        await validateField({ fieldName: name, value: e, fieldObj: 'costOfGoods' });
        return;
      }

      if (name === "costOfGoodTotal") {
        const gstValue = +e > 0 ? parseFloat((+e * (10 / 110))?.toFixed(2)) : 0; // GST is 10% of the total amount inclusive of GST
        // const netValue = +e - gstValue;
        const netValue = parseFloat((+e - gstValue)?.toFixed(2))

        // setAllValue({ ...allValues, [name]: +e, netAssetValue: +netValue, assetGst: +gstValue });
        setNewAllValues({ ...allValues, [name]: e, netAssetValue: netValue, assetGst: gstValue });
        await validateField({ fieldName: name, value: e, fieldObj: 'costOfGoods' });
        return
      }

      if (name === "netAssetValue") {
        const assetGst = +e > 0 ? +(e * 0.10) : 0
        const costOfGoodTotal = +e > 0 ? +e + assetGst : 0

        // setAllValue({ ...allValues, [name]: +e, netAssetValue: +netValue, assetGst: +gstValue });
        setNewAllValues({ ...allValues, [name]: e, costOfGoodTotal: costOfGoodTotal, assetGst: assetGst });
        await validateField({ fieldName: name, value: e, fieldObj: 'costOfGoods' });
        return
      }


      // setAllValue({ ...allValues, [name]: e });
      setNewAllValues({ ...allValues, [name]: e });
      await validateField({ fieldName: name, value: e, fieldObj: 'costOfGoods' });
    },
    blurFn: async (fieldName, value) => {
      try {
        setAllValue({
          ...allValues,
          costOfGoodTotal: handle.formatValueToDecimal(+newAllValues?.costOfGoodTotal),
          assetGst: handle.formatValueToDecimal(+newAllValues?.assetGst),
          netAssetValue: handle.formatValueToDecimal(+newAllValues?.netAssetValue),
          additionalFees: handle.formatValueToDecimal(+newAllValues?.additionalFees),
          additionalTaxes: handle.formatValueToDecimal(+newAllValues?.additionalTaxes),
        })

        if (fieldName === "costOfGoodTotal") {
          // saveAssetDetails({ netAssetValue: +allValues?.netAssetValue?.toFixed(2) || 0 });
          saveAssetDetails({ netAssetValue: handle.formatValueToDecimal(+newAllValues?.netAssetValue) || 0 });
          return
        }

        if (calculatorFieldsErrors['costOfGoods'][fieldName] === "") {
          saveAssetDetails({
            [fieldName]: +value || 0,
          });
        }
      } catch (error) {
        console.log("error", error)
      }
    },
    formatValueToDecimal: (value) => {
      const result = value > 0 && !isNaN(value) && isFinite(value)
        ? parseFloat((value)?.toFixed(2))
        : 0;

      return result
    },
    formatNumberValue: (value) => {
      const result = value > 0 && !isNaN(value) && isFinite(value)
        ? value
        : "";

      return result
    }
  }

  return (
    <Box>
      <CalCustomTitleBar title={"Cost Of Goods"} />

      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        mb={"20px"}
        spacing={1}
        direction="row"
      >
        <Grid item xs={4} sm={4}>
          <PrefixTextField
            name="costOfGoodTotal" // Base on total we need to count net and gst amount
            label="Total"
            value={handle.formatNumberValue(costOfGoodTotal)}
            changeHandler={handle.onChangeField}
            blurHandler={handle.blurFn}
          />
        </Grid>

        <Grid item xs={4} sm={4}>
          <PrefixTextField
            name="netAssetValue"
            label="Net"
            // value={+netAssetValue ? +netAssetValue?.toFixed(2) : 0}
            value={handle.formatNumberValue(netAssetValue)}
            error={calculatorFieldsErrors?.costOfGoods?.netAssetValue}
            changeHandler={handle.onChangeField}
            blurHandler={handle.blurFn}
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <PrefixTextField
            name="assetGst"
            label={"GST"}
            disabled={true}
            value={assetGst ? +assetGst?.toFixed(2) : 0}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PrefixTextField
            name="additionalFees"
            label={"Additional Fees"}
            value={additionalFees}
            error={calculatorFieldsErrors?.costOfGoods?.additionalFees}
            changeHandler={handle.onChangeField}
            blurHandler={handle.blurFn}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PrefixTextField
            name="additionalTaxes"
            label={"Additional Taxes"}
            value={additionalTaxes}
            error={calculatorFieldsErrors?.costOfGoods?.additionalTaxes}
            changeHandler={handle.onChangeField}
            blurHandler={handle.blurFn}
          />
        </Grid>
      </Grid>
    </Box>
  )
})

export default CalCostOfGoods