import React from 'react'
import { Box, Grid } from '@mui/material'
import CalCustomTitleBar from './common/CalCustomTitleBar'
import ShowValues from './common/ShowValues'

const CalAmountFinanced = React.memo(({ allValues }) => {
  const { financedWithBrokerage = 0, financedAmount = 0 } = allValues

  return (
    <Box>
      <CalCustomTitleBar title={"Amount Financed"} />

      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        mb={"20px"}
        spacing={1}
        direction="row"
      >
        <Grid item xs={12} sm={12}>
          <ShowValues
            label={"Excluding brokerage"}
            value={financedAmount?.toFixed(2)}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <ShowValues
            label={"Total"}
            value={financedWithBrokerage?.toFixed(2)}
          />
        </Grid>
      </Grid>
    </Box>
  )
})

export default CalAmountFinanced