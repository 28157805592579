import React from "react";
// import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, TextField } from "@mui/material";
import { APPLICATION_TYPES } from "../../../constants";
import assetTypes from "../../../assetTypes.json";
import consumerAssetTypes from "../../..//utils/consumerAssetTypes";
import { saveValue, userSelector } from "../../..//store/slices/userSlice";
import {
  updateAsset,
  assetSelector
} from "../../../store/slices/applicationFormSlice";

export default function AssetType({ applicationType, disabled }) {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  // const { applicationId } = useParams();
  const { assetType, _id } = useSelector(assetSelector);

  const assetTypesList =
    applicationType === APPLICATION_TYPES.COMMERCIAL ? assetTypes?.assetTypes : consumerAssetTypes;

  function handleUpdateAssetDetails(event) {
    dispatch(updateAsset({ _id, assetType: event.target.value }));
    if (event.target.value === "Motorbike" && user.status === "new") {
      dispatch(saveValue({ walkthroughIndex: 1 }));
    }
  }

  return (
    <TextField
      fullWidth
      variant="filled"
      style={{ background: "#ffffff" }}
      select
      value={assetType || ""}
      label="Asset type"
      name="assetTypeValue"
      size="small"
      onChange={handleUpdateAssetDetails}
      disabled={disabled}
    >
      {assetTypesList?.map((asset, i) => (
        <MenuItem value={asset.label} key={asset.label}>{asset.label}</MenuItem>
      ))}
    </TextField>
  )
}