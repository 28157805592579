import { livingArrangementsTypes, typeOfSaleList } from "../../../constants";
import criteria from "../../../utils/criteria";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } = livingArrangementsTypes;
const { PRIVATE_SALE, MID_TERM_REFINANCE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY } = typeOfSaleList;

const {
  livingArrangements,
  termIsLessThanOrEqualTo,
  assetValueBetween,
  assetType,
  ageOfAssetGreaterThan,
  ageOfAssetBetween,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  gstRegistrationGreaterThan,
  creditScoreGreaterThan,
  brokerageIsEqualTo,
} = criteria;

const primaryAssets = [
  "Motor Vehicle (<4.5 tonnes)",
  "Electric Motor Vehicle (<4.5 tonnes)",
  "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
  "Commercial (Heavy) Vehicles (>12 Tonnes)",
  "Trailers and Caravans",
  "Warehouse Equipment",
  "Earth Moving & Construction (wheeled or tracked)",
  "Agricultural & Landscaping Equipment (wheeled or tracked)",
  "Motorbikes, ATVs",
];

const secondaryAssets = ["Manufacturing Equipment", "Industrial Plant & Printing", "Medical Equipment"];

// const tertiaryAssets = [
//   "IT, Computer & Audio Equipment",
//   "Office Equipment",
//   "Restaurant & Catering Equipment",
//   "Office Furniture",
//   "Health & Beauty Equipment",
//   "Solar",
//   "Other",
// ];

const shift = {
  date: "1 Feb 2024",
  lender: "Shift",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos/shift.png",
  fees: [
    {
      name: "Document fee",
      value: 499,
      capitalised: true,
      frequency: "upfront",
    },
  ],
  lenderCredentials: [
    {
      label: "Email",
      attribute: "email",
    },
    {
      label: "User Name",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
    {
      label: "User ID",
      attribute: "brokerId",
    },
    {
      label: "Api Key",
      attribute: "apiKey",
    },
  ],
  requiredFields: {
    entity: [
      "abn",
      // "addresses[0].streetAddress",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
    ],
    customer: [
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      "firstName",
      "lastName",
      "dateOfBirth",
      // "addresses[0].streetAddress",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "IDNumber",
      "IDCardNumber",
    ],
    loanDetails: ["rate"],
    asset: ["assetType", "typeOfSale", "ageOfAsset", "glassesMake", "glassesModel", "glassesVariant", "assetValue"],
  },
  brokerage: [
    {
      value: 4,
      max: 8,
      valueType: "percentage",
      criteria: [assetValueBetween(0, 24999.99)],
    },
    {
      value: 4,
      max: 7,
      valueType: "percentage",
      criteria: [assetValueBetween(25000, 74999.99)],
    },
    {
      value: 4,
      max: 6,
      valueType: "percentage",
      criteria: [assetValueBetween(75000, 149999.99)],
    },
    {
      value: 4,
      max: 5,
      valueType: "percentage",
      criteria: [assetValueBetween(150000, 249999.99)],
    },
    {
      value: 4,
      max: 4,
      valueType: "percentage",
      criteria: [assetValueBetween(250000, 1000000)],
    },
  ],
  loading: [
    {
      name: PRIVATE_SALE,
      value: 0.5,
      valueType: "percentage",
      criteria: [PRIVATE_SALE],
    },
    {
      name: MID_TERM_REFINANCE,
      value: 0.5,
      valueType: "percentage",
      criteria: [MID_TERM_REFINANCE],
    },
    {
      name: BALLOON_RESIDUAL_REFINANCE,
      value: 0.5,
      valueType: "percentage",
      criteria: [BALLOON_RESIDUAL_REFINANCE],
    },
    {
      name: SALE_BUYBACK_EQUITY,
      value: 2.5,
      valueType: "percentage",
      criteria: [SALE_BUYBACK_EQUITY],
    },
    {
      name: "Brokerage 6%",
      value: 0.5,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(6)],
    },
    {
      name: "Brokerage 7%",
      value: 1,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(7)],
    },
    {
      name: "Brokerage 8%",
      value: 1.5,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(8)],
    },
  ],
  info: [`Minimun turnover $250,000/year`],
  productTiers: general([
    ...primary([...basePrimary(), ...fullDoc()]),
    ...secondary([...baseSecondary(), ...fullDoc()]),
  ]),
};

function general(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      termIsLessThanOrEqualTo(60),
      timeInBusinessGreaterThan(24),
      gstRegistrationGreaterThan(24),
      creditScoreGreaterThan(600),
    ],
  }));
}

function primary(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, assetType(primaryAssets)],
  }));
}

function secondary(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, assetType(secondaryAssets)],
  }));
}

function basePrimary() {
  return [
    {
      name: "Asset - Primary",
      rate: 9.4,
      productType: "Light doc",
      info: [`6-months bank statements (all business-related accounts)`, `Copy of trust deed (if applicable)`],
      criteria: [loanAmountBetween(5000, 500000), livingArrangements([PROPERTY_OWNER]), ageOfAssetBetween(0, 72)],
    },
    {
      name: "Asset - Primary",
      rate: 10.4,
      productType: "Light doc",
      info: [`6-months bank statements (all business-related accounts)`, `Copy of trust deed (if applicable)`],
      criteria: [loanAmountBetween(5000, 500000), livingArrangements([PROPERTY_OWNER]), ageOfAssetBetween(72, 156)],
    },
    {
      name: "Asset - Primary",
      rate: 10.9,
      productType: "Light doc",
      info: [`6-months bank statements (all business-related accounts)`, `Copy of trust deed (if applicable)`],
      criteria: [loanAmountBetween(5000, 500000), livingArrangements([PROPERTY_OWNER]), ageOfAssetGreaterThan(156)],
    },
    {
      name: "Asset - Primary",
      rate: 11.1,
      productType: "Light doc",
      info: [`6-months bank statements (all business-related accounts)`, `Copy of trust deed (if applicable)`],
      criteria: [
        loanAmountBetween(5000, 200000),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        ageOfAssetBetween(0, 72),
      ],
    },
    {
      name: "Asset - Primary",
      rate: 12.1,
      productType: "Light doc",
      info: [`6-months bank statements (all business-related accounts)`, `Copy of trust deed (if applicable)`],
      criteria: [
        loanAmountBetween(5000, 200000),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        ageOfAssetBetween(72, 156),
      ],
    },
    {
      name: "Asset - Primary",
      rate: 12.6,
      productType: "Light doc",
      info: [`6-months bank statements (all business-related accounts)`, `Copy of trust deed (if applicable)`],
      criteria: [
        loanAmountBetween(5000, 200000),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        ageOfAssetGreaterThan(156),
      ],
    },
  ];
}

function baseSecondary() {
  return [
    {
      name: "Asset - Secondary",
      rate: 10.9,
      productType: "Light doc",
      info: [`6-months bank statements (all business-related accounts)`, `Copy of trust deed (if applicable)`],
      criteria: [loanAmountBetween(5000, 500000), livingArrangements([PROPERTY_OWNER]), ageOfAssetBetween(0, 72)],
    },
    {
      name: "Asset - Secondary",
      rate: 10.9,
      productType: "Light doc",
      info: [`6-months bank statements (all business-related accounts)`, `Copy of trust deed (if applicable)`],
      criteria: [loanAmountBetween(5000, 500000), livingArrangements([PROPERTY_OWNER]), ageOfAssetBetween(72, 156)],
    },
    {
      name: "Asset - Secondary",
      rate: 12.3,
      productType: "Light doc",
      info: [`6-months bank statements (all business-related accounts)`, `Copy of trust deed (if applicable)`],
      criteria: [loanAmountBetween(5000, 500000), livingArrangements([PROPERTY_OWNER]), ageOfAssetGreaterThan(156)],
    },
    {
      name: "Asset - Secondary",
      rate: 11.6,
      productType: "Light doc",
      info: [`6-months bank statements (all business-related accounts)`, `Copy of trust deed (if applicable)`],
      criteria: [
        loanAmountBetween(5000, 100000),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        ageOfAssetBetween(0, 72),
      ],
    },
    {
      name: "Asset - Secondary",
      rate: 13.7,
      productType: "Light doc",
      info: [`6-months bank statements (all business-related accounts)`, `Copy of trust deed (if applicable)`],
      criteria: [
        loanAmountBetween(5000, 100000),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        ageOfAssetBetween(72, 156),
      ],
    },
    {
      name: "Asset - Secondary",
      rate: 14.3,
      productType: "Light doc",
      info: [`6-months bank statements (all business-related accounts)`, `Copy of trust deed (if applicable)`],
      criteria: [
        loanAmountBetween(5000, 100000),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        ageOfAssetGreaterThan(156),
      ],
    },
  ];
}

function fullDoc() {
  return [
    {
      name: "Asset - Full doc",
      rate: 9.4,
      productType: "Full doc",
      info: [
        `Up-to-date financial statements (including ages accounts payable & accounts receivable)`,
        `Copy of ATO portal`,
      ],
      criteria: [loanAmountBetween(500000, 1000000), livingArrangements([PROPERTY_OWNER]), ageOfAssetBetween(0, 72)],
    },
    {
      name: "Asset - Full doc",
      rate: 10.4,
      productType: "Full doc",
      info: [
        `Up-to-date financial statements (including ages accounts payable & accounts receivable)`,
        `Copy of ATO portal`,
      ],
      criteria: [loanAmountBetween(500000, 1000000), livingArrangements([PROPERTY_OWNER]), ageOfAssetBetween(72, 156)],
    },
    {
      name: "Asset - Full doc",
      rate: 10.9,
      productType: "Full doc",
      info: [
        `Up-to-date financial statements (including ages accounts payable & accounts receivable)`,
        `Copy of ATO portal`,
      ],
      criteria: [loanAmountBetween(500000, 1000000), livingArrangements([PROPERTY_OWNER]), ageOfAssetGreaterThan(156)],
    },
  ];
}

export default shift;
