import React from 'react';
import { Box, Typography } from '@mui/material';
import { ID_TYPE } from '../../../src/constants';

const LicencePassportToggle = ({ allValues, setAllValue, handleSaveIDType }) => {

  const handleToggle = (value) => {
    setAllValue({
      ...allValues,
      IDType: value
    })
    handleSaveIDType(value)
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="start">
      {allValues?.IDType === ID_TYPE.LICENCE ? (
        <Typography
          variant="body2"
          // color={allValues?.IDType === ID_TYPE.PASSPORT ? 'primary' : 'inherit'}
          color={'primary'}
          onClick={() => handleToggle(ID_TYPE.PASSPORT)}
          sx={{
            display: 'inline',
            cursor: 'pointer',
            // fontWeight: allValues?.IDType === ID_TYPE.PASSPORT ? 'bold' : 'normal',
            fontWeight: 'normal',
            ml: '12px'
          }}
        >
          Use {ID_TYPE.PASSPORT}
        </Typography>
      ) : (
        <Typography
          variant="body2"
          // color={allValues?.IDType === ID_TYPE.LICENCE ? 'primary' : 'inherit'}
          color={'primary'}
          onClick={() => handleToggle(ID_TYPE.LICENCE)}
          sx={{
            display: 'inline',
            cursor: 'pointer',
            // fontWeight: allValues?.IDType !== ID_TYPE.LICENCE ? 'bold' : 'normal',
            fontWeight: 'normal',
            ml: '12px'
          }}
        >
          Use {ID_TYPE.LICENCE}
        </Typography>
      )}
    </Box>
  )
}


export default LicencePassportToggle;