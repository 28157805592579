import { useEffect } from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { userSelector } from "../store/slices/userSlice";

export default function Login() {
  const user = useSelector(userSelector);

  useEffect(() => {
    // if (user) navigate("/login");
  }, [user]);

  return (
    <>
      <Grid container style={{ padding: "30px 50px 100px" }}>
        <Grid container item xs={12} justifyContent="center">
          <Grid item direction="column" sm={12} md={6}>
            <Typography variant="h1" style={{ fontSize: "30px", marginBottom: "20px" }}>
              Whoops!
            </Typography>
            <Typography variant="h2" style={{ fontSize: "24px", marginBottom: "30px" }}>
              Looks like you've gotten lost.
            </Typography>
            <Typography style={{ marginBottom: "50px" }}>
              Head back to the <a href="https://financeable.com.au">homepage</a>.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
