import { livingArrangementsTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } = livingArrangementsTypes;

const {
  assetType,
  ageOfAssetBetween,
  ageOfAssetLessThan,
  ageOfAssetGreaterThan,
  loanAmountBetween,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
  creditScoreBetween,
  dateOfBirthGreaterThan,
  dateOfBirthLessThan,
} = criteria;

const aPlus = [
  {
    name: "A+ Tier Under 23 Years Old - Motor Vehicle New",
    rate: 11.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 25000),
      ageOfAssetLessThan(12),
      dateOfBirthLessThan(23),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType(["Self employed", "Full-time", "Part-time", "Contract", "Casual"]),
      timeOfEmploymentGreaterThan(12),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      creditScoreBetween(611, 700),
    ],
  },
  {
    name: "A+ Tier Under 23 Years Old - Motor Vehicle 12-60 Months Old",
    rate: 12.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 25000),
      ageOfAssetBetween(12, 59),
      dateOfBirthLessThan(23),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType(["Self employed", "Full-time", "Part-time", "Contract", "Casual"]),
      timeOfEmploymentGreaterThan(12),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      creditScoreBetween(611, 700),
    ],
  },
  {
    name: "A+ Tier Under 23 Years Old - Motor Vehicle 60-119 Months Old",
    rate: 13.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 25000),
      ageOfAssetBetween(60, 119),
      dateOfBirthLessThan(23),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType(["Self employed", "Full-time", "Part-time", "Contract", "Casual"]),
      timeOfEmploymentGreaterThan(12),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      creditScoreBetween(611, 700),
    ],
  },
  {
    name: "A+ Tier Under 23 Years Old - Motor Vehicle >119 Months Old",
    rate: 13.99,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 25000),
      ageOfAssetGreaterThan(119),
      dateOfBirthLessThan(23),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType(["Self employed", "Full-time", "Part-time", "Contract", "Casual"]),
      timeOfEmploymentGreaterThan(12),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      creditScoreBetween(611, 700),
    ],
  },

  {
    name: "A+ Tier Over 23 Years Old - Motor Vehicle New",
    rate: 11.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 50000),
      ageOfAssetLessThan(12),
      dateOfBirthGreaterThan(23),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType(["Self employed", "Full-time", "Part-time", "Contract", "Casual"]),
      timeOfEmploymentGreaterThan(12),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      creditScoreBetween(611, 700),
    ],
  },
  {
    name: "A+ Tier Over 23 Years Old - Motor Vehicle 12-60 Months Old",
    rate: 12.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 50000),
      ageOfAssetBetween(12, 59),
      dateOfBirthGreaterThan(23),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType(["Self employed", "Full-time", "Part-time", "Contract", "Casual"]),
      timeOfEmploymentGreaterThan(12),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      creditScoreBetween(611, 700),
    ],
  },
  {
    name: "A+ Tier Over 23 Years Old - Motor Vehicle 60-119 Months Old",
    rate: 13.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 50000),
      ageOfAssetBetween(60, 119),
      dateOfBirthGreaterThan(23),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType(["Self employed", "Full-time", "Part-time", "Contract", "Casual"]),
      timeOfEmploymentGreaterThan(12),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      creditScoreBetween(611, 700),
    ],
  },
  {
    name: "A+ Tier Over 23 Years Old - Motor Vehicle >119 Months Old",
    rate: 13.99,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 50000),
      ageOfAssetGreaterThan(119),
      dateOfBirthGreaterThan(23),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),
      employmentType(["Self employed", "Full-time", "Part-time", "Contract", "Casual"]),
      timeOfEmploymentGreaterThan(12),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      creditScoreBetween(611, 700),
    ],
  },
];
export default aPlus;
