import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { set2faCode } from "../../store/slices/applicationFormSlice";

const VerifyDialog = ({ verifiedDialogBox, title, message, setVerifiedDialogBox }) => {
  const dispatch = useDispatch();
  // const { enqueueSnackbar } = useSnackbar();

  const [code, setCode] = useState("");
  // const [fieldError, setFieldError] = useState("");

  // useEffect(() => {
  //   return () => {
  //     socket.off("disconnect", () => {});
  //   };
  // }, [socket]);

  const handle = {
    closeDialog: () => {
      setVerifiedDialogBox({ isOpen: false });
    },
    userVerified: async () => {
      // socket.emit("2fa-code", {
      //   code: code,
      // });
      dispatch(
        set2faCode({
          applicationId: verifiedDialogBox?.applicationId,
          lenderType: verifiedDialogBox?.lenderName,
          lenderName: verifiedDialogBox?.lenderName?.toLowerCase(),
          code,
        }),
      );
      setCode("");
      handle.closeDialog();
      return;
    },
  };

  return (
    <React.Fragment>
      <Dialog open={verifiedDialogBox?.isOpen} onClose={handle.closeDialog} fullWidth maxWidth={"sm"}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
          <TextField
            autoFocus
            label="Verify code"
            type="number"
            value={code}
            onChange={(event) => setCode(event.target.value)}
            fullWidth
            variant="filled"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handle.closeDialog} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handle.userVerified} variant="outlined">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default VerifyDialog;
