import React from 'react'
import { Stack, Typography } from '@mui/material'

const ShowValues = ({ label, value }) => (
  <Stack direction={'row'} spacing={1} alignItems={'center'}>
    <Typography
      variant="subtitle1"
      sx={{
        // fontWeight: "bold",
        fontSize: "18px"
        // position: "relative",
        // mb: "10px",
        // mt: "10px",
      }}
    >
      {label || ""} :
    </Typography>
    <Typography
      variant="subtitle1"
      sx={{
        fontWeight: "bold",
        fontSize: "18px"
        // position: "relative",
        // mb: "10px",
        // mt: "10px",
      }}
    >
      {value}
    </Typography>
  </Stack>

)

export default ShowValues