import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Slider, TextField, Typography, useMediaQuery } from "@mui/material";
import * as Yup from "yup";
import PhoneNumber from "./PhoneNumber";
import { saveCustomerProfile } from "../../store/slices/applicationFormSlice";
import regex from "../../utils/regex";
import { NumericFormatCustom, currencyMaskedValue } from "../../utils/currencyMaskFormat";
import RadioButtonGroup from "../Utils/RadioButtonGroup";
import { userPermissionsSelector } from "../../store/slices/userSlice";
import { customerSelector } from "../../store/slices/customerSlice";

const CustomerPersonalProfile = React.memo(({ customer, customerId, applicationId }) => {
  const dispatch = useDispatch();
  const permissions = useSelector(userPermissionsSelector);
  const { isCustomerLogin } = useSelector(customerSelector);
  const isDisable = isCustomerLogin ? true : permissions?.application?.edit;

  const [allValues, setAllValue] = useState({
    livingArrangementsValue: customer?.customerProfile?.livingArrangements,
    dependentsValue: customer?.customerProfile?.numberOfDependants,
    RentAmountMonthly: customer?.customerProfile?.rentAmount || "",
    contactNumber: customer?.customerProfile?.contactNumber || "",
  });

  const [errorField, setErrorField] = useState({
    contactNumber: "",
    RentAmountMonthly: "",
  });

  useEffect(() => {
    if (customer) {
      setAllValue({
        livingArrangementsValue: customer?.customerProfile?.livingArrangements,
        dependentsValue: customer?.customerProfile?.numberOfDependants,
        RentAmountMonthly: customer?.customerProfile?.rentAmount,
        contactNumber: customer?.customerProfile?.contactNumber,
      })
    }
  }, [customer])

  const validationSchema = Yup.object({
    RentAmountMonthly: Yup.string()
      .matches(regex.allowOnlyNumber, "Only numbers and decimal points allowed.")
      .min(3, "Minimum of 3 digits")
      .max(10, "Maximum of 7 digits"),
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setErrorField((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setErrorField((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  const handle = {
    onChangeField: async (value, name) => {
      setAllValue({ ...allValues, [name]: value });
      await validateField(name, value);
    },
    saveCustomerProfile: (fieldName, value) => {
      if (customer?.customerProfile?.[fieldName] === value) {
        return;
      }
      const data = {
        [fieldName]: value,
        numberOfDependants: allValues?.dependentsValue || 0,
        customer: customer._id,
        applicationId: applicationId,
      };

      if (fieldName !== "rentAmount" && fieldName !== "contactNumber" && value !== "Renting") {
        data.RentAmountMonthly = "";
        data.contactNumber = "";
      }

      dispatch(saveCustomerProfile({ data }));
    },
    numberOfDependant: (event, value) => {
      if (customer?.customerProfile?.numberOfDependants === value) {
        return;
      }
      dispatch(
        saveCustomerProfile({
          data: {
            numberOfDependants: value,
            customer: customer._id,
          applicationId: applicationId,
          },
        }),
      );
    },
  };

  const isLargeScreen = useMediaQuery("(min-width: 768px)");

  return (
    <React.Fragment>
      <Grid
        container
        item
        xs={12}
        style={{
          margin: "0 0 30px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          paddingBottom: "30px",
        }}
      >
        <Grid item md={12} sm={12} style={{ margin: "0 0 10px" }}>
          <Typography fontWeight={600}>Personal Profile</Typography>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} sm={7}>
            <Typography variant="subtitle2">Property/Living arrangements</Typography>
            <RadioButtonGroup
              value={allValues?.livingArrangementsValue}
              name={"livingArrangementsValue"}
              disabled={!isDisable}
              handleValue={(value) => {
                handle.onChangeField(value, "livingArrangementsValue");
                handle.saveCustomerProfile("livingArrangements", value);
              }}
              style={{
                margin: "0 0 0 8px",
                flexWrap: `${!isLargeScreen ? "wrap" : "unset"}`,
              }}
              options={[
                { icon: "House", label: "Property owner" },
                { icon: "HomeWork", label: "Renting" },
                { icon: "Hotel", label: "Boarding" },
                { icon: "Living w/ parents", label: "Living w/ parents" },
              ]}
            />
          </Grid>

          {/* {allValues?.livingArrangementsValue === "Property owner" && ( */}
          <Grid item xs={11} sm={5} margin={"0 0 10px"}>
            <Typography
              variant="subtitle2"
              gutterBottom
            // style={{ marginBottom: "40px" }}
            >
              Number of dependents
            </Typography>
            <Box style={{ padding: "0 7px" }}>
              <Slider
                required
                color="secondary"
                size="large"
                marks={[
                  { value: 0, label: 0 },
                  { value: 1, label: 1 },
                  { value: 2, label: 2 },
                  { value: 3, label: 3 },
                  { value: 4, label: 4 },
                  { value: 5, label: 5 },
                  { value: 6, label: 6 },
                  { value: 7, label: 7 },
                  { value: 8, label: 8 },
                  { value: 9, label: 9 },
                  { value: 10, label: "10+" },
                ]}
                step={1}
                min={0}
                max={10}
                disabled={!isDisable}
                aria-label="Default"
                valueLabelDisplay="auto"
                onChange={(event) =>
                  setAllValue({
                    ...allValues,
                    dependentsValue: event.target.value,
                  })
                }
                defaultValue={allValues?.dependentsValue}
                onChangeCommitted={handle.numberOfDependant}
              />
            </Box>
          </Grid>
          {/* )} */}
        </Grid>
        {allValues?.livingArrangementsValue === "Renting" && (
          <Grid container item xs={12} spacing={2} sx={{ marginTop: "10px" }}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Landlord/Agent details</Typography>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={12} sm={6}>
                  <PhoneNumber
                    label={"Contact number"}
                    singleCustomerId={customerId}
                    customerId={customer?._id}
                    guarantorFieldErrors={errorField}
                    setGuarantorFieldErrors={setErrorField}
                    saveContactNum={handle.saveCustomerProfile}
                    phoneNumber={allValues?.contactNumber}
                    applicationId={applicationId}
                  // phoneNumber={
                  //   customer ? customer?.customerProfile?.contactNumber : ""
                  // }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="outlined-basic"
                    label={"Rent amount monthly"}
                    value={allValues?.RentAmountMonthly}
                    variant="filled"
                    size="small"
                    fullWidth
                    disabled={!isDisable}
                    error={errorField.RentAmountMonthly}
                    helperText={errorField.RentAmountMonthly}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                    onChange={(e) => handle.onChangeField(e.target.value, "RentAmountMonthly")}
                    onBlur={(e) => handle.saveCustomerProfile("rentAmount", currencyMaskedValue(e.target.value))}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
});

export default CustomerPersonalProfile;
