import a from "./a";
import b from "./b";
import c from "./c";
import { typeOfSaleList, employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { FULL_TIME, PART_TIME, CASUAL, CONTRACT, SELF_EMPLOYED } = employmentTypes;

const { typeOfSale, assetValueBetween, assetType, employmentType, timeOfEmploymentGreaterThan } = criteria;

function selfEmployedCasual(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, employmentType([SELF_EMPLOYED, CASUAL]), timeOfEmploymentGreaterThan(12)],
  }));
}

function fullTime(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, employmentType([FULL_TIME]), timeOfEmploymentGreaterThan(3)],
  }));
}

function partTimeContractor(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, employmentType([PART_TIME, CONTRACT]), timeOfEmploymentGreaterThan(6)],
  }));
}

const plenti = {
  date: "01 Jul 2023",
  lender: "Plenti",
  type: "consumer",
  logoURL: "https://assets.financeable.com.au/lender-logos%2Fplenti-logo.gif",
  actions: ["upload"],
  lenderCredentials: [
    {
      label: "Partner ID",
      attribute: "organisationId",
    },
  ],
  requiredFields: {
    asset: [
      "assetValue",
      "assetType",
      "typeOfSale",
      "ageOfAsset",
      "condition",
      "glassesMake",
      "glassesModel",
      // "Make", 
      // "Model",
    ],
    customer: [
      "customerTitle",
      "firstName",
      "lastName",
      "dateOfBirth",
      "IDNumber",
      "IDCardNumber",
      "IDExpiryDate",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      // "addresses[1].fullAddress", // What if user not have previous address ?
      // "addresses[1].city",
      // "addresses[1].state",
      // "addresses[1].postCode",
      // "addresses[1].monthsAt",
      // "addresses[1].yearsAt",
      "customerProfile.livingArrangements",
      "customerProfile.numberOfDependants",
      "customerProfile.residencyStatus",
      "customerProfile.maritalStatus",
      "customerAssets.[0].assetType",
      "customerAssets.[0].value",
      "customerAssets.[0].description",
      "liabilities.[0].liabilitiesType",
      "liabilities.[0].outstandingAmount",
      "liabilities.[0].limit",
      "liabilities.[0].financier",
      "liabilities.[0].repaymentFrequency",
      "liabilities.[0].repaymentAmount",
      "liabilities.[0].description",
      "income.[0].incomeType",
      "income.[0].amount",
      "income.[0].frequency",
      "expenses.[0].expensesType",
      "expenses.[0].amount",
      "expenses.[0].frequency",
      "employers.[0].entityName",
      "employers.[0].status",
      "employers.[0].timeOfEmployment",
      "employers.[0].occupation",
      "employers.[0].manager",
      "employers.[0].phoneNumber",
      "employers.[0].industry",
      "employers.[0].address.fullAddress",
      "employers.[0].address.city",
      "employers.[0].address.state",
    ],
    loanDetails: [
      // "rate"
      // "term",
      // "balloon",
      // "deposit",
      // "tradeIn",
      // "payout",
      // "rate",
      // "rateAdjustment",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Establishment fee",
      value: 499,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        typeOfSale(typeOfSaleList.DEALER_SALE),
        assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      ],
    },
    {
      name: "Establishment fee",
      value: 299,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.DEALER_SALE), assetType(["Motorbike"])],
    },
    {
      name: "Private sale",
      value: 599,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        typeOfSale(typeOfSaleList.PRIVATE_SALE),
        assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"]),
      ],
    },
    {
      name: "Private sale",
      value: 399,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.PRIVATE_SALE), assetType(["Motorbike"])],
    },
    {
      name: "Origination fee",
      value: 990,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Monthly account fee",
      value: 9.9,
      capitalised: false,
      frequency: "monthly",
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 4,
      valueType: "percentage",
      criteria: [assetValueBetween(0, 1000000)],
    },
  ],
  loading: [
    {
      name: "EV discount",
      value: -0.5,
      valueType: "percentage",
      criteria: [assetType(["Electric Motor Vehicle"])],
    },
    {
      name: "Caravans",
      value: 0.75,
      valueType: "percentage",
      criteria: [assetType(["Caravan"])],
    },
    {
      name: "Motorbikes",
      value: 1,
      valueType: "percentage",
      criteria: [assetType(["Motorbike"])],
    },
  ],
  info: [
    `
      New 12 months or less from build date / Demo 24 months or less from build date and under 5000kms
    `,
    `
      "Asset backed customers with credit score <600 are subject to $40,000 max lend and 115% LVR"
    `,
  ],
  productTiers: [
    ...selfEmployedCasual([...a, ...b, ...c]),
    ...fullTime([...a, ...b, ...c]),
    ...partTimeContractor([...a, ...b, ...c]),
  ],
};
export default plenti;
