import React from "react";
// import { useSelector } from "react-redux";
import { Checkbox, FormControl, Input, InputLabel, MenuItem, Select } from "@mui/material";
import { isArray, startCase } from "lodash";
// import { userSelector } from "../../store/slices/userSlice";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "75vh",
      width: 300,
    },
  },
};

const ProductSelectorFilterby = ({ list, handleFilterBy, value, title, multiple, screenSize }) => {
  // const user = useSelector(userSelector);

  const generateDistinctId = () => `product-selector-${title.toLowerCase().replace(/\s+/g, "-")}-input`;

  const handleSelectAll = (event) => {
    const emptyEvent = { target: { value: [] } };
    if (!value[0]) emptyEvent.target.value = list;
    setTimeout(() => handleFilterBy(emptyEvent), 50);
  };

  return (
    <FormControl
      className={title === "Must match" ? "seventh-step" : ""}
      fullWidth
      size="small"
      sx={{ m: 1 }}
      style={{ margin: "0 0 10px 0", width: screenSize.dynamicWidth < 900 ? 120 : 160 }}
    >
      <InputLabel id="demo-multiple-chip-label">{title}</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id={generateDistinctId()}
        multiple={multiple}
        value={value}
        onChange={handleFilterBy}
        style={{ marginTop: "10px", padding: "0 15px", flexDirection: "row" }}
        input={<Input id="select-multiple-chip" label="Name" />}
        renderValue={(selected) => (multiple ? `${selected.length} selected` || undefined : selected)}
        MenuProps={MenuProps}
        size="small"
      >
        {multiple && (
          <MenuItem onClick={handleSelectAll}>
            <Checkbox checked={value?.[0] ? true : false} />
            {/* Condition removed: user.status === "new" */}
            {isArray(value) && value[0] ? "Deselect all" : "Select all"}
          </MenuItem>
        )}
        {isArray(list) && list.map((param) => (
          <MenuItem key={param} value={param}>
            {multiple && <Checkbox checked={value?.includes(param)} />}
            {title === "Must match" ? startCase(param) : param}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ProductSelectorFilterby;
