import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import { requestResetPassword } from "../../store/slices/userSlice";
import regex from "../../utils/regex";

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const handle = {
    onChangeFn: (value) => {
      setEmail(value);
      if (regex.email.test(value.toLowerCase())) setEmailError(false);
      else setEmailError(true);
    },
    handleRequestResetPassword: async () => {
      if (regex.email.test(email.toLowerCase())) await dispatch(requestResetPassword(email)).unwrap();
      navigate("/applications");
    },
  };

  return (
    <>
      <Grid
        container
        item
        height="calc(100vh - 64px)"
        width="100%"
        justifyContent="center"
        alignItems="center"
        direction="row-reverse"
        marginTop="-64px"
      >
        <Grid
          container
          item
          xs={12}
          sm={6}
          justifyContent="center"
          alignItems="center"
          direction="column"
          style={{ padding: "100px" }}
        >
          <Typography
            variant="h4"
            style={{
              letterSpacing: "-1.8px",
              fontWeight: 800,
              marginBottom: "10px",
            }}
          >
            Forgot password
          </Typography>
          <Typography fontWeight="400" style={{ marginBottom: "10px" }}>
            What is the email address for your account?
          </Typography>

          <TextField
            fullWidth
            type="email"
            name="email"
            label="Email"
            value={email}
            onChange={(event) => handle.onChangeFn(event.target.value)}
            margin="dense"
            error={emailError}
            helperText={emailError ? "Invalid email." : null}
          />

          <LoadingButton
            fullWidth
            loading={""}
            loadingPosition="end"
            variant="contained"
            disabled={""}
            onClick={() => handle.handleRequestResetPassword()}
            style={{ marginTop: "20px" }}
          >
            Request reset password
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
}
