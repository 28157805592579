import React from 'react'
import { Box, Grid } from '@mui/material'
import CalCustomTitleBar from './common/CalCustomTitleBar'
import ShowValues from './common/ShowValues'

const CalAllRepayment = React.memo(({ allValues }) => {
  const { allRepayments, totalExcRepayments, totalINCRepayments } = allValues

  return (
    <Box>
      <CalCustomTitleBar title={"All Repayments"} />

      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        mb={"20px"}
        spacing={1}
        direction="row"
      >
        <Grid item xs={12} sm={12}>
          <ShowValues
            label={"Net"}
            value={allRepayments?.toFixed(2)}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <ShowValues
            label={"Total (exc Residual)"}
            value={totalExcRepayments?.toFixed(2)}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <ShowValues
            label={"Total (inc Residual)"}
            value={totalINCRepayments?.toFixed(2)}
          />
        </Grid>
      </Grid>
    </Box>
  )
})

export default CalAllRepayment