import { type FC, useEffect } from "react";
import { Loader } from "../../components/Loader";
import { VITE_WORKOS_AUTHKIT_DOMAIN } from "../../constants";

const SignIn: FC = () => {
  // Redirect to the WorkOS sign-in page.
  useEffect(() => {
    window.location.replace(VITE_WORKOS_AUTHKIT_DOMAIN);
  }, []);

  return <Loader />;
};

export { SignIn };
