import criteria from "../../../utils/criteria";

const {
  assetType,
  creditScoreGreaterThan,
  creditScoreBetween,
  ageOfAssetLessThan,
  ageOfAssetAtEndLessThan,
  assetValueBetween,
} = criteria;

const base = [
  {
    name: "Secured",
    rate: 10.74,
    productType: "Standard",
    criteria: [
      assetValueBetween(10000, 100000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      ageOfAssetLessThan(84),
      ageOfAssetAtEndLessThan(17),
      creditScoreGreaterThan(951),
    ],
  },
  {
    name: "Secured",
    rate: 10.99,
    productType: "Standard",
    criteria: [
      assetValueBetween(10000, 100000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      ageOfAssetLessThan(84),
      ageOfAssetAtEndLessThan(17),
      creditScoreBetween(901, 950),
    ],
  },
  {
    name: "Secured",
    rate: 11.49,
    productType: "Standard",
    criteria: [
      assetValueBetween(10000, 100000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      ageOfAssetLessThan(84),
      ageOfAssetAtEndLessThan(17),
      creditScoreBetween(851, 900),
    ],
  },
  {
    name: "Secured",
    rate: 11.99,
    productType: "Standard",
    criteria: [
      assetValueBetween(10000, 100000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      ageOfAssetLessThan(84),
      ageOfAssetAtEndLessThan(17),
      creditScoreBetween(801, 850),
    ],
  },
  {
    name: "Secured",
    rate: 12.99,
    productType: "Standard",
    criteria: [
      assetValueBetween(10000, 100000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      ageOfAssetLessThan(84),
      ageOfAssetAtEndLessThan(17),
      creditScoreBetween(751, 800),
    ],
  },
  {
    name: "Secured",
    rate: 14.49,
    productType: "Standard",
    criteria: [
      assetValueBetween(10000, 100000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      ageOfAssetLessThan(84),
      ageOfAssetAtEndLessThan(17),
      creditScoreBetween(701, 750),
    ],
  },
  {
    name: "Secured",
    rate: 16.49,
    productType: "Standard",
    criteria: [
      assetValueBetween(10000, 100000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      ageOfAssetLessThan(84),
      ageOfAssetAtEndLessThan(17),
      creditScoreBetween(651, 700),
    ],
  },
  {
    name: "Secured",
    rate: 18.49,
    productType: "Standard",
    criteria: [
      assetValueBetween(10000, 100000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle"]),
      ageOfAssetLessThan(84),
      ageOfAssetAtEndLessThan(17),
      creditScoreBetween(601, 650),
    ],
  },
];

export default base;
