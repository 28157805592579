import criteria from "../../utils/criteria";
import { typeOfSaleList, livingArrangementsTypes } from "../../constants";

const { PRIVATE_SALE, DEALER_SALE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE, BALLOON_RESIDUAL_REFINANCE } =
  typeOfSaleList;

const { PROPERTY_OWNER, LIVING_WITH_PARENTS, RENTING, BOARDING } = livingArrangementsTypes;

const {
  livingArrangements,
  gstRegistrationGreaterThan,
  assetValueBetween,
  assetType,
  ageOfAssetBetween,
  ageOfAssetGreaterThan,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  creditScoreGreaterThan,
  typeOfSale,
} = criteria;

const afs = {
  date: "01 Feb 2024",
  lender: "AFS",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos/afs.png",
  actions: ["upload"],
  lenderCredentials: [
    {
      label: "Username",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    {
      label: "Pin number",
      attribute: "pin",
    }
  ],
  requiredFields: {
    asset: [
      "assetValue",
      "assetType",
      "typeOfSale",
      "ageOfAsset",
      "condition",
      "glassesMake",
      "glassesModel",
      // "Make", 
      // "Model",
    ],
    loanDetails: [
      // "rate"
      // "term",
      // "balloon",
      // "deposit",
      // "tradeIn",
      // "payout",
      // "rate",
      // "brokerageAmount",
    ],
    entity: [
      "abn",
      "entityName",
      "entityType",
      "industryType",
      "turnover",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      // "addresses[0].monthsAt",
      // "addresses[0].yearsAt",
    ],
    customer: [
      "customerTitle",
      "firstName",
      "lastName",
      "dateOfBirth",
      "IDNumber",
      "IDCardNumber",
      "IDExpiryDate",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "customerProfile.livingArrangements",
      "customerProfile.numberOfDependants",
      "customerProfile.residencyStatus",
      "customerProfile.maritalStatus",
      "customerAssets.[0].assetType",
      "customerAssets.[0].value",
      "customerAssets.[0].description",
      "liabilities.[0].liabilitiesType",
      "liabilities.[0].outstandingAmount",
      "liabilities.[0].limit",
      "liabilities.[0].financier",
      "liabilities.[0].repaymentFrequency",
      "liabilities.[0].repaymentAmount",
      "liabilities.[0].description",
    ],
  },
  fees: [
    {
      name: "Authorised supplier",
      value: 495,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(DEALER_SALE)],
    },
    {
      name: "Private sale",
      value: 495,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(PRIVATE_SALE)],
    },
    {
      name: "Origination fee",
      value: 990,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Account fee",
      value: 5.5,
      capitalised: false,
      frequency: "monthly",
    },
  ],
  brokerage: [
    {
      value: 0,
      max: 5,
      valueType: "percentage",
      criteria: [assetValueBetween(5000, 2500000)],
    },
  ],
  loading: [
    {
      name: "Private sale",
      value: 0.7,
      valueType: "percentage",
      criteria: [typeOfSale([PRIVATE_SALE])],
    },
  ],
  info: [
    `
      New ABNs considered
    `,
    `
     Contact lender for Balloon amounts - balloons for assets upto 6 years at start are offered.
    `,
    `
      MV restriction upto <7.5T
    `,
    `
      Asset backed: Equity in a property owned by the director will be used as comparable credit as long as
the equity amount is greater than the amount being borrowed
    `,
    `
      Non- Asset backed: Same amount as current repayments of all directors’ personal and car loans, loans
being used for comparable credit must been running for 12 months, we can also use
loans that have been paid out in the last 6 months
    `,
    `
      LVR - 150% for Dealer Sale and 130% for Private Sale
    `,
  ],
  productTiers: [
    {
      name: "Low doc",
      rate: 8.97,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 75000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(6),
        ageOfAssetBetween(0, 6),
        ageOfAssetAtEndLessThan(16),
        livingArrangements([PROPERTY_OWNER]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
        ]),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Low doc",
      rate: 10.27,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 50000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(6),
        ageOfAssetBetween(0, 6),
        ageOfAssetAtEndLessThan(16),
        livingArrangements([LIVING_WITH_PARENTS, RENTING, BOARDING]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
        ]),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Low doc",
      rate: 10.27,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 35000),
        timeInBusinessGreaterThan(24),
        ageOfAssetBetween(0, 6),
        ageOfAssetAtEndLessThan(16),
        livingArrangements([LIVING_WITH_PARENTS, RENTING, BOARDING]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
        ]),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Low doc",
      rate: 11.97,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 75000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(6),
        ageOfAssetGreaterThan(6),
        ageOfAssetAtEndLessThan(16),
        livingArrangements([PROPERTY_OWNER]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
        ]),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Low doc",
      rate: 14.47,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 50000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(6),
        ageOfAssetGreaterThan(6),
        ageOfAssetAtEndLessThan(16),
        livingArrangements([LIVING_WITH_PARENTS, BOARDING, RENTING]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
        ]),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Low doc",
      rate: 14.47,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 35000),
        timeInBusinessGreaterThan(24),
        ageOfAssetGreaterThan(6),
        ageOfAssetAtEndLessThan(16),
        livingArrangements([LIVING_WITH_PARENTS, BOARDING, RENTING]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
        ]),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(500),
      ],
    },

    {
      name: "Full doc",
      rate: 8.97,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(6),
        ageOfAssetBetween(0, 6),
        ageOfAssetAtEndLessThan(16),
        livingArrangements([PROPERTY_OWNER]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
        ]),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Full doc",
      rate: 10.27,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        ageOfAssetBetween(0, 6),
        ageOfAssetAtEndLessThan(16),
        livingArrangements([LIVING_WITH_PARENTS, RENTING, BOARDING]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
        ]),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Full doc",
      rate: 11.97,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(6),
        ageOfAssetGreaterThan(6),
        ageOfAssetAtEndLessThan(16),
        livingArrangements([PROPERTY_OWNER]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
        ]),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Full doc",
      rate: 14.47,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        ageOfAssetAtEndLessThan(16),
        livingArrangements([LIVING_WITH_PARENTS, RENTING, BOARDING]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
        ]),
        typeOfSale([DEALER_SALE, PRIVATE_SALE, BALLOON_RESIDUAL_REFINANCE, SALE_BUYBACK_EQUITY, MID_TERM_REFINANCE]),
        creditScoreGreaterThan(500),
      ],
    },
  ],
};
export default afs;
