import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  // DialogContentText,
  DialogActions,
  Grid,
  Typography,
} from "@mui/material";
import { dollarStringOptions } from "../../constants";
import DataGridTable from "../dataGridTable/DataGridTable";

const AmortisationModal = ({
  AmortisationScheduleList,
  openAmortisationModal,
  handleCloseAmortisationModal
}) => {
  const [params, setParams] = useState({ offset: 1 });

  const AmortisationScheduleColumns = [
    {
      field: "paymentNumber",
      headerName: "Payment Number",
      type: "string",
      width: 50,
      editable: false,
    },
    {
      field: "paymentDate",
      headerName: "Payment Date",
      type: "string",
      width: 130,
      editable: false,
    },
    {
      field: "payment",
      headerName: "Payment (net)",
      type: "string",
      width: 130,
      editable: false,
      renderCell: (params) => (
        <div>
          {/* <Typography style={{ fontSize: "14px", textWrap: "initial" }}>{params.row.payment?.toFixed(2)}</Typography> */}
          <Typography style={{ fontSize: "14px", textWrap: "initial" }}>
            {params.row.payment?.toLocaleString("en-US", dollarStringOptions) || "0"}
          </Typography>
        </div>
      ),
    },
    {
      field: "accountFee",
      headerName: "Account Fee",
      type: "string",
      width: 130,
      editable: false,
      renderCell: (params) => (
        <div>
          <Typography style={{ fontSize: "14px", textWrap: "initial" }}>
            {params.row.accountFee?.toLocaleString("en-US", dollarStringOptions) || "0"}
          </Typography>
        </div>
      ),
    },
    {
      field: "principalPaymentRounded",
      headerName: "Principal",
      type: "string",
      width: 160,
      editable: false,
      renderCell: (params) => (
        <div>
          <Typography style={{ fontSize: "14px", textWrap: "initial" }}>
            {params.row.principalPaymentRounded?.toLocaleString("en-US", dollarStringOptions) || "0"}
          </Typography>
        </div>
      ),
    },
    {
      field: "interestPaymentRounded",
      headerName: "Interest",
      type: "string",
      width: 160,
      editable: false,
      renderCell: (params) => (
        <div>
          <Typography style={{ fontSize: "14px", textWrap: "initial" }}>
            {params.row.interestPaymentRounded?.toLocaleString("en-US", dollarStringOptions) || "0"}
          </Typography>
        </div>
      ),
    },
    {
      field: "principalBalanceRounded",
      headerName: "Balance",
      type: "string",
      width: 160,
      editable: false,
      renderCell: (params) => (
        <div>
          <Typography style={{ fontSize: "14px", textWrap: "initial" }}>
            {params.row.principalBalanceRounded?.toLocaleString("en-US", dollarStringOptions) || "0"}
          </Typography>
        </div>
      ),
    },
    {
      field: "actuarialPayout",
      headerName: "Actuarial Payout",
      type: "string",
      width: 160,
      editable: false,
      renderCell: (params) => (
        <div>
          <Typography style={{ fontSize: "14px", textWrap: "initial" }}>
            {params.row.actuarialPayout?.toLocaleString("en-US", dollarStringOptions) || "0"}
          </Typography>
        </div>
      ),
    },
    // {
    //   field: "accInterestRounded",
    //   headerName: "acc Interest",
    //   type: "string",
    //   width: 160,
    //   editable: false,
    // }
  ];

  const getRowId = (row) => row["paymentNumber"]

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={"xl"}
        open={openAmortisationModal}
        onClose={handleCloseAmortisationModal}
        disableEscapeKeyDown={true}
      >

        <DialogTitle style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <Typography>
            Amortisation Schedule
          </Typography>
        </DialogTitle>

        <DialogContent>
          {/* <DialogContentText></DialogContentText> */}
          <Grid item style={{ maxHeight: 420, width: '100%' }}>
            {AmortisationScheduleList?.length > 0 &&
              <DataGridTable
                data={AmortisationScheduleList || []}
                columns={AmortisationScheduleColumns}
                onRowClick={() => { }}
                // onRowClick={(data) => {
                //   handleRowSelection({
                //     id: data?.uniqueId,
                //     data: data
                //   })
                // }}
                rowsPerPageOptions={[10]}
                pageSize={50}
                rowCount={AmortisationScheduleList?.length || 0} // length
                params={params}
                setParams={setParams}
                page={1}
                // isLoading={isLoading}
                // getRowId={true}
                // getRowId={"paymentNumber"}
                getRowId={getRowId}
                isHideTableFooter={true}
                hideFooterPagination={true}
              />
            }
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: '0 20px 20px 0' }}>
          <Button
            onClick={handleCloseAmortisationModal}
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AmortisationModal;