import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Chip, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import HistoryIcon from "@mui/icons-material/History";
import { deepPurple } from "@mui/material/colors";
import { useSnackbar } from "notistack";
import QuillEditor from "./QuillEditor";
import VersionHistoryModal from "./VersionHistoryModal";
import {
  ERRORS_MESSAGE,
  SUCCESS_MESSAGE,
  emailTemplatePath,
  emailTemplateEditorConfig,
  smsTemplateEditorConfig,
} from "../../../constants";
import {
  getTemplatesById,
  getTemplatesVersions,
  updateTemplate,
  templatesSelector,
} from "../../../store/slices/templatesSlice";
import "./styles/style.css";
import { formatDateTime } from "../../../utils/formatTime";

const TemplateEditor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const templateId = location?.pathname?.replace("/settings/templates/", "");

  const [innerHTML, setInnerHtml] = useState();
  const [innerPlainText, setInnerPlainText] = useState();
  const [openVersionHistory, setOpenVersionHistory] = useState(false);
  const editorRef = useRef();

  const { allowedVariables, isFetchingTemplate, currentlyEditingTemplate, isFetchingVersion, templateVersions } =
    useSelector(templatesSelector);

  const handleOnBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1); // Only go back if there's a history to go back to
    } else {
      navigate(emailTemplatePath); // Navigate to a fallback route
    }
  };

  const handleInsertVariable = (variable) => {
    const quill = editorRef.current;
    if (quill) {
      const range = quill.getSelection();
      if (range) {
        quill.insertText(range.index, variable);
      }
    }
  };

  const handleOpenVersionHistory = async () => {
    await dispatch(
      getTemplatesVersions({
        templateId: templateId,
      }),
    ).unwrap();
    setOpenVersionHistory(true);
  };

  const handleCloseVersionHistory = () => setOpenVersionHistory(false);

  const processVariables = (variables) => {
    const result = {};

    Object.entries(variables).forEach(([parent, children]) => {
      result[parent] = children.map((child) => {
        const marker = `{{ ${parent}.${child} }}`;
        return {
          marker: marker,
          title: child,
        };
      });
    });

    return result;
  };

  const getAllowedMarkers = (templateVariables) => {
    let allowedMarkers = [];

    if (currentlyEditingTemplate.isShortUrl) {
      allowedMarkers.push("{{shortenedUrl.shortUrl}}");
    }

    Object.keys(templateVariables).forEach((category) => {
      allowedMarkers = [
        ...allowedMarkers,
        ...templateVariables[category].map((item) => item.marker.replace(/\s/g, "")),
      ];
    });

    return allowedMarkers;
  };

  const templateVariables = Object.keys(allowedVariables).length > 0 ? processVariables(allowedVariables) : [];

  const allowedMarkers = getAllowedMarkers(templateVariables) ?? [];

  useEffect(() => {
    dispatch(getTemplatesById({ templateId }));
  }, [templateId]);

  const handleUpdateTemplate = (type) => {
    if (!currentlyEditingTemplate) return;

    const { _id: templateId, name, subject, messageType, templateType } = currentlyEditingTemplate;

    const delta = editorRef.current.getContents();
    const detectedMarkers = [];

    delta.ops.forEach((op) => {
      if (op.insert && typeof op.insert === "object" && op.insert.TemplateMarker) {
        const marker = op.insert.TemplateMarker.marker;
        detectedMarkers.push(marker);
      }
    });

    const variableMarkers = detectedMarkers
      .filter((marker) => allowedMarkers.includes(marker.replaceAll(/\s/g, "")))
      .map((marker) => marker.replace(/{{\s*([^}]*)\s*}}/g, "$1"));

    let payload = {
      templateId,
      name,
      subject,
      messageType,
      templateType,
      variables: variableMarkers,
    };

    if (type === "email") {
      payload.body = innerHTML;
    } else if (type === "sms") {
      payload.body = innerPlainText;
    }

    dispatch(updateTemplate(payload)).then((res) => {
      if (res?.payload?.data?.data) {
        enqueueSnackbar(res?.payload?.data?.status_message || SUCCESS_MESSAGE.fetchMsg, {
          variant: "success",
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar(res?.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    });
  };

  return (
    <>
      <Button variant="text" sx={{ textTransform: "none", color: "black", ml: "-14px" }} onClick={handleOnBack}>
        <ChevronLeftIcon htmlColor={deepPurple[500]} />
        Back to files
      </Button>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "24px 0",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h5" fontWeight="bold">
            {currentlyEditingTemplate?.name}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
            <Typography variant="body2" sx={{ marginRight: "8px" }}>
              Version
            </Typography>
            <Chip
              label={currentlyEditingTemplate?.updatedAt ? formatDateTime(currentlyEditingTemplate?.updatedAt) : "N/A"}
              size="small"
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.05)",
                fontWeight: "500",
                fontSize: "12px",
              }}
            />
          </Box>
        </Box>
        <Button
          variant="contained"
          startIcon={<HistoryIcon />}
          disabled={isFetchingVersion}
          sx={{
            backgroundColor: "#6200ea",
            color: "white",
            textTransform: "none",
            padding: "8px 16px",
          }}
          onClick={handleOpenVersionHistory}
        >
          {isFetchingVersion ? "Fetching..." : "Version History"}
        </Button>
      </Box>
      <Grid container spacing={2} sx={{ padding: 4 }}>
        {/* Left Container: Email Editor */}
        {!isFetchingTemplate ? (
          <>
            {currentlyEditingTemplate && currentlyEditingTemplate?.messageType === "email" && (
              <Grid item xs={12} md={8}>
                <Box id="quill-editor" sx={{ minHeight: "50vh", maxHeight: "50vh" }}>
                  <QuillEditor
                    config={emailTemplateEditorConfig}
                    ref={editorRef}
                    defaultValue={currentlyEditingTemplate?.body || ""}
                    setInnerHtml={setInnerHtml}
                    setInnerPlainText={setInnerPlainText}
                    templateType={currentlyEditingTemplate.messageType}
                    allowedMarkers={allowedMarkers}
                  />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
                  <Button variant="contained" onClick={() => handleUpdateTemplate("email")}>
                    Update
                  </Button>
                </Box>
                {/* <Grid mt={2}>
                  <Box>Test HTML Output: {innerHTML}</Box>
                </Grid> */}
              </Grid>
            )}
            {currentlyEditingTemplate && currentlyEditingTemplate?.messageType === "sms" && (
              <Grid item xs={12} md={8}>
                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                  Message Body
                </Typography>
                <Box id="quill-editor" sx={{ minHeight: "50vh", maxHeight: "50vh" }}>
                  <QuillEditor
                    config={smsTemplateEditorConfig}
                    defaultValue={currentlyEditingTemplate.body}
                    ref={editorRef}
                    setInnerHtml={setInnerHtml}
                    setInnerPlainText={setInnerPlainText}
                    templateType={currentlyEditingTemplate.messageType}
                    allowedMarkers={allowedMarkers}
                  />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
                  <Button variant="contained" onClick={() => handleUpdateTemplate("sms")}>
                    Update
                  </Button>
                </Box>
                {/* <Grid>
                  <Box>
                    Test Plain Text Output: <pre>{innerPlainText}</pre>
                  </Box>
                </Grid> */}
              </Grid>
            )}
          </>
        ) : (
          <Stack direction="column" alignItems="center" spacing={3} sx={{ width: "100%" }}>
            <CircularProgress />
          </Stack>
        )}
        {/* <Divider variant="fullWidth" orientation="vertical" /> */}
        {/* Right Container: Variables */}
        <Grid item xs={12} md={4} sx={{ height: "100%" }}>
          {templateVariables &&
            Object.entries(templateVariables).map(([varKey, children]) => (
              <>
                <Typography variant="h4" sx={{ mb: 2 }}>
                  {varKey
                    .split(/(?=[A-Z])/)
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    .join(" ")
                    .replace(/\bUrl\b/g, "URL")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "12px",
                    mb: 2,
                  }}
                >
                  {children.map((child, index) => (
                    <Button
                      className={`${varKey.toLowerCase()}-vars btn`}
                      key={index}
                      variant="contained"
                      disableElevation={true}
                      sx={{
                        textTransform: "none",
                      }}
                      onClick={() => handleInsertVariable(child.marker)}
                    >
                      {child.title}
                    </Button>
                  ))}
                </Box>
              </>
            ))}
        </Grid>
      </Grid>
      <VersionHistoryModal
        templateId={templateId}
        open={openVersionHistory}
        onClose={handleCloseVersionHistory}
        versionHistory={templateVersions}
      />
    </>
  );
};

export default TemplateEditor;
