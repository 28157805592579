import { livingArrangementsTypes } from "../../constants";
import criteria from "../../utils/criteria";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } = livingArrangementsTypes;

const {
  standard,
  livingArrangements,
  creditScoreGreaterThan,
  typeOfSale,
  hasDeposit,
  assetType,
  termIsLessThan,
  ageOfAssetLessThan,
  ageOfAssetBetween,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessLessThan,
  timeInBusinessGreaterThan,
  gstRegistrationLessThan,
  gstRegistrationGreaterThan,
} = criteria;

const angle = {
  date: "20 March 2024",
  lender: "Angle Finance",
  productName: "Streamline",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos%2Fangle-higher-resolution.gif",
  actions: ["upload"],
  lenderCredentials: [
    {
      label: "Username",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
  ],
  requiredFields: {
    asset: [
      "assetValue",
      "assetType",
      "typeOfSale",
      "ageOfAsset",
      "condition",
      "glassesMake",
      "glassesModel",
      // "Make",
      // "Model",
    ],
    loanDetails: [
      "rate",
      "term",
      // "balloon",
      "deposit",
      // "tradeIn",
      // "payout",
      // "rate",
      // "brokerageAmount",
    ],
    entity: [
      "abn",
      "entityName",
      "entityType",
      "industryType",
      "turnover",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
    ],
    customer: [
      "customerTitle",
      "firstName",
      "lastName",
      "dateOfBirth",
      "IDNumber",
      "IDCardNumber",
      "IDExpiryDate",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "customerProfile.livingArrangements",
      "customerProfile.numberOfDependants",
      "customerProfile.residencyStatus",
      "customerProfile.maritalStatus",
      "customerAssets.[0].assetType",
      "customerAssets.[0].value",
      "customerAssets.[0].description",
      "liabilities.[0].liabilitiesType",
      "liabilities.[0].outstandingAmount",
      "liabilities.[0].limit",
      "liabilities.[0].financier",
      "liabilities.[0].repaymentFrequency",
      "liabilities.[0].repaymentAmount",
      "liabilities.[0].description",
    ],
  },
  fees: [
    {
      name: "Private sale",
      value: 700,
      capitalised: false,
      frequency: "upfront",
      criteria: [typeOfSale("Private sale")],
    },
    {
      name: "Authorised supplier",
      value: 440,
      capitalised: false,
      frequency: "upfront",
      criteria: [typeOfSale("Authorised seller")],
    },
    {
      name: "Origination fee",
      value: 900,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Monthly account keeping fee",
      value: 4.95,
      capitalised: false,
      frequency: "monthly",
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 7,
      valueType: "percentage",
      criteria: [standard],
    },
  ],
  loading: [],
  info: [
    `No financial defaults on credit files (paid/unpaid), 
    except for telco or utilities(paid up to $2,500)`,
    `Satisfactory bank account conduct in relation
    to dishonours, running balances and
    overdrawn incidents`,
    `Rates notice for proof of property ownership (within
    the last 6 months)`,
    `Max lend per MV is $150K with overall limit of $250K under 250K faster policy for ABN >2 yr and GST >1 yr`,
    `MV loans >$100K will require a credit reference or mortgage statement as proof of debt servicing history`,
  ],
  productTiers: [
    {
      name: "Angle Edge",
      rate: 7.99,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(48),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Property owner"]),
        ageOfAssetLessThan(120),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        termIsLessThan(61),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Angle Edge",
      rate: 8.85,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Property owner"]),
        ageOfAssetLessThan(120),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        termIsLessThan(61),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Angle Edge",
      rate: 9.95,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Property owner"]),
        ageOfAssetBetween(120, 180),
        ageOfAssetAtEndLessThan(15),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        termIsLessThan(61),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Angle Edge",
      rate: 10.95,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Property owner"]),
        ageOfAssetBetween(180, 240),
        ageOfAssetAtEndLessThan(20),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        termIsLessThan(61),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Angle Edge",
      rate: 10.60,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        ageOfAssetLessThan(120),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        termIsLessThan(61),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Angle Edge",
      rate: 11.25,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        ageOfAssetBetween(120, 180),
        ageOfAssetAtEndLessThan(15),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        termIsLessThan(61),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Angle Edge",
      rate: 16.95,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        ageOfAssetBetween(180, 240),
        ageOfAssetAtEndLessThan(20),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        termIsLessThan(61),
        creditScoreGreaterThan(500),
      ],
    },

    {
      name: "Angle Edge",
      rate: 8.85,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Property owner"]),
        ageOfAssetLessThan(120),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Angle Edge",
      rate: 9.95,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 250000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Property owner"]),
        ageOfAssetBetween(120, 180),
        ageOfAssetAtEndLessThan(15),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Angle Edge",
      rate: 10.95,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 250000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Property owner"]),
        ageOfAssetBetween(180, 240),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Angle Edge",
      rate: 10.60,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 250000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        ageOfAssetLessThan(120),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Angle Edge",
      rate: 11.25,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 250000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        ageOfAssetBetween(120, 180),
        ageOfAssetAtEndLessThan(15),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Angle Edge",
      rate: 16.95,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 250000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        ageOfAssetBetween(180, 240),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(500),
      ],
    },

    {
      name: "Angle Edge",
      rate: 10.45,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(2000, 250000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Property owner"]),
        ageOfAssetLessThan(120),
        ageOfAssetAtEndLessThan(11),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
          "IT, Computer & Audio Equipment",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Angle Edge",
      rate: 12.95,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(2000, 50000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Property owner"]),
        ageOfAssetLessThan(120),
        ageOfAssetAtEndLessThan(11),
        assetType(["Health & Beauty Equipment", "Office Furniture", "Solar"]),
        termIsLessThan(61),
        creditScoreGreaterThan(500),
      ],
    },

    {
      name: "Angle Edge",
      rate: 11.25,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(2000, 50000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Property owner"]),
        ageOfAssetLessThan(120),
        ageOfAssetAtEndLessThan(11),
        assetType(["Office Equipment", "Restaurant & Catering Equipment"]),
        termIsLessThan(61),
        creditScoreGreaterThan(500),
      ],
    },

    {
      name: "Angle Edge",
      rate: 11.25,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(2000, 250000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        ageOfAssetLessThan(120),
        ageOfAssetAtEndLessThan(11),
        hasDeposit(20),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
          "IT, Computer & Audio Equipment",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Angle Edge",
      rate: 16.95,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(2000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        ageOfAssetLessThan(120),
        ageOfAssetAtEndLessThan(11),
        hasDeposit(20),
        assetType(["Health & Beauty Equipment", "Office Furniture", "Solar"]),
        termIsLessThan(61),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Angle Edge",
      rate: 16.95,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(2000, 50000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        ageOfAssetLessThan(120),
        ageOfAssetAtEndLessThan(11),
        hasDeposit(20),
        assetType(["Office Equipment", "Restaurant & Catering Equipment"]),
        termIsLessThan(61),
        creditScoreGreaterThan(500),
      ],
    },



    {
      name: "Angle Edge",
      rate: 12.95,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessLessThan(24),
        gstRegistrationLessThan(12),
        livingArrangements([PROPERTY_OWNER]),
        ageOfAssetLessThan(240),
        ageOfAssetAtEndLessThan(21),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        termIsLessThan(61),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Angle Edge",
      rate: 12.95,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 250000),
        timeInBusinessLessThan(24),
        gstRegistrationLessThan(12),
        livingArrangements([PROPERTY_OWNER]),
        ageOfAssetLessThan(240),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(500),
      ],
    },

    {
      name: "Angle Edge",
      rate: 16.95,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessLessThan(24),
        gstRegistrationLessThan(12),
        livingArrangements([RENTING, LIVING_WITH_PARENTS, BOARDING]),
        ageOfAssetLessThan(240),
        ageOfAssetAtEndLessThan(21),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        hasDeposit(20),
        termIsLessThan(61),
        creditScoreGreaterThan(500),
      ],
    },
    {
      name: "Angle Edge",
      rate: 16.95,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 250000),
        timeInBusinessLessThan(24),
        gstRegistrationLessThan(12),
        livingArrangements([RENTING, LIVING_WITH_PARENTS, BOARDING]),
        ageOfAssetLessThan(240),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        hasDeposit(20),
        termIsLessThan(61),
        creditScoreGreaterThan(500),
      ],
    },
    // {
    //   name: "Angle Edge",
    //   rate: 13.30,
    //   productType: "Low doc",
    //   info: [
    //   ],
    //   criteria: [
    //     loanAmountBetween(2000, 150000),
    //     timeInBusinessGreaterThan(24),
    //     gstRegistrationLessThan(12),
    //     livingArrangements([PROPERTY_OWNER]),
    //     ageOfAssetLessThan(120),
    //     ageOfAssetAtEndLessThan(11),
    //     assetType([
    //       "Industrial Plant & Printing",
    //       "Medical Equipment",
    //       "Manufacturing Equipment",
    //       "IT, Computer & Audio Equipment",
    //     ]),
    //     termIsLessThan(61),
    //     creditScoreGreaterThan(500)
    //   ],
    // },
    // {
    //   name: "Angle Edge",
    //   rate: 13.30,
    //   productType: "Low doc",
    //   info: [
    //   ],
    //   criteria: [
    //     loanAmountBetween(2000, 100000),
    //     timeInBusinessGreaterThan(24),
    //     gstRegistrationLessThan(12),
    //     livingArrangements([PROPERTY_OWNER]),
    //     ageOfAssetLessThan(120),
    //     ageOfAssetAtEndLessThan(11),
    //     assetType([
    //       "Health & Beauty Equipment",
    //       "Office Furniture",
    //       "Solar"
    //     ]),
    //     termIsLessThan(61),
    //     creditScoreGreaterThan(500)
    //   ],
    // },
    {
      name: "Angle Edge",
      rate: 16.95,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(2000, 50000),
        timeInBusinessGreaterThan(24),
        gstRegistrationLessThan(12),
        livingArrangements([PROPERTY_OWNER]),
        ageOfAssetLessThan(120),
        ageOfAssetAtEndLessThan(11),
        assetType(["Office Equipment", "Restaurant & Catering Equipment"]),
        termIsLessThan(61),
        creditScoreGreaterThan(500),
      ],
    },

    {
      name: "Angle Edge",
      rate: 16.95,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(24),
        gstRegistrationLessThan(12),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        hasDeposit(20),
        ageOfAssetLessThan(240),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(500),
      ],
    },
    // {
    //   name: "Angle Edge",
    //   rate: 13.30,
    //   productType: "Low doc",
    //   info: [
    //   ],
    //   criteria: [
    //     loanAmountBetween(2000, 150000),
    //     timeInBusinessGreaterThan(24),
    //     gstRegistrationLessThan(12),
    //     livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
    //     hasDeposit(20),
    //     ageOfAssetLessThan(120),
    //     ageOfAssetAtEndLessThan(11),
    //     assetType([
    //       "Industrial Plant & Printing",
    //       "Medical Equipment",
    //       "Manufacturing Equipment",
    //       "IT, Computer & Audio Equipment",
    //     ]),
    //     termIsLessThan(61),
    //     creditScoreGreaterThan(500)
    //   ],
    // },
    // {
    //   name: "Angle Edge",
    //   rate: 13.30,
    //   productType: "Low doc",
    //   info: [
    //   ],
    //   criteria: [
    //     loanAmountBetween(2000, 100000),
    //     timeInBusinessGreaterThan(24),
    //     gstRegistrationLessThan(12),
    //     livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
    //     hasDeposit(20),
    //     ageOfAssetLessThan(120),
    //     ageOfAssetAtEndLessThan(11),
    //     assetType([
    //       "Health & Beauty Equipment",
    //       "Office Furniture",
    //       "Solar"
    //     ]),
    //     termIsLessThan(61),
    //     creditScoreGreaterThan(500)
    //   ],
    // },
    // {
    //   name: "Angle Edge",
    //   rate: 13.30,
    //   productType: "Low doc",
    //   info: [
    //   ],
    //   criteria: [
    //     loanAmountBetween(2000, 50000),
    //     timeInBusinessGreaterThan(24),
    //     gstRegistrationLessThan(12),
    //     livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
    //     hasDeposit(20),
    //     ageOfAssetLessThan(120),
    //     ageOfAssetAtEndLessThan(11),
    //     assetType([
    //       "Office Equipment",
    //       "Restaurant & Catering Equipment",
    //     ]),
    //     termIsLessThan(61),
    //     creditScoreGreaterThan(500)
    //   ],
    // },

    {
      name: "Angle Edge",
      rate: 13.95,
      productType: "Full doc",
      info: [
        `6 months bank statements(within last 7 days of
          submission date) and / or 2 years financial statements, 
          commitment schedule and income declaration`,
      ],
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessLessThan(24),
        gstRegistrationLessThan(12),
        livingArrangements([PROPERTY_OWNER]),
        ageOfAssetLessThan(240),
        ageOfAssetAtEndLessThan(20),
        hasDeposit(20),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(650),
      ],
    },
    {
      name: "Angle Edge",
      rate: 16.95,
      productType: "Full doc",
      info: [
        `6 months bank statements(within last 7 days of
          submission date) and / or 2 years financial statements, 
          commitment schedule and income declaration`,
      ],
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessLessThan(24),
        gstRegistrationLessThan(12),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        hasDeposit(20),
        ageOfAssetLessThan(240),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(650),
      ],
    },

    {
      name: "Angle Edge",
      rate: 10.60,
      productType: "Full doc",
      info: [
        `6 months bank statements(within last 7 days of
          submission date) and / or 2 years financial statements, 
          commitment schedule and income declaration`,
      ],
      criteria: [
        loanAmountBetween(150000, 2000000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements([PROPERTY_OWNER]),
        ageOfAssetLessThan(120),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        termIsLessThan(61),
        creditScoreGreaterThan(650),
      ],
    },
    {
      name: "Angle Edge",
      rate: 11.25,
      productType: "Full doc",
      info: [
        `6 months bank statements(within last 7 days of
          submission date) and / or 2 years financial statements, 
          commitment schedule and income declaration`,
      ],
      criteria: [
        loanAmountBetween(150000, 2000000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements([PROPERTY_OWNER]),
        ageOfAssetBetween(120, 180),
        ageOfAssetAtEndLessThan(15),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        termIsLessThan(61),
        creditScoreGreaterThan(650),
      ],
    },
    {
      name: "Angle Edge",
      rate: 16.95,
      productType: "Full doc",
      info: [
        `6 months bank statements(within last 7 days of
          submission date) and / or 2 years financial statements, 
          commitment schedule and income declaration`,
      ],
      criteria: [
        loanAmountBetween(150000, 2000000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements([PROPERTY_OWNER]),
        ageOfAssetBetween(180, 240),
        ageOfAssetAtEndLessThan(20),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        termIsLessThan(61),
        creditScoreGreaterThan(650),
      ],
    },

    {
      name: "Angle Edge",
      rate: 10.60,
      productType: "Full doc",
      info: [
        `6 months bank statements(within last 7 days of
          submission date) and / or 2 years financial statements, 
          commitment schedule and income declaration`,
      ],
      criteria: [
        loanAmountBetween(250000, 2000000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements([PROPERTY_OWNER]),
        ageOfAssetLessThan(120),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(650),
      ],
    },
    {
      name: "Angle Edge",
      rate: 11.25,
      productType: "Full doc",
      info: [
        `6 months bank statements(within last 7 days of
          submission date) and / or 2 years financial statements, 
          commitment schedule and income declaration`,
      ],
      criteria: [
        loanAmountBetween(250000, 2000000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements([PROPERTY_OWNER]),
        ageOfAssetBetween(120, 180),
        ageOfAssetAtEndLessThan(15),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(650),
      ],
    },
    {
      name: "Angle Edge",
      rate: 16.95,
      productType: "Full doc",
      info: [
        `6 months bank statements(within last 7 days of
          submission date) and / or 2 years financial statements, 
          commitment schedule and income declaration`,
      ],
      criteria: [
        loanAmountBetween(250000, 2000000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements([PROPERTY_OWNER]),
        ageOfAssetBetween(180, 240),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(650),
      ],
    },
    {
      name: "Angle Edge",
      rate: 11.25,
      productType: "Full doc",
      info: [
        `6 months bank statements(within last 7 days of
          submission date) and / or 2 years financial statements, 
          commitment schedule and income declaration`,
      ],
      criteria: [
        loanAmountBetween(250000, 2000000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements([PROPERTY_OWNER]),
        ageOfAssetLessThan(120),
        ageOfAssetAtEndLessThan(11),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
          "IT, Computer & Audio Equipment",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(650),
      ],
    },
    {
      name: "Angle Edge",
      rate: 16.95,
      productType: "Full doc",
      info: [
        `6 months bank statements(within last 7 days of
          submission date) and / or 2 years financial statements, 
          commitment schedule and income declaration`,
      ],
      criteria: [
        loanAmountBetween(100000, 2000000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements([PROPERTY_OWNER]),
        ageOfAssetLessThan(120),
        ageOfAssetAtEndLessThan(11),
        assetType(["Health & Beauty Equipment", "Office Furniture", "Solar"]),
        termIsLessThan(61),
        creditScoreGreaterThan(650),
      ],
    },
    {
      name: "Angle Edge",
      rate: 16.95,
      productType: "Full doc",
      info: [
        `6 months bank statements(within last 7 days of
          submission date) and / or 2 years financial statements, 
          commitment schedule and income declaration`,
      ],
      criteria: [
        loanAmountBetween(50000, 2000000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements([PROPERTY_OWNER]),
        ageOfAssetLessThan(120),
        ageOfAssetAtEndLessThan(11),
        assetType(["Office Equipment", "Restaurant & Catering Equipment"]),
        termIsLessThan(61),
        creditScoreGreaterThan(650),
      ],
    },

    {
      name: "Angle Edge",
      rate: 8.85,
      productType: "Full doc",
      info: [
        `6 months bank statements(within last 7 days of
          submission date) and / or 2 years financial statements, 
          commitment schedule and income declaration`,
      ],
      criteria: [
        loanAmountBetween(150000, 2000000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        ageOfAssetLessThan(120),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        termIsLessThan(61),
        creditScoreGreaterThan(650),
      ],
    },
    {
      name: "Angle Edge",
      rate: 9.95,
      productType: "Full doc",
      info: [
        `6 months bank statements(within last 7 days of
          submission date) and / or 2 years financial statements, 
          commitment schedule and income declaration`,
      ],
      criteria: [
        loanAmountBetween(150000, 2000000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        ageOfAssetBetween(120, 180),
        ageOfAssetAtEndLessThan(15),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        termIsLessThan(61),
        creditScoreGreaterThan(650),
      ],
    },
    {
      name: "Angle Edge",
      rate: 10.95,
      productType: "Full doc",
      info: [
        `6 months bank statements(within last 7 days of
          submission date) and / or 2 years financial statements, 
          commitment schedule and income declaration`,
      ],
      criteria: [
        loanAmountBetween(150000, 2000000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        ageOfAssetBetween(180, 240),
        ageOfAssetAtEndLessThan(20),
        assetType(["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]),
        termIsLessThan(61),
        creditScoreGreaterThan(650),
      ],
    },
    {
      name: "Angle Edge",
      rate: 8.85,
      productType: "Full doc",
      info: [
        `6 months bank statements(within last 7 days of
          submission date) and / or 2 years financial statements, 
          commitment schedule and income declaration`,
      ],
      criteria: [
        loanAmountBetween(250000, 2000000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        ageOfAssetLessThan(120),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(650),
      ],
    },
    {
      name: "Angle Edge",
      rate: 9.95,
      productType: "Full doc",
      info: [
        `6 months bank statements(within last 7 days of
          submission date) and / or 2 years financial statements, 
          commitment schedule and income declaration`,
      ],
      criteria: [
        loanAmountBetween(250000, 2000000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        ageOfAssetBetween(120, 180),
        ageOfAssetAtEndLessThan(15),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(650),
      ],
    },
    {
      name: "Angle Edge",
      rate: 10.95,
      productType: "Full doc",
      info: [
        `6 months bank statements(within last 7 days of
          submission date) and / or 2 years financial statements, 
          commitment schedule and income declaration`,
      ],
      criteria: [
        loanAmountBetween(250000, 2000000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        ageOfAssetBetween(180, 240),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(650),
      ],
    },
    {
      name: "Angle Edge",
      rate: 10.45,
      productType: "Full doc",
      info: [
        `6 months bank statements(within last 7 days of
          submission date) and / or 2 years financial statements, 
          commitment schedule and income declaration`,
      ],
      criteria: [
        loanAmountBetween(250000, 2000000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        ageOfAssetLessThan(120),
        ageOfAssetAtEndLessThan(11),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
          "IT, Computer & Audio Equipment",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(650),
      ],
    },
    {
      name: "Angle Edge",
      rate: 16.95,
      productType: "Full doc",
      info: [
        `11 months bank statements(within last 7 days of
          submission date) and / or 2 years financial statements, 
          commitment schedule and income declaration`,
      ],
      criteria: [
        loanAmountBetween(100000, 2000000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        ageOfAssetLessThan(120),
        ageOfAssetAtEndLessThan(6),
        assetType(["Health & Beauty Equipment", "Office Furniture", "Solar"]),
        termIsLessThan(61),
        creditScoreGreaterThan(650),
      ],
    },
    {
      name: "Angle Edge",
      rate: 16.95,
      productType: "Full doc",
      info: [
        `11 months bank statements(within last 7 days of
          submission date) and / or 2 years financial statements, 
          commitment schedule and income declaration`,
      ],
      criteria: [
        loanAmountBetween(50000, 2000000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        ageOfAssetLessThan(120),
        ageOfAssetAtEndLessThan(6),
        assetType(["Office Equipment", "Restaurant & Catering Equipment"]),
        termIsLessThan(61),
        creditScoreGreaterThan(650),
      ],
    },

    {
      name: "250k Faster",
      rate: 8.85,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(150000, 250000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Property owner"]),
        ageOfAssetLessThan(120),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "250k Faster",
      rate: 9.95,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(150000, 250000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Property owner"]),
        ageOfAssetBetween(120, 180),
        ageOfAssetAtEndLessThan(15),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "250k Faster",
      rate: 10.95,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(150000, 250000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Property owner"]),
        ageOfAssetBetween(180, 240),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(550),
      ],
    },
    // {
    //   name: "250k Faster",
    //   rate: 10.45,
    //   productType: "Low doc",
    //   info: [],
    //   criteria: [
    //     loanAmountBetween(150000, 250000),
    //     timeInBusinessGreaterThan(24),
    //     gstRegistrationGreaterThan(12),
    //     livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
    //     hasDeposit(20),
    //     ageOfAssetLessThan(120),
    //     assetType([
    //       "Motor Vehicle (<4.5 tonnes)",
    //       "Electric Motor Vehicle (<4.5 tonnes)",
    //       "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
    //       "Commercial (Heavy) Vehicles (>12 Tonnes)",
    //       "Warehouse Equipment (wheeled or tracked)",
    //       "Earth Moving & Construction (wheeled or tracked)",
    //       "Agricultural & Landscaping Equipment (wheeled or tracked)",
    //       "Mini Buses & Route Buses (<25 seats)",
    //       "Trailers and Caravans",
    //     ]),
    //     termIsLessThan(61),
    //     creditScoreGreaterThan(550),
    //   ],
    // },
    // {
    //   name: "250k Faster",
    //   rate: 16.95,
    //   productType: "Low doc",
    //   info: [],
    //   criteria: [
    //     loanAmountBetween(150000, 250000),
    //     timeInBusinessGreaterThan(24),
    //     gstRegistrationGreaterThan(12),
    //     livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
    //     hasDeposit(20),
    //     ageOfAssetBetween(120, 180),
    //     ageOfAssetAtEndLessThan(15),
    //     assetType([
    //       "Motor Vehicle (<4.5 tonnes)",
    //       "Electric Motor Vehicle (<4.5 tonnes)",
    //       "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
    //       "Commercial (Heavy) Vehicles (>12 Tonnes)",
    //       "Warehouse Equipment (wheeled or tracked)",
    //       "Earth Moving & Construction (wheeled or tracked)",
    //       "Agricultural & Landscaping Equipment (wheeled or tracked)",
    //       "Mini Buses & Route Buses (<25 seats)",
    //       "Trailers and Caravans",
    //     ]),
    //     termIsLessThan(61),
    //     creditScoreGreaterThan(550),
    //   ],
    // },
    // {
    //   name: "250k Faster",
    //   rate: 13.6,
    //   productType: "Low doc",
    //   info: [],
    //   criteria: [
    //     loanAmountBetween(150000, 250000),
    //     timeInBusinessGreaterThan(24),
    //     gstRegistrationGreaterThan(12),
    //     livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
    //     hasDeposit(20),
    //     ageOfAssetBetween(180, 240),
    //     ageOfAssetAtEndLessThan(20),
    //     assetType([
    //       "Motor Vehicle (<4.5 tonnes)",
    //       "Electric Motor Vehicle (<4.5 tonnes)",
    //       "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
    //       "Commercial (Heavy) Vehicles (>12 Tonnes)",
    //       "Warehouse Equipment (wheeled or tracked)",
    //       "Earth Moving & Construction (wheeled or tracked)",
    //       "Agricultural & Landscaping Equipment (wheeled or tracked)",
    //       "Mini Buses & Route Buses (<25 seats)",
    //       "Trailers and Caravans",
    //     ]),
    //     termIsLessThan(61),
    //     creditScoreGreaterThan(550),
    //   ],
    // },
    {
      name: "250k Faster",
      rate: 10.45,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(150000, 250000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        livingArrangements(["Property owner"]),
        ageOfAssetLessThan(120),
        ageOfAssetAtEndLessThan(11),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
          "IT, Computer & Audio Equipment",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(550),
      ],
    },
    // {
    //   name: "250k Faster",
    //   rate: 10.95,
    //   productType: "Low doc",
    //   info: [
    //   ],
    //   criteria: [
    //     loanAmountBetween(100000, 250000),
    //     timeInBusinessGreaterThan(24),
    //     gstRegistrationGreaterThan(12),
    //     livingArrangements(["Property owner"]),
    //     ageOfAssetLessThan(120),
    //     ageOfAssetAtEndLessThan(11),
    //     assetType([
    //       "Health & Beauty Equipment",
    //       "Office Furniture",
    //       "Solar"
    //     ]),
    //     termIsLessThan(61),
    //     creditScoreGreaterThan(550)
    //   ]
    // },
    // {
    //   name: "250k Faster",
    //   rate: 10.95,
    //   productType: "Low doc",
    //   info: [
    //   ],
    //   criteria: [
    //     loanAmountBetween(50000, 250000),
    //     timeInBusinessGreaterThan(24),
    //     gstRegistrationGreaterThan(12),
    //     livingArrangements(["Property owner"]),
    //     ageOfAssetLessThan(120),
    //     ageOfAssetAtEndLessThan(11),
    //     assetType([
    //       "Office Equipment",
    //       "Restaurant & Catering Equipment",
    //     ]),
    //     termIsLessThan(61),
    //     creditScoreGreaterThan(550)
    //   ]
    // },

    // {
    //   name: "250k Faster",
    //   rate: 11.25,
    //   productType: "Low doc",
    //   info: [],
    //   criteria: [
    //     loanAmountBetween(150000, 250000),
    //     timeInBusinessGreaterThan(24),
    //     gstRegistrationGreaterThan(12),
    //     livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
    //     hasDeposit(20),
    //     ageOfAssetLessThan(120),
    //     ageOfAssetAtEndLessThan(11),
    //     assetType([
    //       "Industrial Plant & Printing",
    //       "Medical Equipment",
    //       "Manufacturing Equipment",
    //       "IT, Computer & Audio Equipment",
    //     ]),
    //     termIsLessThan(61),
    //     creditScoreGreaterThan(550),
    //   ],
    // },
    // {
    //   name: "250k Faster",
    //   rate: 10.95,
    //   productType: "Low doc",
    //   info: [
    //   ],
    //   criteria: [
    //     loanAmountBetween(100000, 250000),
    //     timeInBusinessGreaterThan(24),
    //     gstRegistrationGreaterThan(12),
    //     livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
    //     hasDeposit(20),
    //     ageOfAssetLessThan(120),
    //     ageOfAssetAtEndLessThan(11),
    //     assetType([
    //       "Health & Beauty Equipment",
    //       "Office Furniture",
    //       "Solar"
    //     ]),
    //     termIsLessThan(61),
    //     creditScoreGreaterThan(550)
    //   ]
    // },
    // {
    //   name: "250k Faster",
    //   rate: 10.95,
    //   productType: "Low doc",
    //   info: [
    //   ],
    //   criteria: [
    //     loanAmountBetween(50000, 250000),
    //     timeInBusinessGreaterThan(24),
    //     gstRegistrationGreaterThan(12),
    //     livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
    //     hasDeposit(20),
    //     ageOfAssetLessThan(120),
    //     ageOfAssetAtEndLessThan(11),
    //     assetType([
    //       "Office Equipment",
    //       "Restaurant & Catering Equipment",
    //     ]),
    //     termIsLessThan(61),
    //     creditScoreGreaterThan(550)
    //   ]
    // },

    {
      name: "250k Faster",
      rate: 16.95,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(150000, 250000),
        timeInBusinessGreaterThan(24),
        gstRegistrationLessThan(12),
        livingArrangements([PROPERTY_OWNER]),
        ageOfAssetLessThan(240),
        ageOfAssetAtEndLessThan(21),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "250k Faster",
      rate: 16.95,
      productType: "Low doc",
      info: [],
      criteria: [
        loanAmountBetween(150000, 250000),
        timeInBusinessGreaterThan(24),
        gstRegistrationLessThan(12),
        livingArrangements([PROPERTY_OWNER]),
        ageOfAssetLessThan(120),
        ageOfAssetAtEndLessThan(11),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
          "IT, Computer & Audio Equipment",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(550),
      ],
    },
    // {
    //   name: "250k Faster",
    //   rate: 13.30,
    //   productType: "Low doc",
    //   info: [
    //   ],
    //   criteria: [
    //     loanAmountBetween(100000, 250000),
    //     timeInBusinessGreaterThan(24),
    //     gstRegistrationLessThan(12),
    //     livingArrangements([PROPERTY_OWNER]),
    //     ageOfAssetLessThan(120),
    //     ageOfAssetAtEndLessThan(11),
    //     assetType([
    //       "Health & Beauty Equipment",
    //       "Office Furniture",
    //       "Solar"
    //     ]),
    //     termIsLessThan(61),
    //     creditScoreGreaterThan(550)
    //   ],
    // },
    // {
    //   name: "250k Faster",
    //   rate: 13.30,
    //   productType: "Low doc",
    //   info: [
    //   ],
    //   criteria: [
    //     loanAmountBetween(50000, 250000),
    //     timeInBusinessGreaterThan(24),
    //     gstRegistrationLessThan(12),
    //     livingArrangements([PROPERTY_OWNER]),
    //     ageOfAssetLessThan(120),
    //     ageOfAssetAtEndLessThan(11),
    //     assetType([
    //       "Office Equipment",
    //       "Restaurant & Catering Equipment",
    //     ]),
    //     termIsLessThan(61),
    //     creditScoreGreaterThan(550)
    //   ],
    // },

    // {
    //   name: "250k Faster",
    //   rate: 16.95,
    //   productType: "Low doc",
    //   info: [],
    //   criteria: [
    //     loanAmountBetween(150000, 250000),
    //     timeInBusinessGreaterThan(24),
    //     gstRegistrationLessThan(12),
    //     livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
    //     hasDeposit(20),
    //     ageOfAssetLessThan(240),
    //     ageOfAssetAtEndLessThan(21),
    //     assetType([
    //       "Motor Vehicle (<4.5 tonnes)",
    //       "Electric Motor Vehicle (<4.5 tonnes)",
    //       "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
    //       "Commercial (Heavy) Vehicles (>12 Tonnes)",
    //       "Warehouse Equipment (wheeled or tracked)",
    //       "Earth Moving & Construction (wheeled or tracked)",
    //       "Agricultural & Landscaping Equipment (wheeled or tracked)",
    //       "Mini Buses & Route Buses (<25 seats)",
    //       "Trailers and Caravans",
    //     ]),
    //     termIsLessThan(61),
    //     creditScoreGreaterThan(550),
    //   ],
    // },
    // {
    //   name: "250k Faster",
    //   rate: 16.95,
    //   productType: "Low doc",
    //   info: [],
    //   criteria: [
    //     loanAmountBetween(150000, 250000),
    //     timeInBusinessGreaterThan(24),
    //     gstRegistrationLessThan(12),
    //     livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
    //     hasDeposit(20),
    //     ageOfAssetLessThan(120),
    //     ageOfAssetAtEndLessThan(11),
    //     assetType([
    //       "Industrial Plant & Printing",
    //       "Medical Equipment",
    //       "Manufacturing Equipment",
    //       "IT, Computer & Audio Equipment",
    //     ]),
    //     termIsLessThan(61),
    //     creditScoreGreaterThan(550),
    //   ],
    // },
    // {
    //   name: "250k Faster",
    //   rate: 13.30,
    //   productType: "Low doc",
    //   info: [
    //   ],
    //   criteria: [
    //     loanAmountBetween(100000, 250000),
    //     timeInBusinessGreaterThan(24),
    //     gstRegistrationLessThan(12),
    //     livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
    //     hasDeposit(20),
    //     ageOfAssetLessThan(120),
    //     ageOfAssetAtEndLessThan(11),
    //     assetType([
    //       "Health & Beauty Equipment",
    //       "Office Furniture",
    //       "Solar"
    //     ]),
    //     termIsLessThan(61),
    //     creditScoreGreaterThan(550)
    //   ],
    // },
    // {
    //   name: "250k Faster",
    //   rate: 13.30,
    //   productType: "Low doc",
    //   info: [
    //   ],
    //   criteria: [
    //     loanAmountBetween(50000, 250000),
    //     timeInBusinessGreaterThan(24),
    //     gstRegistrationLessThan(12),
    //     livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
    //     hasDeposit(20),
    //     ageOfAssetLessThan(120),
    //     ageOfAssetAtEndLessThan(11),
    //     assetType([
    //       "Office Equipment",
    //       "Restaurant & Catering Equipment",
    //     ]),
    //     termIsLessThan(61),
    //     creditScoreGreaterThan(550)
    //   ],
    // },
  ],
};
export default angle;
