import plenti from "./plenti";
import now from "./now";
import wisr from "./wisr";
import money3 from "./money3";
import latitude from "./latitude";
// import moneyplace from "./moneyplace";
// import alex from "./alex";

const personal = [
  // alex,
  money3,
  latitude,
  // moneyplace,
  now,
  plenti,
  wisr,
];
export default personal;