import base from "./base";
import criteria from "../../../utils/criteria";

const { termIsLessThan, assetValueBetween, assetType, ageOfAssetLessThan, ageOfAssetAtEndLessThan } = criteria;

function traileredBoats(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      assetType(["Trailered Boat"]),
      ageOfAssetLessThan(216),
      ageOfAssetAtEndLessThan(21),
    ],
  }));
}

function motorBike(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      assetType(["Motorbike"]),
      ageOfAssetLessThan(120),
      ageOfAssetAtEndLessThan(13),
      termIsLessThan(61),
    ],
  }));
}

function jetski(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      assetType(["Jetski/PWC"]),
      ageOfAssetLessThan(180),
      ageOfAssetAtEndLessThan(21),
      termIsLessThan(61),
    ],
  }));
}

function atv(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      assetType(["ATV/Off-Road Bike"]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(6),
      termIsLessThan(61),
    ],
  }));
}

function golfCart(l) {
  return l.map((product) => ({
    ...product,
    criteria: [
      ...product.criteria,
      assetType(["Golf carts"]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(6),
      termIsLessThan(61),
    ],
  }));
}

const ammf = {
  date: "24 May 2024",
  lender: "AMMF",
  type: "consumer",
  logoURL: "https://assets.financeable.com.au/lender-logos/ammf-portrait-normal-rgb.png",
  actions: ["upload"],
  lenderCredentials: [
    {
      label: "Username",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
  ],
  requiredFields: {
    asset: ["assetType", "typeOfSale", "ageOfAsset", "glassesMake", "glassesModel", "glassesVariant", "assetValue"],
    customer: [
      "firstName",
      "lastName",
      "dateOfBirth",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      //   "addresses[0].street",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "IDNumber",
      "IDCardNumber",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "employers.[0].entityName",
      "employers.[0].timeOfEmployment",
      "employers.[0].employmentType",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Establishment fee",
      value: 395,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Origination fee",
      value: 990,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Monthly account fee",
      value: 5,
      capitalised: false,
      frequency: "monthly",
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 4,
      valueType: "percentage",
      criteria: [assetValueBetween(0, 1000000)],
    },
  ],
  loading: [],
  info: [
    `
      Broker commission of up to 4.0%* of the loan proceeds for loans written at the recommended interest rate
    `,
    `
       Loans written below 2 the recommended rate will receive a proportionally lower broker commission
    `,
    `
       A discount of the maximum 2% results in 0% broker commission
    `,
  ],
  productTiers: [...motorBike(base), ...traileredBoats(base), ...jetski(base), ...atv(base), ...golfCart(base)],
};

export default ammf;
