import React from "react";
import { NumericFormat } from "react-number-format";

export const PercentageFormatCustom = React.forwardRef(function PercentageFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      decimalScale={2}
      fixedDecimalScale
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      suffix="%"
    />
  );
});

export const percentageMaskedValue = (value) => {
  const val = value.replace(/[%,]/g, "");
  // return `{val}%`;
  return val;
};
