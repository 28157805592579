export default function generateArrayOfYears() {
  var max = new Date().getFullYear() + 1;
  var min = max - 100;
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push(i.toString());
  }
  return years;
}
