import criteria from "../../../utils/criteria";
import { livingArrangementsTypes, employmentTypes } from "../../../constants";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } = livingArrangementsTypes;
const { FULL_TIME, PART_TIME, CASUAL, CONTRACT, SELF_EMPLOYED } = employmentTypes;

const {
  assetType,
  ageOfAssetBetween,
  assetCondition,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
  creditScoreBetween,
  termIsLessThan,
} = criteria;

const base = [
  {
    name: "Premium Tier New/Demo",
    rate: 9.29,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 25000),
      assetCondition(["New", "Demo"]),
      livingArrangements([PROPERTY_OWNER, RENTING]),

      creditScoreGreaterThan(1080),

      assetType(["ATV/Off-Road Bike", "Ride on mower", "Tractor"]),
    ],
  },

  {
    name: "Tier 1 New/Demo",
    rate: 9.79,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 25000),
      assetCondition(["New", "Demo"]),
      livingArrangements([PROPERTY_OWNER, RENTING]),

      creditScoreGreaterThan(800),

      assetType(["ATV/Off-Road Bike", "Ride on mower", "Tractor"]),
    ],
  },
  {
    name: "Tier 1 Asset less than 24 months",
    rate: 9.79,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 25000),
      ageOfAssetBetween(0, 24),
      assetCondition(["Used"]),
      livingArrangements([PROPERTY_OWNER, RENTING]),

      creditScoreGreaterThan(800),

      assetType(["ATV/Off-Road Bike", "Ride on mower", "Tractor"]),
    ],
  },
  {
    name: "Tier 1 Asset 25 - 59 months",
    rate: 10.69,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 25000),
      ageOfAssetBetween(25, 59),
      livingArrangements([PROPERTY_OWNER, RENTING]),

      creditScoreGreaterThan(800),

      assetType(["ATV/Off-Road Bike", "Ride on mower", "Tractor"]),
    ],
  },
  {
    name: "Tier 1 Asset greater than 59 months",
    rate: 11.89,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 25000),
      ageOfAssetBetween(60, 216),
      livingArrangements([PROPERTY_OWNER, RENTING]),

      creditScoreGreaterThan(800),

      assetType(["ATV/Off-Road Bike", "Ride on mower", "Tractor"]),
    ],
  },

  {
    name: "Tier 2 New/Demo",
    rate: 9.79,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 25000),
      assetCondition(["New", "Demo"]),
      livingArrangements([BOARDING, LIVING_WITH_PARENTS]),

      creditScoreGreaterThan(800),

      assetType(["ATV/Off-Road Bike", "Ride on mower", "Tractor"]),
    ],
  },
  {
    name: "Tier 2 Asset less than 24 months",
    rate: 9.79,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 25000),
      ageOfAssetBetween(0, 24),
      assetCondition(["Used"]),
      livingArrangements([BOARDING, LIVING_WITH_PARENTS]),

      creditScoreGreaterThan(800),

      assetType(["ATV/Off-Road Bike", "Ride on mower", "Tractor"]),
    ],
  },
  {
    name: "Tier 2 Asset 25 - 59 months",
    rate: 10.69,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 25000),
      ageOfAssetBetween(25, 59),
      livingArrangements([BOARDING, LIVING_WITH_PARENTS]),

      creditScoreGreaterThan(800),

      assetType(["ATV/Off-Road Bike", "Ride on mower", "Tractor"]),
    ],
  },
  {
    name: "Tier 2 Asset greater than 59 months",
    rate: 11.89,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 25000),
      ageOfAssetBetween(60, 216),

      livingArrangements([BOARDING, LIVING_WITH_PARENTS]),

      creditScoreGreaterThan(800),
      assetType(["ATV/Off-Road Bike", "Ride on mower", "Tractor"]),
    ],
  },

  {
    name: "Tier 2 New/Demo",
    rate: 11.29,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 25000),
      assetCondition(["New", "Demo"]),
      livingArrangements([PROPERTY_OWNER, RENTING]),

      creditScoreBetween(660, 800),

      assetType(["ATV/Off-Road Bike", "Ride on mower", "Tractor"]),
    ],
  },
  {
    name: "Tier 2 Asset less than 24 months",
    rate: 11.29,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 25000),
      ageOfAssetBetween(0, 24),
      assetCondition(["Used"]),
      livingArrangements([PROPERTY_OWNER, RENTING]),

      creditScoreBetween(660, 800),

      assetType(["ATV/Off-Road Bike", "Ride on mower", "Tractor"]),
    ],
  },
  {
    name: "Tier 2 Asset 25 - 59 months",
    rate: 11.59,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 25000),
      ageOfAssetBetween(25, 59),
      livingArrangements([PROPERTY_OWNER, RENTING]),

      creditScoreBetween(660, 800),

      assetType(["ATV/Off-Road Bike", "Ride on mower", "Tractor"]),
    ],
  },
  {
    name: "Tier 2 Asset greater than 59 months",
    rate: 12.79,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 25000),
      ageOfAssetBetween(60, 216),

      livingArrangements([PROPERTY_OWNER, RENTING]),

      creditScoreBetween(660, 800),
      assetType(["ATV/Off-Road Bike", "Ride on mower", "Tractor"]),
    ],
  },

  {
    name: "Tier 3 New/Demo",
    rate: 11.29,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 15000),
      assetCondition(["New", "Demo"]),
      livingArrangements([BOARDING, LIVING_WITH_PARENTS]),

      creditScoreBetween(660, 800),

      assetType(["ATV/Off-Road Bike", "Ride on mower", "Tractor"]),
    ],
  },
  {
    name: "Tier 3 Asset less than 24 months",
    rate: 11.29,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 15000),
      ageOfAssetBetween(0, 24),
      assetCondition(["Used"]),
      livingArrangements([BOARDING, LIVING_WITH_PARENTS]),

      creditScoreBetween(660, 800),

      assetType(["ATV/Off-Road Bike", "Ride on mower", "Tractor"]),
    ],
  },
  {
    name: "Tier 3 Asset 25 - 59 months",
    rate: 11.59,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 15000),
      ageOfAssetBetween(25, 59),
      livingArrangements([BOARDING, LIVING_WITH_PARENTS]),

      creditScoreBetween(660, 800),

      assetType(["ATV/Off-Road Bike", "Ride on mower", "Tractor"]),
    ],
  },
  {
    name: "Tier 3 Asset greater than 59 months",
    rate: 12.79,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 15000),
      ageOfAssetBetween(60, 216),

      livingArrangements([BOARDING, LIVING_WITH_PARENTS]),

      creditScoreBetween(660, 800),
      assetType(["ATV/Off-Road Bike", "Ride on mower", "Tractor"]),
    ],
  },

  {
    name: "Tier 3 New/Demo",
    rate: 12.79,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 15000),
      assetCondition(["New", "Demo"]),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),

      creditScoreBetween(499, 659),

      assetType(["ATV/Off-Road Bike", "Ride on mower", "Tractor"]),
    ],
  },
  {
    name: "Tier 3 Asset less than 24 months",
    rate: 12.79,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 15000),
      ageOfAssetBetween(0, 24),
      assetCondition(["Used"]),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),

      creditScoreBetween(499, 659),

      assetType(["ATV/Off-Road Bike", "Ride on mower", "Tractor"]),
    ],
  },
  {
    name: "Tier 3 Asset 25 - 59 months",
    rate: 13.69,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 15000),
      ageOfAssetBetween(25, 59),
      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),

      creditScoreBetween(499, 659),

      assetType(["ATV/Off-Road Bike", "Ride on mower", "Tractor"]),
    ],
  },
  {
    name: "Tier 3 Asset greater than 59 months",
    rate: 14.99,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(3000, 15000),
      ageOfAssetBetween(60, 216),

      livingArrangements([PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS]),

      creditScoreBetween(499, 659),
      assetType(["ATV/Off-Road Bike", "Ride on mower", "Tractor"]),
    ],
  },
];

const selfEmployed = base.map((product) => ({
  ...product,
  criteria: [...product.criteria, employmentType([SELF_EMPLOYED]), timeOfEmploymentGreaterThan(24)],
}));

const perm = base.map((product) => ({
  ...product,
  criteria: [...product.criteria, employmentType([FULL_TIME, PART_TIME, CONTRACT]), timeOfEmploymentGreaterThan(3)],
}));

const casual = base.map((product) => ({
  ...product,
  criteria: [...product.criteria, employmentType([CASUAL]), timeOfEmploymentGreaterThan(6)],
}));

const final = [...selfEmployed, ...perm, ...casual].map((product) => ({
  ...product,
  criteria: [...product.criteria, termIsLessThan(85), ageOfAssetAtEndLessThan(8)],
}));

const ATVMowersTractors = [...final];
export default ATVMowersTractors;
