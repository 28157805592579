export const occupationListOptions = [
  "Accountant (General)",
  "Advertising Specialist",
  "Advertising Manager",
  "Agricultural Consultant",
  "Agricultural Technician",
  "Airconditioning and Mechanical Services Plumber",
  "Airconditioning and Refrigeration Mechanic",
  "Aircraft Maintenance Engineer (Avionics)",
  "Air Transport Professionals nec",
  "Ambulance Officer",
  "Apiarist",
  "Arborist",
  "Architect",
  "Architectural, Building and Surveying Technicians nec",
  "Artistic Director",
  "Faculty Head",
  "Auctioneer",
  "External Auditor",
  "Internal Auditor",
  "Barrister",
  "Marine Biologist",
  "Boat Builder and Repairer",
  "Botanist",
  "Bricklayer",
  "Carpenter and Joiner",
  "Chef",
  "Cook",
  "Chemical Plant Operator",
  "Chemist",
  "Child Care Centre Manager",
  "Clinical Coder",
  "Clothing Trades Workers nec",
  "Media Producer (excluding Video)",
  "Communications Operator",
  "Community Worker",
  "Company Secretary",
  "Construction Project Manager",
  "Gallery or Museum Curator",
  "Counsellors nec",
  "Database Administrator",
  "Dental Therapist",
  "Retail Buyer",
  "Dietitian",
  "Nutritionist",
  "Disabilities Services Officer",
  "Economist",
  "Early Childhood (Pre-primary School) Teacher",
  "Education Managers nec",
  "Electrician (General)",
  "Electronic Equipment Trades Worker",
  "Engineering Manager",
  "Engineering Professionals nec",
  "Telecommunications Network Engineer",
  "Urban and Regional Planner",
  "Translator",
  "Truck driver",
  "Crop Farmers nec",
  "Livestock Farmers nec",
  "Mixed Crop and Livestock Farmer",
  "Aquaculture Farmer",
  "Pet Groomer",
  "Fashion Designer",
  "Finance Manager",
  "ICT Business Analyst",
  "Policy Analyst",
  "Policy and Planning Manager",
  "Financial Brokers nec",
  "Financial Dealers nec",
  "Corporate Treasurer",
  "Florist",
  "Food Technologist",
  "Funeral Workers nec",
  "Gas or Petroleum Operator",
  "Geologist",
  "Geophysicist",
  "Glazier",
  "Greenkeeper",
  "Gymnastics Coach or Instructor",
  "Hairdresser",
  "Health and Welfare Services Managers nec",
  "Health Information Manager",
  "Hospitality, Retail and Service Managers nec",
  "Human Resource Adviser",
  "Human Resource Manager",
  "Insurance Agent",
  "Insurance Risk Surveyor",
  "Industrial Designer",
  "Intelligence Officer",
  "Interior Decorator",
  "Interpreter",
  "Jeweller",
  "Journalists and Other Writers nec",
  "Judicial and Other Legal Professionals nec",
  "Landscape Gardener",
  "Librarian",
  "Corporate General Manager",
  "Corporate Services Manager",
  "Procurement Manager",
  "Production Manager (Forestry)",
  "Production Manager (Manufacturing)",
  "Production Manager (Mining)",
  "Regional Education Manager",
  "Research and Development Manager",
  "Specialist Managers nec",
  "Supply and Distribution Manager",
  "Chief Executive or Managing Director",
  "Manufacturer",
  "Market Research Analyst",
  "Marketing Specialist",
  "Small Engine Mechanic",
  "Medical Laboratory Scientist",
  "Medical Radiation Therapist",
  "Emergency Service Worker",
  "Medical Practitioners nec",
  "Surgeon (General)",
  "Medical Diagnostic Radiographer",
  "Medical Technicians nec",
  "Diversional Therapist",
  "Sonographer",
  "Metal Fabricator",
  "Metal Fitters and Machinists nec",
  "Meteorologist",
  "Music Professionals nec",
  "Nursing Clinical Director",
  "Occupational Health and Safety Adviser",
  "Power Generation Plant Operator",
  "Optometrist",
  "Orthoptist",
  "Other Spatial Scientist",
  "Photographer",
  "Specialist Physicians nec",
  "Physicist",
  "Primary Products Inspectors nec",
  "Practice Managers nec",
  "Primary Health Organisation Manager",
  "Information and Organisation Professionals nec",
  "Marine Transport Professionals nec",
  "Natural and Physical Science Professionals nec",
  "Sportspersons nec",
  "Psychiatrist",
  "Psychologists nec",
  "Public Relations Manager",
  "Public Relations Professional",
  "Records Manager",
  "Real Estate Representative",
  "Conveyancer",
  "Registered Nurses nec",
  "Sales and Marketing Manager",
  "Environmental Scientists nec",
  "Life Scientists nec",
  "Social Professionals nec",
  "Science Technicians nec",
  "Other Sports Coach or Instructor",
  "Other Sports Official",
  "Sports Administrator",
  "Kaiako Kohanga Reo (Maori Language Nest Teacher)",
  "Kaiako Kura Kaupapa Maori (Maori-medium Primary School Teacher)",
  "Middle School Teacher (Aus) Intermediate School Teacher (NZ)",
  "Pouako Kura Kaupapa Maori (Maori-medium Primary School Senior Teacher)",
  "Primary School Teacher",
  "Private Tutors and Teachers nec",
  "School Principal",
  "Secondary School Teacher",
  "Special Education Teachers nec",
  "University Lecturer",
  "Technical Sales Representatives nec",
  "Technicians and Trades Workers nec",
  "Building and Engineering Technicians nec",
  "Hydrogeologist",
  "Nuclear Medicine Technologist",
  "Telecommunications Technician",
  "Actors, Dancers and Other Entertainers nec",
  "Film, Television, Radio and Stage Directors nec",
  "Performing Arts Technicians nec",
  "Visual Arts and Crafts Professionals nec",
  "Importer or Exporter",
  "Wholesaler",
  "Training and Development Professional",
  "Travel Attendants nec",
  "Valuer",
  "Vehicle Body Builder",
  "Veterinary Nurse",
  "Veterinary Nurse",
  "Wall and Floor Tiler",
  "Software and Applications Programmers nec",
  "Wine Maker",
  "Wool Buyer",
  "Workplace Relations Adviser",
  "Zookeeper",
  "Zoologist",
  "Historian",
  "Leather Goods Maker",
  "Quarantine Officer",
  "Recruitment Consultant",
]
