import React from "react";
import { Grid, Typography } from "@mui/material";
const BillingWrapper = ({ title, children }) => (
  <Grid container spacing={1.5}>
    <Grid item xs={8}>
      <Typography variant="h4" style={{ margin: "0 0 5px 0" }}>
        {title}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      {children}
    </Grid>
  </Grid>
);

export default BillingWrapper;
