export default function parseComment(comment) {
  const mentionRegex = /@\[([^\]]+)\]\(([^)]+)\)/g;
  let parsedSegments = [];
  let lastIndex = 0;
  let match;

  while ((match = mentionRegex.exec(comment)) !== null) {
    if (match.index > lastIndex) {
      parsedSegments.push({
        type: "text",
        text: comment.substring(lastIndex, match.index),
      });
    }

    parsedSegments.push({
      type: "mention",
      display: match[1],
      id: match[2],
    });

    lastIndex = mentionRegex.lastIndex;
  }

  if (lastIndex < comment.length) {
    parsedSegments.push({
      type: "text",
      text: comment.substring(lastIndex),
    });
  }

  return parsedSegments;
}