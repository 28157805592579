import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { approveUser, verifyEmail } from "../../store/slices/userSlice";

export default function VerifyEmail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { key } = useParams();

  useEffect(() => {
    try {
      if (key) {
        dispatch(verifyEmail(key)).then((res) => {
          if (res.payload.data && res.payload.data.data && res.payload.data.data.user && res.payload.data.data.user._id) {
            const userId = res.payload.data.data.user._id
            dispatch(approveUser(res.payload.data.data.user._id))
            if (res.payload.data.data.user.userType === "owner") {
              localStorage.removeItem("tokenExpired");
              navigate(`/invite/${userId}`);
              return;
            }
            navigate(`/login`);
          } else {
            navigate('/login');
          }
        });
      }
    } catch (error) {
      navigate('/');
    }
  }, [key]);

  return (
    <>
    </>
  )

}