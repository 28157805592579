import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItemButton,
  ListItemText,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HistoryIcon from "@mui/icons-material/History";
import moment from "moment";
import { useSnackbar } from "notistack";
import { ERRORS_MESSAGE, SUCCESS_MESSAGE } from "../../../constants";
import { getDefaultTemplate, getTemplatesById, templatesSelector } from "../../../store/slices/templatesSlice";

const VersionHistoryModal = ({ templateId, open, onClose, versionHistory }) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar();
  const { isFetchingTemplate, currentlyEditingTemplate } = useSelector(templatesSelector)

  const handle = {
    getVersionFromTemplateId: (versionId) => {
      dispatch(
        getTemplatesById({
          templateId,
          templateVersionId: versionId,
        }),
      ).then((res) => {
        if (res?.payload?.data?.data) {
          enqueueSnackbar(res?.payload?.data?.status_message || SUCCESS_MESSAGE.fetchMsg, {
            variant: "success",
            autoHideDuration: 3000,
          });
          // Close modal after value set
          onClose()
        } else {
          enqueueSnackbar(res?.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      });

    },
    restoreToDefaultTemplate: () => {
      dispatch(
        getDefaultTemplate({
          messageType: currentlyEditingTemplate.messageType,
          templateType: currentlyEditingTemplate.templateType,
        }),
      ).then((res) => {
        if (res?.payload?.data?.data) {
          enqueueSnackbar(res?.payload?.data?.status_message || SUCCESS_MESSAGE.fetchMsg, {
            variant: "success",
            autoHideDuration: 3000,
          });
          // Close modal after value set
          onClose()
        } else {
          enqueueSnackbar(res?.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
            variant: "error",
            autoHideDuration: 3000,
          });
        }

      });
    },
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      fullScreen
      PaperProps={{
        sx: {
          position: "fixed",
          top: 0,
          right: 0,
          margin: 0,
          height: "100vh",
          width: "300px",
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Version History
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 0, display: "flex", flexDirection: "column", height: "100%" }} dividers>
        {/* {versionHistory?.length > 0
          && <Typography sx={{ ml: 2, mt: 1 }}>
            {versionHistory?.length} available versions
          </Typography>
        } */}
        {versionHistory?.length > 0 ?
          (<List sx={{ flexGrow: 1, overflowY: "auto", padding: 2 }}>
            {versionHistory.map((version, index) => (
              <ListItemButton
                sx={{
                  border: "1px solid #EAEAEA",
                  borderRadius: "6px", mb: 1.5,
                  alignItems: 'baseline'
                }}
                key={index}
              >
                <ListItemText
                  // primary={version.date}
                  // secondary={version.time}
                  primary={moment(version.LastModified).format('MMMM Do YYYY, h:mm a')}
                  secondary={version?.IsLatest && "Current"}
                  primaryTypographyProps={{
                    fontSize: "12px",
                    // fontWeight: "bold",
                  }}
                />
                {!version?.IsLatest && (
                  <Button
                    variant="text"
                    color="primary"
                    size="small"
                    sx={{ mb: 1, width: "20%", mx: "auto", textTransform: "none" }}
                    disabled={isFetchingTemplate}
                    // onClick={onReset}
                    onClick={() => {
                      handle.getVersionFromTemplateId(version?.VersionId)
                    }}
                  >
                    Use
                  </Button>
                )}
              </ListItemButton>
            ))}
          </List>
          ) : (
            <Typography sx={{ margin: '20px 16px', textAlign: 'center' }}>
              No versions found
            </Typography>
          )}
        <Button
          variant="contained"
          color="primary"
          sx={{ mb: 1, width: "90%", mx: "auto", textTransform: "none" }}
          disabled={isFetchingTemplate}
          onClick={handle.restoreToDefaultTemplate}
        >
          <HistoryIcon style={{ fontSize: "16px", marginRight: "8px" }} /> Reset to default
        </Button>
      </DialogContent>
    </Dialog>
  )
};

export default VersionHistoryModal;
