import base from "./base";
import { employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { FULL_TIME, PART_TIME, CASUAL, CONTRACT, SELF_EMPLOYED } = employmentTypes;

const { loanAmountBetween, employmentType, timeOfEmploymentGreaterThan } = criteria;

function selfEmployed(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, employmentType([SELF_EMPLOYED, CONTRACT]), timeOfEmploymentGreaterThan(12)],
  }));
}

function permanentEmployed(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, employmentType([FULL_TIME, PART_TIME]), timeOfEmploymentGreaterThan(6)],
  }));
}

function temporaryEmployed(l) {
  return l.map((product) => ({
    ...product,
    criteria: [...product.criteria, employmentType([CASUAL]), timeOfEmploymentGreaterThan(6)],
  }));
}

const data = {
  date: "13 Jul 2023",
  lender: "WISR",
  type: "personal",
  logoURL: "https://assets.financeable.com.au/lender-logos%2FWisr-logo.png",
  actions: ["upload"],
  lenderCredentials: [
    {
      label: "Email",
      attribute: "email",
    },
    {
      label: "User Name",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
    {
      label: "User ID",
      attribute: "brokerId",
    },
    {
      label: "Api Key",
      attribute: "apiKey",
    },
  ],
  requiredFields: {
    customer: [
      "firstName",
      "lastName",
      "dateOfBirth",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      // "addresses[0].street",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "IDNumber",
      "IDCardNumber",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "employers.[0].entityName",
      "employers.[0].timeOfEmployment",
      "employers.[0].employmentType",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Establishment fee",
      value: 550,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Origination fee",
      value: 2000,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(20000, 65000)],
    },
    {
      name: "Origination fee",
      value: 990,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(5000, 19999)],
    },
  ],
  brokerage: [],
  loading: [],
  info: [
    `Resident / PR`,
    `18+years`,
    `No defaults & paid defaults - <$1k telco & utility`,
    `Credit repayment history`,
    `No current or discharged bankrupts`,
    `Repayment history check`,
  ],
  productTiers: [...selfEmployed(base), ...permanentEmployed(base), ...temporaryEmployed(base)],
};

export default data;
