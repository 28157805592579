import { typeOfSaleList, employmentTypes } from "../../../constants";
import criteria from "../../../utils/criteria";

const { SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL } = employmentTypes;

const {
  typeOfSale,
  assetValueBetween,
  assetType,
  ageOfAssetBetween,
  ageOfAssetLessThan,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
  creditScoreBetween,
} = criteria;

const base = [
  {
    name: "Auto",
    rate: 7.87,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 80000), ageOfAssetLessThan(12), creditScoreGreaterThan(870)],
  },
  {
    name: "Auto",
    rate: 8.04,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 80000), ageOfAssetBetween(12, 59), creditScoreGreaterThan(870)],
  },
  {
    name: "Auto",
    rate: 8.22,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 80000), ageOfAssetBetween(60, 119), creditScoreGreaterThan(870)],
  },
  {
    name: "Auto",
    rate: 8.39,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 80000), ageOfAssetBetween(119, 180), creditScoreGreaterThan(870)],
  },

  {
    name: "Auto <12 months",
    rate: 8.59,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 80000), ageOfAssetLessThan(12), creditScoreBetween(701, 870)],
  },
  {
    name: "Auto <12 months",
    rate: 8.78,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 80000), ageOfAssetBetween(12, 59), creditScoreBetween(701, 870)],
  },
  {
    name: "Auto <12 months",
    rate: 8.97,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 80000), ageOfAssetBetween(60, 119), creditScoreBetween(701, 870)],
  },
  {
    name: "Auto <12 months",
    rate: 9.16,
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 80000), ageOfAssetBetween(119, 180), creditScoreBetween(701, 870)],
  },

  {
    name: "Auto",
    rate: [10.12, 11.92],
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 50000), ageOfAssetLessThan(12), creditScoreBetween(601, 700)],
  },
  {
    name: "Auto",
    rate: [10.34, 12.18],
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 50000), ageOfAssetBetween(12, 59), creditScoreBetween(601, 700)],
  },
  {
    name: "Auto",
    rate: [10.57, 12.45],
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 50000), ageOfAssetBetween(60, 119), creditScoreBetween(601, 700)],
  },
  {
    name: "Auto",
    rate: [10.79, 12.71],
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 50000), ageOfAssetBetween(119, 180), creditScoreBetween(601, 700)],
  },

  {
    name: "Auto",
    rate: [12.64, 15.52],
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 35000), ageOfAssetLessThan(12), creditScoreBetween(501, 600)],
  },
  {
    name: "Auto",
    rate: [12.92, 15.86],
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 35000), ageOfAssetBetween(12, 59), creditScoreBetween(501, 600)],
  },
  {
    name: "Auto",
    rate: [13.2, 16.21],
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 35000), ageOfAssetBetween(60, 119), creditScoreBetween(501, 600)],
  },
  {
    name: "Auto",
    rate: [13.48, 16.55],
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 35000), ageOfAssetBetween(119, 180), creditScoreBetween(501, 600)],
  },

  {
    name: "Auto",
    rate: [16.19, 17.99],
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 30000), ageOfAssetLessThan(12), creditScoreBetween(300, 500)],
  },
  {
    name: "Auto",
    rate: [16.55, 18.39],
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 30000), ageOfAssetBetween(12, 59), creditScoreBetween(300, 500)],
  },
  {
    name: "Auto",
    rate: [16.91, 18.79],
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 30000), ageOfAssetBetween(60, 119), creditScoreBetween(300, 500)],
  },
  {
    name: "Auto",
    rate: [17.27, 19.19],
    productType: "Standard",
    criteria: [loanAmountBetween(5000, 30000), ageOfAssetBetween(119, 180), creditScoreBetween(300, 500)],
  },
];

const baseMap = base.map((product) => {
  product.criteria.push(ageOfAssetAtEndLessThan(20), assetType(["Motor Vehicle", "Electric Motor Vehicle"]));

  return product;
});

const selfEmployed = baseMap.map((product) => ({
  ...product,
  criteria: [...product.criteria, employmentType([SELF_EMPLOYED]), timeOfEmploymentGreaterThan(18)],
}));

const fullTime = baseMap.map((product) => ({
  ...product,
  criteria: [...product.criteria, employmentType([FULL_TIME]), timeOfEmploymentGreaterThan(3)],
}));

const partTime = baseMap.map((product) => ({
  ...product,
  criteria: [...product.criteria, employmentType([PART_TIME]), timeOfEmploymentGreaterThan(6)],
}));

const casual = baseMap.map((product) => ({
  ...product,
  criteria: [...product.criteria, employmentType([CASUAL]), timeOfEmploymentGreaterThan(12)],
}));

const contract = baseMap.map((product) => ({
  ...product,
  criteria: [...product.criteria, employmentType([CONTRACT])],
}));

const moneyplace = {
  date: "1 Jul 2023",
  lender: "MoneyPlace",
  type: "consumer",
  logoURL: "https://assets.financeable.com.au/lender-logos%2FMoneyPlace.gif",
  actions: ["upload"],
  lenderCredentials: [
    {
      label: "Email",
      attribute: "email",
    },
    {
      label: "User Name",
      attribute: "username",
    },
    {
      label: "Password",
      attribute: "password",
    },
    {
      label: "Merchant ID",
      attribute: "organisationId",
    },
    {
      label: "User ID",
      attribute: "brokerId",
    },
    {
      label: "Api Key",
      attribute: "apiKey",
    },
  ],
  requiredFields: {
    asset: [
      "assetValue",
      "assetType",
      "typeOfSale",
      "ageOfAsset",
      "condition",
      "glassesMake",
      "glassesModel",
      // "Make", 
      // "Model",
    ],
    customer: [
      "customerTitle",
      "firstName",
      "lastName",
      "dateOfBirth",
      "IDNumber",
      "IDCardNumber",
      "IDExpiryDate",
      "phoneNumbers?.slice(-1)[0].number",
      "emails?.slice(-1)[0].address",
      "addresses[0].fullAddress",
      "addresses[0].city",
      "addresses[0].state",
      "addresses[0].postCode",
      "addresses[0].monthsAt",
      "addresses[0].yearsAt",
      "customerProfile.livingArrangements",
      "customerProfile.numberOfDependants",
      "customerProfile.residencyStatus",
      "customerProfile.maritalStatus",
      "customerAssets.[0].assetType",
      "customerAssets.[0].value",
      "customerAssets.[0].description",
      "liabilities.[0].liabilitiesType",
      "liabilities.[0].outstandingAmount",
      "liabilities.[0].limit",
      "liabilities.[0].financier",
      "liabilities.[0].repaymentFrequency",
      "liabilities.[0].repaymentAmount",
      "liabilities.[0].description",
      "income.[0].incomeType",
      "income.[0].amount",
      "income.[0].frequency",
      "expenses.[0].expensesType",
      "expenses.[0].amount",
      "expenses.[0].frequency",
      "employers.[0].entityName",
      "employers.[0].status",
      "employers.[0].timeOfEmployment",
      "employers.[0].occupation",
      "employers.[0].manager",
      "employers.[0].phoneNumber",
      "employers.[0].industry",
      "employers.[0].address.fullAddress",
      "employers.[0].address.city",
      "employers.[0].address.state",
    ],
    loanDetails: [
      // "rate"
      // "term",
      // "balloon",
      // "deposit",
      // "tradeIn",
      // "payout",
      // "rate",
      // "rateAdjustment",
    ],
    agreement: ["introducerDeclarationAgree"],
  },
  fees: [
    {
      name: "Establishment fee",
      value: 975,
      capitalised: true,
      frequency: "upfront",
    },

    {
      name: "Private sale fee",
      value: 575,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.PRIVATE_SALE)],
    },

    {
      name: "Origination fee",
      value: 990,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(5000, 20000)],
    },
    {
      name: "Origination fee",
      value: 1690,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(20000, 40000)],
    },
    {
      name: "Origination fee",
      value: 1990,
      capitalised: true,
      frequency: "upfront",
      criteria: [loanAmountBetween(40000, 80000)],
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 4,
      valueType: "percentage",
      criteria: [assetValueBetween(0, 1000000)],
    },
  ],
  loading: [],
  info: [
    `Multiple loans allowed up to $200,000 max loan size $80,000.`,
    `Current bankrupts are not accepted.`,
    `Ex-bankrupts must be discharged > 2years to be considered.`,
  ],
  productTiers: [...fullTime, ...partTime, ...casual, ...selfEmployed, ...contract],
};
export default moneyplace;
