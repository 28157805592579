import React from "react";
import { useDispatch } from "react-redux";
import { FormControl, MenuItem, Select } from "@mui/material";
import _ from "lodash";
import { useStyles } from "./UserList";
import { updateUserMember } from "../../store/slices/userSlice";
import { STATUS_CODE, userStatusList } from "../../constants";

export default function StatusCell({ rowInfo, rows, setRows }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleUpdateUserStatus = (id, value) => {
    dispatch(
      updateUserMember({
        userId: id,
        status: value === "Live" ? _.toLower(value) : value,
      }),
    ).then(({ payload }) => {
      if (payload?.status_code === STATUS_CODE.success) {
        const updatedRows = rows?.map((row) => (row?.id === id ? { ...row, status: value } : row));
        setRows(updatedRows);
      }
    });
  };

  return (
    <FormControl fullWidth>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={rowInfo?.status || ""}
        className={classes.borderRemove}
        onChange={(event) => handleUpdateUserStatus(rowInfo?.id, event.target.value)}
      >
        {userStatusList?.map((status) => (
          <MenuItem value={status}>{status}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
